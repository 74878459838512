import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import history from 'appHistory';
import { setCurrentProject, setDeployments } from 'actions/currentProject.actions';
import { addSnackbar } from 'actions/snackbars.actions';
import { deployScenario } from 'actions/scenario.actions';
import { bindActionCreators } from 'redux';
import * as ChannelsActions from 'actions/channels.actions';
import { getChannelsList, setChannelToEdit, setChannelToCreation, dropEditableChannel } from 'actions/channels.actions';
import { loadProjects } from 'actions/botprojects.actions';
import localize from 'localization';

import ChannelEditFormModal from './ChannelEditFormModal';
import ChannelsAddCardsList from './ChannelsAddCardsList';
import ChannelItem from './ChannelItem';
import { Button } from 'altreidsds';
import { GA, isDev } from 'pipes/pureFunctions';
import { Spinner } from 'components';
import { DeleteChannelModal } from './DeleteChannelModal';
import * as OperatorChannelsActions from 'actions/operatorChannels.actions';
import { cloneDeep } from 'lodash';
import { isEuroInstance } from '../../../isAccessFunction';

import classNames from 'classnames';
import classes from './Channels.module.scss';
import { pseudoOperatorChannels } from '../../../constants/channelTypes';
import { updateChannels } from '../../../actions/botprojects.actions';
import { channelsaddingLocalization } from './localization/channelsadding.loc';
import { RCurrentProject, RCurrentUser } from 'types';
import { BotType } from '@just-ai/api/dist/generated/Botadmin';
import { AppDispatch, RootState } from 'storeTypes';
import { AxiosError } from 'axios';
import cn from 'classnames';

localize.addTranslations(channelsaddingLocalization);

const { translate } = localize;

type ChannelsProps = {
  isInWizard: boolean;
  actions: {
    dropEditableChannel: typeof dropEditableChannel;
    setChannelToCreation: typeof setChannelToCreation;
    setChannelToEdit: typeof setChannelToEdit;
    getChannelsList: typeof getChannelsList;
    loadProjects: typeof loadProjects;
    setCurrentProject: typeof setCurrentProject;
    addSnackbar: typeof addSnackbar;
    deployScenario: typeof deployScenario;
    setDeployments: typeof setDeployments;
    fetchWebimWebhook: typeof OperatorChannelsActions.fetchWebimWebhook;
    updateChannels: typeof updateChannels;
  };
  operatorActions: any;
  currentUser: RCurrentUser['currentUser'];
  currentProject: RCurrentProject;
  language: string;
  appConfig: {
    zenflow: any;
    metaSettings: any;
  };

  botList: BotType[];
  currentBot: any;
  deployments: any;
  projectShortName: string;

  errors: any;
  fetching: any;
  channels: any[];
  operatorChannels: any[];

  voiceChannels: string[];
  voiceFetching: any;

  channelDetail: {
    errors: any[];
    fetching: any;
    editableChannel: any;
  };
} & RouteComponentProps<{ projectShortName: string }>;
type ChannelsState = {
  openEditModal: boolean;
  openRemoveModal: boolean;
  removingChannelId: number | null;
  isOperatorChannelToRemove: boolean;
  okDisabled: boolean;
};
class Channels extends Component<ChannelsProps, ChannelsState> {
  state = {
    openEditModal: false,
    openRemoveModal: false,
    removingChannelId: null,
    isOperatorChannelToRemove: false,
    okDisabled: false,
  };

  asyncSetState = (state: Partial<ChannelsState>) => {
    const setState = async () => {
      // @ts-ignore
      await this.setState({ ...state });
    };
    return setState();
  };

  componentWillMount() {
    const { currentBot, currentProject } = this.props;
    const { setCurrentProject } = this.props.actions;
    const { projectShortName } = this.props.match.params;

    if (!currentBot) return history.push('/404');

    if (Boolean(projectShortName)) {
      this.fetchChannels();
    }

    if (!Boolean(currentProject)) {
      setCurrentProject(projectShortName, currentBot);
    }

    const { editableChannel } = this.props.channelDetail;
    const { accessToken, id } = editableChannel;

    if (Boolean(accessToken) && Boolean(id)) {
      this.saveEditableChannel(editableChannel);
    } else if (Boolean(accessToken)) {
      this.createChannel(editableChannel);
    }
  }

  componentDidUpdate(prevProps: ChannelsProps, prevState: ChannelsState) {
    const { currentBot, actions } = this.props;
    if (prevProps.currentBot.shortName !== currentBot.shortName && Boolean(currentBot.shortName)) {
      const { getChannelsList } = actions;
      getChannelsList(currentBot.shortName);
      this.props.operatorActions.fetchChannels(currentBot.shortName);
    }
  }

  componentWillUnmount() {
    const { channels, fetching, voiceFetching, voiceChannels, operatorChannels, projectShortName } = this.props;
    if (fetching || voiceFetching) return;
    this.props.actions.updateChannels({
      shortName: projectShortName,
      botConfigs: [...channels, ...voiceChannels, ...operatorChannels],
    });
  }

  handleFacebookTypeChannel() {
    const { channels } = this.props;
    const { removingChannelId } = this.state;
    const channelsWithFBAuth = ['FACEBOOK', 'INSTAGRAM'];
    const removingChannel = channels.find(({ id }) => id === removingChannelId);

    if (!channelsWithFBAuth.includes(removingChannel?.channelType) || !removingChannel) {
      return;
    }
    const incomingChannels = channels.filter(channel => isDev() || !channel.autoCreated);

    const isLastFBTypeChannel = incomingChannels.filter(ch => channelsWithFBAuth.includes(ch.channelType)).length === 1;
    if (isLastFBTypeChannel) {
      Object.keys(sessionStorage).forEach(key => {
        const facebookStorageKey = 'fbssls';
        if (key.startsWith(facebookStorageKey)) {
          sessionStorage.removeItem(key);
        }
      });
    }
  }

  removeChannel = async () => {
    const { removingChannelId: id, isOperatorChannelToRemove } = this.state;

    this.setState({ okDisabled: true });
    this.handleFacebookTypeChannel();

    const { removeChannel } = isOperatorChannelToRemove ? this.props.operatorActions : this.props.actions;
    await removeChannel(id);

    this.setState({ okDisabled: false });
    this.toggleRemoveModal(id);
  };

  fetchChannels = () => {
    const {
      actions: { getChannelsList },
      operatorActions,
    } = this.props;
    const { projectShortName } = this.props.match.params;
    getChannelsList(projectShortName);
    operatorActions.fetchChannels(projectShortName);
  };

  saveEditableChannel = async (channelData: any, isOperatorChannel = false) => {
    const { saveEditableChannel, publishChannel } = isOperatorChannel ? this.props.operatorActions : this.props.actions;

    try {
      const payload = await saveEditableChannel(channelData.id, channelData);
      this.closeChannelEditModal();
      !isOperatorChannel && publishChannel(payload.value.data.id);
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 400 && channelData.channelType.toLowerCase() === 'alexa') {
          this.checkErrorType(e.response.data);
        }
      }
      throw e;
    }
  };

  createChannel = async (channelData: any, isOperatorChannel = false) => {
    const { projectShortName } = this.props.match.params;
    const { createChannel, publishChannel } = isOperatorChannel ? this.props.operatorActions : this.props.actions;

    try {
      const payload = await createChannel(projectShortName, channelData);
      !isOperatorChannel && GA('GAEvent', 'channels_connect', 'success', channelData.channelType);
      !isOperatorChannel &&
        this.props.isInWizard &&
        GA(undefined, 'Channel_Connected', undefined, channelData.channelType);
      this.closeChannelEditModal();
      !isOperatorChannel && publishChannel(payload.value.data.id);
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 400 && channelData.channelType.toLowerCase() === 'alexa') {
          this.checkErrorType(e.response.data);
        }
      }
      throw e;
    }
  };

  checkErrorType = (errType: any) => {
    const { addSnackbar } = this.props.actions;

    switch (errType) {
      case 'alexa.skillId.validation.invalid':
      case 'alexa.skillId.validation.forbidden': {
        addSnackbar(
          <div>
            <span style={{ whiteSpace: 'pre-line' }}>{translate('Invalid skill ID')}</span>
          </div>
        );
        break;
      }
      case 'amazon.dev.account.required': {
        addSnackbar(
          <div>
            <span
              style={{
                whiteSpace: 'pre-line',
              }}
            >
              {translate('You need dev account to ALEXA')}
            </span>{' '}
            <Button
              component='a'
              color='primary'
              target='_blank'
              href='//developer.amazon.com/login'
              variant='contained'
            >
              {translate('Create an account')}
            </Button>
          </div>
        );
        break;
      }
      default: {
        addSnackbar(
          <div>
            <span style={{ whiteSpace: 'pre-line' }}>{errType.errors?.join(' ') || errType}</span>{' '}
          </div>
        );
      }
    }
  };

  setChannelToEdit = (id: string) => {
    const { setChannelToEdit } = this.props.actions;
    let channel = [...this.props.channels, ...this.props.operatorChannels].find(obj => obj.id === id);
    if (channel) {
      setChannelToEdit(cloneDeep(channel));
      this.openChannelEditModal();
    }
  };

  setChannelToCreation = (channelType: string) => {
    const { setChannelToCreation } = this.props.actions;
    setChannelToCreation(channelType);
    GA('GAEvent', 'channels_connect', 'opened', channelType);
    this.openChannelEditModal();
  };

  openChannelEditModal = () => this.setState({ openEditModal: true });

  closeChannelEditModal = () => {
    if (this.state.openEditModal) {
      this.props.actions.dropEditableChannel();
      this.asyncSetState({
        openEditModal: false,
      }).then(() => {
        const focusedEl = document.activeElement as any;
        if (Boolean(focusedEl)) {
          focusedEl.blur();
        }
      });
    }
  };

  toggleRemoveModal = (id: number | null, isOperatorChannelToRemove = false) => {
    let removingChannelId = !this.state.openRemoveModal ? id : null;
    this.asyncSetState({
      openRemoveModal: !this.state.openRemoveModal,
      removingChannelId: removingChannelId,
      isOperatorChannelToRemove,
    }).then(() => {
      const focusedEl = document.activeElement as any;
      if (Boolean(focusedEl)) {
        focusedEl.blur();
      }
    });
  };

  render() {
    const {
      actions,
      match,
      deployments,
      channels,
      operatorChannels: realOperatorChannels,
      currentBot,
      fetching,
      voiceChannels,
      appConfig,
    } = this.props;
    const { openEditModal } = this.state;
    const currentUserTariff = this.props.currentUser?.tariff;

    const incomingChannels = channels.filter(channel => isDev() || !channel.autoCreated);
    let fakeOperatorChannels = channels.filter(({ channelType }) => pseudoOperatorChannels.includes(channelType));
    fakeOperatorChannels = Object.values(
      fakeOperatorChannels.reduce((result, channel) => {
        result[channel.channelType] = channel;
        return result;
      }, {})
    );

    const operatorChannels = [...realOperatorChannels, ...fakeOperatorChannels];

    const isOperatorChannelsExist = operatorChannels.length > 0;
    const incomingChannelsExist = incomingChannels.length > 0;

    return (
      <>
        <div key='settings' className={cn(classes.container, 'channels-page-wrapper')}>
          <h1 className={classes.title}>
            {this.props.isInWizard ? translate('ChannelsStep:Title') : translate('ChannelsAdding:Channels')}
          </h1>

          {this.props.isInWizard && !incomingChannelsExist ? null : (
            <div className={classes.createdChannels}>
              <h2 className={classes.subtitle}>{translate('ChannelsAdding: Connections')}</h2>
              {incomingChannelsExist ? (
                incomingChannels.map((channel, index) => (
                  <ChannelItem
                    {...actions}
                    key={`channel_${index}`}
                    addSnackbar={actions.addSnackbar}
                    appConfig={appConfig}
                    blocked={currentUserTariff?.blocked}
                    currentUserTariffUniqueName={currentUserTariff?.tariffUniqueName}
                    channel={channel}
                    channels={channels}
                    isInWizard={this.props.isInWizard}
                    currentBot={currentBot}
                    deployments={deployments}
                    projectShortName={match.params.projectShortName}
                    setChannelToEdit={this.setChannelToEdit}
                    openRemoveModal={this.toggleRemoveModal}
                  />
                ))
              ) : (
                <>
                  {fetching && <Spinner />}
                  <div
                    className={classNames(
                      classes.noIncomeChannels,
                      isOperatorChannelsExist && classes.noIncomeChannelsWithOperator
                    )}
                  >
                    <span>{translate('ChannelsAdding:Connection:NoOperator')}</span>
                  </div>
                </>
              )}
            </div>
          )}

          {this.props.isInWizard && !isOperatorChannelsExist ? null : (
            <div className={classes.operators}>
              <h2 className={classes.subtitle}>{translate('ChannelsAdding: Operator')}</h2>
              {isOperatorChannelsExist ? (
                operatorChannels.map((channel, index) => (
                  <ChannelItem
                    {...actions}
                    key={`channel_${index}`}
                    addSnackbar={actions.addSnackbar}
                    appConfig={appConfig}
                    blocked={currentUserTariff?.blocked}
                    currentUserTariffUniqueName={currentUserTariff?.tariffUniqueName}
                    channel={channel}
                    channels={channels}
                    currentBot={currentBot}
                    deployments={deployments}
                    openRemoveModal={(id: number) => this.toggleRemoveModal(id, true)}
                    projectShortName={match.params.projectShortName}
                    setChannelToEdit={this.setChannelToEdit}
                    isOperatorChannel
                  />
                ))
              ) : (
                <>
                  {fetching && <Spinner />}
                  <div className={classes.noOperatorChannels}>
                    <span>
                      {translate(
                        isEuroInstance()
                          ? 'ChannelsAdding: Add webim, livetex and etc to unblock operator channels euroInstance'
                          : 'ChannelsAdding: Add webim, livetex and etc to unblock operator channels'
                      )}
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
          <div className={classes.addNewChannelLabel}>
            <h2 className={classes.subtitle}>{translate('ChannelsAdding:AddNewConnections')}</h2>
            {['business_jivo', 'skillmaster'].includes(currentUserTariff?.tariffUniqueName) && (
              <span
                dangerouslySetInnerHTML={{
                  __html: translate(
                    currentUserTariff?.tariffUniqueName === 'business_jivo'
                      ? 'ChannelsAdding:OnlyTariff:Jivo'
                      : currentUserTariff?.tariffUniqueName === 'skillmaster'
                        ? 'ChannelsAdding:OnlyTariff:SkillMaster'
                        : '',
                    '/prices'
                  ),
                }}
              />
            )}
          </div>
          <ChannelsAddCardsList
            onAddBtnClick={this.setChannelToCreation}
            loadProjects={this.props.actions.loadProjects}
            channelsCount={channels.length + voiceChannels.length}
            extraChannelsEnabled={false}
            currentUserTariff={currentUserTariff}
            appConfig={appConfig}
            isOperatorChannelsExist={isOperatorChannelsExist}
            currentBot={currentBot}
            isInWizard={this.props.isInWizard}
            accountId={this.props.currentUser?.account?.id}
          />
        </div>
        <ChannelEditFormModal
          // @ts-ignore
          actions={{
            ...this.props.actions,
            createChannel: this.createChannel,
            saveEditableChannel: this.saveEditableChannel,
            openChannelEditModal: this.openChannelEditModal,
          }}
          key='channel-edit-form-modal'
          appConfig={appConfig}
          open={openEditModal}
          onClose={this.closeChannelEditModal}
        />
        <DeleteChannelModal
          open={this.state.openRemoveModal}
          toggleRemoveModal={this.toggleRemoveModal}
          okDisabled={this.state.okDisabled}
          removeChannel={this.removeChannel}
        />
      </>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    currentUser: state.CurrentUserReducer.currentUser,
    language: state.CurrentUserReducer.language,
    appConfig: {
      zenflow: state.AppConfigReducer.zenflow,
      metaSettings: state.AppConfigReducer.metaSettings,
    },

    botList: state.BotProjectsReducer.botList,
    currentBot: state.CurrentProjectsReducer.currentBot,
    deployments: state.CurrentProjectsReducer.deployments,
    projectShortName: state.CurrentProjectsReducer.currentProject,

    // @ts-ignore
    operatorChannels: state.OperatorChannelsReducer.channels,
    // @ts-ignore
    errors: state.ChannelsReducer.errors,
    // @ts-ignore
    fetching: state.ChannelsReducer.fetching,
    // @ts-ignore
    channels: state.ChannelsReducer.channels,
    // @ts-ignore
    voiceChannels: state.ChannelsReducer.voiceChannels,
    // @ts-ignore
    voiceFetching: state.ChannelsReducer.voiceFetching,

    channelDetail: {
      errors: state.ChannelDetailReducer.errors,
      fetching: state.ChannelDetailReducer.fetching,
      editableChannel: state.ChannelDetailReducer.editableChannel,
    },
  };
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  actions: bindActionCreators(
    {
      ...ChannelsActions,
      loadProjects: loadProjects,
      setCurrentProject: setCurrentProject,
      addSnackbar: addSnackbar,
      deployScenario: deployScenario,
      setDeployments: setDeployments,
      fetchWebimWebhook: OperatorChannelsActions.fetchWebimWebhook,
      updateChannels: updateChannels,
    },
    dispatch
  ),
  operatorActions: bindActionCreators({ ...OperatorChannelsActions }, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Channels));

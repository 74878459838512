export const commonLocalization = {
  Continue: {
    eng: 'Continue',
    ru: 'Продолжить',
    pt: 'Continuar',
    cn: '继续',
  },
  'Cancel Bot Create': {
    eng: 'Cancel',
    ru: 'Отменить',
    pt: 'Cancelar',
    cn: '取消',
  },
  'Cancel delete bot': {
    eng: 'Cancel',
    ru: 'Нет, не удалять',
    pt: 'Cancelar',
  },
  'Cancel Subscribe': {
    eng: 'Cancel subscription',
    ru: 'Отменить подписку',
    pt: 'Cancelar assinatura',
  },
  'Cancel Bot Copy': {
    eng: 'Cancel',
    ru: 'Отменить',
    pt: 'Cancelar',
    cn: '取消',
  },
  'Cancel change tariff': {
    eng: 'Cancel',
    ru: 'Отменить',
    pt: 'Cancelar',
    cn: '取消',
  },
  'Cancel delete channel': {
    eng: 'Cancel',
    ru: 'Нет, не удалять',
    pt: 'Cancelar',
  },
  'Bot create': {
    eng: 'Chatbot',
    ru: 'Чат-бот',
    pt: 'Chatbot',
    cn: '创建会话机器人',
  },
  'Bot settings': {
    eng: 'Bot settings',
    ru: 'Настройки бота',
    pt: 'Configurações do bot',
    cn: '设置',
  },
  'Bot token': {
    eng: 'Bot token',
    ru: 'Токен',
    pt: 'Token do bot',
    cn: '会话机器人令牌',
  },
  'Bot name': {
    eng: 'Bot name',
    ru: 'Название бота',
    pt: 'Nome de bot',
    cn: '会话机器人名称',
  },
  'Bot key': {
    eng: 'Bot key',
    ru: 'Ключ',
    pt: 'Chave do bot',
  },
  'Bot answers in catch all tab name': {
    eng: 'In case any problems occur',
    ru: 'Ответы бота при форс-мажорах',
    pt: 'Caso ocorram problemas',
  },
  'Bot last name': {
    eng: 'Bot last name',
    ru: 'Фамилия',
    pt: 'Sobrenome do bot',
  },
  'Bot email': {
    eng: 'Bot email',
    ru: 'Email',
    pt: 'E-mail do bot',
    cn: 'Email',
  },
  'Bot avatar': {
    eng: 'Bot avatar',
    ru: 'Аватар бота',
    pt: 'Avatar do bot',
  },
  'Bot will start': {
    eng: 'Bot will start the campaign',
    ru: 'Бот запустит обзвон',
    pt: 'O bot iniciará a campanha',
  },
  'Bot category': {
    eng: 'Bot category',
    ru: 'Категория бота',
    pt: 'Categoria do bot',
  },
  'bot category modal text': {
    eng: 'Please choose the category. You can also do it later in the ',
    ru: 'Помогите нам узнать категорию вашего бота. Вы можете выбрать категорию сейчас или сделать это позже в ',
    pt: 'Escolha a categoria. Também pode fazê-lo mais tarde nas ',
  },
  'bot category modal link': {
    eng: 'script settings',
    ru: 'настройках бота',
    pt: 'configurações de script',
  },
  'Bot never published': {
    eng: 'No previous bot versions',
    ru: 'Нет версии бота',
    pt: 'Nenhuma versão anterior do bot',
  },
  'Callbot create': {
    eng: 'Voice bot',
    ru: 'Голосовой бот',
    pt: 'Bot de voz',
  },
  'Callbot IVR create': {
    eng: 'Bot for inbound calls',
    ru: 'Бот для входящих звонков',
    pt: 'Bot para chamadas recebidas',
  },
  'Callbot DIALER create': {
    eng: 'Bot for outbound calls',
    ru: 'Бот для исходящих звонков',
    pt: 'Bot para chamadas de saída',
  },
  Templates: {
    eng: 'Bot and call templates',
    ru: 'Шаблоны ботов и обзвонов',
    pt: 'Modelos de chamada e de bot',
    cn: '模板',
  },
  Bots: {
    eng: 'Bots',
    ru: 'Боты',
    pt: 'Bots',
    cn: '会话机器人',
  },
  Newsletters: {
    eng: 'Outbound calls',
    ru: 'Обзвоны',
    pt: 'Chamadas de saída',
    cn: '时事通讯',
  },
  Statistics: {
    eng: 'Statistics',
    ru: 'Статистика',
    pt: 'Estatísticas',
    cn: '数据',
  },
  Help: {
    eng: 'Help',
    ru: 'Помощь',
    pt: 'Ajuda',
    cn: '帮助',
  },
  Search: {
    eng: 'Search',
    ru: 'Поиск',
    pt: 'Pesquisa',
    cn: '搜索',
  },
  'New bot': {
    eng: 'Bot',
    ru: 'Бот',
    pt: 'Bot',
    cn: '会话机器人',
  },
  'New phrase': {
    eng: 'Bot says',
    ru: 'Новая фраза',
    pt: 'O bot diz',
    cn: '新的句子/段落',
  },
  'New tariff start from': {
    eng: 'New plan “$[1]” will start on $[2]',
    ru: 'Новый тариф «$[1]» начнет действовать с $[2]',
    pt: 'O novo plano "$[1]" começará em $[2]',
  },
  'Log in': {
    eng: 'Sign in',
    ru: 'Войти',
    pt: 'Fazer login',
    cn: '登陆',
  },
  'Log into account': {
    eng: 'Log in to account',
    ru: 'Войти в аккаунт',
    pt: 'Fazer login na conta',
  },
  'Log in Just AI': {
    eng: 'Continue',
    ru: 'Продолжить',
    pt: 'Continuar',
  },
  'Log in with number': {
    eng: 'sign in with phone number',
    ru: 'войти по номеру телефона',
    pt: 'fazer login com número de telefone',
  },
  'Log in with email': {
    eng: 'Sign in with email',
    ru: 'Войти по email',
    pt: 'Fazer login com e-mail',
  },
  Password: {
    eng: 'Password',
    ru: 'Пароль',
    pt: 'Senha',
    cn: '密码',
  },
  'E-mail': {
    eng: 'Email',
    ru: 'Email',
    pt: 'E-mail',
    cn: '邮箱',
  },
  Email: {
    eng: 'Email',
    ru: 'Email',
    pt: 'E-mail',
    cn: '邮箱',
  },
  Send: {
    eng: 'Send',
    ru: 'Отправлено',
    pt: 'Enviar',
    cn: '已发送',
  },
  from: {
    eng: 'from',
    ru: 'из',
    pt: 'de',
    cn: '从',
  },
  'from time': {
    eng: 'from',
    ru: 'с',
    pt: 'das',
  },
  'from to': {
    eng: 'from $[1] to $[2]',
    ru: 'с $[1] до $[2]',
    pt: 'das $[1] às $[2]',
  },
  'From $[1], $[2] per month will be automatically charged': {
    eng: 'Starting from $[1] you will be automatically charged $[2] per month.',
    ru: 'С $[1] будет автоматически списываться оплата $[2] в месяц',
    pt: 'A partir de $[1], haverá uma cobrança automática de $[2] por mês.',
  },
  Active: {
    eng: 'Active',
    ru: 'Активные',
    pt: 'Ativados',
    cn: '活跃的',
  },
  Close: {
    eng: 'Close',
    ru: 'Закрыть',
    pt: 'Fechar',
    cn: '关闭',
  },
  Billing: {
    eng: 'Billing',
    ru: 'Биллинг',
    pt: 'Faturamento',
    cn: '计费',
  },
  Ok: {
    eng: 'OK',
    ru: 'Ok',
    pt: 'OK',
    cn: '好的',
  },
  Cancel: {
    eng: 'Cancel',
    ru: 'Отмена',
    pt: 'Cancelar',
    cn: '取消',
  },
  'Save and exit': {
    eng: 'Save and leave',
    ru: 'Сохранить и выйти',
    pt: 'Salvar e sair',
    cn: '保存并退出',
  },
  'Save changes before exit?': {
    eng: 'Save changes before leaving the page?',
    ru: 'Сохранить изменения перед выходом?',
    pt: 'Salvar alterações antes de sair da página?',
    cn: '退出之前需是否需要保存？',
  },
  'Save changes': {
    eng: 'Save changes',
    ru: 'Сохранить изменения',
    pt: 'Salvar alterações',
  },
  'Save password': {
    eng: 'Save password',
    ru: 'Сохранить пароль',
    pt: 'Salvar senha',
  },
  'Save changes before upload?': {
    eng: 'Save the changes before uploading the file?',
    ru: 'Сохранить изменения перед загрузкой?',
    pt: 'Salvar alterações antes de carregar o arquivo?',
  },
  'Save and upload': {
    eng: 'Save and upload',
    ru: 'Сохранить и загрузить',
    pt: 'Salvar e carregar',
  },
  Exit: {
    eng: 'Leave',
    ru: 'Выйти',
    pt: 'Sair',
    cn: '退出',
  },
  View: {
    eng: 'View',
    ru: 'Посмотреть',
    pt: 'Visualizar',
    cn: '查看',
  },
  Clone: {
    eng: 'Clone',
    ru: 'Клонировать',
    pt: 'Clonar',
    cn: '克隆',
  },
  Edit: {
    eng: 'Edit',
    ru: 'Редактировать',
    pt: 'Editar',
    cn: '编辑',
  },
  'Forgot password?': {
    eng: 'Forgot password?',
    ru: 'Забыли пароль?',
    pt: 'Esqueceu a senha?',
    cn: '忘记密码？',
  },
  Register: {
    eng: 'Register',
    ru: 'Зарегистрироваться',
    pt: 'Registrar-se',
    cn: '开始注册',
  },
  Test: {
    eng: 'Test',
    ru: 'Тест',
    pt: 'Teste',
    cn: '测试',
  },
  Run: {
    eng: 'Run',
    ru: 'Запустить',
    pt: 'Executar',
    cn: '运行',
  },
  'Stop test': {
    eng: 'Stop test',
    ru: 'Остановить тест',
    pt: 'Interromper teste',
    cn: '停止测试',
  },
  'Stop scheduler': {
    eng: 'Finish the campaign',
    ru: 'Завершить обзвон',
    pt: 'Finalizar a campanha',
  },
  'Stop mailing': {
    eng: 'Confirm campaign completion',
    ru: 'Подтверждение завершения обзвон',
    pt: 'Confirme a conclusão da campanha',
  },
  'Your Email has been successfully confirmed.': {
    eng: 'Your Email has been successfully confirmed.',
    ru: 'Ваш Email успешно подтвержден.',
    pt: 'Seu e-mail foi confirmado com sucesso.',
    cn: '您的邮箱地址已通过审核。',
  },
  'Your message': {
    eng: 'Your message',
    ru: 'Ваше сообщение',
    pt: 'Sua mensagem',
    cn: '您的信息',
  },
  'Your current email:': {
    eng: 'Your current email:',
    ru: 'Ваш текущий email:',
    pt: 'Seu e-mail atual:',
  },
  'Your subscription': {
    eng: 'Your plan',
    ru: 'Управление подпиской',
    pt: 'Seu plano',
  },
  'Your name': {
    eng: 'Your name',
    ru: 'Ваше имя',
    pt: 'Seu nome',
  },
  'Your subscription plan is $[1]': {
    eng: 'Your subscription plan is $[1]',
    ru: 'Ваш тарифный план $[1]',
    pt: 'Seu plano de assinatura é $[1]',
  },
  'Your plan is not yet paid for': {
    eng: 'Your plan is not yet paid for',
    ru: 'Подписка не оплачена',
    pt: 'Seu plano ainda não foi pago',
  },
  'Your contact number': {
    eng: 'Your contact number',
    ru: 'Ваш контактный телефон',
    pt: 'Seu número de contato',
  },
  'Your referral link': {
    eng: 'Your referral link',
    ru: 'Ваша реферальная ссылка',
    pt: 'Seu link de indicação',
  },
  'Your customers': {
    eng: 'Your customers',
    ru: 'Ваши клиенты',
    pt: 'Seus clientes',
  },
  'Your rate': {
    eng: 'Your plan',
    ru: 'Ваш тариф',
    pt: 'Seu plano',
  },
  'Your have three tries': {
    eng: 'You have <b>$[1]</b> to enter the correct digits',
    ru: 'У вас есть <b>$[1]</b> ввести правильные цифры',
    pt: 'Você tem <b>$[1]</b> para inserir os dígitos corretos',
  },
  'Incorrect token': {
    eng: 'Incorrect token',
    ru: 'Неверный токен',
    pt: 'Token incorreto',
    cn: '令牌不正确',
  },
  'Incorrect image URL': {
    eng: 'Incorrect image URL',
    ru: 'Некорректный URL изображения',
    pt: 'URL incorreto da imagem',
  },
  'Incorrect image file': {
    eng: 'Incorrect image format',
    ru: 'Некорректный формат файла',
    pt: 'Formato incorreto da imagem',
  },
  'Reset password': {
    eng: 'Reset password',
    ru: 'Сброс пароля',
    pt: 'Redefinir senha',
    cn: '重置密码',
  },
  Next: {
    eng: 'Next',
    ru: 'Далее',
    pt: 'Avançar',
    cn: '下一步',
  },
  'Repeat password': {
    eng: 'Repeat password',
    ru: 'Повторите пароль',
    pt: 'Repetir senha',
    cn: '再次输入密码',
  },
  'Repeat payment': {
    eng: 'Repeat payment',
    ru: 'Повторить платеж',
    pt: 'Repetir pagamento',
  },
  'Repeat call label': {
    eng: 'Call again in $[1] sec',
    ru: 'Повторный звонок через $[1] сек ',
    pt: 'Ligue novamente em $[1] s',
  },
  'Repeat call': {
    eng: 'call again',
    ru: 'позвонить еще раз',
    pt: 'ligar novamente',
  },
  'First name': {
    eng: 'Your name',
    ru: 'Имя',
    pt: 'Seu nome',
    cn: '名',
  },
  'First you need to create a script': {
    eng: 'First you need to create a script',
    ru: 'Для начала нужно создать сценарий',
    pt: 'Primeiro você precisa criar um script',
    cn: '首先您需要创建一个脚本',
  },
  'Register name': {
    eng: 'Your full name',
    ru: 'Ваше имя и фамилия',
    pt: 'Seu nome completo',
  },
  'Register and create smart bot': {
    eng: 'Sign up and assemble your bot',
    ru: 'Зарегистрируйтесь и соберите бота',
    pt: 'Inscreva-se e monte seu bot',
  },
  'Register first step': {
    eng: 'register',
    ru: 'зарегистрироваться',
    pt: 'registar-se',
    cn: 'registe',
  },
  'Register second step': {
    eng: 'receive a gift',
    ru: 'получить подарок',
    pt: 'receber um presente',
  },
  'Register third step': {
    eng: 'get started',
    ru: 'начать работать',
    pt: 'começar',
  },
  Restore: {
    eng: 'Reset',
    ru: 'Сбросить пароль',
    pt: 'Redefinir',
    cn: '恢复',
  },
  'Password was successfully changed.': {
    eng: 'Password was successfully changed.',
    ru: 'Пароль был успешно изменен',
    pt: 'A senha foi alterada com sucesso.',
    cn: '密码已修改成功',
  },
  'Password length requirement': {
    eng: 'The password must be longer than 8 characters',
    ru: 'Пароль должен быть длиннее 8 символов',
    pt: 'A senha tem de ter mais de 8 caracteres',
  },
  'We sent you an email with a link to reset your password': {
    eng: 'We sent you an email with a link to reset your password',
    ru: 'На вашу почту отправлено письмо со ссылкой для смены пароля',
    pt: 'Enviamos um e-mail com um link para redefinir sua senha',
    cn: '我们已发送邮件里面有更改密码的链接，请确认',
  },
  'We sent you a confirmation email. Please check your mailbox to complete an account creation': {
    eng: 'Please check your mailbox for the confirmation email.',
    ru: 'Мы отправили письмо на указанный email для подтверждения регистрации.',
    pt: 'Localize o e-mail de confirmação na sua caixa de correio.',
    cn: '我们已发送注册确认邮件到您指定的邮箱',
  },
  'We work': {
    eng: 'We know about this issue and will fix it ASAP',
    ru: 'Мы знаем о проблеме и уже над ней работаем',
    pt: 'Sabemos sobre este problema e vamos corrigi-lo assim que possível',
  },
  'We call to number': {
    eng: 'We have sent you an SMS to $[1]',
    ru: 'Мы отправили СМС на номер $[1]',
    pt: 'Enviamos a você um SMS para $[1]',
  },
  'Change password': {
    eng: 'Save password and sign in',
    ru: 'Сохранить пароль и войти',
    pt: 'Salvar a senha e fazer login',
  },
  'Change tariff clonebot': {
    eng: 'Change plan',
    ru: 'Изменить тариф',
    pt: 'Alterar plano',
  },
  'Set password': {
    eng: 'Create a new password',
    ru: 'Придумайте новый пароль',
    pt: 'Criar uma nova senha',
  },
  'set bot category later': {
    eng: 'Choose later',
    ru: 'Выбрать позже',
    pt: 'Escolher mais tarde',
  },
  Save: {
    eng: 'Save',
    ru: 'Сохранить',
    pt: 'Salvar',
    cn: '保存',
  },
  Delete: {
    eng: 'Delete',
    ru: 'Удалить',
    pt: 'Excluir',
    cn: '删除',
  },
  'Delete bot': {
    eng: 'Delete bot',
    ru: 'Удалить бота',
    pt: 'Excluir bot',
  },
  'Delete dictionary title': {
    eng: 'Do you want to delete this dictionary?',
    ru: 'Вы хотите удалить словарь?',
    pt: 'Quer excluir este dicionário?',
  },
  'Delete dictionary body': {
    eng: 'Deleting this dictionary might affect your script.',
    ru: 'Если вы удалите этот словарь, это может сломать вам сценарий.',
    pt: 'Excluir este dicionário pode afetar seu script.',
  },
  'Delete bot body': {
    eng: 'The deleted bot and its data can’t be restored.',
    ru: 'Восстановление бота невозможно. Вы потеряете сценарий, подключение к каналам и доступ ко всем данным.',
    pt: 'O bot excluído e seus dados não podem ser restaurados.',
  },
  'Delete bot title': {
    eng: 'Delete bot',
    ru: 'Удаление бота',
    pt: 'Excluir bot',
  },
  'Delete channel': {
    eng: 'Delete channel',
    ru: 'Удаление интеграции с $[1]',
    pt: 'Excluir canal',
  },
  'Delete file': {
    eng: 'Delete the list',
    ru: 'Удалить список',
    pt: 'Excluir a lista',
  },
  'delete number list': {
    eng: 'Delete number list',
    ru: 'Удаление списка номеров',
    pt: 'Excluir lista de números',
  },
  'delete number list warning': {
    eng: 'You won’t be able to recover this number list. Delete?',
    ru: 'Восстановить удаленный список номеров будет невозможно. Удалить список?',
    pt: 'Não será possível recuperar esta lista de números. Excluir?',
  },
  ACTIVE: {
    eng: 'Active',
    ru: 'Активные',
    pt: 'Ativados',
    cn: '活跃的',
  },
  FINISHED: {
    eng: 'Finished',
    ru: 'Завершенные',
    pt: 'Finalizada',
    cn: '已结束',
  },
  Name: {
    eng: 'Channel name',
    ru: 'Название канала',
    pt: 'Nome do canal',
    cn: '名称',
  },
  'No search results': {
    eng: 'No search results',
    ru: 'Ничего не найдено',
    pt: 'Sem resultados de pesquisa',
    cn: '未找到任何结果',
  },
  'No data': {
    eng: 'No data',
    ru: 'Нет данных',
    pt: 'Nenhum dado',
    cn: '没有数据',
  },
  'No entities': {
    eng: 'No entities',
    ru: 'Нет сущностей',
    pt: 'Sem entidades',
  },
  'No matches found for': {
    eng: 'No matches found for',
    ru: 'Совпадений не найдено для',
    pt: 'Não foram encontradas correspondências para',
  },
  'No app installed text title': {
    eng: 'To connect the Bitrix24 channel:',
    ru: 'Чтобы подключить канал Битрикс24:',
    pt: ' ',
  },
  'No app installed text 1': {
    eng: '1. Install the #{config.zenflow.productName} application from the marketplace to your Bitrix24 account.',
    ru: '1. Установите приложение #{config.zenflow.productName} из маркетплейса в ваш аккаунт Битрикс24.',
    pt: ' ',
  },
  'No app installed text 2': {
    eng: '2. Log in to the application #{config.zenflow.productName} user.',
    ru: '2. Авторизуйтесь в приложении как пользователь #{config.zenflow.productName}.',
    pt: ' ',
  },
  'No app installed text 3': {
    eng: '3. Try to connect the Bitrix24 channel again.',
    ru: '3. Попробуйте подключить канал Битрикс24 еще раз.',
    pt: ' ',
  },
  'No account yet?': {
    eng: 'No account yet?',
    ru: 'Еще нет аккаунта?',
    pt: 'Ainda não tem conta?',
  },
  "no don't turn off": {
    eng: 'Don’t turn autopay off',
    ru: 'нет, не отключать',
    pt: 'Não desative a opção de pagamento automático',
  },
  'Close scenario window': {
    eng: 'Leaving the page',
    ru: 'Выход из сценария',
    pt: 'Saindo da página',
    cn: '关闭上下文窗口',
  },
  'Close dictionary window': {
    eng: 'Leaving the page',
    ru: 'Выход из справочника',
    pt: 'Saindo da página',
  },
  'Close knowledge base': {
    eng: 'Leaving the page',
    ru: 'Выход из базы знаний',
    pt: 'Saindo da página',
  },
  'Close and continue': {
    eng: 'Close and continue',
    ru: 'Закрыть и продолжить',
    pt: 'Fechar e continuar',
  },
  'Go to': {
    eng: 'Go to',
    ru: 'Перейдите в',
    pt: 'Acessar',
    cn: '跳转到',
  },
  'Go up': {
    eng: 'Go up',
    ru: 'Наверх',
    pt: 'Ir para cima',
  },
  'Go to channels': {
    eng: 'Choose a channel',
    ru: 'Выбрать канал',
    pt: 'Escolher um canal',
  },
  'Go to payment error': {
    eng: 'Go to payment error',
    ru: 'При переходе к оплате произошла ошибка',
    pt: 'Acessar erro de pagamento',
  },
  'Channel type': {
    eng: 'Channel type',
    ru: 'Тип канала',
    pt: 'Tipo de canal',
    cn: '渠道类型',
  },
  'Channel name': {
    eng: 'Channel name',
    ru: 'Название канала',
    pt: 'Nome do canal',
    cn: '渠道名称',
  },
  'Channel ID': {
    eng: 'Channel ID',
    ru: 'Идентификатор канала',
    pt: 'ID do canal',
  },
  'Channel connection': {
    eng: 'Channel settings',
    ru: 'Подключение канала',
    pt: 'Configurações do canal',
  },
  'Integration Token': {
    eng: 'Integration token',
    ru: 'Токен интеграции',
    pt: 'Token de integração',
  },
  'Integration with online chats': {
    eng: 'Integration with online chats',
    ru: 'Интеграция с онлайн-чатами',
    pt: 'Integração com chats on-line',
  },
  'Greeting Message': {
    eng: 'Greeting message',
    ru: 'Приветственное сообщение',
    pt: 'Mensagem de saudação',
  },
  Phone: {
    eng: 'Phone',
    ru: 'Телефон',
    pt: 'Telefone',
    cn: '电话',
  },
  Connect: {
    eng: 'Connect',
    ru: 'Подключить',
    pt: 'Conectar',
    cn: '连接',
  },
  'Connect to lower plan': {
    eng: 'Choose plan',
    ru: 'Подключить',
    pt: 'Escolher plano',
    cn: '连接',
  },
  'Connect with us': {
    eng: 'Request',
    ru: 'Свяжитесь с нами',
    pt: 'Solicitar',
  },
  'Connect later': {
    eng: 'Upgrade later',
    ru: 'Подключить позже',
    pt: 'Atualizar mais tarde',
  },
  'Connect later calls': {
    eng: 'Maybe later',
    ru: 'Может быть позже',
    pt: 'Talvez mais tarde',
  },
  'Edit channel $[1]': {
    eng: 'Edit channel $[1]',
    ru: 'Редактирование канала $[1]',
    pt: 'Editar canal $[1]',
    cn: '编辑渠道 $[1]',
  },
  'Edit entity name': {
    eng: 'Edit object name',
    ru: 'Изменение названия справочника',
    pt: 'Editar nome do objeto',
  },
  BITRIX: {
    eng: 'Bitrix24',
    ru: 'Битрикс24',
    pt: 'Bitrix24',
    cn: 'Open line Bitrix24',
  },
  'Show widget headline': {
    eng: 'Show widget headline',
    ru: 'Показывать заголовок виджета',
    pt: 'Mostrar cabeçalho do widget',
    cn: '显示窗口标题',
  },
  'Show send button': {
    eng: 'Show send button',
    ru: 'Показывать кнопку отправки',
    pt: 'Mostrar botão de envio',
    cn: '显示发送按钮',
  },
  'Show names': {
    eng: 'Show names',
    ru: 'Показывать имена',
    pt: 'Mostrar nomes',
    cn: '显示名称',
  },
  'ChatWidgetEditor:Show:Animation': {
    eng: 'Animation when waiting for bot response',
    ru: 'Анимация при ожидании ответа бота',
  },
  'Show avatars': {
    eng: 'Show avatars',
    ru: 'Показывать аватары',
    pt: 'Mostrar avatares',
    cn: '显示头像',
  },
  'Widget code': {
    eng: 'Widget code',
    ru: 'Код виджета',
    pt: 'Código do widget',
    cn: '窗口代码',
  },
  'Widget headline': {
    eng: 'Widget headline',
    ru: 'Заголовок виджета',
    pt: 'Cabeçalho do widget',
    cn: '窗口标题',
  },
  'Widget assistant name': {
    eng: 'Widget assistant name',
    ru: 'Имя ассистента',
    pt: 'Nome do assistente do widget',
    cn: '助手名窗口',
  },
  'Widget user name': {
    eng: 'Widget user name',
    ru: 'Имя пользователя',
    pt: 'Nome de usuário do widget',
    cn: '用户名窗口',
  },
  'Widget insertion code is copied to clipboard': {
    eng: 'Widget insertion code copied to clipboard',
    ru: 'Код для вставки виджета скопирован в буфер обмена',
    pt: 'Código de inserção do widget copiado para a área de transferência',
    cn: '插入窗口的代码已拷贝到缓存中',
  },
  'Widget logo': {
    eng: 'Widget logo',
    ru: 'Логотип виджета',
    pt: 'Logotipo do widget',
  },
  'Placeholder text': {
    eng: 'Placeholder text',
    ru: 'Текст-подсказка в поле ввода',
    pt: 'Texto do marcador de posição',
    cn: '占位符文本',
  },
  'URL Bitrix24 webhook is copied to clipboard': {
    eng: 'Bitrix24 webhook URL copied to clipboard',
    ru: 'URL вебхука для Битрикс24 скопирован в буфер обмена',
    pt: 'URL do webhook Bitrix24 copiado para a área de transferência',
  },
  "URL Alisa's webhook is copied to clipboard": {
    eng: 'Alice webhook URL copied to clipboard',
    ru: 'URL вебхука для Алисы скопирован в буфер обмена',
    pt: 'URL do webhook da Alice copiado para a área de transferência',
    cn: 'Alice网络挂接的URL地址已拷贝到剪切板',
  },
  'URL MARUSIA webhook is copied to clipboard': {
    eng: 'Marusia webhook URL copied to clipboard',
    ru: 'URL вебхука для Маруси скопирован в буфер обмена',
    pt: 'URL do webhook da Márcia copiado para área de transferência',
    cn: 'Marusia网络挂接的URL地址已拷贝到剪切板',
  },
  'URL JIVOSITE webhook is copied to clipboard': {
    eng: 'JivoChat webhook URL copied to clipboard',
    ru: 'URL вебхука для Jivo скопирован в буфер обмена',
    pt: 'URL do webhook JivoChat copiado para a área de transferência',
  },
  'URL AZURE webhook is copied to clipboard': {
    eng: 'Microsoft Teams webhook URL copied to clipboard',
    ru: 'URL вебхука для Microsoft Teams скопирован в буфер обмена',
    pt: 'O URL do webhook do Microsoft Teams foi copiado para a área de transferência',
  },
  "URL Aimybox's webhook is copied to clipboard": {
    eng: 'Aimybox webhook URL copied to clipboard',
    ru: 'URL вебхука для Aimybox скопирован в буфер обмена',
    pt: 'URL do webhook Aimybox copiado para a área de transferência',
    cn: 'Aimybox网络挂接的URL地址已拷贝到剪切板',
  },
  "URL Salut's webhook is copied to clipboard": {
    eng: 'Salut webhook URL copied to clipboard',
    ru: 'URL вебхука для Салют скопирован в буфер обмена',
    pt: 'URL do webhook do Sílvio copiado para área de transferência',
  },
  'URL THREADS webhook is copied to clipboard': {
    eng: 'edna.chatCenter webhook URL copied to clipboard',
    ru: 'URL вебхука для edna.chatCenter скопирован в буфер обмена',
    pt: 'URL do webhook edna.chatCenter copiado para a área de transferência',
  },
  'URL INCOMING_WEBIM2 webhook is copied to clipboard': {
    eng: 'Webim (External Bot API 2.0) webhook URL copied to clipboard',
    ru: 'URL вебхука для Webim (External Bot API 2.0) скопирован в буфер обмена',
    pt: 'URL do webhook Webim (External Bot API 2.0) copiado para a área de transferência',
  },
  'URL INBOUND_LIVETEX webhook is copied to clipboard': {
    eng: 'LiveTex webhook URL copied to clipboard',
    ru: 'URL вебхука для LiveTex скопирован в буфер обмена',
    pt: 'URL do webhook LiveTex copiado para a área de transferência',
  },
  'URL Facebook webhook is copied to clipboard': {
    eng: 'Facebook webhook URL copied to clipboard',
    ru: 'URL вебхука для Facebook скопирован в буфер обмена',
    pt: 'URL do webhook do Facebook copiado para a área de transferência',
  },
  'URL Instagram webhook is copied to clipboard': {
    eng: 'Instagram webhook URL copied to clipboard',
    ru: 'URL вебхука для Instagram скопирован в буфер обмена',
    pt: 'URL do Webhook do Instagram copiado para a área de transferência',
  },
  'URL WAZZUP webhook is copied to clipboard': {
    eng: 'Wazzup webhook URL copied to clipboard',
    ru: 'URL вебхука для Wazzup скопирован в буфер обмена',
    pt: 'URL do webhook do Wazzup copiado para a área de transferência',
  },
  'URL WhatsApp webhook is copied to clipboard': {
    eng: 'WhatsApp webhook URL copied to clipboard',
    ru: 'URL вебхука для WhatsApp скопирован в буфер обмена',
    pt: 'URL do webhook do WhatsApp copiado para a área de transferência',
  },
  Webhook: {
    eng: 'Webhook',
    ru: 'Вебхук',
    pt: 'Webhook',
    cn: '网络挂接',
  },
  'The specified email is not registered': {
    eng: 'This email is not registered',
    ru: 'Нет пользователя с таким email',
    pt: 'Este e-mail não está registado',
    cn: '没有用户使用这个邮箱地址',
  },
  'The link to the image must end with .gif, .jpg, .jpeg, .tiff, or .png': {
    eng: 'The link to the image must end with .gif, .jpg, .jpeg, .tiff, or .png',
    ru: 'Ссылка на изображение должна заканчиваться на .gif, .jpg, .jpeg, .tiff или .png',
    pt: 'O link para a imagem deve terminar com .gif, .jpg, .jpeg, .tiff ou .png',
  },
  'The file is too large. Maximum file size is $[1]': {
    eng: 'The file is too large. Maximum file size is $[1]',
    ru: 'Слишком большой файл. Максимальный размер файла $[1]',
    pt: 'O arquivo é muito grande. O tamanho máximo do arquivo é de $[1]',
  },
  'The file is too large $[1]Mb. Maximum file size is 20Mb': {
    eng: 'The file is too large $[1]Mb. Maximum file size is 20Mb',
    ru: 'Слишком большой файл $[1]Мб. Максимальный размер файла 20Мб',
    pt: 'O arquivo é muito grande: $[1] Mb. O arquivo só pode ter até 20 Mb',
  },
  'The number of channels available is limited': {
    eng: 'Number of available channels is limited',
    ru: 'Количество доступных каналов ограничено',
    pt: 'O número de canais disponíveis é limitado',
  },
  'Get webhook': {
    eng: 'Get webhook',
    ru: 'Получить webhook',
    pt: 'Obter webhook',
    cn: '获取网络挂接',
  },
  'Get script': {
    eng: 'Get script',
    ru: 'Получить script',
    pt: 'Obter script',
    cn: '获得脚本',
  },
  'Get actions json': {
    eng: 'Download action.json',
    ru: 'Скачать action.json',
    pt: 'Baixar action.json',
  },
  'Get OAuth-token': {
    eng: 'Get OAuth-token',
    ru: 'Получить OAuth-токен',
    pt: 'Baixar token OAuth',
  },
  'GET request example': {
    eng: 'GET request example',
    ru: 'Пример GET запроса',
    pt: 'Exemplo de solicitação GET',
    cn: 'GET请求例子',
  },
  'Confirm number after registration': {
    eng: 'Confirm your phone number',
    ru: 'Подтвердите свой номер телефона',
    pt: 'Confirme seu número de telefone',
  },
  'Get 10 free minutes': {
    eng: 'Confirm your phone number<br/> and get <b>10 free minutes</b> for calling',
    ru: 'Подтвердите свой номер телефона<br/>и получите <b>10 бесплатных минут</b> на обзвоны',
    pt: 'Confirme seu número de telefone<br/> e receba <b>10 minutos gratuitos</b> para chamadas',
  },
  'chat in Telegram': {
    eng: 'chat in Telegram',
    ru: 'чат в Telegram',
    pt: 'chat no Telegram',
    cn: 'Telegram上聊天',
  },
  'Chat API token is copied to clipboard': {
    eng: 'Chat API token copied to clipboard',
    ru: 'Chat API токен скопирован в буфер обмена',
    pt: 'Token de Chat API copiado para a área de transferência',
  },
  Feedback: {
    eng: 'Feedback',
    ru: 'Обратная связь',
    pt: 'Feedback',
    cn: '反馈',
  },
  'Message text': {
    eng: 'Message text',
    ru: 'Текст сообщения',
    pt: 'Texto da mensagem',
    cn: '信息内容',
  },
  'Creating an account': {
    eng: 'Sign up',
    ru: 'Создайте аккаунт',
    pt: 'Inscreva-se',
    cn: '创建账号',
  },
  'Creating a dictionary': {
    eng: 'Creating a dictionary',
    ru: 'Создание справочника',
    pt: 'Criando um dicionário',
  },
  Create: {
    eng: 'Create account',
    ru: 'Создать аккаунт',
    pt: 'Criar conta',
    cn: '创建',
  },
  'Already have an account?': {
    eng: 'Already have an account?',
    ru: 'Уже есть аккаунт?',
    pt: 'Já tem uma conta?',
    cn: '已有账号？',
  },
  'Complete registration': {
    eng: 'Complete your account creation',
    ru: 'Завершите регистрацию',
    pt: 'Conclua a criação da sua conta',
    cn: '完成注册',
  },
  'Create an account': {
    eng: 'Create an account',
    ru: 'Создать аккаунт',
    pt: 'Criar uma conta',
    cn: '还没有账号？',
  },
  'Create bot': {
    eng: 'Create a chatbot',
    ru: 'Создать чат-бота',
    pt: 'Criar um chatbot',
  },
  'Create calls bot': {
    eng: 'Create a voice bot',
    ru: 'Создать звонок',
    pt: 'Criar um bot de voz',
  },
  'Create dictionary action': {
    eng: 'Create a dictionary',
    ru: 'Создать справочник синонимов',
    pt: 'Criar um dicionário',
  },
  'Create empty base': {
    eng: 'Create a new knowledge base',
    ru: 'Создать пустую базу',
    pt: 'Criar uma nova base de conhecimento',
  },
  'Create mailing': {
    eng: 'Create a campaign',
    ru: 'Создать обзвон',
    pt: 'Criar uma campanha',
  },
  'Create chatbots for your business customers and receive monthly payments of up to 50% of the total amount of customer payments':
    {
      eng: 'Create $[1] chatbots for your customers and receive monthly payout of up to 50% of the total license costs of all your customers.',
      ru: 'Создавайте чат-ботов на $[1] для ваших бизнес-заказчиков и получайте ежемесячные выплаты до 50% от общей суммы выплат клиентов за пользование $[1]',
      pt: 'Crie chatbots $[1] para seus clientes e receba um pagamento mensal de até 50% do total de custos de licença de todos os seus clientes.',
    },
  'Create partner error': {
    eng: 'Error while registering a partner',
    ru: 'При регистрации партнера произошла ошибка',
    pt: 'Erro ao registrar um parceiro',
  },
  'Create partner success': {
    eng: 'Partner registration was successful',
    ru: 'Регистрация партнера прошла успешно',
    pt: 'O registro do parceiro foi bem-sucedido',
  },
  'create your own sip telephony message': {
    eng: 'In order to use the phone channel, use your own',
    ru: 'Чтобы использовать телефонный канал, используйте собственное',
    pt: 'Para utilizar o canal do telefone, utilize o seu próprio',
  },
  'create your own sip telephony link': {
    eng: 'SIP connection',
    ru: 'SIP-подключение',
    pt: 'Conexão SIP',
  },
  'Sign in': {
    eng: 'Sign in',
    ru: 'Bxoд',
    pt: 'Fazer login',
    cn: '进入账号',
  },
  'Sign out': {
    eng: 'Sign out',
    ru: 'Выйти',
    pt: 'Sair',
  },
  'Sign into account': {
    eng: 'Sign in to $[1]',
    ru: 'Вход в аккаунт $[1]',
    pt: 'Fazer login em $[1]',
  },
  'Sign into euro': {
    eng: 'Sign in',
    ru: 'Вход в аккаунт',
    pt: 'Fazer login',
  },
  'Sign up into euro': {
    eng: 'Sign up',
    ru: 'Регистрация аккаунта',
    pt: 'Inscreva-se',
  },
  'Sign up into Just AI': {
    eng: 'Sign up for $[1]',
    ru: 'Регистрация аккаунта $[1]',
    pt: 'Inscrever-se para $[1]',
  },
  'Email has successfully send': {
    eng: 'Email has been sent',
    ru: 'Ваше сообщение отправлено',
    pt: 'O e-mail foi enviado',
    cn: '您的信息已成功发送',
  },
  'Email can not be empty': {
    eng: 'Email cannot be empty',
    ru: 'Поле email не может быть пустым',
    pt: 'O e-mail não pode estar vazio',
  },
  'Email is verified': {
    eng: 'Email is verified',
    ru: 'Email подтвержден',
    pt: 'O e-mail foi confirmado',
  },
  'Error when sending email': {
    eng: 'Error when sending email',
    ru: 'При отправке сообщения возникла ошибка',
    pt: 'Erro ao enviar e-mail',
    cn: '邮件发送出现错误',
  },
  'Error loading file': {
    eng: 'Error loading file',
    ru: 'Ошибка загрузки файла',
    pt: 'Erro ao carregar arquivo',
  },
  'Bot name is not specified': {
    eng: 'Enter the bot name',
    ru: 'Введите имя бота',
    pt: 'Digite o nome do bot',
  },
  'Channel name is not specified': {
    eng: 'Enter the channel name',
    ru: 'Введите название канала',
    pt: 'Digite o nome do canal',
  },
  send: {
    eng: 'send',
    ru: 'отправить',
    pt: 'enviar',
    cn: '发送',
  },
  Yes: {
    eng: 'Yes',
    ru: 'Да',
    pt: 'Sim',
    cn: '是',
  },
  No: {
    eng: 'No',
    ru: 'Нет',
    pt: 'Não',
    cn: '否',
  },
  'How to create a bot': {
    eng: 'How to create my first chatbot?',
    ru: 'Как создать первого чат-бота?',
    pt: 'Como criar o meu primeiro chatbot?',
  },
  'How to connect Google Assistant?': {
    eng: 'How to connect Google Assistant?',
    ru: 'Как подключить Google Assistant?',
    pt: 'Como conectar o Google Assistente?',
  },
  'How to connect?': {
    eng: 'How to connect?',
    ru: 'Как подключить?',
    pt: 'Como conectar?',
  },
  'How to get started?': {
    eng: 'How to get started?',
    ru: 'С чего начать работу?',
    pt: 'Como começar?',
  },
  'How to create a smart call': {
    eng: 'How to create a call',
    ru: 'Как создать обзвон',
    pt: 'Como criar uma chamada',
  },
  'How to train bot': {
    eng: 'How do chatbots work?',
    ru: 'Как устроены боты?',
    pt: 'Como funcionam os chatbots?',
  },
  'How to create smart calls label': {
    eng: 'How to create a call campaign',
    ru: 'Как создать умный обзвон',
    pt: 'Como criar uma campanha de chamadas',
  },
  'How it works?': {
    eng: 'How does it work?',
    ru: 'Как это работает?',
    pt: 'Como funciona?',
  },
  'How to link a client to your affiliate account?': {
    eng: 'How to bind my client’s account to mine?',
    ru: 'Как привязать клиента к своему партнерскому аккаунту?',
    pt: 'Como associar a conta do meu cliente à minha?',
  },
  'How to transfer a bot to a client account?': {
    eng: 'How to transfer a project to client’s account?',
    ru: 'Как перенести бота в аккаунт клиента?',
    pt: 'Como transferir um projeto para a conta do cliente?',
  },
  'How will I receive payments?': {
    eng: 'How will I get my commissions?',
    ru: 'Как я буду получать выплаты?',
    pt: 'Como vou obter minhas comissões?',
  },
  "Can't find an email in your mailbox?": {
    eng: 'Can’t find an email in your mailbox?',
    ru: 'Не получили письмо?',
    pt: 'Não consegue encontrar um e-mail na sua caixa de correio?',
    cn: '还没有收到邮件？',
  },
  Resend: {
    eng: 'Resend',
    ru: 'Отправить повторно',
    pt: 'Reenviar',
    cn: '再次发送',
  },
  'Resending is possible in $[1] seconds': {
    eng: 'Resending is possible in $[1] seconds',
    ru: 'Повторная отправка возможна через $[1] секунд',
    pt: 'É possível reenviar em $[1] segundos',
    cn: '60秒后可再次发送',
  },
  'Calls Create bot': {
    eng: 'Create a voice bot',
    ru: 'Создать бота для телефонного канала',
    pt: 'Criar um bot de voz',
  },
  'Calls details': {
    eng: 'Call details',
    ru: 'Детализация звонков',
    pt: 'Detalhes da chamada',
  },
  'calls only for russian operators': {
    eng: 'Calls can only be made to customers of Russian operators',
    ru: 'Звонки возможны только абонентам Российских операторов',
    pt: 'As chamadas só podem ser efetuadas para clientes de operadores russos',
  },
  'Calls reports': {
    eng: 'Call reports',
    ru: 'Отчеты по звонкам',
    pt: 'Relatórios de chamadas',
  },
  'Calls headline': {
    eng: 'Calls',
    ru: 'Звонки',
    pt: 'Chamadas',
  },
  'Calls templates headline': {
    eng: 'Ready-made solutions for calls',
    ru: 'Готовые решения для звонков',
    pt: 'Soluções prontas para chamadas',
  },
  'Calls templates tab label normal': {
    eng: 'outbound call campaigns',
    ru: 'исходящие обзвоны',
    pt: 'campanhas de chamadas de saída',
  },
  'Calls templates tab label ivr': {
    eng: 'IVRs',
    ru: 'IVR',
    pt: 'IVRs',
  },
  'Calls choose tariff': {
    eng: 'Calls are not available at your Community rate. Please choose a rate!',
    ru: 'На вашем тарифе Community обзвоны недоступны. Пожалуйста, выберите тариф!',
    pt: 'As chamadas não estão disponíveis com a tarifa da sua Comunidade. Escolhar uma tarifa!',
  },
  'Calls Tariffs Card minutes': {
    eng: '$[1] minutes',
    ru: '$[1] минуты',
    pt: '$[1] minutos',
  },
  'Calls Tariffs Card 0 minutes': {
    eng: 'You can buy minutes separately',
    ru: 'Минуты покупаются отдельно',
    pt: 'Você pode comprar minutos em separado',
  },
  'Global fallback': {
    eng: 'Default fallback',
    ru: 'Ответы по умолчанию',
    pt: 'Fallback predefinido',
  },
  'fallback description': {
    eng: 'Bot replies with these phrases to unexpected user query',
    ru: 'Эти фразы бот будет произносить в ответ на неизвестные команды пользователя',
    pt: 'O bot responde com estas frases a uma consulta inesperada do usuário',
  },
  Entity: {
    eng: '$[1] $[2]',
    ru: '$[1] $[2]',
    pt: '$[1] $[2]',
  },
  entity1: {
    eng: 'entity',
    ru: 'сущность',
    pt: 'entidade',
  },
  entity2: {
    eng: 'entities',
    ru: 'сущности',
    pt: 'entidades',
  },
  entity5: {
    eng: 'entities',
    ru: 'сущностей',
    pt: 'entidades',
  },
  Synonym: {
    eng: '$[1] $[2]',
    ru: '$[1] $[2]',
    pt: '$[1] $[2]',
  },
  synonym1: {
    eng: 'synonym',
    ru: 'синоним',
    pt: 'sinônimo',
  },
  synonym2: {
    eng: 'synonyms',
    ru: 'синонима',
    pt: 'sinônimos',
  },
  synonym5: {
    eng: 'synonyms',
    ru: 'синонимов',
    pt: 'sinônimos',
  },
  Scenario: {
    eng: 'Script',
    ru: 'Сценарий',
    pt: 'Script',
  },
  Channels: {
    eng: 'Channels',
    ru: 'Каналы',
    pt: 'Canais',
  },
  Knowledge: {
    eng: 'Knowledge',
    ru: 'Знания',
    pt: 'Conhecimento',
  },
  'Knowledge base': {
    eng: 'FAQ knowledge base',
    ru: 'База знаний FAQ',
    pt: 'Base de conhecimento das perguntas frequentes',
  },
  'Knowledge sidebar': {
    eng: 'FAQ bot',
    ru: 'FAQ-бот',
    pt: 'Bot das Perguntas Frequentes',
  },
  'Knowledge text': {
    eng: '',
    ru: 'Наполните базу знаний вопросами, которые клиенты часто задают боту, и заполните ответы на них. Чтобы генерировать ответы на неизвестные вопросы, подключите ChatGPT в разделе Настройки.',
    pt: '',
  },
  'Knowledge:Text:Constructor': {
    eng: '',
    ru: 'Наполните базу знаний вопросами, которые клиенты часто задают боту, и заполните ответы на них. Чтобы генерировать ответы на неизвестные вопросы, подключите ChatGPT в разделе Настройки.',
    pt: '',
  },
  'Knowledge alert': {
    eng: 'You can add up to 250 questions to the FAQ bot',
    ru: 'Вы можете добавить до 250 вопросов в базу знаний',
    pt: 'Você pode adicionar até 250 perguntas ao bot de Perguntas Frequentes',
  },
  Entities: {
    eng: 'Entities',
    ru: 'Сущности',
    pt: 'Entidades',
  },
  'Confirm delete bot': {
    eng: 'Yes, I confirm',
    ru: 'Да, удалить',
    pt: 'Sim, confirmo',
  },
  'Confirm new password': {
    eng: 'Confirm new password',
    ru: 'Введите пароль еще раз',
    pt: 'Confirme nova senha',
  },
  'Confirm delete channel': {
    eng: 'Delete',
    ru: 'Да, удалить',
    pt: 'Excluir',
  },
  'Confirm start': {
    eng: 'Confirm start',
    ru: 'Подтверждение запуска',
    pt: 'Confirmar início',
  },
  'confirm delete number list': {
    eng: 'Yes, delete',
    ru: 'Да, удалить',
    pt: 'Sim, excluir',
  },
  'Confirm and continue': {
    eng: 'Confirm and continue',
    ru: 'Подтвердить и продолжить',
    pt: 'Confirmar e continuar',
  },
  'Confirm and continue calls': {
    eng: 'Confirm and continue',
    ru: 'Продолжить',
    pt: 'Confirmar e continuar',
  },
  'There are unsaved changes. Do you really want to leave?': {
    eng: 'Some changes are unsaved. Do you really want to leave?',
    ru: 'Есть несохраненные изменения. Вы действительно хотите выйти?',
    pt: 'Algumas alterações não são salvas. Quer mesmo sair?',
  },
  'Unexpected name': {
    eng: 'Unexpected name',
    ru: 'Недопустимые символы в названии cправочника',
    pt: 'Nome inesperado',
  },
  'If you have any questions, or idea, write to our service desc': {
    eng: 'If you have a question or an idea, contact our service desk',
    ru: 'Если у вас возникли вопросы или предложения по использованию #{config.zenflow.productName}, напишите нам в',
    pt: 'Se tiver alguma dúvida ou ideia, entre em contato com nossa Central de Ajuda',
  },
  'Technical support': {
    eng: 'Technical support',
    ru: 'Техподдержку',
    pt: 'Suporte técnico',
  },
  'Technical difficulties': {
    eng: 'Technical issues occurred',
    ru: 'Возникли технические трудности',
    pt: 'Ocorreram problemas técnicos',
  },
  'or connect to our': {
    eng: 'or connect to our',
    ru: 'или подключайтесь в наш',
    pt: 'ou conecte-se ao nosso',
  },
  'or drop here': {
    eng: 'or drop the file here',
    ru: 'или перетащите файл сюда',
    pt: 'ou solte o arquivo aqui',
  },
  Add: {
    eng: 'Add',
    ru: 'Добавить',
    pt: 'Adicionar',
  },
  Synonyms: {
    eng: 'Synonyms',
    ru: 'Синонимы',
    pt: 'Sinônimos',
  },
  'System dictionaries': {
    eng: 'System dictionaries',
    ru: 'Системные справочники',
    pt: 'Dicionários do sistema',
  },
  'Webhook settings': {
    eng: 'Webhook settings',
    ru: 'Настройки вебхука',
    pt: 'Configurações de webhook',
  },
  'Server URL': {
    eng: 'Webhook URL',
    ru: 'URL вашего вебхука',
    pt: 'URL do webhook',
  },
  'Server address': {
    eng: 'Server address',
    ru: 'Адрес сервера',
    pt: 'Endereço do servidor',
  },
  'Test webhook URL': {
    eng: 'Test webhook URL',
    ru: 'URL вашего тестового вебхука',
    pt: 'Testar URL do webhook',
  },
  'Test call': {
    eng: 'Call',
    ru: 'Звонок',
    pt: 'Chamada',
  },
  'Select custom date range': {
    eng: 'Select a period',
    ru: 'Выбрать день или период',
    pt: 'Selecione um período',
  },
  'Select another': {
    eng: 'Select another',
    ru: 'Выбрать другой',
    pt: 'Selecione outro',
  },
  week: {
    eng: 'last week',
    ru: 'неделя',
    pt: 'semana passada',
  },
  month: {
    eng: 'month',
    ru: 'месяц',
    pt: 'mês',
  },
  'two weeks': {
    eng: 'last two weeks',
    ru: 'две недели',
    pt: 'últimas duas semanas',
  },
  day: {
    eng: 'day',
    ru: 'день',
    pt: 'dia',
  },
  period: {
    eng: 'period',
    ru: 'период',
    pt: 'período',
  },
  choose: {
    eng: 'select',
    ru: 'выбрать',
    pt: 'selecionar',
  },
  or: {
    eng: 'or',
    ru: 'или',
    pt: 'ou',
  },
  for: {
    eng: 'for',
    ru: 'за',
    pt: 'for',
  },
  'All channels': {
    eng: 'All channels',
    ru: 'Все каналы',
    pt: 'Todos os canais',
  },
  'All week': {
    eng: 'Everyday from $[1] to $[2]',
    ru: 'Ежедневно с $[1] до $[2]',
    pt: 'Todos os dias das $[1] às $[2]',
  },
  people: {
    eng: 'people',
    ru: 'чел',
    pt: 'pessoas',
  },
  'selected period': {
    eng: 'selected period:',
    ru: 'выбран период:',
    pt: 'período selecionado:',
  },
  'selected day': {
    eng: 'selected day:',
    ru: 'выбран день:',
    pt: 'dia selecionado:',
  },
  Report: {
    eng: 'Report',
    ru: 'Отчет',
    pt: 'Relatório',
  },
  'Unique users': {
    eng: 'Unique users',
    ru: 'Уникальные пользователи',
    pt: 'Usuários únicos',
  },
  'Download statistic': {
    eng: 'Download statistic',
    ru: 'Выгрузить статистику',
    pt: 'Estatística de download',
  },
  'Download details': {
    eng: 'Download call details',
    ru: 'Скачать детализацию звонков',
    pt: 'Baixar detalhes de chamadas',
  },
  'Download report': {
    eng: 'Download report',
    ru: 'Скачать отчет',
    pt: 'Baixar relatório',
  },
  'Those webhook used while you testing your scenario in widget': {
    eng: 'Bot invokes this URL when you’re using test widget',
    ru: 'Этот вебхук используется, когда вы тестируете сценарий в тестовом виджете',
    pt: 'O bot invoca este URL quando você estiver usando o widget de teste',
  },
  'Help Center': {
    eng: 'Help Center',
    ru: 'Справка',
    pt: 'Central de Ajuda',
  },
  'Project ID': {
    eng: 'Project ID',
    ru: 'ID проекта',
    pt: 'ID do projeto',
  },
  Dialogs: {
    eng: 'Dialogs',
    ru: 'Диалоги',
    pt: 'Diálogos',
  },
  OauthToken: {
    eng: 'OAuth token',
    ru: 'OAuth-токен',
    pt: 'Token OAuth',
  },
  users1: {
    eng: 'user',
    ru: 'пользователь',
    pt: 'usuário',
    cn: 'users',
  },
  users2: {
    eng: 'users',
    ru: 'пользователя',
    pt: 'usuários',
  },
  users5: {
    eng: 'users',
    ru: 'пользователей',
    pt: 'usuários',
  },
  dialogs1: {
    eng: 'dialog',
    ru: 'диалог',
    pt: 'diálogo',
  },
  dialogs2: {
    eng: 'dialogs',
    ru: 'диалогa',
    pt: 'diálogos',
  },
  dialogs5: {
    eng: 'dialogs',
    ru: 'диалогов',
    pt: 'diálogos',
  },
  'field OAuth-token is reqiured': {
    eng: 'field OAuth-token is reqiured',
    ru: 'поле OAuth-токен является обязательным',
    pt: 'O token de OAuth do campo é necessário',
  },
  'Field can not be empty': {
    eng: 'Field cannot be empty',
    ru: 'Поле не может быть пустым',
    pt: 'O campo não pode ficar vazio',
  },
  'Add trigger': {
    eng: 'Add invocation phrase',
    ru: 'Добавить триггер',
    pt: 'Adicionar frase de invocação',
  },
  'Add phrase': {
    eng: 'Add a phrase',
    ru: 'Добавить фразу',
    pt: 'Adicione uma frase',
  },
  'Add group': {
    eng: 'Add group',
    ru: 'Добавить группу',
    pt: 'Adicionar grupo',
    cn: '添加组',
  },
  Triggers: {
    eng: 'Invocation phrases',
    ru: 'Триггеры',
    pt: 'Frases de acionamento',
  },
  'Channels:Google:TriggerTooltip': {
    eng: 'The phrase that will trigger the skill',
    ru: 'Фраза, которая будет вызывать навык',
    pt: 'A frase que acionará a habilidade',
  },
  people1: {
    eng: 'user',
    ru: 'человек',
    pt: 'usuário',
  },
  people2: {
    eng: 'people',
    ru: 'человека',
    pt: 'pessoas',
  },
  people5: {
    eng: 'people',
    ru: 'человек',
    pt: 'pessoas',
  },
  of: {
    eng: 'of',
    ru: 'из',
    pt: 'de',
  },
  'Copy ID': {
    eng: 'Copy ID',
    ru: 'Скопировать ID',
    pt: 'Copiar ID',
  },
  'Copy log': {
    eng: 'Copy log',
    ru: 'Скопировать лог',
    pt: 'Copiar log',
  },
  'Copy key': {
    eng: 'Copy key',
    ru: 'Скопировать ключ',
    pt: 'Copiar chave',
  },
  'Copy webhook': {
    eng: 'Copy webhook',
    ru: 'Скопировать адрес',
    pt: 'Copiar webhook',
  },
  'Copy bot': {
    eng: 'Clone bot',
    ru: 'Копировать бота',
    pt: 'Clonar bot',
  },
  'Copy token': {
    eng: 'Copy token',
    ru: 'Скопировать токен',
    pt: 'Copiar token',
  },
  Copied: {
    eng: 'Copied',
    ru: 'Скопировано',
    pt: 'Copiado',
  },
  'These phrases the bot will pronounce if an error has occurred in the work': {
    eng: 'Bot picks one of these answers for every error in the script',
    ru: 'Эти фразы бот будет произносить, если произошла ошибка в работе',
    pt: 'O bot escolhe uma destas respostas para cada erro no script',
  },
  Phrase: {
    eng: 'Phrase',
    ru: 'Фраза',
    pt: 'Frase',
    cn: '句子/段落',
  },
  'Answers on error': {
    eng: 'Error fallback',
    ru: 'Ответы при ошибке',
    pt: 'Erro de fallback',
  },
  'Max: 20 symbols.': {
    eng: 'Max: 20 characters.',
    ru: 'Максимум 20 символов.',
    pt: 'Máx: 20 caracteres.',
  },
  'Run test': {
    eng: 'Test',
    ru: 'Тестировать',
    pt: 'Testar',
  },
  voice: {
    eng: 'voice',
    ru: 'голос',
    pt: 'voz',
  },
  chat: {
    eng: 'chat',
    ru: 'чат',
    pt: 'chat',
  },
  'Dialogs not found': {
    eng: 'No dialogs for the selected filters',
    ru: 'Не найдено диалогов по выбранным фильтрам',
    pt: 'Nenhum diálogo para os filtros selecionados',
  },
  'Dialogs alert': {
    eng: 'You have access to bot-to-client chat history',
    ru: 'Вам доступна история диалогов бота с клиентами',
    pt: 'Você tem acesso ao histórico de chat entre cliente e bot',
  },
  'Alexa skill ID': {
    eng: 'Alexa skill ID (optional)',
    ru: 'ID навыка (опционально)',
    pt: 'ID de Skills da Alexa (opcional)',
    cn: 'Skill ID (optional)',
  },
  'ALEXA invocation name restriction': {
    eng: "You can use a-z, ', and underscore.",
    ru: "Вы можете использовать a-z, ', и нижнее подчеркивание.",
    pt: "Você pode usar a-z, ' e sublinhado.",
  },
  'Invocation Name': {
    eng: 'Invocation Name',
    ru: 'Имя вызова навыка',
    pt: 'Nome de acionamento',
  },
  Settings: {
    eng: 'Settings',
    ru: 'Настройки',
    pt: 'Configurações',
    cn: '设置',
  },
  'Account settings': {
    eng: 'Account settings',
    ru: 'Настройки профиля',
    pt: 'Configurações da conta',
  },
  'Enter new password': {
    eng: 'Enter new password',
    ru: 'Новый пароль',
    pt: 'Digite a nova senha',
  },
  'Enter image URL': {
    eng: 'Enter image URL',
    ru: 'введите URL картинки',
    pt: 'Digite o URL da imagem',
  },
  'Enter captcha': {
    eng: 'Verify you are not a robot',
    ru: 'Подтвердите, что вы не робот',
    pt: 'Confirme que você não é um robô',
  },
  'Enter last 4 number': {
    eng: 'Enter confirmation code',
    ru: 'Введите код подтверждения',
    pt: 'Digite o código de confirmação',
  },
  'Enter another phone number': {
    eng: 'Enter another number',
    ru: 'Ввести другой номер телефона',
    pt: 'Digite outro número',
  },
  'Enter old password': {
    eng: 'Enter old password',
    ru: 'Старый пароль',
    pt: 'Digite a senha antiga',
  },
  'Enter old password error': {
    eng: 'Your old and new passwords must not match',
    ru: 'Старый и новый пароли не должны совпадать',
    pt: 'As suas senhas antigas e novas não podem ser iguais',
  },
  'Passwords are different': {
    eng: 'Passwords are different',
    ru: 'Пароли не совпадают',
    pt: 'As senhas são diferentes',
  },
  Language: {
    eng: 'Language',
    ru: 'Язык интерфейса',
    pt: 'Idioma',
  },
  'Personal Area': {
    eng: 'Personal Area',
    ru: 'Личный кабинет',
    pt: 'Área pessoal',
  },
  'Personal main heading 0': {
    eng: 'Your account',
    ru: 'Личный кабинет',
    pt: 'Sua conta',
    cn: 'Your account ',
  },
  'Personal main heading 1': {
    eng: 'Payment history',
    ru: 'Платежи',
    pt: 'Histórico de pagamento',
  },
  'Personal main heading 4': {
    eng: 'Configuring telephony',
    ru: 'Настройка телефонии',
    pt: 'Configurando telefonia',
  },
  'Personal main heading 3': {
    eng: 'Account settings',
    ru: 'Настройки профиля',
    pt: 'Configurações da conta',
  },
  'Personal main heading 2': {
    eng: 'Affiliate program',
    ru: 'Партнерская программа',
    pt: 'Programa de afiliados',
  },
  Prices: {
    eng: 'Plans & Pricing',
    ru: 'Тарифные планы',
    pt: 'Planos e preços',
  },
  Payments: {
    eng: 'Payment history',
    ru: 'Платежи',
    pt: 'Histórico de pagamento',
  },
  'Search by phrases, names and IDs': {
    eng: 'Search by phrases, names, and IDs',
    ru: 'Поиск по фразам, именам и ID',
    pt: 'Pesquisar por frases, nomes e IDs',
  },
  'Search returned no results': {
    eng: 'Search returned no results',
    ru: 'К сожалению, поиск не дал результатов.\n Попробуйте переформулировать запрос.',
    pt: 'A pesquisa não apresentou resultados',
  },
  'Was active': {
    eng: 'Was active',
    ru: 'Был активен',
    pt: 'Estava ativo',
  },
  'Client name': {
    eng: 'Client name',
    ru: 'Имя клиента',
    pt: 'Nome do cliente',
  },
  'Client activity': {
    eng: 'Client activity',
    ru: 'Активность клиента',
    pt: 'Atividade do cliente',
  },
  'Client activity was yesterday': {
    eng: 'Client was active yesterday',
    ru: 'Был активен вчера',
    pt: 'O cliente estava ativo ontem',
  },
  'h.': {
    eng: 'h.',
    ru: 'ч.',
    pt: 'h',
  },
  min: {
    eng: 'min',
    ru: 'мин',
    pt: 'min',
  },
  sec: {
    eng: 'sec',
    ru: 'сек',
    pt: 's',
  },
  Information: {
    eng: 'Information',
    ru: 'Информация',
    pt: 'Informações',
  },
  Description: {
    eng: 'Description',
    ru: 'Описание справочника',
    pt: 'Descrição',
  },
  Download: {
    eng: 'Download',
    ru: 'Скачать',
    pt: 'Baixar',
  },
  Purchase: {
    eng: 'Purchase',
    ru: 'Покупка',
    pt: 'Compra',
  },
  Term: {
    eng: 'Period',
    ru: 'Срок',
    pt: 'Período',
  },
  Amount: {
    eng: 'Amount',
    ru: 'Сумма',
    pt: 'Montante',
  },
  'Date of charge': {
    eng: 'Billing date',
    ru: 'Дата списания',
    pt: 'Data de faturamento',
  },
  'Paid before': {
    eng: 'Paid until',
    ru: 'Оплачено до',
    pt: 'Pago até',
  },
  'Language cannot be changed after bot creation': {
    eng: 'You cannot change the language later',
    ru: 'После создания бота поменять язык нельзя',
    pt: 'Não é possível alterar o idioma posteriormente',
  },
  'This field is required': {
    eng: 'Required field',
    ru: 'Это поле обязательно для заполнения',
    pt: 'Campo obrigatório',
  },
  'This will lead to the deletion of the channel and all the settings. The channel will marked as deleted in Dialogs and Statistics. Do you really want to proceed':
    {
      eng: 'This will lead to the deletion of the channel and all the settings. The channel will marked as deleted in Dialogs and Statistics. Proceed?',
      ru: 'Это приведет к удалению канала и настроек канала. В разделах Диалоги и Статистика канал отметится удаленным. В самом деле удалить канал?',
      pt: 'Isso levará à exclusão do canal e de todas as configurações. O canal será marcado como excluído em Diálogos e Estatísticas. Continuar?',
    },
  'This action cannot be undone': {
    eng: 'This action cannot be undone',
    ru: 'Отменить удаление нельзя',
    pt: 'Esta ação não pode ser desfeita',
  },
  'This time bot will call': {
    eng: 'Bot will call within this period before the campaign ends',
    ru: 'Бот позвонит в этот период до завершения обзвона',
    pt: 'O bot fará a ligação dentro deste período antes de a campanha terminar',
  },
  'This month you have already earned': {
    eng: 'This month you have already earned',
    ru: 'В этом месяце вы уже заработали',
    pt: 'Este mês você já ganhou',
  },
  'Privacy Policy': {
    eng: 'Privacy Policy',
    ru: 'Политика конфиденциальности',
    pt: 'Política de Privacidade',
  },
  'Terms of Service': {
    eng: 'Terms of Service',
    ru: 'условиями использования',
    pt: 'Termos de Serviço',
  },
  'Terms of use': {
    eng: 'Terms of use',
    ru: 'Пользовательское соглашение',
    pt: 'Termos de uso',
  },
  'Minimum N characters': {
    eng: 'The password must be at least $[1] characters long',
    ru: 'Пароль должен быть не менее $[1] символов',
    pt: 'A senha tem de ter pelo menos $[1] caracteres',
  },
  'Required field': {
    eng: 'This field is required',
    ru: 'Обязательное поле',
    pt: 'Este campo é obrigatório',
  },
  'Invalid email': {
    eng: 'Specify email',
    ru: 'Укажите email',
    pt: 'Especifique o e-mail',
  },
  'Invalid skill ID': {
    eng: 'Invalid ID skill',
    ru: 'Недопустимый идентификатор навыка',
    pt: 'Competência inválida de ID',
  },
  'Invalid JSON': {
    eng: 'Invalid JSON',
    ru: 'Некорректный JSON',
    pt: 'JSON inválido',
  },
  'I agree with': {
    eng: 'I agree with',
    ru: 'Я согласен(на) с',
    pt: 'Concordo com',
  },
  'I see': {
    eng: 'I see',
    ru: 'Понятно',
    pt: 'Vejo',
  },
  Title: {
    eng: 'Bot name',
    ru: 'Название',
    pt: 'Nome de bot',
  },
  Publish: {
    eng: 'Publish',
    ru: 'Опубликовать',
    pt: 'Publicar',
  },
  Publishing: {
    eng: 'Publishing…',
    ru: 'Публикуется…',
    pt: 'Publicando…',
    cn: 'Publishing...',
  },
  Published: {
    eng: 'Published',
    ru: 'Опубликован',
    pt: 'Publicado',
  },
  'You have new messages': {
    eng: 'You have new messages',
    ru: 'У вас новые сообщения',
    pt: 'Você tem mensagens novas',
  },
  'You have unsaved data': {
    eng: 'You have unsaved changes',
    ru: 'У вас есть несохраненные данные',
    pt: 'Tem alterações não salvas',
  },
  'You need dev account to ALEXA': {
    eng: 'Connect failed. \nPlease, use Amazon developer account.',
    ru: 'Подключение не удалось. \nИспользуйте аккаунт разработчика Amazon.',
    pt: 'Falha na conexão. \nUtilize a conta de desenvolvedor da Amazon.',
  },
  'You are using for free now': {
    eng: 'You are using #{config.zenflow.productName} for free now',
    ru: 'Вы пользуетесь бесплатным тарифом #{config.zenflow.productName}',
    pt: 'Você está usando o #{config.zenflow.productName} gratuitamente agora',
  },
  'You must install the application in the Bitrix profile and log in': {
    eng: 'First, install the application to your Bitrix profile and log in',
    ru: 'Необходимо установить приложение в профиле Битрикс и авторизоваться',
    pt: 'Primeiro, instale o aplicativo no seu perfil Bitrix e faça login',
  },
  'You will be able to restore your password and contact the support to manage your minutes balance': {
    eng: 'You will be able to restore your password and contact the support to manage your minutes balance',
    ru: 'Вы можете восстанавливать пароль и обращаться в техподдердку для управления балансом минут',
    pt: 'Você poderá restaurar sua senha e entrar em contato com o suporte para gerenciar seu saldo de minutos',
  },
  'You have no customers yet': {
    eng: 'You have no customers yet',
    ru: 'У вас пока нет клиентов',
    pt: 'Ainda não tem clientes',
  },
  'You could enter the account with this number': {
    eng: 'You will be able to use your phone number to sign in',
    ru: 'Вы сможете заходить в аккаунт по номеру телефона',
    pt: 'Você poderá usar seu número de telefone para fazer login',
  },
  'Open Help Center': {
    eng: 'Open Help Center',
    ru: 'Перейти в справочный центр',
    pt: 'Abrir Central de Ajuda',
  },
  'Open adding': {
    eng: 'Add call list manually',
    ru: 'Добавить номера вручную',
    pt: 'Adicionar lista de chamadas manualmente',
  },
  'Access from the script': {
    eng: 'Use in the script',
    ru: 'Доступ из сценария',
    pt: 'Usar no script',
  },
  'Access key': {
    eng: 'Access key',
    ru: 'Ключ доступа',
    pt: 'Chave de acesso',
  },
  'Channels:LivetexVisitor:InvalidToken': {
    eng: 'Invalid token',
    ru: 'Недействительный токен',
  },
  'Channels:LivetexVisitor:DefaultDepartment': {
    eng: 'Default agent group',
    ru: 'Группа операторов по умолчанию',
  },
  'Channels:LivetexVisitor:DownloadDepartments': {
    eng: 'The group list is loading…',
    ru: 'Загрузка групп…',
  },
  'Channels:LivetexVisitor:Department': {
    eng: 'Agent group',
    ru: 'Группа операторов',
  },
  'Channels:LivetexVisitor:Department:SupText': {
    eng: 'Specify a token to display the group list',
    ru: 'Укажите токен, чтобы появился список групп',
  },
  'Channels:LivetexVisitor:NoDepartmentsAvailable': {
    eng: 'There are no agent groups available',
    ru: 'Нет доступных групп операторов',
  },
  'Channels:LivetexVisitor:RequiredField': {
    eng: 'This field is required',
    ru: 'Обязательное поле',
    pt: 'Este campo é obrigatório',
  },
  'Speech synthez markup': {
    eng: 'Speech synthesis markup',
    ru: 'Разметка синтеза речи',
    pt: 'Marcação de síntese de fala',
  },
  'Speech synthez markup information': {
    eng: 'The bot will use speech synthesis when giving answers in voice channels',
    ru: 'Бот будет использовать синтез речи при ответах в телефонных каналах',
    pt: 'O bot usará a síntese de fala ao dar respostas nos canais de voz',
  },
  'Speech not recognized block title': {
    eng: 'What if the client stops talking during the call',
    ru: 'Что делать, если клиент замолчал во время звонка',
    pt: 'E se o cliente parar de falar durante a chamada',
  },
  'Question placeholder': {
    eng: 'User’s question',
    ru: 'Введите вопрос клиента, на который должен ответить бот',
    pt: 'Pergunta do usuário',
  },
  Answers: {
    eng: 'Answers',
    ru: 'Ответы',
    pt: 'Respostas',
  },
  'Synonyms of the question': {
    eng: 'Question synonyms',
    ru: 'Синонимы вопроса',
    pt: 'Sinônimos de perguntas',
  },
  'Base with examples': {
    eng: 'Use a template',
    ru: 'База с примерами',
    pt: 'Usar um modelo',
  },
  'Examples can be removed': {
    eng: 'You can remove sample questions',
    ru: 'Примеры заполнения можно удалить',
    pt: 'Você pode remover perguntas de amostra',
  },
  'Successfully load: $[1] questions, skipped $[2] questions': {
    eng: 'Successfully loaded $[1] questions, skipped $[2] questions',
    ru: 'Успешно загружено: $[1] вопросов, пропущено $[2] вопросов',
    pt: 'Foram carregadas com sucesso $[1] perguntas, $[2] perguntas ignoradas',
  },
  Upload: {
    eng: 'Upload',
    ru: 'Загрузить',
    pt: 'Carregar',
  },
  'Wrong file format': {
    eng: 'Wrong file format',
    ru: 'Некорректный формат файла',
    pt: 'Formato errado de arquivo',
  },
  'Wrong numbers': {
    eng: 'Invalid numbers',
    ru: 'Неправильные номера',
    pt: 'Números inválidos',
  },
  'To cancel deletion, press $[1] + Z': {
    eng: 'To cancel deletion, press $[1] + Z',
    ru: 'Для отмены удаления нажмите $[1] + Z',
    pt: 'Para cancelar a exclusão, pressione $[1] + Z',
  },
  'To connect': {
    eng: 'To connect',
    ru: 'Подключить',
    pt: 'Para conectar',
  },
  'To connect another carrier channel, first delete the current one.': {
    eng: 'To connect another customer engagement platform, delete the current one first.',
    ru: 'Для подключения другого операторского канала сначала удалите текущий.',
    pt: 'Para conectar outra plataforma de engajamento do cliente, exclua primeiro a atual.',
  },
  'to use functions change the tariff plan': {
    eng: 'To be able to clone bots, switch to a paid plan',
    ru: 'Для использования функций #{config.zenflow.productName} смените тарифный план',
    pt: 'Para poder clonar bots, mude para um plano pago',
  },
  'To bot page': {
    eng: 'Back home',
    ru: 'Перейти на страницу с ботами',
    pt: 'Voltar para a tela inicial',
  },
  'To pay': {
    eng: 'Buy now',
    ru: 'Оплатить',
    pt: 'Comprar agora',
  },
  'Type answer': {
    eng: 'Bot’s reply option',
    ru: 'Введите вариант ответа',
    pt: 'A opção de resposta do bot',
  },
  'Type synonym': {
    eng: 'Add a synonym for the user’s question',
    ru: 'Введите синоним вопроса',
    pt: 'Adicione um sinônimo para a pergunta do usuário',
  },
  'Exceeded the number of subject areas $[1], questions and answers. Maximum is $[2].': {
    eng: 'Exceeded the number of subject areas $[1], questions and answers. Maximum is $[2].',
    ru: 'Превышено допустимое количество предметных областей, вопросов и ответов $[1]. Максимум $[2]',
    pt: 'Excedeu o número de temas $[1], perguntas e respostas. O máximo é $[2].',
  },
  'Error:PreviouslyEditedScript:Exists': {
    eng: 'You previously edited a script in another product — $[1]. Save these changes. Unfortunately, your current Knowledge base edits will be lost.',
    ru: 'Ранее вы редактировали сценарий в другом продукте — $[1]. Сохраните эти изменения. К сожалению, текущие правки Базы знаний будут потеряны.',
  },
  'An error occurred while saving the knowledge base': {
    eng: 'An error occurred while saving the knowledge base',
    ru: 'При сохранении базы знаний произошла ошибка',
    pt: 'Ocorreu um erro ao salvar a base de dados de conhecimento',
  },
  'JS valid identifier names': {
    eng: 'Use Aa–Zz, _, and 0–9 characters only. The name should start with Aa–Zz. JS reserved words are not allowed.',
    ru: 'Используйте Aa–Zz, _, 0–9. Начните имя с Aa–Zz. Нельзя использовать зарезервированные слова JS.',
    pt: 'Use apenas caracteres Aa–Zz, _ e 0–9. O nome deve começar com Aa–Zz. Palavras reservadas JS não são permitidas.',
  },
  'Questions not found': {
    eng: 'FAQ is empty',
    ru: 'База знаний пока пуста',
    pt: 'As perguntas frequentes estão vazias',
  },
  'questions and solutions': {
    eng: 'questions and solutions',
    ru: 'вопросы и решения',
    pt: 'perguntas e soluções',
  },
  'Scenario was published successfully': {
    eng: 'Script published to the $[1] channel',
    ru: 'Сценарий опубликован в канале $[1]',
    pt: 'Script publicado no canal $[1]',
  },
  'Scenario publications was failed': {
    eng: 'Script publishing failed for the $[1] channel.',
    ru: 'Ошибка при публикации сценария в канал $[1].',
    pt: 'A publicação de scripts falhou para o canal de $[1].',
  },
  'Scenario error': {
    eng: 'Script error:',
    ru: 'Ошибка в сценарии:',
    pt: 'Erro de script:',
  },
  'Scenario is not saved. You will lost the changes if you leave the page': {
    eng: 'Changes not saved. Leave the page?',
    ru: 'Изменения не сохранены. Вы действительно хотите выйти?',
    pt: 'Alterações não salvas. Sair da página?',
  },
  'Scenario for calls': {
    eng: 'Call campaign script',
    ru: 'Сценарий для обзвона',
    pt: 'Script da campanha de chamadas',
  },
  'publish no channel': {
    eng: 'To publish the script, you need at least one connected channel',
    ru: 'Чтобы опубликовать бота, подключите хотя бы один канал',
    pt: 'Para publicar o script, você precisa de pelo menos um canal conectado',
  },
  'publish no channel title': {
    eng: 'Connect a channel and publish the bot',
    ru: 'Подключите канал и опубликуйте бота',
    pt: 'Conecte um canal e publique o bot',
  },
  'publish no channel to ask bot': {
    eng: 'Channels for client-to-bot interactions',
    ru: 'Каналы для обращений к боту',
    pt: 'Canais para interações entre clientes e bots',
  },
  'publish no channel to call': {
    eng: 'Channels for campaigns',
    ru: 'Каналы для обзвонов',
    pt: 'Canais para campanhas',
  },
  'Frequently asking questions': {
    eng: 'Frequently asked questions',
    ru: 'Часто задаваемые вопросы',
    pt: 'Perguntas Frequentes',
  },
  'Getting started': {
    eng: 'Getting started',
    ru: 'Быстрый старт',
    pt: 'Início',
  },
  Tutorials: {
    eng: 'Tutorials',
    ru: 'Туториалы',
    pt: 'Tutoriais',
  },
  Basics: {
    eng: 'Basics',
    ru: 'Базовые понятия $[1]',
    pt: 'Básico',
  },
  Blocks: {
    eng: 'Blocks',
    ru: 'Блоки в $[1]',
    pt: 'Blocos',
  },
  'Dev account to ALEXA modal': {
    eng: 'You need an Amazon developer account to connect to Alexa.',
    ru: 'Для подключения Alexa вам нужен аккаунт разработчика.',
    pt: 'Você precisa de uma conta de desenvolvedor da Amazon para se conectar à Alexa.',
  },
  'Dictionary name': {
    eng: 'Dictionary name',
    ru: 'Название',
    pt: 'Nome do dicionário',
  },
  'mon.': {
    eng: 'month',
    ru: 'мес',
    pt: 'mês',
  },
  'year.': {
    eng: 'year',
    ru: 'год',
    pt: 'ano',
  },
  'Demo available': {
    eng: 'First $[1] days for free! ',
    ru: 'Первые $[1] дней бесплатно!',
    pt: 'Os primeiros $[1] dias de cortesia! ',
  },
  'Demo expired': {
    eng: 'Purchase now',
    ru: 'Оплатить подписку',
    pt: 'Comprar agora',
  },
  'Demo available options': {
    eng: '<span class="warning">$[1] minutes for free</span> in the firts <span class="warning">$[2] days</span>',
    ru: '<span class="warning">$[1] бесплатных</span> минуты на <span class="warning">$[2] дня</span>',
    pt: '<span class="warning">$[1] minutos grátis</span> nos primeiro <span class="warning">$[2] dias</span>',
  },
  'Compare rates': {
    eng: 'Subscription options',
    ru: 'Сравнить тарифы',
    pt: 'Opções de assinatura',
  },
  Disable: {
    eng: 'Disable',
    ru: 'Убрать',
    pt: 'Desativar',
  },
  Enable: {
    eng: 'Enable',
    ru: 'Добавить',
    pt: 'Ativar',
  },
  Soon: {
    eng: 'Soon',
    ru: 'Скоро',
    pt: 'Em breve',
  },
  'TariffProgress popper Uniq clients': {
    eng: 'Maximum number of unique users for all the bots',
    ru: 'Максимальное количество уникальных пользователей для всех ботов аккаунта',
    pt: 'Número máximo de usuários exclusivos para todos os bots',
  },
  'TariffProgress popper Group phrases': {
    eng: 'Maximum number of intents for one bot',
    ru: 'Максимальное количество интентов в сценарии одного бота',
    pt: 'Número máximo de intenções para um bot',
  },
  'TariffProgress popper Themes': {
    eng: 'Simple tool for creating a FAQ assistant',
    ru: 'Простой инструмент создания бота-консультанта',
    pt: 'Ferramenta simples para criar um assistente de Perguntas Frequentes',
  },
  'TariffProgress popper Channels': {
    eng: 'Maximum number of simultaneously connected channels',
    ru: 'Максимальное количество одновременно подключенных каналов для бота',
    pt: 'Número máximo de canais conectados simultaneamente',
  },
  'TariffProgress popper Telephony': {
    eng: 'Time available in the campaign',
    ru: 'Минут и секунд в обзвоне',
    pt: 'Tempo disponível na campanha',
  },
  'TariffProgress Uniq clients': {
    eng: 'Unique users',
    ru: 'Пользователи',
    pt: 'Usuários exclusivos',
  },
  'TariffProgress Group phrases': {
    eng: 'Intents',
    ru: 'Группы интентов',
    pt: 'Intenções',
  },
  'TariffProgress Themes': {
    eng: 'FAQ',
    ru: 'Тематики',
    pt: 'Perguntas Frequentes',
  },
  'TariffProgress Telephony': {
    eng: 'Telephony',
    ru: 'Телефония',
    pt: 'Telefonia',
  },
  'TariffProgress Channels': {
    eng: 'Channels',
    ru: 'Каналы',
    pt: 'Canais',
  },
  Versions: {
    eng: 'Versions',
    ru: 'Версии',
    pt: 'Versões',
  },
  'Versions history is empty bold': {
    eng: 'Version history is empty',
    ru: 'История версий пуста ',
    pt: 'O histórico da versão está vazio',
  },
  'Versions history is empty text': {
    eng: 'You haven’t saved any versions yet',
    ru: 'Вы еще не делали сохранений',
    pt: 'Você ainda não salvou nenhuma versão',
  },
  'Restoring selected version': {
    eng: 'Restoring selected version',
    ru: 'Загружается сохраненная версия',
    pt: 'Restaurando versão selecionada',
  },
  CurrentHistory: {
    eng: 'Current',
    ru: 'Текущая',
    pt: 'Atual',
  },
  RestoreHistory: {
    eng: 'Restore',
    ru: 'Восстановить',
    pt: 'Restaurar',
  },
  'Locale changes': {
    eng: 'Local changes',
    ru: 'Локальные изменения',
    pt: 'Alterações locais',
  },
  PublishedHistory: {
    eng: 'Published',
    ru: 'Опубликованные версии',
    pt: 'Publicadas',
  },
  Saved: {
    eng: 'Saved',
    ru: 'Сохраненные версии',
    pt: 'Salvas',
  },
  Today: {
    eng: 'Today',
    ru: 'Сегодня',
    pt: 'Hoje',
  },
  Yesterday: {
    eng: 'Yesterday',
    ru: 'Вчера',
    pt: 'Ontem',
  },
  'Send request': {
    eng: 'Submit',
    ru: 'Отправить',
    pt: 'Enviar',
  },
  'Send a message about adding to the dialogue': {
    eng: 'Enable the bot manually',
    ru: 'Добавлять бота только вручную',
    pt: 'Ativar o bot manualmente',
  },
  'Send /start on dialogue start': {
    eng: 'Start dialog with any user message',
    ru: 'Начинать диалог с любого сообщения пользователя',
    pt: 'Inicie o diálogo com qualquer mensagem de usuário',
  },
  'Contact Us': {
    eng: 'Contact Us',
    ru: 'Свяжитесь с нами',
    pt: 'Fale conosco',
  },
  'Contact us and we will help you find the rate that suits you and your business.': {
    eng: 'Contact us and we will help you choose the rate that best suits you and your business.',
    ru: 'Свяжитесь с нами, и мы поможем подобрать тариф, который подходит именно вам и вашему бизнесу.',
    pt: 'Fale conosco, e ajudaremos você a escolher a taxa mais adequada para você e seus negócios.',
  },
  'Company name': {
    eng: 'Company name',
    ru: 'Название компании',
    pt: 'Nome da empresa',
  },
  Tariffs: {
    eng: 'Plans & pricing',
    ru: 'Тарифные планы',
    pt: 'Planos e preços',
  },
  'My tariff': {
    eng: 'Current subscription plan',
    ru: 'Текущий тариф',
    pt: 'Plano de assinatura atual',
  },
  'Uniq clients': {
    eng: 'Available users',
    ru: 'Доступные пользователи',
    pt: 'Usuários disponíveis',
  },
  'Group phrases': {
    eng: 'Intents',
    ru: 'Группы интентов',
    pt: 'Intenções',
  },
  Themes: {
    eng: 'FAQ',
    ru: 'Тематики',
    pt: 'Perguntas Frequentes',
  },
  'Choose tariff': {
    eng: 'Choose a plan',
    ru: 'Выбрать тариф',
    pt: 'Escolha um plano',
  },
  'Choose another tariff': {
    eng: 'Change the plan',
    ru: 'Выбрать другой тариф',
    pt: 'Altere o plano',
  },
  'Choose another tariff link': {
    eng: 'Change the plan',
    ru: 'Выбрать другой',
    pt: 'Altere o plano',
  },
  'Choose tariff header': {
    eng: 'Upgrade your plan to $[1]',
    ru: 'Подключение тарифа $[1]',
    pt: 'Atualize o seu plano para $[1]',
  },
  'Choose period': {
    eng: 'Set period',
    ru: 'Настроить период',
    pt: 'Definir período',
  },
  'Choose period popper': {
    eng: 'When to call people',
    ru: 'Когда звонить людям',
    pt: 'Quando ligar para pessoas',
  },
  'Choose week day': {
    eng: 'Select a day',
    ru: 'Выберите день',
    pt: 'Selecionar um dia',
  },
  'Choose period from': {
    eng: 'Start at',
    ru: 'Начинать в',
    pt: 'Iniciar às',
  },
  'Choose period to': {
    eng: 'End at',
    ru: 'Заканчивать в',
    pt: 'Terminar às',
  },
  'Choose another card': {
    eng: 'Choose another card',
    ru: 'Выбрать другую карту',
    pt: 'Escolher outro cartão',
  },
  'another tariffs': {
    eng: 'other plans',
    ru: 'других тарифах',
    pt: 'outros planos',
  },
  'Next payment': {
    eng: 'You will be charged',
    ru: 'Следующая оплата',
    pt: 'Próximo pagamento',
  },
  'Payment date': {
    eng: 'Billing date',
    ru: 'Дата списания',
    pt: 'Data de faturamento',
  },
  'Payment tab MONTHLY': {
    eng: 'month',
    ru: 'месяц',
    pt: 'mês',
  },
  'Payment tab -': {
    eng: '-',
    ru: '-',
    pt: '-',
  },
  'Payment tab month': {
    eng: 'last month',
    ru: 'месяц',
    pt: 'mês passado',
  },
  'Billing tariffs': {
    eng: 'Plan limits',
    ru: 'Тарификация',
    pt: 'Limites do plano',
  },
  'Billing interval': {
    eng: 'Billing interval&nbsp;<b>15&nbsp;seconds</b>',
    ru: 'Тарификация по&nbsp;<b>15&nbsp;секунд</b>',
    pt: 'Intervalo de faturamento&nbsp;<b>15&nbsp;segundos</b>',
  },
  'Retry subscription': {
    eng: 'Resume subscription',
    ru: 'Возобновить подписку',
    pt: 'Retomar assinatura',
  },
  Type: {
    eng: 'Type',
    ru: 'Тип',
    pt: 'Tipo',
  },
  Status: {
    eng: 'Status',
    ru: 'Статус',
    pt: 'Status',
  },
  'View log': {
    eng: 'View log',
    ru: 'Показать лог',
    pt: 'Ver log',
  },
  'View later': {
    eng: 'View later',
    ru: 'Посмотреть позже',
    pt: 'Ver mais tarde',
  },
  'View tariff': {
    eng: 'View subscription',
    ru: 'Посмотреть тарифы',
    pt: 'Ver assinatura',
  },
  'Deploy log': {
    eng: 'Deploy log',
    ru: 'Лог публикации',
    pt: 'Implantar log',
  },
  'Deploy log is copied to clipboard': {
    eng: 'Deploy log copied to clipboard',
    ru: 'Лог публикации скопирован в буфер обмена',
    pt: 'Implantar log copiado para a área de transferência',
  },
  'Sorry, work is temporarily suspended.': {
    eng: 'Sorry, work is temporarily on pause',
    ru: 'Извините, работа временно приостановлена',
    pt: 'Desculpe, mas o trabalho está temporariamente em pausa',
  },
  'Will be active on': {
    eng: 'Will be active on $[1]',
    ru: 'Активен с $[1]',
    pt: 'Estará ativo em $[1]',
  },
  'Subscribe dialogs blur': {
    eng: '#{config.zenflow.productName} paid subscription',
    ru: 'Платные тарифы #{config.zenflow.productName}',
    pt: 'Assinatura paga do #{config.zenflow.productName}',
  },
  'Subscribe dialogs text': {
    eng: 'On #{config.zenflow.productName} paid subscription, you get access to client-to-bot dialogs. \nUse them to train your bot and make it even more effective!',
    ru: 'На платных тарифах #{config.zenflow.productName} вы получаете доступ к просмотру диалогов клиентов с вашим ботом. \nИспользуйте их, чтобы дообучить вашего бота и сделать его еще более эффективным!',
    pt: 'Na assinatura paga do #{config.zenflow.productName}, você obtém acesso aos diálogos entre clientes e bots. \nUse-os treinar seu bot e torná-lo ainda mais eficaz!',
  },
  'Subscribe basic restrictions blur': {
    eng: '$[1] subscription.',
    ru: 'Ограничение тарифа Basic.',
    pt: 'Assinatura do $[1].',
  },
  'Subscribe basic restrictions text': {
    eng: 'You’ve reached the limit of maximum FAQ size for the current plan.',
    ru: 'Вы достигли максимального количества тематик для текущего тарифа. Для увеличения базы знаний выберите следующий тариф',
    pt: 'Atingiu o limite do tamanho máximo das Perguntas Frequentes para o plano atual.',
  },
  'Subscribe kb text': {
    eng: 'You’ve reached the maximum size of FAQ base for the current plan.',
    ru: 'Не терпите ограничения!\nС подпиской вы получаете полный доступ к базе знаний',
    pt: 'Atingiu o tamanho máximo da base de Perguntas Frequentes para o plano atual.',
  },
  'Continue subscription': {
    eng: 'Continue subscription',
    ru: 'Возобновить подписку',
    pt: 'Continuar assinatura',
  },
  'Continue scheduler': {
    eng: 'Resume the campaign',
    ru: 'Продолжить обзвон',
    pt: 'Retomar a campanha',
  },
  'Available on payed blur': {
    eng: 'Subscribe to access',
    ru: 'Доступно по подписке',
    pt: 'Assinar para acessar',
  },
  'Available on': {
    eng: 'Available on ',
    ru: 'Доступен на ',
    pt: 'Disponível em',
  },
  '$[1] is not available at this tariff': {
    eng: '$[1] is not available at this plan',
    ru: '$[1] недоступен на данном тарифе',
    pt: 'O $[1] não está disponível neste plano',
  },
  Confirm: {
    eng: 'Confirm',
    ru: 'Подтвердить',
    pt: 'Confirmar',
  },
  'Basic settings': {
    eng: 'Basic settings',
    ru: 'Базовые настройки',
    pt: 'Configurações básicas',
  },
  'Catch all block title': {
    eng: 'Bot response to unrecognized client messages',
    ru: 'Реакция бота на неизвестную фразу',
    pt: 'Resposta do bot a mensagens de cliente não reconhecidas',
  },
  'Catch all type phrases': {
    eng: 'Say the phrase',
    ru: 'Произнести фразу',
    pt: 'Diga a frase',
  },
  'Catch all type audios': {
    eng: 'Play the audio',
    ru: 'Воспроизвести аудио',
    pt: 'Reproduza o áudio',
  },
  'Catch all type chatGpt': {
    eng: 'Generate a response using ChatGPT',
    ru: 'Сгенерировать ответ с помощью ChatGPT',
    pt: '',
  },
  'Settings:Bot:Tone:Label': {
    ru: 'Тон общения',
    eng: 'Tone of communication',
    pt: 'Tom da comunicação',
  },
  'Settings:Bot:Tone:Neutral': {
    ru: '👤 Нейтральный',
    eng: '👤 Neutral',
    pt: '👤 Neutro',
  },
  'Settings:Bot:Tone:Friendly': {
    ru: '😊 Дружеский',
    eng: '😊 Friendly',
    pt: '😊 Amigável',
  },
  'Settings:Bot:Tone:Formal': {
    ru: '💼 Формальный',
    eng: '💼 Formal',
    pt: '💼 Formal',
  },
  'Settings:Bot:Emoji:Label': {
    ru: 'Использует эмодзи',
    eng: 'Uses emoji',
    pt: 'Usa emoji',
  },
  'Settings:Bot:Emoji:Rarely': {
    ru: '👀 Редко',
    eng: '👀 Rarely',
    pt: '👀 Raramente',
  },
  'Settings:Bot:Emoji:Never': {
    ru: '❌ Никогда',
    eng: '❌ Never',
    pt: '❌ Nunca',
  },
  'Settings:Bot:Emoji:Often': {
    ru: '😀 Часто',
    eng: '😀 Often',
    pt: '😀 Com frequência',
  },
  'Settings:Company:Name': {
    ru: 'Название и описание компании',
    eng: 'Company name and description',
    pt: 'Nome e descrição da empresa',
  },
  'Settings:Company:Name:Helper': {
    ru: 'Расскажите, чем занимается компания и добавьте любую информацию, которая может быть полезна клиенту. Максимальная длина текста – 2 000 символов.',
    eng: 'Please provide the information about your company and add helpful information for the client. The maximum text length is 2,000 characters.',
    pt: 'Forneça informações sobre sua empresa e adicione informações úteis para o cliente. O comprimento máximo do texto é 2000 caracteres.',
  },
  'Settings:Gpt:BotAnswer': {
    ru: 'Ответ бота при исчерпании лимита обращений к ChatGPT',
    eng: 'Bot response when the limit of calls to ChatGPT is exceeded',
    pt: '',
  },
  'Settings:Gpt:BotAnswer:Helper': {
    ru: 'Максимальная длина текста – 500 символов',
    eng: 'The maximum text length is 500 characters',
    pt: 'O comprimento máximo do texto é 500 caracteres',
  },
  'On technical error answer': {
    eng: 'If there was a technical issue, the bot will say:',
    ru: 'При технической ошибке бот скажет:',
    pt: 'Se houver um problema técnico, o bot dirá:',
  },
  'Operator connection': {
    eng: 'Connecting the agent',
    ru: 'Подключение оператора',
    pt: 'Conectando o agente',
  },
  'Operator editing': {
    eng: 'Agent editing',
    ru: 'Редактирование оператора',
    pt: 'Edição de agente',
  },
  'Operator channel error': {
    eng: 'Error connecting an agent',
    ru: 'Ошибка при подключении оператора',
    pt: 'Erro ao conectar um agente',
  },
  'Webim secret key': {
    eng: 'Webim secret key',
    ru: 'Секретный ключ Webim',
    pt: 'Chave secreta Webim',
  },
  'Webim account name': {
    eng: 'Webim account name',
    ru: 'Имя аккаунта Webim',
    pt: 'Nome da conta Webim',
  },
  'Corresponds to the subdomain of the account {your_name_account} .webim.ru': {
    eng: 'Corresponds to the subdomain of the account {your_name_account}.webim.ru',
    ru: 'Соответствует поддомену аккаунта {имя_вашего_аккаунта}.webim.ru',
    pt: 'Corresponde ao subdomínio da conta {nome_da_sua_conta}.webim.ru',
  },
  Copy: {
    eng: 'Copy',
    ru: 'Скопировать',
    pt: 'Copiar',
  },
  'offers chatbot developer affiliate program': {
    eng: '$[1] offers a partnership program for chatbot developers',
    ru: '$[1] предлагает партнерскую программу для разработчиков чат-ботов',
    pt: 'O $[1] oferece um programa de parceria para desenvolvedores de chatbot',
  },
  Documentation: {
    eng: '$[1] Documentation',
    ru: 'Документация $[1]',
    pt: 'Documentação do $[1]',
  },
  'Copied to clipboard': {
    eng: 'Copied to clipboard',
    ru: 'Скопировано в буфер обмена',
    pt: 'Copiado para a área de transferência',
  },
  'Deleting the integration will cause the bot to work incorrectly when transferred to the operator. Do you really want to delete?':
    {
      eng: 'Deleting the integration might cause the bot to work incorrectly when transferred to agent. Delete?',
      ru: 'Вы действительно хотите удалить? Удаление интеграции может привести к некорректной работе бота при попадании в блок переход на оператора.',
      pt: 'A exclusão da integração pode fazer com que o bot funcione incorretamente quando transferido para o agente. Excluir?',
    },
  Operator: {
    eng: 'Agent',
    ru: 'Оператор',
    pt: 'Agente',
  },
  Remove: {
    eng: 'Remove',
    ru: 'Удалить',
    pt: 'Remover',
    cn: '删除',
  },
  'Service Access token': {
    eng: 'Service access token',
    ru: 'Сервисный ключ доступа',
    pt: 'Token de acesso ao serviço',
  },
  Disconnected: {
    eng: 'Disconnected',
    ru: 'Отключен',
    pt: 'Desconectado',
  },
  'Once you choose your Subscription plan, here you will see the history of your payments': {
    eng: 'Once you pick a Subscription plan, you check the history of your payments here.',
    ru: 'При переходе на платный тариф на этой странице будет отображаться история ваших платежей.',
    pt: 'Depois de escolher um plano de Assinatura, verifique o seu histórico de pagamentos aqui.',
  },
  'Copying bot': {
    eng: 'Clone bot',
    ru: 'Копирование бота',
    pt: 'Clonar bot',
  },
  'expand your capabilities': {
    eng: 'Enable new features',
    ru: 'Расширьте свои возможности',
    pt: 'Ativar novas funcionalidades',
  },
  'Cloned bot name': {
    eng: 'Cloned bot name',
    ru: 'Название',
    pt: 'Nome do bot clonado',
  },
  'Here you will see the history of your payments': {
    eng: 'Check your payment history here',
    ru: 'На этой странице будет отображаться история ваших платежей',
    pt: 'Verifique o seu histórico de pagamentos aqui',
  },
  'For calls': {
    eng: 'Calls',
    ru: 'звонки',
    pt: 'Chamadas',
  },
  'For chatbots': {
    eng: 'chatbots',
    ru: 'Чат-боты',
    pt: 'chatbots',
  },
  'For partners': {
    eng: 'For partners',
    ru: 'Для партнеров',
    pt: 'Para parceiros',
  },
  'Is open lines': {
    eng: 'Open Channels',
    ru: 'Открытые линии',
    pt: 'Canais abertos',
  },
  'Placeholder for input link': {
    eng: '',
    ru: 'Вставьте ссылку',
    pt: '',
  },
  'Application key': {
    eng: 'Application key',
    ru: 'Ключ приложения',
    pt: 'Chave do aplicativo',
  },
  'Application code': {
    eng: 'Application code',
    ru: 'Код приложения',
    pt: 'Código do aplicativo',
  },
  'Not found': {
    eng: 'Oops, page not found',
    ru: 'Упс, страница не найдена',
    pt: 'Opa, página não encontrada',
  },
  'Dont care': {
    eng: 'Don’t worry, it’s just a 404 error. Check the requested page address or return to the main page',
    ru: 'Не переживайте, это всего лишь 404 ошибка, проверьте вводимый адрес или перейдите на страницу с ботами',
    pt: 'Não se preocupe, é apenas um erro 404. Verifique o endereço da página solicitada ou retorne à página principal',
  },
  'Error:': {
    eng: 'Error:',
    ru: 'Ошибка:',
    pt: 'Erro:',
  },
  'Error:Webim2:WrongCredentials': {
    eng: 'Invalid credentials',
    ru: 'Неправильные учетные данные',
    pt: 'Credenciais inválidas',
  },
  'Error:Webim2:NoInternetConnection': {
    eng: 'No internet connection',
    ru: 'Нет соединения с интернетом',
    pt: 'Sem conexão com a internet',
  },
  'Adding channel Bitrix24': {
    eng: 'Bitrix24',
    ru: 'Битрикс24',
    pt: 'Bitrix24',
  },
  'Adding call list window': {
    eng: 'Add call list',
    ru: 'Добавление списка номеров',
    pt: 'Adicionar lista de chamadas',
  },
  'Install app': {
    eng: 'Open marketplace',
    ru: 'Открыть маркетплейс',
    pt: 'Abrir loja de aplicativos',
  },
  'Too much Bitrix channels text': {
    eng: 'You cannot add more than 5 bots to your Bitrix24 account. To connect a bot, delete the previously added Bitrix24 channel from one of the bots.',
    ru: 'Вы не можете добавить больше 5 ботов к вашему аккаунту Битрикс24. Чтобы подключить бота, удалите ранее добавленный канал Битрикс24 в одном из ботов. ',
    pt: 'Você não pode adicionar mais de 5 bots à sua conta Bitrix24. Para conectar um bot, exclua o canal Bitrix24 adicionado anteriormente de um dos bots.',
  },
  'Impossible to add channel Bitrix24': {
    eng: 'Impossible to add Bitrix24',
    ru: 'Невозможно добавить канал Битрикс24',
    pt: 'Impossível adicionar Bitrix24',
  },
  'Bitrix24 createBitrixAnyway': {
    eng: 'Ignore',
    ru: 'Все равно добавить',
    pt: 'Ignorar',
  },
  'Bitrix24 bot name': {
    eng: 'Bitrix24 bot name',
    ru: 'Имя бота в Битрикс24',
    pt: 'Nome do bot Bitrix24',
  },
  'Removing the channel': {
    eng: 'Removing the channel',
    ru: 'Удаление канала',
    pt: 'Removendo o canal',
  },
  'POST request example': {
    eng: 'POST request example',
    ru: 'Пример POST-запроса',
    pt: 'Exemplo de solicitação POST',
    cn: 'POST请求例子',
  },
  'Documentation CHAT_API': {
    eng: 'Chat API documentation',
    ru: 'Документация Chat API',
    pt: 'Documentação de Chat API',
  },
  'Documentation AZURE': {
    eng: 'Microsoft Teams documentation',
    ru: 'Документация Microsoft Teams',
    pt: 'Documentação do Microsoft Teams',
  },
  'Documentation INCOMING_JIVOSITE': {
    eng: 'JivoChat documentation',
    ru: 'Документация Jivo',
    pt: 'Documentação do JivoChat',
  },
  'Documentation Viber': {
    eng: 'Viber documentation',
    ru: 'Документация Viber',
    pt: 'Documentação do Viber',
  },
  'Documentation getMessageAboutConfiguration VK': {
    eng: 'How to get a key?',
    ru: 'Как получить ключ?',
    pt: 'Como obter uma chave?',
  },
  'Documentation getMessageAboutConfiguration FACEBOOK': {
    eng: 'How to get a token?',
    ru: 'Как получить токен?',
    pt: 'Como obter um token?',
  },
  'Documentation getMessageAboutConfiguration INSTAGRAM': {
    eng: 'How to get a token?',
    ru: 'Как получить токен?',
    pt: 'Como obter um token?',
  },
  'Documentation getMessageAboutConfiguration TELEGRAM': {
    eng: 'How to get a token?',
    ru: 'Как получить токен?',
    pt: 'Como obter um token?',
  },
  voicechannel: {
    eng: 'Outbound calls',
    ru: 'Исходящие звонки',
    pt: 'Chamadas de saída',
  },
  'attempts 1': {
    eng: '1 attempt',
    ru: '1 попытка',
    pt: '1 tentativa',
  },
  'attempts 2': {
    eng: '2 attempts',
    ru: '2 попытки',
    pt: '2 tentativas',
  },
  'attempts 3': {
    eng: '3 attempts',
    ru: '3 попытки',
    pt: '3 tentativas',
  },
  Pause: {
    eng: 'Pause',
    ru: 'Пауза',
    pt: 'Pausa',
  },
  'Po budnyam': {
    eng: 'Every weekday, from $[1] to $[2]',
    ru: 'По будням с $[1] до $[2]',
    pt: 'Todos os dias úteis, das $[1] às $[2]',
  },
  'Po budnyam + sat': {
    eng: 'Every weekday and Saturday from $[1] to $[2]',
    ru: 'По будням и в субботу с $[1] до $[2]',
    pt: 'Todos os dias úteis e todos os sábados, das $[1] às $[2]',
  },
  'Po budnyam + sun': {
    eng: 'Every weekday and Sunday from $[1] to $[2]',
    ru: 'По будням и в воскресенье с $[1] до $[2]',
    pt: 'Todos os dias úteis e todos os domingos, das $[1] às $[2]',
  },
  Weekend: {
    eng: 'Weekdays from $[1] to $[2]',
    ru: 'В выходные с $[1] до $[2]',
    pt: 'Dias úteis de $[1] a $[2]',
  },
  'Whole week': {
    eng: 'everyday',
    ru: 'ежедневно',
    pt: 'todos os dias',
  },
  'Weekday mon': {
    eng: 'Mon',
    ru: 'Пн',
    pt: 'Seg',
  },
  'Weekday tue': {
    eng: 'Tue',
    ru: 'Вт',
    pt: 'Ter',
  },
  'Weekday wed': {
    eng: 'Wed',
    ru: 'Ср',
    pt: 'Qua',
  },
  'Weekday thu': {
    eng: 'Thu',
    ru: 'Чт',
    pt: 'Qui',
  },
  'Weekday fri': {
    eng: 'Fri',
    ru: 'Пт',
    pt: 'Sex',
  },
  'Weekday sat': {
    eng: 'Sat',
    ru: 'Сб',
    pt: 'Sáb',
  },
  'Weekday sun': {
    eng: 'Sun',
    ru: 'Вс',
    pt: 'Dom',
  },
  'Weekday mon full': {
    eng: 'monday',
    ru: 'понедельник',
    pt: 'segunda',
  },
  'Weekday tue full': {
    eng: 'tuesday',
    ru: 'вторник',
    pt: 'terça',
  },
  'Weekday wed full': {
    eng: 'wednesday',
    ru: 'среду',
    pt: 'quarta',
  },
  'Weekday thu full': {
    eng: 'thursday',
    ru: 'четверг',
    pt: 'quinta',
  },
  'Weekday fri full': {
    eng: 'friday',
    ru: 'пятницу',
    pt: 'sexta',
  },
  'Weekday sat full': {
    eng: 'saturday',
    ru: 'субботу',
    pt: 'sábado',
  },
  'Weekday sun full': {
    eng: 'sunday',
    ru: 'воскресенье',
    pt: 'domingo',
  },
  weekdays: {
    eng: 'in weekdays',
    ru: 'в будни',
    pt: 'nos dias úteis',
  },
  weekend: {
    eng: 'in weekend',
    ru: 'в выходные',
    pt: 'nos fins de semana',
  },
  'Nothing choosed': {
    eng: 'No period chosen',
    ru: 'Период не выбран',
    pt: 'Nenhum período escolhido',
  },
  'Use abon local time': {
    eng: 'Use customer local time for making calls',
    ru: 'Звонить по местному времени абонента',
    pt: 'Usar o horário local do cliente para fazer chamadas',
  },
  'Use abon text': {
    eng: 'The bot will detect the timezone',
    ru: 'Бот сам определит часовой пояс',
    pt: 'O bot detectará o fuso horário',
  },
  'Use html label text': {
    eng: 'Use HTML markup in bot messages',
    ru: 'Использовать HTML-разметку в сообщениях бота',
    pt: 'Usar marcação HTML em mensagens do bot',
  },
  'Use html helper text': {
    eng: 'Text formatting is not supported by all channels',
    ru: 'Форматирование текста поддерживают не все каналы',
    pt: 'A formatação de texto não é compatível com todos os canais',
  },
  'File adder placeholder': {
    eng: 'Use the spreadsheet to provide the phone number list. You can add no more than 10,000 numbers',
    ru: 'Для добавления воспользуйтесь электронной таблицей. Перечислите не более 10 000 номеров в первом столбце.',
    pt: 'Use a planilha para fornecer a lista de números de telefone. Você não pode adicionar mais que 10 mil números',
    cn: 'Use the spreadsheet to provide the phone number list. You can add no more than 10 000 numbers',
  },
  'File upload button': {
    eng: 'File upload button',
    ru: 'Отправка файлов в виджете',
    pt: 'Botão de carregamento de arquivos',
  },
  'Table download': {
    eng: 'A correct spreadsheet example',
    ru: 'Пример правильной таблицы',
    pt: 'Um exemplo correto de planilha',
  },
  'Upload a file': {
    eng: 'Upload',
    ru: 'Загрузите',
    pt: 'Carregar',
  },
  'Only this files': {
    eng: '.xls, .xlsx, .csv, no larger than 2Mb',
    ru: 'Принимаются .xls, .xlsx, .csv и не более 2 Мб',
    pt: '.xls, .xlsx, .csv com até 2 Mb',
  },
  Rules: {
    eng: 'the Terms of Service',
    ru: 'правилами использования',
    pt: 'Os Termos de Serviço',
  },
  Retry: {
    eng: 'Try again',
    ru: 'Повторить',
    pt: 'Tente novamente',
  },
  'Confirmed quantity 1': {
    eng: 'Validated',
    ru: 'Получит рассылку',
    pt: 'Validado',
  },
  'Confirmed quantity 2': {
    eng: 'Validated',
    ru: 'Получат рассылку',
    pt: 'Validados',
  },
  'Confirmed quantity 5': {
    eng: 'Validated',
    ru: 'Получат рассылку',
    pt: 'Validados',
  },
  prediction: {
    eng: 'Cost prediction',
    ru: 'Прогноз расхода',
    pt: 'Previsão de custos',
  },
  'Current balance': {
    eng: 'Current balance',
    ru: 'Текущий баланс',
    pt: 'Saldo atual',
  },
  'Minutes packages': {
    eng: 'Minutes packages',
    ru: 'Пакеты минут',
    pt: 'Pacotes de minutos',
  },
  'Minutes packages buy title': {
    eng: 'Buy more minute packages',
    ru: 'Докупите пакеты минут',
    pt: 'Comprar mais pacotes de minutos',
  },
  minutes: {
    eng: 'minutes',
    ru: 'минут',
    pt: 'minutos',
  },
  Buy: {
    eng: 'Buy',
    ru: 'Купить',
    pt: 'Comprar',
  },
  'Call answers': {
    eng: 'Subscription FAQ',
    ru: 'Ответы на частые вопросы по тарификации',
    pt: 'Perguntas Frequentes sobre assinatura',
  },
  'Status finished': {
    eng: 'Completed',
    ru: 'Обзвон завершен',
    pt: 'Concluída',
  },
  'Status inProgress': {
    eng: 'In progress',
    ru: 'Идет обзвон',
    pt: 'Em andamento',
  },
  'Status new': {
    eng: 'Draft',
    ru: 'Черновик',
    pt: 'Rascunho',
  },
  'Status paused': {
    eng: 'Paused',
    ru: 'Обзвон на паузе',
    pt: 'Pausada',
  },
  start: {
    eng: 'Start',
    ru: 'Старт',
    pt: 'Início',
  },
  in: {
    eng: 'on',
    ru: 'в',
    pt: 'on',
  },
  at: {
    eng: 'at',
    ru: 'в',
    pt: 'às',
  },
  'Start scheduler': {
    eng: 'Start the campaign',
    ru: 'Запустить обзвон',
    pt: 'Iniciar a campanha',
  },
  'Start button scheduler': {
    eng: 'Start the campaign',
    ru: 'Запустить обзвон',
    pt: 'Iniciar a campanha',
  },
  'Start a dialogue with any user messages': {
    eng: 'Start a dialog with any user message',
    ru: 'Начинать диалог с любого сообщения пользователя',
    pt: 'Inicie um diálogo com qualquer mensagem de usuário',
  },
  'Start to work and get the present later': {
    eng: 'Get started?',
    ru: 'Начать работу?',
    pt: 'Começar?',
  },
  'start to work': {
    eng: 'get started',
    ru: 'начать работу',
    pt: 'comece',
  },
  'Pause scheduler': {
    eng: 'Pause',
    ru: 'Поставить на паузу',
    pt: 'Pausar',
  },
  'Packages of extra minutes': {
    eng: 'Packages of extra minutes',
    ru: 'Пакеты минут для телефонии',
    pt: 'Pacotes de minutos extra',
  },
  'Cost per minute': {
    eng: 'Cost per minute',
    ru: 'Стоимость минуты',
    pt: 'Custo por minuto',
  },
  'Package price': {
    eng: 'Package price',
    ru: 'Цена пакета',
    pt: 'Preço do pacote',
  },
  'Want more extra minutes?': {
    eng: 'Need more minutes?',
    ru: 'Хотите больше минут?',
    pt: 'Precisa de mais minutos?',
  },
  Limit: {
    eng: 'Package',
    ru: 'Пакет',
    pt: 'Pacote',
  },
  'List pause button': {
    eng: 'PAUSE',
    ru: 'НА ПАУЗУ',
    pt: 'PAUSAR',
  },
  'List play button': {
    eng: 'Continue',
    ru: 'Продолжить',
    pt: 'Continuar',
  },
  Mailing: {
    eng: 'Campaign',
    ru: 'Обзвон',
    pt: 'Campanha',
  },
  Processing: {
    eng: 'Processing\n Please wait',
    ru: 'Обработка\n Пожалуйста подождите',
    pt: 'Processar\n Aguarde',
  },
  'Remove mailing': {
    eng: 'Delete the campaign?',
    ru: 'Удалить обзвон?',
    pt: 'Excluir a campanha?',
  },
  'After remove cant be restored': {
    eng: 'You won’t be able to recover this campaign',
    ru: 'После удаления обзвон нельзя будет восстановить',
    pt: 'Não será possível recuperar esta campanha',
  },
  'Cancel, do not remove': {
    eng: 'Cancel',
    ru: 'Нет, оставить обзвон',
    pt: 'Cancelar',
  },
  'Yes, confirm delete': {
    eng: 'Yes, delete',
    ru: 'Да, удалить обзвон',
    pt: 'Sim, excluir',
  },
  'Mailing will stop': {
    eng: 'The campaign will be stopped. Cannot be resumed',
    ru: 'Обзвон будет завершен. Его нельзя будет возобновить',
    pt: 'A campanha será interrompida. Não é possível retomar',
  },
  'Mailing status now': {
    eng: 'Now',
    ru: 'Сейчас',
    pt: 'Agora',
  },
  'Mailing status plan': {
    eng: 'Schedule',
    ru: 'Запланировать',
    pt: 'Agendar',
  },
  'Bad format': {
    eng: 'Incorrect format',
    ru: 'Неправильный формат',
    pt: 'Formato incorreto',
  },
  'Duplicates line': {
    eng: 'Line duplicate',
    ru: 'Дубликат строки',
    pt: 'Linha duplicada',
  },
  Telephony: {
    eng: 'Telephony',
    ru: 'Телефония',
    pt: 'Telefonia',
  },
  Ivr: {
    eng: 'Inbound telephony',
    ru: 'Входящая телефония',
    pt: 'Telefonia de entrada',
  },
  Dialer: {
    eng: 'Outbound campaigns',
    ru: 'Обзвоны',
    pt: 'Campanhas de saída',
  },
  sek: {
    eng: 'sec',
    ru: 'сек',
    pt: 's',
  },
  'Number list popper prediction': {
    eng: 'Average call lasts ~30 sec',
    ru: 'Средний расход на каждый разговор ~30 сек',
    pt: 'A chamada média dura aproximadamente 30 segundos',
  },
  Remainder: {
    eng: 'Balance',
    ru: 'Остаток',
    pt: 'Saldo',
  },
  Indicator: {
    eng: 'Indicator',
    ru: 'Показатель',
    pt: 'Indicador',
  },
  'ConfirmationTimer Recall timer': {
    eng: 'Try call in $[1] sec',
    ru: 'Повторить звонок через $[1] сек',
    pt: 'Tente ligar em $[1] s',
  },
  'ConfirmationTimer Recall timer error': {
    eng: 'Less than a minute has passed since the last number confirmation attempt. You can retry in $[1] sec',
    ru: 'С последней попытки подтверждения номера прошло мало времени. Вы можете повторить звонок через $[1] сек',
    pt: 'Passou menos de um minuto desde a última tentativa de confirmação do número. Pode tentar novamente em $[1] segundos',
  },
  'BotWillCallModal title': {
    eng: 'Test by phone',
    ru: 'Тестирование по телефону',
    pt: 'Teste por telefone',
  },
  'BotWillCallModal confirm': {
    eng: 'Make a call',
    ru: 'Позвонить',
    pt: 'Faça uma chamada',
  },
  'BotWillCallModal subtitle': {
    eng: 'You will receive an automatic call to \n$[1]',
    ru: 'Бот позвонит по номеру \n$[1]',
    pt: 'Você receberá uma chamada automática para \n$[1]',
  },
  'BotWillCallModal buy minutes': {
    eng: 'Buy more minutes',
    ru: 'Купить минуты',
    pt: 'Comprar mais minutos',
  },
  'BotWillCallModal current balance': {
    eng: 'Balance',
    ru: 'Баланс',
    pt: 'Saldo',
  },
  'BotWillCallModal tooltip subtitle content': {
    eng: '15-second billing. Starts when the addressee answers or voicemail turns on',
    ru: '15-секундная тарификация начнется с 1 секунды ответа абонента или автоответчика',
    pt: 'Cobrança de 15 segundos. Começa quando o destinatário responde ou o correio de voz é ativado',
  },
  'BotWillCallModal tooltip balance content': {
    eng: 'To change the phone number used for testing, please contact customer support at {contactUsEmail}',
    ru: 'Пожалуйста, обратитесь в техподдержку {contactUsEmail}, если вы хотите изменить номер телефона для тестирования',
    pt: 'Para alterar o número de telefone utilizado para o teste, fale com o atendimento ao cliente em {contactUsEmail}',
  },
  'Publishing was successful': {
    eng: 'Published successfully',
    ru: 'Публикация прошла успешно',
    pt: 'Publicado com sucesso',
  },
  'Publishing was failed': {
    eng: 'Publishing failed',
    ru: 'Ошибка публикации',
    pt: 'A publicação falhou',
  },
  'finished task processed calls': {
    eng: 'Completed',
    ru: 'Завершено звонков',
    pt: 'Concluído',
  },
  'finished task numbers count': {
    eng: 'Numbers in campaign',
    ru: 'Номеров в обзвоне',
    pt: 'Números na campanha',
  },
  'finished task end date': {
    eng: 'End date',
    ru: 'Дата окончания',
    pt: 'Data final',
  },
  'finished task call list': {
    eng: 'Call list',
    ru: 'Список номеров',
    pt: 'Lista de chamadas',
  },
  'finished task not committed': {
    eng: 'Not committed',
    ru: 'Не совершено',
    pt: 'Não comprometida',
  },
  'Html enabled': {
    eng: 'Message text formatting',
    ru: 'Форматирование текста в сообщениях',
    pt: 'Formatação de texto da mensagem',
  },
  'Just AI copyright': {
    eng: 'Mind Craft LLC',
    ru: 'Mind Craft LLC',
    pt: 'Mind Craft LLC',
  },
  'Just create': {
    eng: 'Create',
    ru: 'Создать',
    pt: 'Criar',
  },
  VOICE_CHANNEL: {
    eng: 'Outbound calls',
    ru: 'Исходящие звонки',
    pt: 'Chamadas de saída',
  },
  'Outgoing calls is not available at this tariff': {
    eng: '$[1] are not available for this plan',
    ru: '$[1] недоступны на данном тарифе',
    pt: '$[1] não estão disponíveis para este plano',
  },
  'outgoing calls only': {
    eng: 'outbound calls only',
    ru: 'только исходящие звонки',
    pt: 'apenas chamadas de saída',
  },
  'Appeal to bot': {
    eng: 'Talk to bot',
    ru: 'Обращение к боту',
    pt: 'Falar com o bot',
  },
  'Sidebar Your account': {
    eng: 'Your account',
    ru: 'Личный кабинет',
    pt: 'Sua conta',
  },
  'AppHelpNavListItems chat': {
    eng: 'Chat with support',
    ru: 'Чат с техподдержкой',
    pt: 'Converse com o suporte',
  },
  'AppHelpNavListItems How to start': {
    eng: 'Getting started',
    ru: 'C чего начать?',
    pt: 'Início',
  },
  'AppHelpNavListItems Help': {
    eng: 'Help',
    ru: 'Справка',
    pt: 'Ajuda',
  },
  'AppHelpNavListItems Dashboard': {
    eng: 'Dashboard',
    ru: 'Главная',
    pt: 'Painel',
  },
  'Autopayment is disabled': {
    eng: 'Autopay is off',
    ru: 'Автоплатеж отключен',
    pt: 'O pagamento automático está desativado',
  },
  'Autopayment connection': {
    eng: 'Turn autopay on',
    ru: 'Подключение автоплатежа',
    pt: 'Ativar pagamento automático',
  },
  'Autopayment is enabled': {
    eng: 'Autopay is on',
    ru: 'Автоплатеж подключен',
    pt: 'O pagamento automático está ativado',
  },
  'Autopayment Disable': {
    eng: 'Turn autopay off',
    ru: 'Отключение автоплатежа',
    pt: 'Desativar pagamento automático',
  },
  'Accept and continue': {
    eng: 'Confirm and continue',
    ru: 'Принять и продолжить',
    pt: 'Confirmar e continuar',
  },
  'AutopaymentModal warning message': {
    eng: 'To turn the autopay on, please add a bank card. \nWe will charge 1 ₽ from your card and then return it later. You can cancel the autopay in Your plan menu.',
    ru: 'Для включения автоплатежа необходимо привязать карту. \nС карты будет списан 1 ₽, а потом возвращен обратно. Отключить автоплатеж можно в Управлении подпиской.',
    pt: 'Para ativar o pagamento automático, adicione um cartão bancário. \nCobraremos 1 ₽ no seu cartão e devolveremos mais tarde. Você pode cancelar o pagamento automático no menu Seu plano.',
  },
  'AutopaymentModal warning message stripe': {
    eng: 'To turn the autopay on, please add a bank card. You can cancel the autopay in Your plan menu.',
    ru: 'Для включения автоплатежа необходимо привязать карту. Отключить автоплатеж можно в Управлении подпиской.',
    pt: 'Para ativar a opção de ativação automática, adicione um cartão bancário. Você pode cancelar a opção de pagamento automático no menu Seu plano.',
  },
  'Bots will continue to work until $[1]. Then payment will not be debited, the bots will be suspended': {
    eng: 'Your bots will continue working till $[1]. Then they will stop unless you pay for the service.',
    ru: 'Боты продолжат работать до $[1]. Потом оплата списываться не будет, работа ботов будет приостановлена',
    pt: 'Os seus bots continuarão a funcionar até $[1]. Depois disso, serão interrompidos a menos que você pague pelo serviço.',
  },
  'AutopaymentDisableModal warning message': {
    eng: 'If you turn the autopay back on, you will need to enter your card information. We do not store your bank card details.',
    ru: 'При возобновлении автоплатежа сервис попросит ввести данные карты. Данные о вашей карте не хранятся в #{config.zenflow.productName}',
    pt: 'Se você ativar novamente o pagamento automático, será necessário inserir as informações do seu cartão. Não armazenamos os dados do seu cartão bancário.',
  },
  'yes disable': {
    eng: 'Turn autopay off',
    ru: 'да, отключить',
    pt: 'Desativar pagamento automático',
  },
  'Free of charge until $[1]': {
    eng: 'Free of charge until $[1]',
    ru: 'Бесплатно до $[1]',
    pt: 'Gratuito até $[1]',
  },
  'AutopaymentTariffModal warning message': {
    eng: 'To use the plan, please add a bank card. \nWe will charge 1 ₽ from your card and the return it. You can cancel the autopay in Your plan menu.',
    ru: 'Для использования тарифа необходимо привязать карту. \nС карты будет списан 1 ₽, а потом возвращен обратно. Отключить автоплатеж можно в Управлении подпиской.',
    pt: 'Para usar o plano, adicione um cartão bancário. \nCobraremos 1 ₽ no seu cartão e o devolveremos. Você pode cancelar o pagamento automático no menu Seu plano.',
  },
  'AutopaymentTariffModal warning message stripe': {
    eng: 'To use the plan, please add a bank card. You can cancel the autopay in Your plan menu.',
    ru: 'Для использования тарифа необходимо привязать карту. Отключить автоплатеж можно в Управлении подпиской.',
    pt: 'Para utilizar o plano, adicione um cartão bancário. Você pode cancelar a opção de pagamento automático no menu Seu plano.',
  },
  'TariffConnectionModal start from': {
    eng: 'Starting from $[1] the payment will be',
    ru: 'С $[1] оплата',
    pt: 'A partir de $[1], o pagamento será',
  },
  'TariffConnectionModal start from calls': {
    eng: 'You’ll be charged on $[1]',
    ru: 'Оплата с $[1]',
    pt: 'Será cobrada uma taxa de $[1]',
  },
  'TariffConnectionModal month price': {
    eng: '$[1] per month',
    ru: '$[1] в месяц',
    pt: '$[1] por mês',
  },
  'TariffConnectionModal debited automatically': {
    eng: 'You will be charged automatically',
    ru: 'Оплата списывается автоматически',
    pt: 'A cobrança será realizada automaticamente',
  },
  'TariffConnectionModal debited automatically calls': {
    eng: 'You’ll be auto-charged',
    ru: 'Деньги списываются автоматически',
    pt: 'A cobrança será realizada automaticamente',
  },
  'Payment: $[1] per month': {
    eng: 'Payment: $[1] per month',
    ru: 'Оплата: $[1] в месяц',
    pt: 'Pagamento: $[1] por mês',
  },
  'Payment:BE-error accountsadmin.user.email.not.filled': {
    eng: 'To be able to subscribe to a plan and buy additional packages, fill out your email in the profile settings',
    ru: 'Чтобы подключить тариф или купить дополнительные пакеты, заполните email в личном кабинете',
    pt: 'Para poder assinar um plano e comprar pacotes adicionais, preencha o seu e-mail nas configurações do perfil',
  },
  'Pay now': {
    eng: 'Pay now',
    ru: 'Оплатить подписку',
    pt: 'Pagar agora',
  },
  'Why did it happen?': {
    eng: 'Why did it happen?',
    ru: 'Почему это случилось?',
    pt: 'Por que isso aconteceu?',
  },
  'Possible reasons: the autopay is off, there is not enough funds on your bank card balance, your bank card is expired, bank cancelled the operation':
    {
      eng: 'Possible reasons: the autopay is off, your bank card balance is low, your bank card is expired, your bank cancelled the operation',
      ru: 'Возможные причины: не включен автоплатеж, недостаточно средств на карте, истек срок действия карты, банк заблокировал операцию',
      pt: 'Possíveis razões: o pagamento automático está desativado, o saldo do seu cartão de banco está baixo, seu cartão de banco está expirado, seu banco cancelou a operação',
    },
  'Learn more': {
    eng: 'Learn more',
    ru: 'Читать подробнее',
    pt: 'Saiba mais',
  },
  'What does account protection mean?': {
    eng: 'What does account protection mean?',
    ru: 'Что такое защита аккаунта?',
    pt: 'O que significa proteção de conta?',
  },
  'Verification letter sent to your mail': {
    eng: 'We’ve sent you a verification email',
    ru: 'На вашу почту отправлено письмо для верификации',
    pt: 'Enviamos um e-mail de confirmação para você',
  },
  'ClientLogItem Message has been deleted': {
    eng: 'Message has been deleted',
    ru: 'Сообщение удалено',
    pt: 'A mensagem foi excluída',
  },
  'ClientLogItem Audio': {
    eng: 'Audio',
    ru: 'Аудио',
    pt: 'Áudio',
  },
  'ClientLogItem crmIntegration BITRIX LEAD_CREATION': {
    eng: 'Bitrix24 lead creation',
    ru: 'Битрикс24 создание лида',
    pt: 'Criação do cliente em potencial Bitrix24',
  },
  'ClientLogItem crmIntegration BITRIX DEAL_CREATION': {
    eng: 'Bitrix24 deal creation',
    ru: 'Битрикс24 создание сделки',
    pt: 'Criação de oferta Bitrix24',
  },
  'ClientLogItem crmIntegration BITRIX DEAL_UPDATE': {
    eng: 'Bitrix24 deal update',
    ru: 'Битрикс24 обновление сделки',
    pt: 'Atualização de oferta Bitrix24',
  },
  'ClientLogItem switchToOperator': {
    eng: 'Transfer to agent',
    ru: 'Перевод на оператора',
    pt: 'Transferência para o agente',
  },
  "What's next": {
    eng: 'What’s next?',
    ru: 'Что дальше?',
    pt: 'O que vem a seguir?',
  },
  Removed: {
    eng: 'Removed',
    ru: 'Удален',
    pt: 'Removido',
  },
  'settings webhook description': {
    eng: 'Use webhooks to implement a chatbot logic \n on a server using any programming language.',
    ru: 'С помощью вебхуков вы можете реализовывать логику \n бота на сервере на любом языке программирования.',
    pt: 'Use webhooks para implementar uma lógica de chatbots \n em um servidor usando qualquer linguagem de programação.',
  },
  'Header how to create smart calls': {
    eng: 'How to create a call campaign',
    ru: 'Что такое голосовые боты',
    pt: 'Como criar uma campanha de chamadas',
  },
  'Header calls planning': {
    eng: 'Call campaign billing',
    ru: 'Тарификация обзвонов',
    pt: 'Faturamento de campanha de chamadas',
  },
  'Header Telephony Basic': {
    eng: 'Telephony Basic',
    ru: 'Звонки Basic',
    pt: 'Telefonia Básica',
  },
  'Header Telephony Standard': {
    eng: 'Telephony Standard',
    ru: 'Звонки Standard',
    pt: 'Telefonia Padrão',
  },
  'Header Telephony Pro': {
    eng: 'Calls Pro',
    ru: 'Звонки Pro',
    pt: 'Chamadas Pro',
  },
  'Header popover justai minutes': {
    eng: 'Outbound calls',
    ru: 'Исходящие вызовы',
    pt: 'Chamadas de saída',
  },
  'Header popover mixed minutes': {
    eng: 'Speech recognition and synthesis',
    ru: 'Распознавание и синтез речи',
    pt: 'Reconhecimento e síntese de fala',
  },
  'Smart calls label': {
    eng: 'Calls',
    ru: 'Обзвоны',
    pt: 'Chamadas',
  },
  'Incoming calls label': {
    eng: 'Inbound calls',
    ru: 'Входящая телефония',
    pt: 'Chamadas de entrada',
  },
  'Incoming secret': {
    eng: 'Incoming secret',
    ru: 'Входящий ключ',
    pt: 'Segredo de entrada',
    cn: '呼入密钥',
  },
  'Become an Partner': {
    eng: 'Become an $[1] Partner',
    ru: 'Станьте партнером $[1]',
    pt: 'Torne-se um Parceiro $[1]',
  },
  'Become an partner': {
    eng: 'Become an $[1] partner',
    ru: 'Стать партнером $[1]',
    pt: 'Torne-se um parceiro $[1]',
  },
  'Become a partner': {
    eng: 'Become a partner',
    ru: 'Стать партнером',
    pt: 'Torne-se um parceiro',
  },
  'And get money through an affiliate program': {
    eng: 'And get money through our affiliate program',
    ru: 'И получайте деньги по партнерской программе ',
    pt: 'E ganhe dinheiro com nosso programa de afiliados',
  },
  remuneration: {
    eng: 'commission',
    ru: 'вознаграждение',
    pt: 'comissão',
  },
  payouts: {
    eng: 'payouts',
    ru: 'выплаты',
    pt: 'pagamentos',
  },
  monthly: {
    eng: 'monthly',
    ru: 'ежемесячно',
    pt: 'mensal',
  },
  'up to': {
    eng: 'up to',
    ru: 'до',
    pt: 'até',
  },
  'Name of legal entity or individual entrepreneur': {
    eng: 'Name of legal entity or individual entrepreneur',
    ru: 'Название юрлица или ИП',
    pt: 'Nome da pessoa jurídica ou do empresário individual',
  },
  TIN: {
    eng: 'TIN',
    ru: 'ИНН',
    pt: 'CNPJ',
  },
  BIC: {
    eng: 'BIC',
    ru: 'БИК',
    pt: 'BIC',
  },
  'Bank account': {
    eng: 'Bank account',
    ru: 'Счет в банке',
    pt: 'Conta bancária',
  },
  'PartnerRequestModal warning message': {
    eng: 'Check your bank details. We’ll use them to transfer your commissions',
    ru: 'Проверьте корректность банковских реквизитов — по ним мы будем перечислять ваше вознаграждение по партнерской програме',
    pt: 'Verifique seus dados bancários. Vamos utilizá-los para transferir suas comissões',
  },
  'Partnership agreement': {
    eng: 'Partnership agreement',
    ru: 'Партнерским соглашением',
    pt: 'Contrato de parceria',
  },
  '20-digit account number': {
    eng: '20-digit account number',
    ru: '20-значный номер расчетного счета',
    pt: 'Número de conta de 20 dígitos',
  },
  'Affiliate program': {
    eng: 'Affiliate program',
    ru: 'Партнерская программа',
    pt: 'Programa de afiliados',
  },
  'Affiliate Program Rules': {
    eng: 'Affiliate Program Rules',
    ru: 'Правила работы партнерской программы',
    pt: 'Regras do Programa de Afiliados',
  },
  'Partner account': {
    eng: 'Partner account',
    ru: 'Кабинет партнера',
    pt: 'Conta de parceiro',
  },
  'Partner email verification text': {
    eng: 'To continue registration in the affiliate program, please confirm your email address. We sent an email with a confirmation link to <b>$[1]</b>',
    ru: 'Для продолжения регистрации в партнерской програме вы должны подтвердить свой адрес электронной почты. Мы выслали письмо со ссылкой для подтверждения на почту <b>$[1]</b>',
    pt: 'Para continuar o registro no programa de afiliados, confirme o seu endereço de e-mail. Enviamos uma mensagem de e-mail com um link de confirmação para <b>$[1]</b>',
  },
  'Partner email verification text 2': {
    eng: 'Follow the link in the letter and click the button “Become a partner”',
    ru: 'Перейдите по ссылке в письме и вновь нажмите на кнопку «Стать партнером»',
    pt: 'Siga o link na mensagem e clique no botão "Tornar-se um parceiro"',
  },
  'Please confirm the e-mail verification to proceed': {
    eng: 'Email Verification',
    ru: 'Подтверждение электронной почты',
    pt: 'Confirmação por e-mail',
  },
  'Reg date': {
    eng: 'Reg.',
    ru: 'Рег.',
    pt: 'Regist.',
  },
  'Referral link copied': {
    eng: 'Referral link copied',
    ru: 'Реферальная ссылка скопирована',
    pt: 'Link referência copiado',
  },
  active: {
    eng: 'active',
    ru: 'активный',
    pt: 'ativo',
  },
  blocked: {
    eng: 'blocked',
    ru: 'заблокирован',
    pt: 'bloqueado',
  },
  'NLUsettings title': {
    eng: 'NLU settings',
    ru: 'Настройка NLU',
    pt: 'Configurações de NLU',
  },
  'NLUsettings subtitle1': {
    eng: 'Dictionaries',
    ru: 'Словари синонимов',
    pt: 'Dicionários',
  },
  'NLUsettings subtitle2': {
    eng: 'Classifier threshold',
    ru: 'Порог срабатывания классификатора',
    pt: 'Limite do classificador',
  },
  'NLUsettings dictionary custom': {
    eng: 'Custom',
    ru: 'Пользовательские',
    pt: 'Personalizadas',
  },
  'NLUsettings dictionary extended': {
    eng: 'Advanced dictionary',
    ru: 'Расширенный словарь',
    pt: 'Dicionário avançado',
  },
  'NLUsettings dictionary small': {
    eng: 'Reduced dictionary',
    ru: 'Сокращенный словарь',
    pt: 'Dicionário reduzido',
  },
  'NLUsettings dictionary disabled': {
    eng: 'Turn off',
    ru: 'Отключить',
    pt: 'Desligar',
  },
  'NLUsettings subtitle icon 1 text': {
    eng: 'Help bot to understand similar words',
    ru: 'Помогают боту определять похожие по смыслу слова',
    pt: 'Ajude o bot a entender palavras semelhantes',
  },
  'NLUsettings subtitle icon 2 text': {
    eng: 'The minimum confidence level for NLU classifier',
    ru: 'Минимальный confidence level NLU классификатора',
    pt: 'O nível mínimo de confiança para o classificador de NLU',
  },
  'NLUsettings helpertext extended': {
    eng: 'Covers a wide variety of words. This will raise the likelihood of intent recognition. However, false positives are also possible.',
    ru: 'Охватывает большой набор слов. Выбор этого словаря повысит вероятность распознания интента. Однако возможны ложные срабатывания',
    pt: 'Cobre uma grande variedade de palavras. Isso aumentará a probabilidade de reconhecimento de intenções. No entanto, falsos positivos também são possíveis.',
  },
  'NLUsettings helpertext small': {
    eng: 'Only covers a basic word set. Use this dictionary instead of the advanced one to decrease the amount of false positive intent recognition.',
    ru: 'Охватывает только основной набор слов. Используйте этот словарь вместо расширенного, чтобы уменьшить количество ложных срабатываний интентов',
    pt: 'Abrange apenas um conjunto de palavras básico. Use este dicionário em vez do avançado para diminuir a quantidade de falso reconhecimento de intenção positiva.',
  },
  'NLUsettings helpertext disabled': {
    eng: 'This will turn off all the dictionaries. It can be necessary when intents must be determined precisely in the script.',
    ru: 'Отключение всех словарей синонимов. Отключение словаря необходимо, когда интенты должны строго определяться только по описанию в сценарии',
    pt: 'Isso desativará todos os dicionários. Pode ser necessário quando as intenções devem ser determinadas com precisão no script.',
  },
  'NLUsettings save settings': {
    eng: 'Save settings',
    ru: 'Сохранить настройки',
    pt: 'Salvar configurações',
  },
  'NLUsettings restore settings': {
    eng: 'Use default settings',
    ru: 'Использовать настройки по умолчанию',
    pt: 'Utilizar configurações padrão',
  },
  'NLUSettings: BE-error save nlu editorbe.storage.legacy_api_not_applicable': {
    eng: 'Error saving data. Save the script from the editor',
    ru: 'Ошибка сохранения. Необходимо сохранить сценарий из редактора',
    pt: 'Erro ao salvar dados. Salve o script do editor',
  },
  'Price per minute includes costs of speech recognition and synthesis as well as rental costs of phone line and number':
    {
      eng: 'Price per minute includes costs of speech recognition and synthesis as well as rental costs of phone line and number',
      ru: 'В стоимость минуты включена стоимость распознавания и синтеза речи, а также аренда телефонной линии и номера',
      pt: 'O preço por minuto inclui os custos de reconhecimento e síntese de voz, bem como os custos de aluguel da linha telefônica e do número',
    },
  'WhatsAppEditForm I_DIGITAL': {
    eng: 'Via i-Digital',
    ru: 'Через i-Digital',
    pt: 'Via i-Digital',
  },
  'WhatsAppEditForm NEXMO': {
    eng: 'Via Vonage',
    ru: 'Через Vonage',
    pt: 'Via Vonage',
  },
  'WhatsAppEditForm WHATSAPP': {
    eng: 'Direct',
    ru: 'Напрямую',
    pt: 'Direto',
  },
  'WhatsAppEditForm I-DIGITAL source': {
    eng: 'Account name',
    ru: 'Имя учетной записи',
    pt: 'Nome da conta',
  },
  'WhatsAppEditForm service delivered by': {
    eng: 'Connection method',
    ru: 'Способ подключения',
    pt: 'Método de conexão',
  },
  'WhatsAppEditForm how to choose': {
    eng: 'Who is service provider?',
    ru: 'Как выбрать поставщика услуг?',
    pt: 'Quem é provedor de serviços?',
  },
  'Upgrade plan': {
    eng: 'Upgrade',
    ru: 'Подключить',
    pt: 'Atualizar',
    cn: '连接',
  },
  'ChannelsConfig: force reply jivo label': {
    eng: 'Disable text input when using buttons',
    ru: 'Блокировать ввод текста при использовании кнопок',
    pt: 'Desativar entrada de texto quando usar botões',
  },
  "Don't show again": {
    eng: 'Don’t show again',
    ru: 'Больше не показывать',
    pt: 'Não mostrar novamente',
  },
  'PartnersPersonalAccount payments history title': {
    eng: 'Previous payments',
    ru: 'Предыдущие выплаты',
    pt: 'Pagamentos anteriores',
  },
  'Promotional code': {
    eng: 'Promotional code',
    ru: 'Промокод',
    pt: 'Código promocional',
  },
  Activate: {
    eng: 'Activate',
    ru: 'Активировать',
    pt: 'Ativar',
  },
  'VisualEditorHelp headline': {
    eng: 'Help',
    ru: 'Помощь',
    pt: 'Ajuda',
  },
  'VisualEditorHelp docs': {
    eng: 'Read the documentation',
    ru: 'Читайте документацию',
    pt: 'Leia a documentação',
  },
  'VisualEditorHelp video': {
    eng: 'Watch the tutorial video',
    ru: 'Смотрите обучающее видео',
    pt: 'Veja o vídeo tutorial',
  },
  'VisualEditorHelp video link': {
    eng: 'https://youtu.be/D-B0Mu2iScE',
    ru: 'https://www.youtube.com/watch?v=zTRM_YGsixc&feature=youtu.be',
    pt: 'https://youtu.be/D-B0Mu2iScE',
  },

  'VisualEditorHelp:Headline:Gpt': {
    eng: 'Additional settings',
    ru: 'Дополнительные настройки',
    pt: 'Configurações adicionais',
  },
  'VisualEditorHelp:Docs:Gpt': {
    eng: `How to use ChatGPT\nto answer unknown\nphrases`,
    ru: `Как использовать ChatGPT\nдля ответов на неизвестные\nфразы`,
    pt: '',
  },
  Subscription: {
    eng: 'Subscription',
    ru: 'Подписка',
    pt: 'Assinatura',
  },
  'ChannelItem status OK': {
    eng: 'Channel connected',
    ru: 'Канал подключен',
    pt: 'Canal conectado',
  },
  'ChannelItem status TEST_FAILED': {
    eng: 'Test failed',
    ru: 'Ошибка тестов',
    pt: 'Falha no teste',
  },
  'ChannelItem status DEPLOY_FAILED': {
    eng: 'Bot publishing failed',
    ru: 'Не удалось опубликовать бота',
    pt: 'Falha na publicação do bot',
  },
  'ChannelItem status IN_PROGRESS': {
    eng: 'Bot is being published',
    ru: 'Бот публикуется',
    pt: 'O bot está sendo publicado',
  },
  'ChannelItem status OUTDATED': {
    eng: 'Awaiting bot publication',
    ru: 'Бот ожидает публикации',
    pt: 'Aguardando publicação do bot',
  },
  'ChannelItem status UNAVAILABLE': {
    eng: 'Channel disconnected',
    ru: 'Канал отключен',
    pt: 'Canal desconectado',
  },
  'OperatorChannelItem BITRIX': {
    eng: 'Bitrix24',
    ru: 'Битрикс24',
    pt: 'Bitrix24',
    cn: 'Open line Bitrix24',
  },
  'OperatorChannelItem WEBIM': {
    eng: 'Webim (Custom Channel API)',
    ru: 'Webim (Custom Channel API)',
    pt: 'Webim (Custom Channel API)',
  },
  'OperatorChannelItem LIVETEX': {
    eng: 'LiveTex (obsolete)',
    ru: 'LiveTex (устарел)',
    pt: 'LiveTex (obsoleto)',
  },
  'OperatorChannelItem LIVETEX_VISITOR': {
    eng: 'LiveTex',
    ru: 'LiveTex',
    pt: 'LiveTex',
  },
  'OperatorChannelItem INCOMING_JIVOSITE': {
    eng: 'JivoChat',
    ru: 'Jivo',
    pt: 'JivoChat',
  },
  'OperatorChannelItem JIVOSITE': {
    eng: 'JivoChat',
    ru: 'Jivo',
    pt: 'JivoChat',
  },
  'OperatorChannelItem RETAILCRM': {
    eng: 'RetailCRM',
    ru: 'RetailCRM',
    pt: 'RetailCRM',
  },
  'OperatorChannelItem TALKME': {
    eng: 'Talk-me',
    ru: 'Talk-me',
    pt: 'Talk-me',
  },
  'PromocodeActivationForm accountsadmin.billing.promocode_to_many_requests': {
    eng: 'Too many attempts to enter the promo code. Try again in 10 minutes…',
    ru: 'Попытки ввести промокод исчерпаны. Попробуйте через 10 минут…',
    pt: 'Muitas tentativas para inserir o código promocional. Tente novamente em 10 minutos…',
  },
  'PromocodeActivationForm accountsadmin.billing.promocode_not_found': {
    eng: 'The promotional code is invalid',
    ru: 'Промокод недействителен',
    pt: 'O código promocional é inválido',
  },
  'VoiceMailing TimePicker Use abon details false': {
    eng: 'The bot will call your clients $[1] from $[2] to $[3] in your timezone',
    ru: 'Бот будет звонить вашим клиентам $[1] с $[2] до $[3] по вашему часовому поясу',
    pt: 'The bot will call your clients $[1] from $[2] to $[3] in your timezone',
  },
  'VoiceMailing TimePicker Use abon details true': {
    eng: 'The bot will call your clients $[1] from $[2] to $[3] in their timezone',
    ru: 'Бот будет звонить вашим клиентам $[1] с $[2] до $[3] по их часовому поясу',
    pt: 'The bot will call your clients $[1] from $[2] to $[3] in their timezone',
  },
  and: {
    eng: 'and',
    ru: 'и',
    pt: 'e',
  },
  'week in': {
    eng: 'on',
    ru: 'в',
    pt: 'na',
  },
  'week in tuesday': {
    eng: 'on',
    ru: 'во',
    pt: 'na',
  },
  'CallsListItem homepage': {
    eng: 'Chatbots',
    ru: 'Чат-боты',
    pt: 'Chatbots',
  },
  'CallsListItem calls': {
    eng: 'Calls',
    ru: 'Звонки',
    pt: 'Chamadas',
  },
  'CallsListItem tariffs': {
    eng: 'Pricing',
    ru: 'Тарифы',
    pt: 'Preços',
  },
  'CallsListItem partner': {
    eng: 'Affiliate program',
    ru: 'Партнерская программа',
    pt: 'Programa de afiliados',
  },
  'CallsListItem webinars': {
    eng: 'Webinars',
    ru: 'Вебинары',
    pt: 'Webinars',
  },
  'CallsListItem helpCenter': {
    eng: 'Help Center',
    ru: 'Справочный центр',
    pt: 'Central de Ajuda',
  },
  'CallsListItem webinars link': {
    eng: '',
    ru: 'https://aimylogic.com/ru/webinars',
    pt: '',
  },
  'Templates headline': {
    eng: 'Chatbots',
    ru: 'Чат-боты',
    pt: 'Chatbots',
  },
  'Templates templates headline': {
    eng: 'Ready-made solutions for chatbots',
    ru: 'Готовые решения для чат-ботов',
    pt: 'Soluções prontas para chatbots',
  },
  'Templates Help headline': {
    eng: 'Help',
    ru: 'Помощь',
    pt: 'Ajuda',
  },
  'Templates Intents doc': {
    eng: 'How to create a chatbot from a template?',
    ru: 'Как использовать готовые решения?',
    pt: 'Como criar um chatbot a partir de um modelo?',
  },
  'CallsTariffItem Price per minute': {
    eng: 'Cost per minute',
    ru: 'Цена за 1 минуту',
    pt: 'Custo por minuto',
  },
  'CallsTariffItem Try features': {
    eng: 'Try all the Calls features we have!',
    ru: 'Распробуйте все возможности для звонков!',
    pt: 'Experimente todas as funcionalidades de chamadas que temos!',
  },
  'CallsTariffItem Start demo': {
    eng: 'Start for free',
    ru: 'Начать бесплатно',
    pt: 'Iniciar gratuitamente',
  },
  'callsApi title': {
    eng: 'Do you want to use API for calling?',
    ru: 'Хотите использовать API для звонков?',
    pt: 'Deseja usar a API para chamar?',
  },
  'callsApi text': {
    eng: 'For details on how to use Calls API, see the ',
    ru: 'О том, как использовать Calls API, читайте в ',
    pt: 'Para obter detalhes sobre como usar a Calls API, consulte as',
  },
  'callsApi text link': {
    eng: 'instructions',
    ru: 'инструкции',
    pt: 'instruções',
  },
  'callsApi button': {
    eng: 'Create token',
    ru: 'Cоздать токен',
    pt: 'Criar token',
  },
  'callsApi token': {
    eng: 'Token',
    ru: 'Токен',
    pt: 'Token',
  },
  'callsApi token copy': {
    eng: 'Get token',
    ru: 'Получить токен',
    pt: 'Obter token',
  },
  'callsApi token copied': {
    eng: 'Campaign token copied',
    ru: 'Токен обзвона скопирован',
    pt: 'Token de campanha copiado',
  },
  'callsApi token tooltip': {
    eng: 'You can use the token you created for an external service',
    ru: 'Созданный токен можете использовать для внешнего сервиса',
    pt: 'Você pode usar o token criado para um serviço externo',
  },
  'callsApi token modal title': {
    eng: 'Token creation',
    ru: 'Создание токена',
    pt: 'Criação de token',
  },
  'callsApi token modal text': {
    eng: 'Creating a Calls API token is disabled on the selected plan',
    ru: 'Возможность создания токена для использования Calls API заблокирована на выбранном тарифе',
    pt: 'A criação de Token de Calls API está desativada no plano selecionado',
  },
  'EntityCreation SaveError': {
    eng: 'Error while saving',
    ru: 'Ошибка сохранения.',
    pt: 'Erro ao salvar',
  },
  'SecondSimpleCard publish': {
    eng: 'Publish',
    ru: 'Опубликовать',
    pt: 'Publicar',
  },
  'SecondSimpleCard call results': {
    eng: 'Calling results',
    ru: 'Результаты обзвона',
    pt: 'Resultados de chamadas',
  },
  'SecondSimpleCard Deleting': {
    eng: 'Deleting…',
    ru: 'Удаляем…',
    pt: 'Excluindo…',
  },
  'Subscription Subscription $[1]': {
    eng: '$[1] subscription',
    ru: 'Подписка $[1]',
    pt: 'Assinatura de $[1]',
  },
  'Subscription Headline': {
    eng: 'Subscribe to #{config.zenflow.productName}',
    ru: 'Оформление подписки',
    pt: 'Assine o #{config.zenflow.productName}',
  },
  'Subscription Go back': {
    eng: 'Pick another subscription plan',
    ru: 'Вернуться к выбору тарифа',
    pt: 'Escolha outro plano de assinatura',
  },
  'Subscription Pay with a card': {
    eng: 'Pay with a card',
    ru: 'Оплатить картой',
    pt: 'Pagar com um cartão',
  },
  'Subscription Total $[1]': {
    eng: 'Total: <b>$[1] ₽</b>',
    ru: 'К оплате: <b>$[1] ₽</b>',
    pt: 'Total: <b>$[1] ₽</b>',
  },
  'Subscription Billed monthly': {
    eng: 'Billed monthly',
    ru: 'Цена за месяц',
    pt: 'Faturado mensalmente',
  },
  'Subscription Discount': {
    eng: 'Discount',
    ru: 'Скидка',
    pt: 'Desconto',
  },
  'Subscription Upgrade plan': {
    eng: 'Upgrade',
    ru: 'Подключить',
    pt: 'Atualizar',
    cn: '连接',
  },
  'TariffOptions Archived tariff': {
    eng: 'Archived plan',
    ru: 'Тариф в архиве',
    pt: 'Plano arquivado',
  },
  'TariffOptions Demo till $[1]': {
    eng: 'Demo till $[1]',
    ru: 'Демо до $[1]',
    pt: 'Demonstração até $[1]',
  },
  'ProjectSettings Tab variables': {
    eng: 'Template variables management',
    ru: 'Управление переменными шаблона',
    pt: 'Gerenciamento de variáveis de modelo',
  },
  'Page 403 Access denied': {
    eng: 'Access denied',
    ru: 'Доступ запрещен',
    pt: 'Acesso negado',
  },
  'Contacts title': {
    eng: 'Contacts',
    ru: 'Контакты',
    pt: 'Contatos',
  },
  'Contacts phone': {
    eng: 'Phone',
    ru: 'Телефон',
    pt: 'Telefone',
  },
  'error_size error': {
    eng: 'File size exceeds 2 MB',
    ru: 'Файл превышает максимальный размер 2 Мб',
    pt: 'O tamanho do arquivo passa de 2 MB',
  },
  'error_upload error': {
    eng: 'Error loading. Please try again',
    ru: 'Ошибка загрузки. Попробуйте еще раз',
    pt: 'Erro ao carregar. Tente novamente',
  },
  'error_extension error': {
    eng: 'Invalid file extension',
    ru: 'Неверное расширение файла',
    pt: 'Extensão de arquivo inválida',
  },
  'Constructor of chatbots': {
    eng: 'Chatbot and call\n campaign designer',
    ru: 'Конструктор\n чат-ботов и обзвонов',
    pt: 'Chatbot e designer\n de campanha de chamadas',
  },
  'Preparing workplace': {
    eng: 'Preparing your workspace',
    ru: 'Готовим ваше рабочее место',
    pt: 'Preparando seu espaço de trabalho',
    cn: 'Preparing your workspace...',
  },
  'Time left $[1]': {
    eng: '$[1] sec left',
    ru: 'Осталось $[1] сек',
    pt: '$[1] s restante(s)',
    cn: 'C$[1] sec left',
  },
  Return: {
    eng: 'Back',
    ru: 'Вернуться',
    pt: 'Voltar',
  },
  'Skip the step': {
    eng: 'Skip this step',
    ru: 'Пропустить шаг',
    pt: 'Ignorar este passo',
  },
  'ConfirmNumberModal Enter last 4 number': {
    eng: 'Enter the <b>last 4 digits</b> of the inbound call number',
    ru: 'Введите <b>последние 4 цифры</b> входящего номера',
    pt: 'Digite os <b>últimos 4 dígitos</b> do número de chamada de entrada',
  },
  '0 try': {
    eng: '0 attempts',
    ru: '0 попыток',
    pt: '0 tentativa',
  },
  '1 try': {
    eng: '1 attempt',
    ru: '1 попытка',
    pt: '1 tentativa',
  },
  '2 try': {
    eng: '2 attempts',
    ru: '2 попытки',
    pt: '2 tentativas',
  },
  '3 try': {
    eng: '3 attempts',
    ru: '3 попытки',
    pt: '3 tentativas',
  },
  'Verifying attemp failed': {
    eng: 'You can confirm your phone number later',
    ru: 'Вы сможете подтвердить свой номер телефона позже',
    pt: 'Você pode confirmar o seu número de telefone mais tarde',
  },
  'try one more time': {
    eng: 'confirm my phone number',
    ru: 'подтвердить номер телефона',
    pt: 'confirmar meu número de telefone',
  },
  Or: {
    eng: 'Or',
    ru: 'Или',
    pt: 'Ou',
  },
  'Request bot development': {
    eng: 'Order bot',
    ru: 'Заказать бота',
    pt: 'Solicitar bot',
  },
  'BotDevelopmentRequest Our manager got your request': {
    eng: 'Our manager has received your request',
    ru: 'Заявка на разработку бота уже у нашего менеджера',
    pt: 'O nosso gerente recebeu sua solicitação',
    cn: 'Our manager has received your bot development order',
  },
  'BotDevelopmentRequest They will contact you': {
    eng: 'We’ll get back to you within two business days',
    ru: 'Мы свяжемся с вами в течение двух рабочих дней',
    pt: 'Entraremos em contato com você em até dois dias úteis',
  },
  'BotDevelopmentRequest Go to my dashboard': {
    eng: 'back to dashboard',
    ru: 'вернуться на дашборд',
    pt: 'voltar ao painel',
  },
  'BotDevelopmentRequest full name': {
    eng: 'Full name',
    ru: 'Фамилия Имя Отчество',
    pt: 'Nome completo',
  },
  'BotDevelopmentRequest company name': {
    eng: 'Company name',
    ru: 'Название компании',
    pt: 'Nome da empresa',
  },
  'BotDevelopmentRequest description': {
    eng: 'Project description',
    ru: 'Описание проекта',
    pt: 'Descrição do projeto',
  },
  'BotDevelopmentRequest Upload a file': {
    eng: 'Attach your file',
    ru: 'Прикрепите файл',
    pt: 'Anexe seu arquivo',
  },
  'BotDevelopmentRequest or drop here': {
    eng: 'or drag it here',
    ru: 'или перетащите его сюда',
    pt: 'ou arraste-o para cá',
  },
  'BotDevelopmentRequest Confirm btn': {
    eng: 'Send request',
    ru: 'отправить заявку',
    pt: 'Enviar solicitação',
    cn: 'Send order',
  },
  'BotDevelopment Headline': {
    eng: 'Commission your bot development',
    ru: 'Заявка на разработку бота',
    pt: 'Comissione o desenvolvimento do seu bot',
    cn: 'Bot development order',
  },
  'BotDevelopment File input label': {
    eng: 'Files',
    ru: 'Файлы',
    pt: 'Arquivos',
  },
  'BotDevelopment File input message': {
    eng: 'The total file size cannot exceed 5 MB',
    ru: 'Общий размер файлов должен быть до 5 МБ',
    pt: 'O tamanho total do arquivo não pode passar de 5 MB',
  },
  'BotDevelopment File size error': {
    eng: 'The file is too big',
    ru: 'Размер файла слишком большой',
    pt: 'O arquivo é muito grande',
  },
  'BotDevelopment File list': {
    eng: 'File list',
    ru: 'Список файлов',
    pt: 'Lista de arquivos',
  },
  'Return to mobile version': {
    eng: 'mobile version',
    ru: 'версия для мобильных',
    pt: 'versão móvel',
  },
  'Login Thank you for choosing': {
    eng: 'Thank you for choosing $[1]',
    ru: 'Спасибо, что выбрали $[1]',
    pt: 'Agradecemos por escolher $[1]',
  },
  'MobileVersion return to dashboard': {
    eng: 'back to home page',
    ru: 'вернуться на главную страницу',
    pt: 'voltar à página inicial',
  },
  'MobileVersion constuctor modal warning': {
    eng: 'We recommend using your laptop or desktop PC to work with the visual editor',
    ru: 'Мы рекомендуем работать в конструкторе с ноутбука или компьютера',
    pt: 'Recomendamos usar seu laptop ou PC de mesa para trabalhar com o editor visual',
    cn: 'We recommend using your laptop or desktop PC to work with the constructor',
  },
  'MobileVersion modal warning': {
    eng: 'We recommend using your laptop or desktop PC',
    ru: 'Мы рекомендуем работать с ноутбука или компьютера',
    pt: 'Recomendamos a utilização do seu laptop ou desktop',
  },
  'MobileVersion constuctor modal info': {
    eng: '#{config.zenflow.productName} is optimized for any desktop device',
    ru: '#{config.zenflow.productName} оптимизирован под десктопные устройства',
    pt: '#{config.zenflow.productName} é otimizado para qualquer dispositivo desktop',
  },
  'MobileVersion modal stay': {
    eng: 'I do not care',
    ru: 'Мне все равно',
    pt: 'Não me importo',
  },
  Popular: {
    eng: 'Best value',
    ru: 'Популярно',
    pt: 'Melhor valor',
  },
  Sale: {
    eng: 'Sale',
    ru: 'Скидка',
    pt: 'Venda',
  },
  'CallTemplate underline': {
    eng: 'How to work with ready-made templates?',
    ru: 'Как работать с готовыми шаблонами?',
    pt: 'Como trabalhar com modelos prontos?',
    cn: 'How do I work with templates?',
  },
  timezoneBadFormat: {
    eng: 'Wrong timezone format',
    ru: 'Неверный формат таймзоны',
    pt: 'Formato errado de fuso horário',
  },
  'AZURE app id label': {
    eng: 'Microsoft App ID',
    ru: 'Microsoft App ID',
    pt: 'ID do aplicativo Microsoft',
  },
  'VoiceChannelsAdding title': {
    eng: 'Client base call campaign',
    ru: 'Обзвон по базе клиентов',
    pt: 'Campanha de chamadas da base de clientes',
  },
  'VoiceChannelsAdding btn': {
    eng: 'Create a call campaign',
    ru: 'Создать обзвон',
    pt: 'Criar uma campanha de chamadas',
  },
  'VoiceChannelsRestrictedByTariff incoming': {
    eng: 'Inbound calls',
    ru: 'Входящие звонки',
    pt: 'Chamadas de entrada',
  },
  'VoiceChannelsRestrictedByTariff outgoing': {
    eng: 'Outbound calls',
    ru: 'Исходящие звонки',
    pt: 'Chamadas de saída',
  },
  'Statistics call': {
    eng: 'Call',
    ru: 'Звонок',
    pt: 'Chamada',
  },
  'PhoneInput search placeholder': {
    eng: 'Search…',
    ru: 'Поиск',
    pt: 'Pesquisa…',
    cn: 'Search...',
  },
  "9-digit BIC of the beneficiary's bank": {
    eng: '9-digit BIC of the beneficiary’s bank',
    ru: '9-значный БИК банка получателя',
    pt: 'BIC de 9 dígitos do banco do beneficiário',
  },
  'ChatwidgetEditorPage.SoundNotifications option label': {
    eng: 'Sound effect',
    ru: 'Звуковой эффект',
    pt: 'Efeito sonoro',
  },
  'ChatwidgetEditorPage.SoundNotifications option none': {
    eng: 'Mute all sounds',
    ru: 'Выключить все звуки',
    pt: 'Silenciar todos os sons',
  },
  'ChatwidgetEditorPage.SoundNotifications option client': {
    eng: 'Client messages',
    ru: 'Сообщения клиента',
    pt: 'Mensagens de cliente',
  },
  'ChatwidgetEditorPage.SoundNotifications option bot': {
    eng: 'Bot messages',
    ru: 'Сообщения бота',
    pt: 'Mensagens de bot',
  },
  'ChatwidgetEditorPage.SoundNotifications option client_bot': {
    eng: 'Client and bot messages',
    ru: 'Сообщения клиента и бота',
    pt: 'Mensagens do cliente e do bot',
  },
  'ChatwidgetEditorPage.SoundNotifications option sound': {
    eng: 'Sound',
    ru: 'Звук',
    pt: 'Som',
  },
  'ChatwidgetEditorPage.SoundNotifications option choose sound': {
    eng: 'Select sound',
    ru: 'Выберите звук',
    pt: 'Selecione som',
  },
  'ChatWidgetEditor.embedmentContainer': {
    eng: 'Embedding the widget in a container',
    ru: 'Встраивание виджета в контейнер на странице',
    pt: 'Incorporando o widget em um recipiente',
  },
  'ChatWidgetEditor.embedmentContainer.id': {
    eng: 'Embedding ID',
    ru: 'ID для встраивания',
    pt: 'ID de incorporação',
  },
  'ChatWidgetEditor.embedmentContainer.docLink': {
    eng: '/channels/chatwidget/container_cw',
    ru: '/channels/chatwidget/container_cw',
    pt: '/channels/chatwidget/container_cw',
  },
  number1: {
    eng: 'number',
    ru: 'номер',
    pt: 'número',
  },
  number2: {
    eng: 'numbers',
    ru: 'номера',
    pt: 'números',
    cn: 'number',
  },
  number5: {
    eng: 'numbers',
    ru: 'номеров',
    pt: 'números',
    cn: 'number',
  },
  seconds: {
    eng: 'seconds',
    ru: 'секунд',
    pt: 'segundos',
  },
  'IncomingWizardTemplate set number': {
    eng: 'Specify number for testing',
    ru: 'Укажите номер для тестирования',
    pt: 'Especifique o número para teste',
  },
  'IncomingWizardTemplate We call to number': {
    eng: 'We will call it and simulate an inbound call script',
    ru: 'Мы позвоним на него и воспроизведем сценарий входящего звонка',
    pt: 'Vamos chamá-lo e simularemos um script de chamada de entrada',
  },
  Domain: {
    eng: 'Domain',
    ru: 'Домен',
    pt: 'Domínio',
  },
  Department: {
    eng: 'Department',
    ru: 'Отдел',
    pt: 'Departamento',
  },
  'EntitiesDetail:upload': {
    eng: 'Upload dictionary',
    ru: 'Загрузить справочник',
    pt: 'Carregar dicionário',
  },
  'CsvUploader:Title': {
    eng: 'Load entity dictionary',
    ru: 'Загрузка справочника сущностей',
    pt: 'Carregar dicionário de entidade',
  },
  'CsvUploader:addFile': {
    eng: 'Attach your file',
    ru: 'Прикрепите файл',
    pt: 'Anexe seu arquivo',
  },
  'CsvUploader:addFileText': {
    eng: 'or drag it here',
    ru: 'или перетащите его сюда',
    pt: 'ou arraste-o para cá',
  },
  'CsvUploader:extension': {
    eng: 'Format: .xls or .csv',
    ru: 'Формат: .xls или .csv',
    pt: 'Formato: .xls ou .csv',
  },
  'CsvUploader:size': {
    eng: 'Size: up to 1 MB',
    ru: 'Размер: не более 1Мб',
    pt: 'Tamanho: até 1 MB',
  },
  'CsvUploader:downloadExamples': {
    eng: 'Download examples of ',
    ru: 'Скачать примеры',
    pt: 'Baixar exemplos',
  },
  'CsvUploader:file': {
    eng: '$[1] file',
    ru: '$[1]-файла',
    pt: 'de um arquivo $[1]',
  },
  'CsvUploader:docText': {
    eng: 'See detailed recommendations in the',
    ru: 'Подробные рекомендации в',
    pt: 'Consulte as recomendações detalhadas na',
  },
  'CsvUploader:documentation': {
    eng: 'Documentation',
    ru: 'Документации',
    pt: 'Documentação',
  },
  'CsvUploader:megabytes': {
    eng: 'Mb',
    ru: 'Мб',
    pt: 'Mb',
  },
  'CsvUpload:errors:error_size': {
    eng: 'The file is too big. The file being downloaded must not exceed 1 MB.',
    ru: 'Файл слишком большой. Загружаемый файл не должен превышать 1 Мб.',
    pt: 'O arquivo é muito grande. O arquivo que está sendo baixado não deve exceder 1 MB.',
  },
  'CsvUpload:errors:error_extension': {
    eng: 'Wrong file extension.',
    ru: 'Неверное расширение файла.',
    pt: 'Extensão de arquivo errada.',
  },
  'CsvUpload:errors:error_upload': {
    eng: 'Upload failed. Connection error.',
    ru: 'Загрузка не удалась. Соединение прервано.',
    pt: 'Falha no carregamento. Erro de conexão.',
  },
  'CsvUpload:errors:upload_success_snack': {
    eng: 'Dictionary successfully loaded',
    ru: 'Справочник успешно загружен',
    pt: 'Dicionário carregado com êxito',
  },
  'CsvUpload:errors:upload_error_snack': {
    eng: 'Failed to load. Please try again.',
    ru: 'Ошибка при загрузке. Попробуйте ещё раз.',
    pt: 'Falha ao carregar. Tente novamente.',
  },
  'AdditionalPackages tab asr tts': {
    eng: 'Speech recognition and synthesis',
    ru: 'Распознавание и синтез речи',
    pt: 'Reconhecimento e síntese de fala',
  },
  'AdditionalPackages tab outgoing': {
    eng: 'Outbound calls',
    ru: 'Исходящие вызовы',
    pt: 'Chamadas de saída',
  },
  'AdditionalPackages tab sms': {
    eng: 'SMS',
    ru: 'SMS',
    pt: 'SMS',
  },
  'AdditionalPackages title': {
    eng: 'Additional Packages',
    ru: 'Дополнительные пакеты',
    pt: 'Pacotes adicionais',
  },
  'AdditionalPackages asr tts minutes': {
    eng: 'minutes of speech recognition and synthesis',
    ru: 'минут понимания и синтеза речи',
    pt: 'minutos de reconhecimento e síntese de fala',
  },
  'AdditionalPackages may be needed': {
    eng: 'You may need:',
    ru: 'Вам могут понадобиться:',
    pt: 'Você pode precisar:',
  },
  'AdditionalPackages may be needed mobile': {
    eng: 'You may need',
    ru: 'Вам может понадобиться',
    pt: 'Você pode precisar',
  },
  'AdditionalPackages title tooltip': {
    eng: 'Additional packages do not expire at the end of the month',
    ru: 'Дополнительные пакеты не сгорают в конце месяца',
    pt: 'Os pacotes adicionais não expiram no final do mês',
  },
  'AdditionalPackages mixed minutes tooltip': {
    eng: 'Processing of conversation minutes in the phone channel + speech recognition and synthesis minutes',
    ru: 'Обработка минут разговора в телефонном канале + минуты распознавания и генерации речи',
    pt: 'Processamento dos minutos de conversação no canal do telefone mais reconhecimento de voz e minutos de síntese',
  },
  'AdditionalPackages justai minutes tooltip': {
    eng: 'Minutes left. Not consumed for SIP connections',
    ru: 'Остаток минут. При использовании SIP-подключения не расходуется',
    pt: 'Minutos restantes. Não consumido para conexões SIP',
  },
  'AdditionalPackageCheckboxItem justaiMinutes': {
    eng: 'minute(s) of outbound',
    ru: 'мин. исходящих',
    pt: 'minuto(s) de saída',
  },
  'AdditionalPackageCheckboxItem uniqueClientsByChannel': {
    eng: 'users',
    ru: 'пользователей',
    pt: 'usuários',
  },
  'AdditionalPackageCheckboxItem asrMinutes': {
    eng: 'minute(s) of synthesis ',
    ru: 'мин. синтеза ',
    pt: 'minuto(s) de síntese ',
  },
  'AdditionalPackageCheckboxItem minutes shortText': {
    eng: 'min.',
    ru: 'мин.',
    pt: 'min.',
  },
  'AdditionalPackageCheckboxItem sms': {
    eng: 'SMS',
    ru: 'SMS',
    pt: 'SMS',
  },
  'AdditionalPackageItem buy': {
    eng: 'Buy',
    ru: 'Купить',
    pt: 'Comprar',
  },
  'AdditionalPackageItem offer': {
    eng: 'Pay by invoice',
    ru: 'Оплатить по счету',
    pt: 'Pagar por fatura',
  },
  'AdditionalPackageItem total': {
    eng: 'Total: ',
    ru: 'Итого: ',
    pt: 'Total: ',
  },
  'AdditionalPackageItem total mobile': {
    eng: 'Total cost:',
    ru: 'Общая стоимость',
    pt: 'Custo total:',
  },
  'AdditionalPackageItem asrMinutes': {
    eng: 'minutes of speech recognition and synthesis',
    ru: 'минут понимания и синтеза речи',
    pt: 'minutos de reconhecimento e síntese de fala',
  },
  'AdditionalPackageItem justaiMinutes': {
    eng: '#{config.zenflow.companyName} telephony minutes',
    ru: 'минут телефонии #{config.zenflow.companyName}',
    pt: 'Apenas minutos de telefonia #{config.zenflow.companyName}',
  },
  'AdditionalPackageItem uniqueClientsByChannel': {
    eng: 'unique users',
    ru: 'уникальных пользователей',
    pt: 'usuários únicos',
  },
  'AdditionalPackages tab uniques': {
    eng: 'Chatbots',
    ru: 'Чат-боты',
    pt: 'Chatbots',
  },
  'AdditionalPackageItem sms': {
    eng: 'SMS',
    ru: 'SMS',
    pt: 'SMS',
  },
  'MinutesAddingModal title': {
    eng: 'Minute packages',
    ru: 'Пакеты минут',
    pt: 'Pacotes de minutos',
  },
  'MinutesAddingModal JUSTAI title': {
    eng: 'Outbound calls',
    ru: 'Исходящие вызовы',
    pt: 'Chamadas de saída',
  },
  'MinutesAddingModal MIXED title': {
    eng: 'Speech recognition and synthesis',
    ru: 'Распознавание и синтез речи',
    pt: 'Reconhecimento e síntese de fala',
  },
  'Template wizard mixed seconds type': {
    eng: 'Speech recognition and synthesis',
    ru: 'Распознавание и синтез речи',
    pt: 'Reconhecimento e síntese de fala',
  },
  'Template wizard justai seconds type': {
    eng: 'Outbound calls',
    ru: 'Исходящие вызовы',
    pt: 'Chamadas de saída',
  },
  'TariffSettings choose card': {
    eng: 'Change card',
    ru: 'Сменить карту',
    pt: 'Alterar cartão',
  },
  'TariffSettings choose tariff': {
    eng: 'Switch to another plan',
    ru: 'Выбрать другой тариф',
    pt: 'Mudar para outro plano',
  },
  'TariffSettings card': {
    eng: 'Card',
    ru: 'Карта',
    pt: 'Cartão',
  },
  'TariffSettings tariff cost': {
    eng: 'Cost',
    ru: 'Стоимость',
    pt: 'Custo',
  },
  'TariffSettings next tariff date': {
    eng: 'The account will be switched to the <b>$[1]</b> plan starting from $[2]',
    ru: 'Аккаунт будет переведен на тариф <b>$[1]</b> с $[2] года',
    pt: 'A conta será alterada para o plano <b>$[1]</b> a partir de $[2]',
  },
  'TariffSettings tariff free': {
    eng: 'Free',
    ru: 'Бесплатно',
    pt: 'Grátis',
  },
  'TariffLimitsTable table tariff col': {
    eng: 'Left/available in your plan',
    ru: 'Осталось / доступно на тарифе',
    pt: 'Restante/disponíveis no seu plano',
  },
  'TariffLimitsTable table packages col': {
    eng: 'Extra limit',
    ru: 'Дополнительный лимит',
    pt: 'Limite extra',
  },
  'TariffLimitsTable minutes plur1': {
    eng: 'minute',
    ru: 'минута',
    pt: 'minuto',
    cn: '分钟数',
  },
  'TariffLimitsTable minutes plur2': {
    eng: 'minutes',
    ru: 'минуты',
    pt: 'minutos',
  },
  'TariffLimitsTable minutes plur3': {
    eng: 'minutes',
    ru: 'минут',
    pt: 'minutos',
  },
  'TariffLimitsTable uniques': {
    eng: 'Unique users',
    ru: 'Уникальные пользователи',
    pt: 'Usuários exclusivos',
  },
  'TariffLimitsTable mixed': {
    eng: 'Speech recognition and synthesis',
    ru: 'Распознавание и синтез речи',
    pt: 'Reconhecimento e síntese de fala',
  },
  'TariffLimitsTable telephony': {
    eng: 'Telephony minutes',
    ru: 'Минуты телефонии',
    pt: 'Minutos de telefonia',
  },
  'TariffLimitsTable sms': {
    eng: 'SMS',
    ru: 'SMS',
    pt: 'SMS',
  },
  'TariffLimitsTable mlp tokens title': {
    eng: 'ChatGPT access package',
    ru: 'Обращения к ChatGPT',
    pt: 'Pacote de acesso ao ChatGPT',
  },
  'TariffLimitsTable mlp tokens one': {
    eng: '$[1] request to ChatGPT',
    ru: '$[1] обращение к ChatGPT',
    pt: '$[1] request to ChatGPT',
  },
  'TariffLimitsTable mlp tokens few': {
    eng: '$[1] requests to ChatGPT',
    ru: '$[1] обращения к ChatGPT',
    pt: '$[1] requests to ChatGPT',
  },
  'TariffLimitsTable mlp tokens many': {
    eng: '$[1] requests for ChatGPT',
    ru: '$[1] обращений к ChatGPT',
    pt: '$[1] requests for ChatGPT',
  },
  'TariffLimitsTable mlp tokens other': {
    eng: '$[1] requests for ChatGPT',
    ru: '$[1] обращений к ChatGPT',
    pt: '$[1] requests for ChatGPT',
  },
  Autopayment: {
    eng: 'Autopay',
    ru: 'Автоплатеж',
    pt: 'Pagamento automático',
  },
  'CreateBot: choose template': {
    eng: 'Project template',
    ru: 'Шаблон проекта',
    pt: 'Modelo do projeto',
  },
  'CreateBot: No template': {
    eng: 'Empty project',
    ru: 'Пустой проект',
    pt: 'Projeto vazio',
  },
  'UserTariffLimits unique users': {
    eng: 'unique users',
    ru: 'уникальных пользователей',
    pt: 'usuários exclusivos',
    cn: '单个用户',
  },
  'Crash label': {
    eng: 'Oops, something has gone wrong',
    ru: 'Упс, что-то пошло не так',
    pt: 'Opa, ocorreu algo de errado',
  },
  'Reload page': {
    eng: 'Reload page',
    ru: 'Перезагрузить страницу',
    pt: 'Recarregar página',
  },
  'AutoSaveModal title': {
    eng: 'An unsaved version of the script was detected ',
    ru: 'Обнаружена несохраненная версия сценария ',
    pt: 'Foi detectada uma versão não salva do script ',
  },
  'AutoSaveModal text': {
    eng: 'An unsaved version of the script "$[1]" of $[2] was detected. Restore it?\nIf you want to switch to another version, press the <b>Versions</b> button in the #{config.zenflow.productName} visual editor.',
    ru: 'Обнаружена несохраненная версия сценария "$[1]" от $[2]. Загрузить?\nЕсли вы захотите использовать другую версию, то нажмите на кнопку <b>Версии</b> в конструкторе #{config.zenflow.productName}.',
    pt: 'Uma versão não salva do script "$[1]" de $[2] foi detectada. Restaurar?\nSe você quiser mudar para outra versão, pressione o botão <b>Versões</b> no editor visual do #{config.zenflow.productName}.',
  },
  'AutoSaveModal message': {
    eng: 'Press the Versions button if you want to work with another version of the script',
    ru: 'Если вы хотите работать с другой версией сценария, нажмите кнопку Версии',
    pt: 'Pressione o botão Versões se quiser trabalhar com outra versão do script',
  },
  'AddChannelCard: Viber startMessage help text': {
    eng: 'The message from the bot that will show up after opening the chat. The session will start after the user has sent their first message.',
    ru: 'Сообщение бота, которое появится в чате после открытия окна. Сессия начнется после первого сообщения пользователя.',
    pt: 'A mensagem do bot que será exibida após a abertura do chat. A sessão será iniciada depois de o usuário ter enviado a sua primeira mensagem.',
  },
  'Dashboard: main label CHAT_BOT': {
    eng: 'My chatbots',
    ru: 'Мои чат-боты',
    pt: 'Meus chatbots',
  },
  'Dashboard: main label CALLS_BOT': {
    eng: 'My calls',
    ru: 'Мои звонки',
    pt: 'Minhas chamadas',
  },
  'Dashboard: main label no bots': {
    eng: 'Start working in #{config.zenflow.productName}',
    ru: 'Начните свою работу с #{config.zenflow.productName}',
    pt: 'Comece a trabalhar no #{config.zenflow.productName}',
  },
  'Dashboard: no info card help text': {
    eng: 'Create a chatbot in under 5 minutes',
    ru: 'Создайте чат-бота всего за 5 минут',
    pt: 'Crie um chatbot em menos de 5 minutos',
  },
  'Dashboard: telegram link label': {
    eng: 'Telegram user community',
    ru: 'Сообщество пользователей в Telegram',
    pt: 'Comunidade de usuários no Telegram',
  },
  'Dashboard: community link label': {
    eng: 'All #{config.zenflow.productName} news and updates',
    ru: 'Все новости и обновления #{config.zenflow.productName}',
    pt: 'Todas as notícias e atualizações de #{config.zenflow.productName}',
  },
  'Dashboard: no channels': {
    eng: 'No channels',
    ru: 'Нет каналов',
    pt: 'Sem canais',
  },
  'Dashboard: createButton label': {
    eng: 'Create a bot',
    ru: 'Создать бота',
    pt: 'Criar um bot',
  },
  'Dashboard: searchByName input label': {
    eng: 'Search by name',
    ru: 'Поиск по названию',
    pt: 'Pesquisar por nome',
  },
  'BotMenu Duplicate': {
    eng: 'Duplicate',
    ru: 'Дублировать',
    pt: 'Duplicar',
  },
  'BotMenu Rename': {
    eng: 'Rename',
    ru: 'Переименовать',
    pt: 'Renomear',
  },
  'BotMenu ChangeTags': {
    eng: 'Change tags',
    ru: 'Изменить теги',
    pt: 'Alterar tags',
  },
  'UniqueClients in 30 days': {
    eng: '<b>$[1]</b>&nbsp;in 30 days',
    ru: '<b>$[1]</b>&nbsp;за 30 дней',
    pt: '<b>$[1]</b>&nbsp;em 30 dia',
  },
  'LabelEmptyList-title': {
    eng: 'You haven’t created any tags yet',
    ru: 'Вы еще не создавали теги',
    pt: 'Você ainda não criou nenhuma tag',
  },
  'LabelEmptyList-text': {
    eng: 'Enter the new tag name and press Enter',
    ru: 'Введите название нового тега и нажмите Enter',
    pt: 'Digite o nome da nova tag e pressione Enter',
  },
  TagsModalSelectPlaceholder: {
    eng: 'Search for tags or add new ones',
    ru: 'Найдите или добавьте новые теги',
    pt: 'Procure tags ou adicione novas',
  },
  'TagsModal bot tags': {
    eng: 'Bot tags',
    ru: 'Теги бота',
    pt: 'Tags de bot',
  },
  'LabelComponent:Create element': {
    eng: 'Create element',
    ru: 'Создать элемент',
    pt: 'Criar elemento',
  },
  'LabelComponent:Press Enter': {
    eng: 'Press Enter',
    ru: 'Нажмите Enter',
    pt: 'Pressionar Enter',
  },
  'TagsFilter:tags': {
    eng: 'Tags',
    ru: 'Теги',
    pt: 'Tags',
  },
  'BotControls:orderOptions:lastModified': {
    eng: 'Recently updated first',
    ru: 'Сначала последние измененные',
    pt: 'Atualizado recentemente primeiro',
  },
  'BotControls:orderOptions:alphabet': {
    eng: 'Sort by name',
    ru: 'Сортировать по названию',
    pt: 'Ordenar por nome',
  },
  'BotControls:orderOptions:new_desc': {
    eng: 'Newest first',
    ru: 'Сначала новые',
    pt: 'Mais recentes primeiro',
  },
  'BotControls:orderOptions:old_desc': {
    eng: 'Oldest first',
    ru: 'Сначала старые',
    pt: 'Mais antigos primeiro',
  },
  'BotControls:not_found': {
    eng: 'Not found',
    ru: 'Ничего не найдено',
    pt: 'Não encontrado',
  },
  'yandex kassa': {
    eng: 'YooMoney',
    ru: 'ЮKassa',
    pt: 'YooMoney',
  },
  SpecialTariffConfirmModal: {
    eng: '',
    ru: '',
    pt: '',
  },
  'SpecialTariffs tariff connected': {
    eng: 'Connected',
    ru: 'Подключен',
    pt: 'Conectado',
  },
  'SpecialTariffs skillmaster title': {
    eng: 'Free plan: Skill Master',
    ru: 'Бесплатный тариф Skill Master',
    pt: 'Plano gratuito: Mestre das Habilidades',
  },
  'SpecialTariffs skillmaster text': {
    eng: 'For voice assistant skill developers',
    ru: 'Для тех, кто делает навыки для голосовых ассистентов',
    pt: 'Para desenvolvedores de habilidades de assistente de voz',
  },
  'SpecialTariffs jivo title': {
    eng: 'New plan: Business JivoChat',
    ru: 'Новый тариф Business Jivo',
    pt: 'Novo plano: Empresarial JivoChat',
  },
  'SpecialTariffs jivo text': {
    eng: 'Only for the JivoChat channel',
    ru: 'Только для канала Jivo',
    pt: 'Apenas para o canal JivoChat',
  },
  'SpecialTariffConfirmModal jivo text 1': {
    eng: '$[1] users per month',
    ru: '$[1] пользователей / месяц',
    pt: '$[1] usuários por mês',
  },
  'SpecialTariffConfirmModal jivo text 2': {
    eng: '$[1] minutes of speech recognition and synthesis per month',
    ru: '$[1] минут синтеза и понимания речи / месяц',
    pt: '$[1] minutos de reconhecimento e síntese de fala por mês',
  },
  'SpecialTariffConfirmModal jivo text 3': {
    eng: 'Only the JivoChat channel is available. All other channels will be disabled.',
    ru: 'Из каналов доступен только Jivo. Остальные каналы отключатся.',
    pt: 'Apenas o canal JivoChat está disponível. Todos os outros canais serão desativados.',
  },
  'SpecialTariffConfirmModal jivo title': {
    eng: 'What’s included in the plan',
    ru: 'Что включено в тариф',
    pt: 'O que está incluído no plano',
  },
  'SpecialTariffConfirmModal body text 1 from developer tariff': {
    eng: 'You won’t be able to switch back to $[1] until the beginning of the next billing month',
    ru: 'Вернуться на тариф $[1] вы сможете только с начала следующего тарификационного месяца',
    pt: 'Você não poderá voltar para o $[1] até o início do próximo mês de faturamento',
  },
  'SpecialTariffConfirmModal title': {
    eng: 'Switch to $[1]',
    ru: 'Подключение $[1]',
    pt: 'Mudar para $[1]',
  },
  'SpecialTariffConfirmModal confirm': {
    eng: 'Switch',
    ru: 'Подключить',
    pt: 'Mudar',
  },
  'SpecialTariffConfirmModal confirm free tariff': {
    eng: ' to Skill Master',
    ru: ' бесплатный тариф',
    pt: ' para Mestre das Habilidades',
  },
  'SpecialTariffConfirmModal warning text': {
    eng: 'We will switch you to this plan and disable the current one: $[1]',
    ru: 'Мы переключим вас на этот тариф и отключим текущий тариф $[1]',
    pt: 'Mudaremos você para este plano e desativaremos o atual: $[1]',
  },
  'SpecialTariffConfirmModal body text 1': {
    eng: 'To switch back to the $[1] plan, you will need to reestablish your paid subscription',
    ru: 'Вернуться на тариф $[1] вы сможете только после повторной оплаты',
    pt: 'Para voltar ao plano $[1], você terá de restabelecer a sua assinatura paga',
  },
  'SpecialTariffConfirmModal body text 2': {
    eng: 'Skill Master only works with voice assistant skills. All other bots will pause their work until you reestablish your paid subscription',
    ru: 'На тарифе работают только навыки для голосовых ассистентов. Остальные чат-боты приостановят работу до возврата на платный тариф',
    pt: 'O Mestre das Habilidades só funciona com habilidades de assistente de voz. Todos os outros bots pausarão o trabalho até que você restabeleça sua assinatura paga',
  },
  'SpecialTariffConfirmModal body text 3': {
    eng: 'Any additional minute packages you have bought will be saved for further use',
    ru: 'Все дополнительные пакеты минут останутся на вашем аккаунте',
    pt: 'Quaisquer pacotes de minutos adicionais que você tenha comprado serão salvos para utilização posterior',
  },
  'SaveComponent phone verification tooltip': {
    eng: 'Verify your phone number',
    ru: 'Подтвердите номер телефона',
    pt: 'Confirme seu número de telefone',
  },
  'SaveComponent phone verification tooltip-euro': {
    eng: 'To enable calls, add a SIP connection',
    ru: 'Чтобы включить звонки, создайте SIP-подключение',
    pt: 'Para ativar chamadas, adicione uma conexão SIP',
  },
  'TestCallModal title': {
    eng: 'Test call',
    ru: 'Тестовый звонок',
    pt: 'Chamada de teste',
  },
  'TestCallModal title default telephony': {
    eng: 'Test call using #{config.zenflow.productName} telephony',
    ru: 'Тестовый звонок через телефонию #{config.zenflow.productName}',
    pt: 'Chamada de teste usando a telefonia #{config.zenflow.productName}',
  },
  'TestCallModal call': {
    eng: 'Make a call',
    ru: 'Позвонить',
    pt: 'Faça uma chamada',
  },
  'TestCallModal buy minutes': {
    eng: 'Buy more minutes',
    ru: 'Купить минуты',
    pt: 'Comprar mais minutos',
  },
  'TestCallModal:callIsPaid_defaultConnection': {
    eng: 'Outbound call minutes as well as speech recognition and synthesis minutes will be charged for the call. To change the connection used for the test call, go to <i>Telephony settings</i> and select a default connection.',
    ru: 'За звонок будут списаны минуты исходящих вызовов и минуты распознавания и синтеза речи. Чтобы изменить подключение для тестового звонка, перейдите в раздел <i>Настройка телефонии</i> и выберите подключение по умолчанию.',
    pt: 'Os minutos de chamada realizada bem como os minutos de reconhecimento de fala e síntese serão cobrados para a chamada. Para alterar a conexão usada para a chamada de teste acesse <i>Configurações de telefonia</i> e selecione a conexão padrão.',
  },
  'TestCallModal:callIsPaid_clientConnection': {
    eng: 'The test call will use the default SIP connection. Speech recognition and synthesis minutes will be charged for the call. To change the connection used for the test call, go to <i>Telephony settings</i> and select another connection.',
    ru: 'Тестовый звонок будет совершен через SIP-подключение по умолчанию. За звонок будут списаны минуты распознавания и синтеза речи. Чтобы изменить подключение для тестового звонка, перейдите в раздел <i>Настройка телефонии</i> и выберите другое подключение.',
    pt: 'A chamada de teste usará a conexão padrão SIP. Os minutos de reconhecimento de fala e síntese serão cobrados para a chamada. Para alterar a conexão usada para a chamada de teste, acesse <i>Configurações de telefonia</i> e selecione outra conexão.',
  },
  'TestCallModal no minutes': {
    eng: 'Insufficient minutes for making a call',
    ru: 'Недостаточно минут для совершения звонка',
    pt: 'Minutos insuficientes para efetuar uma chamada',
  },
  'TestCallModal:NotVerifiedText': {
    eng: 'You will only be able to make calls to your own phone number until your account is verified.',
    ru: 'Вы можете совершать звонки только на свой номер, пока не пройдете верификацию аккаунта.',
  },
  'TestCallModal:NotVerifiedButton': {
    eng: 'Verify account',
    ru: 'Подтвердить аккаунт',
  },
  'TestCallModal:OnVerificationText': {
    eng: 'Your account is being verified. Right now, you can make test calls using #{config.zenflow.productName} telephony to your own phone number only.',
    ru: 'Ваш аккаунт находится на проверке. Пока что вы можете совершать тестовые звонки через телефонию #{config.zenflow.productName} только на свой номер.',
  },
  'TestCalllUnavailableModal title': {
    eng: 'Skill Master plan',
    ru: 'Тариф Skill Master',
    pt: 'Plano Mestre das Habilidades',
  },
  'TestCalllUnavailableModal text': {
    eng: 'Test calls are unavailable on this plan. Switch to another plan',
    ru: 'Тестовый звонок недоступен на данном тарифе. Выберите другой тариф',
    pt: 'As chamadas de teste não estão disponíveis neste plano. Mude para outro plano',
  },
  'TestCalllUnavailableModal button': {
    eng: 'See all plans',
    ru: 'Посмотреть тарифы',
    pt: 'Ver todos os planos',
  },
  'ChangeEmailModal title': {
    eng: 'Change email',
    ru: 'Изменение электронной почты',
    pt: 'Alterar e-mail',
  },
  'ChangeEmailModal current email placeholder': {
    eng: 'Current email',
    ru: 'Текущий адрес',
    pt: 'E-mail atual',
  },
  'ChangeEmailModal new email placeholder': {
    eng: 'New email',
    ru: 'Новая почта',
    pt: 'Novo e-mail',
  },
  'ChangeEmailModal new email help text': {
    eng: 'We will send confirmation instructions to the new email',
    ru: 'Мы отправим на новый адрес инструкции для подтверждения изменений',
    pt: 'Enviaremos instruções de confirmação para o novo e-mail',
  },
  'ChangeEmailModal new email error change': {
    eng: 'This email is already in use',
    ru: 'Этот адрес электронной почты уже используется',
    pt: 'Este e-mail já está em uso',
  },
  'ChangeEmailModal after change text': {
    eng: 'We have sent an email with instructions to <strong>$[1]</strong>',
    ru: 'Мы отправили письмо с инструкциями на адрес <strong>$[1]</strong>',
    pt: 'Enviamos uma mensagem de e-mail com instruções para <strong>$[1]</strong>',
  },
  'ChangeEmailModal success': {
    eng: 'Your email has been changed to <strong>$[1]</strong>',
    ru: 'Ваш email изменен на <strong>$[1]</strong>',
    pt: 'Seu e-mail foi alterado para <strong>$[1]</strong>',
  },
  'ChangeEmailModal ok text': {
    eng: 'Change',
    ru: 'Изменить',
    pt: 'Alterar',
  },
  'ChangeEmailModal button': {
    eng: 'Change',
    ru: 'Изменить',
    pt: 'Alterar',
  },
  'ChangeEmailModal invalid email': {
    eng: 'Invalid email',
    ru: 'Неправильный формат почты',
    pt: 'E-mail inválido',
  },
  'ChangeEmail:BE-error user.changeEmailToken.expired': {
    eng: 'The link has expired. Try changing the email again',
    ru: 'Ссылка устарела. Смените адрес электронной почты заново',
    pt: 'O link expirou. Tente alterar o e-mail novamente',
  },
  'ChangeEmail:BE-error user.changeEmailToken.wrong': {
    eng: 'Invalid token',
    ru: 'Неверный токен',
    pt: 'Token inválido',
  },
  'ChangeEmail:BE-error user.changeEmailToken.not.sent': {
    eng: 'Token wasn’t sent to the specified address',
    ru: 'Токен не был выслан на этот адрес',
    pt: 'O token não foi enviado para o endereço especificado',
  },
  'EmailVerification title': {
    eng: 'Verify your email',
    ru: 'Подтвердите email',
    pt: 'Confirme seu e-mail',
  },
  'EmailVerification text': {
    eng: 'We have sent an email to $[1].\nFollow the link to complete your account registration',
    ru: 'Мы отправили вам письмо на $[1].\nПерейдите по ссылке, чтобы подтвердить регистрацию аккаунта',
    pt: 'Enviamos uma mensagem de e-mail para $[1].\nSiga o link para concluir o registro da sua conta',
  },
  'EmailVerification button': {
    eng: 'Resend email',
    ru: 'Отправить письмо еще раз',
    pt: 'Reenviar e-mail',
  },
  'EmailVerification login to another account': {
    eng: 'Do you have another account?',
    ru: 'У вас есть другой аккаунт?',
    pt: 'Tem outra conta?',
  },
  'EmailVerification invalid link': {
    eng: 'Oops! Your verification link has expired',
    ru: 'Упс, ваша ссылка для подтверждения регистрации устарела',
    pt: 'Opa! Seu link de confirmação expirou',
  },
  'EmailVerification resend email text': {
    eng: 'Resend in $[1] seconds',
    ru: 'Отправить снова через $[1] секунд',
    pt: 'Reenviar em $[1] segundos',
  },
  'EmailVerification ': {
    eng: '',
    ru: '',
    pt: '',
  },
  'BotSetting:IntegrationTab': {
    eng: 'Integrations',
    ru: 'Интеграции',
    pt: 'Integrações',
  },
  'IntegrationPage:NoIntegrationAdded': {
    eng: 'You don’t have any accounts connected',
    ru: 'У вас нет подключенных аккаунтов',
    pt: 'Você não tem nenhuma conta conectada',
  },
  'IntegrationPage:connect': {
    eng: 'Connect',
    ru: 'Подключить',
    pt: 'Conectar',
  },
  'IntegrationAddingItem:google': {
    eng: 'Sign in with Google',
    ru: 'Войти через Google',
    pt: 'Entrar com o Google',
  },
  'Login:BE-error user.autoLoginToken.expired': {
    eng: 'The sign-in link has expired',
    ru: 'Ссылка для входа устарела',
    pt: 'O link de login expirou',
  },
  'Login:BE-error user.autoLoginToken.wrong': {
    eng: 'Invalid sign-in link',
    ru: 'Неверная ссылка для входа',
    pt: 'Link de login inválido',
  },
  'Login:BE-error user.autoLoginToken.not.sent': {
    eng: 'Token wasn’t sent to the specified address',
    ru: 'Токен не был выслан на этот адрес',
    pt: 'O token não foi enviado para o endereço especificado',
  },
  'Business Jivo Brazil': {
    eng: 'Business JivoChat',
    ru: 'Business Jivo',
    pt: 'Empresarial JivoChat',
  },
  'ProjectOverview: docs': {
    eng: '#{config.zenflow.productName} documentation',
    ru: 'Документации #{config.zenflow.productName}',
    pt: 'Documentação do #{config.zenflow.productName}',
  },
  minutes1: {
    eng: 'minute',
    ru: 'минута',
    pt: 'minuto',
  },
  minutes2: {
    eng: 'minutes',
    ru: 'минуты',
    pt: 'minutos',
  },
  minutes5: {
    eng: 'minutes',
    ru: 'минут',
    pt: 'minutos',
  },
  'AdditionalPackages: available on business tariffs': {
    eng: 'Available on higher plans',
    ru: 'Доступно на старших тарифных планах',
    pt: 'Disponível nos planos de assinatura Empresarial',
  },
  'DeveloperModal: title': {
    eng: 'Switch to the Free plan',
    ru: 'Подключение тарифа Free',
    pt: 'Mude para o plano de Desenvolvedor',
  },
  'DeveloperModal: text': {
    eng: 'Starting from $[2], you will be able to switch from your current $[1] plan to the Free plan.',
    ru: 'Вы сможете перейти на тариф Free и отключить текущий тариф $[1] с $[2].',
    pt: 'A partir de $[2], você poderá mudar do seu plano $[1] atual para o plano de Desenvolvedor.',
  },
  'DeveloperModal: ok button': {
    eng: 'Switch',
    ru: 'Подключить',
    pt: 'Mudar',
  },
  'TariffInfo tariff free': {
    eng: 'Free',
    ru: 'Бесплатно',
    pt: 'Grátis',
  },
  'Register: phone error': {
    eng: 'Enter the full phone number',
    ru: 'Введите номер телефона полностью',
    pt: 'Digite o número de telefone completo',
  },
  'TestCallModal: phone error': {
    eng: 'Enter the full phone number',
    ru: 'Введите номер телефона полностью',
    pt: 'Digite o número de telefone completo',
  },
  'TestCallModal: min of comprehension and speech synthesis': {
    eng: 'Speech recognition and synthesis minutes',
    ru: 'Минуты распознавания и синтеза речи',
    pt: 'Minutos de reconhecimento de fala e síntese',
  },
  'TestCallModal: min of incoming calls': {
    eng: 'Outbound call minutes',
    ru: 'Минуты исходящих вызовов',
    pt: 'Minutos de chamada de saída',
  },
  'TestCallModal: all phone numbers available': {
    eng: 'You can make a call to any Russian phone number.',
    ru: 'Вам доступны звонки на любые номера России.',
  },
  'Sidebar: manual control user': {
    eng: 'Payment agreement (contact us at <a href="mailto:{contactUsEmail}">{contactUsEmail}</a>)',
    ru: 'Договор (по вопросам обращаться на <a href="mailto:{contactUsEmail}">{contactUsEmail}</a>)',
    pt: 'Contrato de pagamento (fale conosco em <a href="mailto:{contactUsEmail}">{contactUsEmail}</a>)',
  },
  'UploadCsv: use Json': {
    eng: 'Add entity values in JSON',
    ru: 'Добавлять значения сущностей в формате JSON',
    pt: 'Adicionar valores de entidade no JSON',
  },
  'UploadCsv: use Json tooltip': {
    eng: 'You can access properties from JSON entity values and use them in the bot script.',
    ru: 'Из значений сущностей в формате JSON вы сможете извлекать поля в сценарии бота.',
    pt: 'Você pode acessar propriedades a partir dos valores de entidade no JSON e usá-las no script do bot.',
  },
  'PublishButton: create calltask dialer': {
    eng: 'Create call campaign',
    ru: 'Создать обзвон',
    pt: 'Criar campanha de chamadas',
  },
  'PublishButton: create calltask ivr': {
    eng: 'Start receiving inbound calls',
    ru: 'Запустить прием входящих звонков',
    pt: 'Comece a receber chamadas de entrada',
  },
  'PublishButtonModal: title callList': {
    eng: 'Campaign by number list',
    ru: 'Обзвон по списку',
    pt: 'Campanha por lista de números',
  },
  'PublishButtonModal: description callList': {
    eng: 'From a file with phone numbers',
    ru: 'Из файла с номерами',
    pt: 'A partir de um arquivo com números de telefone',
  },
  'PublishButtonModal: title crm': {
    eng: 'Campaign from CRM system',
    ru: 'Обзвон из CRM',
    pt: 'Campanha do sistema de CRM',
  },
  'PublishButtonModal: description crm': {
    eng: 'Call customers when a trigger is activated',
    ru: 'Звоните по триггеру',
    pt: 'Ligue para os clientes quando um acionador for ativado',
  },
  'PublishButtonModal: create button': {
    eng: 'Create',
    ru: 'Создать',
    pt: 'Criar',
  },
  'PublishButtonModal: to voicechannels page': {
    eng: 'Go to campaign management',
    ru: 'Перейти в управление обзвонами',
    pt: 'Acessar gerenciamento de campanhas',
  },
  'PublishButtonModal: description test': {
    eng: 'Try #{config.zenflow.productName} out',
    ru: 'Испытайте #{config.zenflow.productName}',
    pt: 'Experimentar #{config.zenflow.productName}',
  },
  'PublishButtonModal: title test': {
    eng: 'Test call',
    ru: 'Пробный звонок',
    pt: 'Chamada de teste',
  },
  'Check fields value': {
    eng: 'Check the field values',
    ru: 'Проверьте значения полей',
    pt: 'Verifique os valores do campo',
  },
  'user.not.owner': {
    eng: 'Your account is only allowed to work in <a style="color: inherit" href="$[1]">#{config.jaicp.productName}</a>',
    ru: 'Вашей учетной записи разрешена работа только в <a style="color: inherit" href="$[1]">#{config.jaicp.productName}</a>',
    pt: 'Sua conta só pode funcionar em <a style="color: inherit" href="$[1]">#{config.jaicp.productName}</a>',
  },
  'PartnerRegisterForm: checkbox label': {
    eng: 'I agree to the terms of <a href="https://just-ai.com/rus/aimylogic_partnership_oferta_2022.pdf" target="_blank">the affiliate program</a>',
    ru: 'Я соглашаюсь с условиями <a href="https://just-ai.com/rus/aimylogic_partnership_oferta_2022.pdf" target="_blank">партнерской программы</a>',
    pt: 'I agree to the terms of <a href="https://just-ai.com/rus/aimylogic_partnership_oferta_2022.pdf" target="_blank">the affiliate program</a>',
  },
  'ErrorModal: title': {
    eng: 'Error log',
    ru: 'Лог ошибки',
    pt: 'Log de erros',
  },
  "Task has status 'IN_PROGRESS' but expected [NEW, PAUSED]": {
    eng: 'Failed to start a campaign. Probably it is already running.',
    ru: 'Не удалось запустить обзвон. Возможно, он уже запущен.',
    pt: 'Falha ao iniciar uma campanha. Provavelmente, já está em execução.',
  },
  "Task has status 'PLANNED' but expected [NEW, PAUSED, IN_PROGRESS]": {
    eng: 'Failed to start a campaign. Probably it is already running.',
    ru: 'Не удалось запустить обзвон. Возможно, он уже запущен.',
    pt: 'Falha ao iniciar uma campanha. Provavelmente, já está em execução.',
  },
  'Integration: integration stopped': {
    eng: 'The integration is disabled',
    ru: 'Интеграция отключена',
    pt: 'A integração está desativada',
  },
  'Integration: integration stopped popover': {
    eng: 'Delete the integration \nand connect your Google account again',
    ru: 'Удалите интеграцию и \nподключите Google-аккаунт снова',
    pt: 'Exclua a integração\n e volte a conectar sua conta do Google',
  },
  raz1: {
    eng: 'time',
    ru: 'раз',
    pt: 'vez',
  },
  raz2: {
    eng: 'times',
    ru: 'раза',
    pt: 'vezes',
  },
  raz5: {
    eng: 'times',
    ru: 'раз',
    pt: 'vezes',
  },
  'YoutubeLink:Chatbots': {
    eng: 'JmGi--2tyC0',
    ru: 'JmGi--2tyC0',
    pt: 'JmGi--2tyC0',
  },
  'YoutubeLink:Chatbots:EuroInstance': {
    eng: 'QaQJ7wNNeKc',
    ru: 'QaQJ7wNNeKc',
    pt: 'QaQJ7wNNeKc',
  },
  'YoutubeLink:Callbots': {
    eng: 'LTYbdm2nJio',
    ru: '5bFSmqkDZ6E',
    pt: 'LTYbdm2nJio',
  },
  'YoutubeLink:Callbots:EuroInstance': {
    eng: '1MO2pSg28mY',
    ru: '1MO2pSg28mY',
    pt: '1MO2pSg28mY',
  },
  'SpecialTariffItem users month': {
    eng: 'users per month',
    ru: 'пользователей в месяц',
    pt: 'usuários por mês',
  },
  'UrlEditor:EnterToSave': {
    eng: 'Enter — save changes',
    ru: 'Enter — сохранить изменения',
    pt: 'Enter — salvar alterações',
  },
  'WysiwygControls:textFormatIsNotAllowedInAllChannels': {
    eng: 'Text formatting is not supported by all channels',
    ru: 'Форматирование текста поддерживают не все каналы',
    pt: 'A formatação de texto não é compatível com todos os canais',
  },
  'SelectConnections: input placeHolder': {
    eng: 'Select connection',
    ru: 'Выберите подключение',
    pt: 'Selecionar conexão',
  },
  'SelectConnections: add connection': {
    eng: 'Add new connection',
    ru: 'Добавить новое подключение',
    pt: 'Adicionar nova conexão',
  },
  'SelectConnections: add connection short': {
    eng: 'Add a connection',
    ru: 'Добавить подключение',
    pt: 'Adicionar uma conexão',
  },
  'SelectConnections: empty list connections': {
    eng: 'You do not have any SIP connections',
    ru: 'У вас нет SIP-подключений',
    pt: 'Você não tem nenhuma conexão SIP',
  },
  'SaveComponent: dropdown ivr btn': {
    eng: 'Save and start receiving inbound calls again',
    ru: 'Сохранить и запустить прием звонков заново',
    pt: 'Salvar e começar a receber chamadas de entrada novamente',
  },
  'sip.trunk.incoming.used': {
    eng: 'This SIP connection is alredy in use. If you select it, it will be disabled in the <b>$[1]</b> script.',
    ru: 'Это SIP-подключение уже используется. Если вы выберете его, оно отключится в сценарии <b>$[1]</b>.',
    pt: 'Esta conexão SIP já está em uso. Se você selecioná-la, ela será desativada no script do <b>$[1]</b>.',
  },
  'sip.trunk.not.unique': {
    eng: 'A connection to this SIP trunk already exists.',
    ru: 'Такой SIP-транк уже существует.',
    pt: 'Já existe uma conexão com este tronco SIP.',
  },
  'WarningSipModal: title': {
    eng: 'Select SIP connection',
    ru: 'Выбор SIP-подключения',
    pt: 'Selecione a conexão SIP',
  },
  Select: {
    eng: 'Select',
    ru: 'Выбрать',
    pt: 'Selecionar',
  },
  'ToggleCollapsedBotList: what is smart call': {
    eng: 'What are smart calls',
    ru: 'Что такое умные звонки',
    pt: 'O que são chamadas inteligentes',
  },
  'ToggleCollapsedBotList: tariff and balance': {
    eng: 'Call billing',
    ru: 'Тарификация обзвонов',
    pt: 'Faturamento de chamadas',
  },
  'AimyvoicePromo text': {
    eng: '#{config.aimyvoice.productName} is a new service for synthesizing live voices',
    ru: '#{config.aimyvoice.productName} — новый сервис синтеза живых голосов',
    pt: 'O #{config.aimyvoice.productName} é um novo serviço para sintetizar vozes ao vivo',
  },
  Try: {
    eng: 'Try it out',
    ru: 'Попробовать',
    pt: 'Experimentar',
  },
  'ChangeVoiceModal title': {
    eng: 'Choose voice for speech synthesis',
    ru: 'Выбрать голос для синтеза',
    pt: 'Escolher voz para síntese de voz',
  },
  'BaseEditForm:BE-error botadmin.common.weak_auth_token': {
    eng: 'The access token is weak. Use the following mask: \n Ax8:Bx40 (where A is a Latin character in lower- or uppercase, and B is a digit or a Latin character in lower- or uppercase)',
    ru: 'Токен недостаточно сложный. Используйте следующую маску: \n Ax8:Bx40 (А — латинская буква в любом регистре, В — цифра или латинская буква в любом регистре)',
    pt: 'O token de acesso é fraco. Use a seguinte máscara:\n Ax8:Bx40 (em que A é um caractere latino em maiúscula ou minúscula e B é um dígito ou um caractere latino em maiúscula ou minúsculas)',
  },
  'BaseEditForm:BE-error chatadapter.common.invalid_url_format': {
    eng: 'Invalid URL format',
    ru: 'Некорректный формат URL',
    pt: 'Formato de URL inválido',
  },
  'BaseEditForm:BE-error chatadapter.messaging.livetex_visitor_invalid_response_body': {
    eng: 'Invalid token',
    ru: 'Недействительный токен',
  },
  'BuyMinutesModal: justai minutes': {
    eng: 'Speech recognition and synthesis',
    ru: 'Распознавание и синтез речи',
    pt: 'Reconhecimento e síntese de fala',
  },
  'BuyMinutesModal: asr minutes': {
    eng: 'Outbound calls',
    ru: 'Исходящие вызовы',
    pt: 'Chamadas de saída',
  },
  'ChatWidgetEditor involving greeting show label': {
    eng: 'Welcome message in the chat window',
    ru: 'Приветствие в окне чата',
    pt: 'Mensagem de boas-vindas na janela de chat',
  },
  'ContractRequest Go to my dashboard': {
    eng: 'Go to my dashboard',
    ru: 'Вернуться на дашборд',
    pt: 'Acessar meu painel',
  },
  'VoiceChannels forceUpdateModal title': {
    eng: 'The inbound channel is already in use',
    ru: 'Входящий канал уже используется',
    pt: 'O canal de entrada já está em uso',
  },
  'VoiceChannels forceUpdateModal btn': {
    eng: 'Connect',
    ru: 'Подключить',
    pt: 'Conectar',
  },
  'VoiceChannels forceUpdateModal text only one channel': {
    eng: 'Your account can only have one active channel for inbound calls, and it is used in the bot',
    ru: 'Ваш аккаунт может иметь только один активный канал для входящих звонков и он используется в боте',
    pt: 'A sua conta só pode ter um canal ativo para chamadas de entrada, e ele é utilizado no bot',
  },
  'VoiceChannels forceUpdateModal text force update': {
    eng: 'It will stop accepting calls after a new inbound channel is enabled',
    ru: 'При подключении нового входящего канала он прекратит прием звонков',
    pt: 'Ele parará de aceitar chamadas depois que um novo canal de entrada for ativado',
  },
  'Tariff limits phrases': {
    eng: 'If you are out of time or user limits the bot will say:',
    ru: 'Если у вас закончились лимиты, бот скажет:',
    pt: 'Se você estiver sem tempo ou limites de usuário, o bot dirá:',
  },
  'Tariff $[1]': {
    eng: 'Plan $[1]',
    ru: 'Тариф $[1]',
    pt: 'Plano $[1]',
  },
  'Tariff Chat API': {
    eng: 'Chat API',
    ru: 'Chat API',
    pt: 'Chat API',
  },
  'Tariff management': {
    eng: 'Subscription management',
    ru: 'Управление тарифом',
    pt: 'Gerenciamento de assinaturas',
  },
  '{user.password.weak.LOW}': {
    eng: 'The minimum password length must be 8 characters. We recommend you to use at least 1 number, 1 lowercase, and 1 uppercase letter',
    ru: 'Длина пароля должна быть не менее 8 символов. Мы рекомендуем использовать в пароле как минимум одну цифру, одну строчную и одну прописную буквы',
    pt: 'O comprimento mínimo da senha deve ser de 8 caracteres. Recomendamos usar pelo menos 1 número, 1 letra minúscula e 1 letra maiúscula',
    cn: 'The minimum password length must be 8 characters. We recommend you to use at least 1 number, 1 lowercase, and 1 uppercase letter',
  },
  '{user.password.weak.MEDIUM}': {
    eng: 'Too weak password. Password length must be at least 10 characters. The password must contain at least one number, one lower case letter, one upper case letter',
    ru: 'Слишком слабый пароль. Длина пароля должна быть не менее 10 символов. Пароль должен содержать как минимум одну цифру, одну строчную и одну прописную буквы.',
    pt: 'Senha muito fraca. O comprimento da senha deve ter, pelo menos, 10 caracteres. A senha deve conter, pelo menos, um número, uma letra minúscula e uma letra maiúscula',
  },
  '{user.password.weak.HIGH}': {
    eng: 'The minimum password length must be 12 characters. The password must contain at least 1 number, 1 lowercase, 1 uppercase letter, and one of the special characters *.!@#$%^&(){}[]:;<>,.?/~_+-=|\\',
    ru: 'Длина пароля должна быть не менее 12 символов. Пароль должен содержать как минимум одну цифру, одну строчную, одну прописную буквы и один из специальных символов *.!@#$%^&(){}[]:;<>,.?/~_+-=|\\',
    pt: 'O comprimento mínimo da senha deve ser de 12 caracteres. A senha deve conter pelo menos 1 número, 1 letra minúscula, 1 letra maiúscula e um dos caracteres especiais *.!@#$%^&(){}[]:;<>,.?/~_+-= |\\',
    cn: 'The minimum password length must be 12 characters. The password must contain at least 1 number, 1 lowercase, 1 uppercase letter, and one of the special characters *.!@#$%^&(){}[]:;<>,.?/~_+-=|\\',
  },
  'TelephonySettings title': {
    eng: 'Telephony settings',
    ru: 'Настройка телефонии',
    pt: 'Configurações de telefonia',
  },
  'AddChannelCard bitrixOpenTooMuchChannels title': {
    eng: 'Number of bots connected to Bitrix24 channel is exceeded.',
    ru: 'Превышено количество ботов, подключенных к каналу Битрикс24',
    pt: 'O número de bots conectados ao canal Bitrix24 foi excedido.',
  },
  'AddChannelCard bitrixOpenTooMuchChannels text': {
    eng: 'The standard settings of the Bitrix24 system allow to use up to 5 bots. If you want to connect this bot, delete the Bitrix24 channel from one of the previously added scripts. If your Bitrix24 system settings allow you to use more bots, click “Ignore”.',
    ru: 'Стандартные настройки системы Битрикс24 позволяют использовать не более 5 ботов. В случае, если вы хотите подключить данного бота, то удалите канал Битрикс24 в одном из ранее добавленных сценариев. Если ваши настройки системы Битрикс24 позволяют использовать большее число ботов, то нажмите «Все равно добавить».',
    pt: 'As configurações padrão do sistema Bitrix24 permitem usar até 5 bots. Se você quiser conectar este bot, exclua o canal Bitrix24 de um dos scripts adicionados anteriormente. Se as configurações do sistema Bitrix24 permitirem que você use mais bots, clique em "Ignorar".',
    cn: 'The standard settings of the Bitrix24 system allow to use up to 5 bots. If you want to connect this bot, delete the Bitrix24 channel from one of the previously added scripts. If your Bitrix24 system settings allow you to use more bots, click "Ignore".',
  },
  'AddChannelCard bitrixOpenTooMuchChannels Connect bitrix24 anyway': {
    eng: 'Ignore',
    ru: 'Все равно добавить',
    pt: 'Ignorar',
  },
  'AddChannelCard bitrixOpenTooMuchChannels Ok': {
    eng: 'OK',
    ru: 'Ok',
    pt: 'OK',
  },
  'AddChannelCard sender name placeholder': {
    eng: 'Please enter the sender name',
    ru: 'Введите имя отправителя',
    pt: 'Digite o nome do remetente',
  },
  'AddChannelCard BITRIX': {
    eng: 'Bitrix24 and Open Channels',
    ru: 'Битрикс24 и Открытые линии',
    pt: 'Bitrix24 e canais abertos',
  },
  'AddChannelCard BITRIX incoming label': {
    eng: 'Bitrix24',
    ru: 'Битрикс24',
    pt: 'Bitrix24',
  },
  'AddChannelCard BITRIX operator label': {
    eng: 'Open Channels',
    ru: 'Открытые линии',
    pt: 'Canais abertos',
  },
  'AddChannelCard INCOMING_JIVOSITE': {
    eng: 'JivoChat',
    ru: 'Jivo',
    pt: 'JivoChat',
  },
  'AddChannelCard WEBIM': {
    eng: 'Webim (Custom Channel API)',
    ru: 'Webim (Custom Channel API)',
    pt: 'Webim (Custom Channel API)',
  },
  'AddChannelCard LIVETEX': {
    eng: 'LiveTex (obsolete)',
    ru: 'LiveTex (устарел)',
    pt: 'LiveTex (obsoleto)',
  },
  'AddChannelCard LIVETEX_VISITOR': {
    eng: 'LiveTex',
    ru: 'LiveTex',
    pt: 'LiveTex',
  },
  'AddChannelCard INBOUND_LIVETEX': {
    eng: 'LiveTex',
    ru: 'LiveTex',
    pt: 'LiveTex',
  },
  'AddChannelCard  INCOMING_JIVOSITE': {
    eng: 'JivoChat',
    ru: 'Jivo',
    pt: 'JivoChat',
  },
  'AddChannelCard JIVOSITE': {
    eng: 'JivoChat',
    ru: 'Jivo',
    pt: 'JivoChat',
  },
  'AddChannelCard THREADS': {
    eng: 'edna.chatCenter',
    ru: 'edna.chatCenter',
    pt: 'edna.chatCenter',
  },
  'AddChannelCard OPERATORAPI': {
    eng: 'Operator API',
    ru: 'Operator API',
    pt: 'Operator API',
  },
  'AddChannelCard WAZZUP': {
    eng: 'Wazzup',
    ru: 'Wazzup',
    pt: 'Wazzup',
  },
  'AddChannelCard AIMYCHAT': {
    eng: '#{config.aimychat.productName}',
    ru: '#{config.aimychat.productName}',
  },
  'AddChannelCard CHAT_WIDGET': {
    eng: 'Chat widget',
    ru: 'Чат-виджет',
    pt: 'Widget de chat',
    cn: '聊天窗口',
  },
  'AddChannelCard CHAT_WIDGET_CHANGED': {
    eng: 'Chat widget on your website',
    ru: 'Чат-виджет на вашем сайте',
    pt: 'Widget de chat no seu site',
  },
  'AddChannelCard ALEXA': {
    eng: 'ALEXA',
    ru: 'ALEXA',
    pt: 'ALEXA',
  },
  'AddChannelCard TELEGRAM': {
    eng: 'Telegram',
    ru: 'Telegram',
    pt: 'Telegram',
  },
  'AddChannelCard YANDEX': {
    eng: 'Alice',
    ru: 'Алиса',
    pt: 'Alice',
  },
  'AddChannelCard ZENBOX': {
    eng: 'Aimybox',
    ru: 'Aimybox',
    pt: 'Aimybox',
  },
  'AddChannelCard WECHAT': {
    eng: 'WeChat',
    ru: 'WeChat',
    pt: 'WeChat',
  },
  'AddChannelCard INCOMING_WEBIM2': {
    eng: 'Webim (External Bot API 2.0)',
    ru: 'Webim (External Bot API 2.0)',
    pt: 'Webim (External Bot API 2.0)',
  },
  'AddChannelCard VK': {
    eng: 'VK',
    ru: 'ВКонтакте',
    pt: 'VK',
  },
  'AddChannelCard GOOGLE': {
    eng: 'Google Assistant',
    ru: 'Google Assistant',
    pt: 'Google Assistente',
  },
  'AddChannelCard FACEBOOK': {
    eng: 'Facebook',
    ru: 'Facebook',
    pt: 'Facebook',
  },
  'AddChannelCard INSTAGRAM': {
    eng: 'Instagram',
    ru: 'Instagram',
    pt: 'Instagram',
  },
  'AddChannelCard I_DIGITAL': {
    eng: 'WhatsApp i-Digital',
    ru: 'WhatsApp i-Digital',
    pt: 'WhatsApp i-Digital',
  },
  'AddChannelCard WHATSAPP': {
    eng: 'WhatsApp Business',
    ru: 'WhatsApp Business',
    pt: 'WhatsApp Business',
  },
  'AddChannelCard NEXMO': {
    eng: 'WhatsApp Vonage',
    ru: 'WhatsApp Vonage',
    pt: 'WhatsApp Vonage',
  },
  'AddChannelCard MARUSIA': {
    eng: 'Marusia',
    ru: 'Маруся',
    pt: 'Marusia',
  },
  'AddChannelCard CHAT_API': {
    eng: 'Chat API',
    ru: 'Chat API',
    pt: 'Chat API',
  },
  'AddChannelCard AZURE': {
    eng: 'Microsoft Teams',
    ru: 'Microsoft Teams',
    pt: 'Microsoft Teams',
  },
  'AddChannelCard VIBER': {
    eng: 'Viber',
    ru: 'Viber',
    pt: 'Viber',
  },
  'AddChannelCard SBER': {
    eng: 'Sber Salut',
    ru: 'Сбер Салют',
    pt: 'Sber Salut',
  },
  'AddChannelCard MATTERMOST': {
    eng: 'Mattermost',
    ru: 'Mattermost',
    pt: 'Mattermost',
  },
  'AddChannelCard sender name label': {
    eng: 'Sender name',
    ru: 'Имя отправителя',
    pt: 'Nome do remetente',
  },
  'ChatWidgetEditor use button label': {
    eng: 'Disable text input when using buttons',
    ru: 'Блокировать ввод текста при использовании кнопок',
    pt: 'Desativar entrada de texto quando usar botões',
  },
  'Webhook address': {
    eng: 'Webhook URL',
    ru: 'URL вебхука',
    pt: 'URL do webhook',
  },
  'BotTemplate bot ready code label': {
    eng: 'Bot code',
    ru: 'Код бота',
    pt: 'Código do bot',
  },
  'BotTemplate bot ready code copy code': {
    eng: 'Copy code',
    ru: 'Скопировать',
    pt: 'Copiar código',
  },
  'CheckPhoneNumber test call start': {
    eng: 'We called at $[1]',
    ru: 'Мы позвонили на номер $[1]',
    pt: 'Ligamos para $[1]',
  },
  'CallTasks: initial title callList': {
    eng: 'Call campaign',
    ru: 'Обзвон',
    pt: 'Campanha de chamadas',
  },
  'CallTasks: initial title crm': {
    eng: 'Campaign from CRM system',
    ru: 'Обзвон из CRM',
    pt: 'Campanha do sistema de CRM',
  },
  'BotTemplate bot ready code description': {
    eng: 'Insert this code anywhere between the <head></head> tags in all pages of your website where you want to have your bot widget published.',
    ru: 'Вставьте этот код в любое место между тегами <head></head> на всех страницах вашего сайта, где вы хотите разместить виджет с вашим ботом.',
    pt: 'Insira este código em qualquer lugar entre as tags <head></head> em todas as páginas do seu site em que você deseja publicar o widget de bot.',
  },
  'AdditionalPackages short text asrMinutes': {
    eng: 'min',
    ru: 'мин',
    pt: 'min',
  },
  'AdditionalPackages short text justaiMinutes': {
    eng: 'min',
    ru: 'мин',
    pt: 'min',
  },
  'AdditionalPackages short text sms': {
    eng: 'SMS',
    ru: 'SMS',
    pt: 'SMS',
  },
  'AdditionalPackages short text uniqueClientsByChannel': {
    eng: 'user',
    ru: 'пользователь',
    pt: 'usuário',
  },
  'FreeTariffModal: title': {
    eng: 'Change plan',
    ru: 'Смените тариф',
    pt: 'Alterar plano',
  },
  'FreeTariffModal: text': {
    eng: 'To buy a package of telephony minutes, switch to a paid plan.',
    ru: 'Чтобы купить пакет минут, перейдите на платный тариф.',
    pt: 'Para comprar um pacote de minutos de telefonia, mude para um plano pago.',
  },
  'FreeTariffModal: cancel': {
    eng: 'Cancel',
    ru: 'Отмена',
    pt: 'Cancelar',
  },
  'FreeTariffModal: go to prices': {
    eng: 'Go to Pricing',
    ru: 'Открыть тарифы',
    pt: 'Acessar Preços',
  },
  Developer: {
    eng: 'Developer',
    ru: 'Developer',
    pt: 'Desenvolvedor',
  },
  'Business Basic': {
    eng: 'Business Basic',
    ru: 'Business Basic',
    pt: 'Empresarial Básico',
  },
  'Business Standard': {
    eng: 'Business Standard',
    ru: 'Business Standard',
    pt: 'Empresarial Padrão',
  },
  'Business Pro': {
    eng: 'Business Pro',
    ru: 'Business Pro',
    pt: 'Empresarial Pro',
  },
  'Telephony Basic': {
    eng: 'Calls Basic plan',
    ru: 'Звонки Basic',
    pt: 'Plano Chamadas Básico',
  },
  'Telephony Standard': {
    eng: 'Calls Standard plan',
    ru: 'Звонки Standard',
    pt: 'Plano Chamadas Padrão',
  },
  'Telephony Pro': {
    eng: 'Calls Pro plan',
    ru: 'Звонки Pro',
    pt: 'Plano Chamadas Pro',
  },
  'TariffLimitsTable aimychatMaxOperatorGroups': {
    eng: '#{config.aimychat.productName} agent groups',
    ru: 'Группы операторов #{config.aimychat.productName}',
    pt: 'Grupos de agentes do #{config.aimychat.productName}',
  },
  'TariffLimitsTable aimychatMaxOperators': {
    eng: '#{config.aimychat.productName} agents',
    ru: 'Операторы #{config.aimychat.productName}',
    pt: 'Agents do #{config.aimychat.productName}',
  },
  'Calls Startup': {
    eng: 'Calls Startup',
    ru: 'Звонки Startup',
    pt: 'Chamadas Startup',
  },
  'Calls Growth': {
    eng: 'Calls Growth',
    ru: 'Звонки Growth',
    pt: 'Chamadas Growth',
  },
  'Calls Optimal': {
    eng: 'Calls Optimal',
    ru: 'Звонки Optimal',
    pt: 'Chamadas Optimal',
  },
  'Calls Premium': {
    eng: 'Calls Premium',
    ru: 'Звонки Premium',
    pt: 'Chamadas Premium',
  },
  'TariffInfo:LimitsUpdateDate': {
    eng: 'Limits will be updated',
    ru: 'Дата обновления лимитов',
    pt: 'Os limites serão atualizados',
  },
  'TariffInfo:FreePeriodUntil': {
    eng: 'Free period until',
    ru: 'Бесплатный период до',
    pt: 'Período gratuito até',
  },
  'Groups field placeholder name': {
    eng: 'Name',
    ru: 'Название',
    pt: 'Nome',
  },
  'Groups field placeholder descriptions': {
    eng: 'Description',
    ru: 'Описание',
    pt: 'Descrição',
  },
  'Destination select placeholder': {
    eng: 'Select from the list',
    ru: 'Выберите из списка',
    pt: 'Selecionar na lista',
  },
  'Enter custom': {
    eng: 'Enter $[1]',
    ru: 'Введите $[1]',
    pt: 'Digite $[1]',
  },
  'Channels:Whatsapp:phoneNumberId': {
    eng: 'Phone number ID',
    ru: 'ID номера телефона',
    pt: 'ID do número de telefone',
  },
  'ConfirmNumber:ResendSms': {
    eng: 'Send again',
    ru: 'Отправить SMS еще раз',
    pt: 'Enviar novamente',
    cn: 'Send again',
  },
  'MyBot:GreetingMessage:Title': {
    eng: 'Your first bot is created',
    ru: 'Вы создали первого бота',
    pt: 'Seu primeiro bot foi criado',
  },
  'MyBot:GreetingMessage:Description': {
    eng: 'Welcome to the bot workspace. You will find sections for configuring the bot on the sidebar. <br> Bot performance summary and useful resources are displayed below.',
    ru: 'Добро пожаловать в рабочее пространство бота. На боковой панели предтавлены разделы для настройки бота. <br> Ниже показана основная информация о его работе и полезные материалы.',
    pt: 'Boas-vindas ao espaço de trabalho do bot. Você encontrará seções para configurar o bot na barra lateral.<br> O resumo do desempenho do bot e recursos úteis são exibidos abaixo.',
  },
  'MyBot:WhatsNext:Title': {
    eng: "What's next",
    ru: 'Что дальше',
    pt: 'O que vem a seguir',
  },
  'MyBot:WhatsNext:HideTooltips': {
    eng: 'Hide hints',
    ru: 'Скрыть подсказки',
    pt: 'Ocultar dicas',
  },
  'MyBot:WhatsNext:Telegram': {
    eng: 'Telegram',
    ru: 'Telegram',
    pt: 'Telegram',
  },
  'MyBot:WhatsNext:WhatsApp': {
    eng: 'WhatsApp',
    ru: 'WhatsApp',
    pt: 'WhatsApp',
  },
  'MyBot:WhatsNext:Description': {
    eng: 'You can set up the bot in the visual editor and connect new channels to it.',
    ru: 'Вы можете настроить бота в конструкторе и подключить к нему другие каналы.',
    pt: 'Você pode definir o bot no editor visual e conectar novos canais a ele.',
  },
  'MyBot:WhatsNext:CardTitle_1': {
    eng: 'Get started with #{config.aimychat.productName}',
    ru: 'Знакомство с #{config.aimychat.productName}',
    pt: 'Comece com o #{config.aimychat.productName}',
  },
  'MyBot:WhatsNext:CardDescription_1': {
    eng: 'Set up an agent workplace',
    ru: 'Настройте рабочее место оператора',
    pt: 'Defina um local de trabalho do agente',
  },
  'MyBot:WhatsNext:CardTitle_2': {
    eng: 'Connect channels',
    ru: 'Подключение к каналам',
    pt: 'Conectar canais',
  },
  'MyBot:WhatsNext:CardDescription_2': {
    eng: 'Connect the bot to other messengers or business channels, for example, $[1]',
    ru: 'Подключите бота в другие мессенджеры или бизнес-каналы, например, $[1]',
    pt: 'Conecte o bot a outros mensageiros ou canais empresariais, por exemplo, o $[1]',
  },
  'MyBot:WhatsNext:CardTitle_Help': {
    eng: 'Help with bot connection',
    ru: 'Помощь с подключением',
  },
  'MyBot:WhatsNext:CardDescription_Help': {
    eng: 'Schedule a free consultation with us to set up and launch a bot for your business',
    ru: 'Запишитесь на бесплатную консультацию по настройке и запуску бота для вашего бизнеса',
  },
  'MyBot:WhatsNext:CardTitle_3': {
    eng: 'Question answering (FAQ)',
    ru: 'Вопрос–Ответ (FAQ)',
    pt: 'Perguntas-Respostas',
  },
  'MyBot:WhatsNext:CardDescription_3': {
    eng: 'Add different question wording so that the bot understands the client better',
    ru: 'Добавьте разные формулировки вопросов, чтобы бот лучше понимал клиента',
    pt: 'Adicione uma redação diferente para a pergunta para que o bot entenda o cliente melhor',
  },
  'MyBot:WhatsNext:CardTitle_4': {
    eng: 'Create a call',
    ru: 'Создайте обзвон',
    pt: 'Crie uma chamada',
  },
  'MyBot:WhatsNext:CardDescription_4': {
    eng: 'Create and run your first customer call',
    ru: 'Создайте и запустите ваш первый обзвон клиентов',
    pt: 'Crie e execute sua primeira chamada de cliente',
  },
  'MyBot:WhatsNext:CardTitle_5': {
    eng: 'Start receiving calls',
    ru: 'Начните принимать звонки',
    pt: 'Comece a receber chamadas',
  },
  'MyBot:WhatsNext:CardDescription_5': {
    eng: 'Set up reception of incoming calls from your clients',
    ru: 'Настройте приём входящих звонков от ваших клиентов',
    pt: 'Defina a recepção de chamadas recebidas dos seus clientes',
  },
  'MyBot:WhatsNext:ChatGPT:Exhausted:Title': {
    eng: 'ChatGPT disabled',
    ru: 'ChatGPT отключен',
    pt: 'ChatGPT desativado',
  },
  'MyBot:WhatsNext:ChatGPT:Exhausted:Description': {
    eng: 'The ChatGPT usage limit has been reached. To increase your limits, switch to another subscription plan.',
    ru: 'Лимит использования ChatGPT исчерпан. Чтобы увеличить лимиты, перейдите на другой тариф.',
    pt: 'O limite de uso do ChatGPT foi alcançado. Para aumentar seus limites, mude para outro plano de assinatura.',
  },
  'MyBot:WhatsNext:ChatGPT:Exhausted:TextButton': {
    eng: 'Change plan',
    ru: 'Сменить тариф',
    pt: 'Alterar plano',
  },
  'MyBot:WhatsNext:ChatGPT:Title': {
    eng: 'ChatGPT usage is limited',
    ru: 'ChatGPT ограничен',
    pt: 'O uso do ChatGPT é limitado',
  },
  'MyBot:WhatsNext:ChatGPT:Description': {
    eng: 'The ChatGPT usage on the free plan is limited. To increase your limits, switch to another plan.',
    ru: 'ChatGPT на бесплатном тарифе ограничен. Чтобы увеличить лимиты, перейдите на другой тариф.',
    pt: 'O uso do ChatGPT no plano gratuito é limitado. Para alterar seus limites, mude para outro plano.',
  },
  'MyBot:WhatsNext:ChatGPT:TextButton': {
    eng: 'Change plan',
    ru: 'Сменить тариф',
    pt: 'Alterar plano',
  },
  'MyBot:WhatsNext:TextButton_Go': {
    eng: 'Go',
    ru: 'Перейти',
    pt: 'Ir',
  },
  'MyBot:WhatsNext:TextButton_Connect': {
    eng: 'Connect',
    ru: 'Подключить',
    pt: 'Conectar',
  },
  'MyBot:Hints:ChooseTime': {
    eng: 'Select a time',
    ru: 'Выбрать время',
  },
  'MyBot:Hints:ChooseTime:link': {
    eng: 'https://calendly.com/aimylogic-product/consultation',
    ru: 'https://calendly.com/aimylogic-product/consultation',
  },
  'MyBot:ChatBot:Title': {
    eng: 'Chatbot',
    ru: 'Чат-бот',
    pt: 'Chatbot',
  },
  'MyBot:CallsBot:DialerTitle': {
    eng: 'Bot for outgoing calls',
    ru: 'Бот для исходящих звонков',
    pt: 'Bot para chamadas de saída',
  },
  'MyBot:CallsBot:AvrTitle': {
    eng: 'Bot for incoming calls',
    ru: 'Бот для входящих звонков',
    pt: 'Bot para chamadas recebidas',
  },
  'MyBot:ChatBot:Waiting': {
    eng: 'Waiting for connection',
    ru: 'Ожидает подключения',
    pt: 'Aguardando conexão',
  },
  'MyBot:ChatBot:Connected': {
    eng: 'Connected',
    ru: 'Подключен',
    pt: 'Conectado',
  },
  'MyBot:Status:NO_CONNECTED': {
    eng: 'Waiting to connect',
    ru: 'Ожидает подключения',
    pt: 'Aguardando para conectar',
  },
  'MyBot:Status:OUTDATED': {
    eng: 'Waiting to connect',
    ru: 'Ожидает подключения',
    pt: 'Aguardando para conectar',
  },
  'MyBot:Status:IN_PROGRESS': {
    eng: 'Waiting for publishing',
    ru: 'Ожидает публикации',
    pt: 'Aguardando publicação',
  },
  'MyBot:Status:UNAVAILABLE': {
    eng: 'Waiting for publishing',
    ru: 'Ожидает публикации',
    pt: 'Aguardando publicação',
  },
  'MyBot:Status:DEPLOY_FAILED': {
    eng: 'Connection error',
    ru: 'Ошибка публикации',
    pt: 'Erro de conexão',
  },
  'MyBot:Status:TEST_FAILED': {
    eng: 'Connection error',
    ru: 'Ошибка публикации',
    pt: 'Erro de conexão',
  },
  'MyBot:Status:OK': {
    eng: 'Connected',
    ru: 'Подключен',
    pt: 'Conectado',
  },
  'MyBot:Status:notRunning': {
    eng: 'Campaign not started',
    ru: 'Обзвон не запущен',
    pt: 'Campanha não iniciada',
  },
  'MyBot:Status:inProgress': {
    eng: 'Campaign in progress',
    ru: 'Идет обзвон',
    pt: 'Campanha em andamento',
  },
  'MyBot:Status:paused': {
    eng: 'Campaign paused',
    ru: 'Обзвон на паузе',
    pt: 'Campanha pausada',
  },
  'MyBot:Status:planned': {
    eng: 'Campaign planned',
    ru: 'Обзвон запланирован',
    pt: 'Campanha planejada',
  },
  'MyBot:Status:finished': {
    eng: 'Campaign finished',
    ru: 'Обзвон завершен',
    pt: 'Campanha encerrada',
  },
  'MyBot:Status:IncomingNoRun': {
    eng: 'Call acceptance is not running',
    ru: 'Приём звонков не запущен',
    pt: 'A aceitação de chamada não está sendo executada',
  },
  'MyBot:Status:IncomingRun': {
    eng: 'Receiving calls',
    ru: 'Идёт приём звонков',
    pt: 'Recebendo chamadas',
  },
  'MyBot:Status:new': {
    eng: 'The call is not running',
    ru: 'Обзвон не запущен',
    pt: 'A chamada não está em execução',
  },
  'MyBot:Statistics:Title': {
    eng: 'Clients for the last 30 days',
    ru: 'Клиенты за последние 30 дней',
    pt: 'Clientes pelos últimos 30 dias',
  },
  'MyBot:Statistics:Remaining': {
    eng: '$[1] left',
    ru: 'осталось на $[1]',
    pt: '$[1] restantes',
  },
  'MyBot:Statistics:Tooltip': {
    eng: 'Here will be the text',
    ru: 'Тут Будет текст',
    pt: 'O texto irá aqui',
  },
  'MyBot:Statistics:To': {
    eng: 'until',
    ru: 'до',
    pt: 'até',
  },
  'MyBot:Statistics:AllChannels': {
    eng: 'All channels',
    ru: 'Все каналы',
    pt: 'Todos os canais',
  },
  'MyBot:Statistics:Days_one': {
    eng: '$[1] day',
    ru: '$[1] день',
    pt: '$[1] dia',
  },
  'MyBot:Statistics:Days_few': {
    eng: '$[1] days',
    ru: '$[1] дня',
    pt: '$[1] dias',
  },
  'MyBot:Statistics:Days_many': {
    eng: '$[1] days',
    ru: '$[1] дней',
    pt: '$[1] dias',
  },
  'MyBot:Statistics:Days_other': {
    eng: '$[1] days',
    ru: '$[1] дней',
    pt: '$[1] dias',
  },
  'MyBot:Statistics:uniqueUsers_one': {
    eng: 'client',
    ru: 'клиент',
    pt: 'cliente',
  },
  'MyBot:Statistics:uniqueUsers_few': {
    eng: 'clients',
    ru: 'клиента',
    pt: 'clientes',
  },
  'MyBot:Statistics:uniqueUsers_many': {
    eng: 'clients',
    ru: 'клиентов',
    pt: 'clientes',
  },
  'MyBot:Statistics:uniqueUsers_other': {
    eng: 'clients',
    ru: 'клиентов',
    pt: 'clientes',
  },
  'MyBot:Statistics:sms_one': {
    eng: 'SMS message',
    ru: 'SMS-сообщение',
    pt: 'mensagem de SMS',
  },
  'MyBot:Statistics:sms_few': {
    eng: 'SMS messages',
    ru: 'SMS-сообщения',
    pt: 'mensagens de SMS',
  },
  'MyBot:Statistics:sms_many': {
    eng: 'SMS messages',
    ru: 'SMS-сообщений',
    pt: 'mensagens de SMS',
  },
  'MyBot:Statistics:sms_other': {
    eng: 'SMS messages',
    ru: 'SMS-сообщений',
    pt: 'mensagens de SMS',
  },
  'MyBot:Statistics:asrSeconds_one': {
    eng: 'speech recognition and synthesis minute',
    ru: 'минута распознавания и синтеза речи',
    pt: 'minuto de reconhecimento de fala e síntese',
  },
  'MyBot:Statistics:asrSeconds_few': {
    eng: 'speech recognition and synthesis minutes',
    ru: 'минуты распознавания и синтеза речи',
    pt: 'minutos de reconhecimento de fala e síntese',
  },
  'MyBot:Statistics:asrSeconds_many': {
    eng: 'speech recognition and synthesis minutes',
    ru: 'минут распознавания и синтеза речи',
    pt: 'minutos de reconhecimento de fala e síntese',
  },
  'MyBot:Statistics:asrSeconds_other': {
    eng: 'speech recognition and synthesis minutes',
    ru: 'минут распознавания и синтеза речи',
    pt: 'minutos de reconhecimento de fala e síntese',
  },
  'MyBot:Statistics:justaiSeconds_one': {
    eng: '#{config.zenflow.companyName} telephony minute',
    ru: 'минута телефонии #{config.zenflow.companyName}',
    pt: 'Minuto de telefonia #{config.zenflow.companyName}',
  },
  'MyBot:Statistics:justaiSeconds_few': {
    eng: '#{config.zenflow.companyName} telephony minutes',
    ru: 'минуты телефонии #{config.zenflow.companyName}',
    pt: 'apenas minutos de telefonia #{config.zenflow.companyName}',
  },
  'MyBot:Statistics:justaiSeconds_many': {
    eng: '#{config.zenflow.companyName} telephony minutes',
    ru: 'минут телефонии #{config.zenflow.companyName}',
    pt: 'apenas minutos de telefonia #{config.zenflow.companyName}',
  },
  'MyBot:Statistics:justaiSeconds_other': {
    eng: '#{config.zenflow.companyName} telephony minutes',
    ru: 'минут телефонии #{config.zenflow.companyName}',
    pt: 'apenas minutos de telefonia #{config.zenflow.companyName}',
  },
  'MyBot:Statistics:Of': {
    eng: 'of',
    ru: 'из',
    pt: 'de',
  },
  'MyBot:Statistics:IncreaseLimits': {
    eng: 'Increase limits',
    ru: 'Увеличить лимиты',
    pt: 'Aumentar limites',
  },
  'Sidebar:Constructor': {
    eng: 'Visual editor',
    ru: 'Конструктор',
    pt: 'Editor visual',
  },
  'Sidebar:MyBot': {
    eng: 'My bot',
    ru: 'Мой бот',
    pt: 'Meu bot',
  },
  'Sidebar:SkillPostSetup': {
    eng: 'Skills',
    ru: 'Навыки',
    pt: 'Habilidades',
  },
  'Sidebar:FAQ': {
    eng: 'Question answering (FAQ)',
    ru: 'Вопрос-Ответ (FAQ)',
    pt: 'Perguntas-Respostas',
  },
  'Sidebar:Channels': {
    eng: 'Channel connections',
    ru: 'Подключения каналов',
    pt: 'Conexões de canal',
  },
  'Sidebar:MailingList': {
    eng: 'Text campaigns',
    ru: 'Рассылки',
  },
  'Sidebar:Support': {
    eng: 'Support',
    ru: 'Поддержка',
    pt: 'Suporte',
  },
  'Sidebar:Profile': {
    eng: 'Profile',
    ru: 'Профиль',
    pt: 'Perfil',
  },
  'Sidebar:ReferenceCenter': {
    eng: 'Help center',
    ru: 'Справочный центр',
    pt: 'Central de ajuda',
  },
  'Sidebar:ConnectChannel': {
    eng: '+ Connect channel',
    ru: '+ Подключить канал',
    pt: '+ Conectar canal',
  },
  'Sidebar:ChatGPTMarker': {
    eng: '<i class="just-emoji-brain"></i> ChatGPT usage is limited',
    ru: '<i class="just-emoji-brain"></i> ChatGPT ограничен',
    pt: '<i class="just-emoji-brain"></i> O uso do ChatGPT é limitado',
  },
  'Sidebar:ChatGPTMarker:Paid': {
    eng: '<i class="just-emoji-brain"></i> ChatGPT',
    ru: '<i class="just-emoji-brain"></i> ChatGPT',
    pt: '<i class="just-emoji-brain"></i> ChatGPT',
  },
  'Sidebar:ChatGPTMarker:LimitExceeded': {
    eng: '<i class="just-emoji-brain"></i> ChatGPT disabled',
    ru: '<i class="just-emoji-brain"></i> ChatGPT отключен',
    pt: '<i class="just-emoji-brain"></i> ChatGPT desativado',
  },
  'Sidebar:ChatGPTMarkerTooltip': {
    eng: 'To increase your limits, <a href="$[1]" target="_blank">switch to another subscription plan</a>.',
    ru: 'Чтобы увеличить лимиты, <a href="$[1]" target="_blank">перейдите на другой тариф</a>.',
    pt: 'Para aumentar seus limites,<a href="$[1]" target="_blank">mude para outro plano de assinatura</a>.',
  },
  'Sidebar:ChatGPTMarkerTooltip:Paid': {
    eng: 'Allows the bot to answer questions as a live agent.',
    ru: 'Позволяет боту отвечать на вопросы как живой оператор.',
    pt: 'Permite que o bot responda a perguntas como um agente ao vivo.',
  },
  'Sidebar:CallsBot:DialerStartCall': {
    eng: 'Start a call',
    ru: 'Запустить обзвон',
    pt: 'Iniciar uma chamada',
  },
  'Sidebar:CallsBot:IvrStartCall': {
    eng: 'Start receiving calls',
    ru: 'Начать принимать звонки',
    pt: 'Comece a receber chamadas',
  },
  'Sidebar:DocsLink:Title_1': {
    eng: 'Visual editor',
    ru: 'Конструктор сценариев',
    pt: 'Editor visual',
  },
  'Sidebar:DocsLink:Skills': {
    eng: 'Skills',
    ru: 'Навыки',
    pt: 'Habilidades',
  },
  'Sidebar:DocsLink:SkillsDescription': {
    eng: 'Edit the bot or add new skills for it',
    ru: 'Отредактируйте бота или добавьте для него новые навыки',
    pt: 'Edite o bot ou adicione novas habilidades a ele',
  },
  'Sidebar:DocsLink:Description_1': {
    eng: 'Edit bot script and add features',
    ru: 'Редактируйте возможности и сценарии работы',
    pt: 'Edite scripts de bot e adicione recursos',
  },
  'Sidebar:DocsLink:Title_2': {
    eng: 'Detailed statistics',
    ru: 'Подробная статистика',
    pt: 'Estatísticas detalhadas',
  },
  'Sidebar:DocsLink:Description_2': {
    eng: 'Analyze how the bot is interacting with your clients',
    ru: 'Проанализируйте работу бота с клиентами',
    pt: 'Analise como o bot está interagindo com seus clientes',
  },
  'Sidebar:DocsLink:Title_3': {
    eng: 'Dialog analysis',
    ru: 'Анализ диалогов',
    pt: 'Análise de diálogo',
  },
  'Sidebar:DocsLink:Description_3': {
    eng: 'Assess the quality of bot answers',
    ru: 'Оцените качество ответов бота',
    pt: 'Avalie a qualidade das respostas do bot',
  },
  'Sidebar:DocsLink:Title_4': {
    eng: '#{config.aimychat.productName}',
    ru: '#{config.aimychat.productName}',
    pt: '#{config.aimychat.productName}',
  },
  'Sidebar:DocsLink:Description_4': {
    eng: 'Learn how agents work',
    ru: 'Узнайте, как работают операторы',
    pt: 'Aprenda como os agentes trabalham',
  },
  'Sidebar:DocsLink:Title_5': {
    eng: 'Starting to receive calls',
    ru: 'Запуск приёма звонков',
    pt: 'Começando a receber chamadas',
  },
  'Sidebar:DocsLink:Description_5': {
    eng: 'Customize the way you receive calls',
    ru: 'Настраивайте способы приёма звонков',
    pt: 'Personalize a maneira como você recebe chamadas',
  },
  'Sidebar:DocsLink:Title_6': {
    eng: 'Call campaign setup',
    ru: 'Запуск обзвонов',
    pt: 'Configuração de campanha de chamadas',
  },
  'Sidebar:DocsLink:Description_6': {
    eng: 'Configure campaign settings and schedule',
    ru: 'Настраивайте списки и время звонков',
    pt: 'Defina as configurações e o agendamento da campanha',
  },
  'Channels:Modal:TooltipTitle': {
    eng: 'This name is visible only for you in the “Channels” section.',
    ru: 'Это название видите только вы в разделе «Каналы».',
    pt: 'Este nome é visível apenas para você na seção "Canais".',
  },
  'TemplateLanguage:ru': {
    eng: 'Russian',
    ru: 'Русский',
    pt: 'Russo',
    cn: '俄语',
  },
  'TemplateLanguage:en': {
    eng: 'English',
    ru: 'Английский',
    pt: 'Inglês',
    cn: '英语',
  },
  'TemplateLanguage:zh': {
    eng: 'Chinese',
    ru: 'Китайский',
    pt: 'Chinês',
    cn: '中文',
  },
  'TemplateLanguage:cn': {
    eng: 'Chinese',
    ru: 'Китайский',
    pt: 'Chinês',
    cn: '中文',
  },
  'TemplateLanguage:pt': {
    eng: 'Portuguese',
    ru: 'Португальский',
    pt: 'Português',
    cn: '葡萄牙语',
  },
  'TemplateLanguage:ja': {
    eng: 'Japanese',
    ru: 'Японский',
    pt: 'Japonês',
    cn: '日本人',
  },
  'TemplateLanguage:es': {
    eng: 'Spanish',
    ru: 'Испанский',
    pt: 'Espanhol',
    cn: '西班牙语',
  },
  'TemplateLanguage:da': {
    eng: 'Danish',
    ru: 'Датский',
    pt: 'Dinamarquês',
    cn: '丹麦文',
  },
  'TemplateLanguage:nl': {
    eng: 'Dutch',
    ru: 'Нидерландский',
    pt: 'Holandês',
    cn: '荷兰语',
  },
  'TemplateLanguage:fr': {
    eng: 'French',
    ru: 'Французский',
    pt: 'Francês',
    cn: '法语',
  },
  'TemplateLanguage:de': {
    eng: 'German',
    ru: 'Немецкий',
    pt: 'Alemão',
    cn: '德语',
  },
  'TemplateLanguage:el': {
    eng: 'Greek',
    ru: 'Греческий',
    pt: 'Grego',
    cn: '希腊语',
  },
  'TemplateLanguage:it': {
    eng: 'Italian',
    ru: 'Итальянский',
    pt: 'Italiano',
    cn: '义大利文',
  },
  'TemplateLanguage:lt': {
    eng: 'Lithuanian',
    ru: 'Литовский',
    pt: 'Lituano',
    cn: '立陶宛语',
  },
  'TemplateLanguage:pl': {
    eng: 'Polish',
    ru: 'Польский',
    pt: 'Polonês',
    cn: '抛光',
  },
  'TemplateLanguage:ro': {
    eng: 'Romanian',
    ru: 'Румынский',
    pt: 'Romeno',
    cn: '罗马尼亚语',
  },
  'TemplateLanguage:kz': {
    eng: 'Kazakh',
    ru: 'Казахский',
    pt: 'Cazaque',
    cn: '哈萨克语',
  },
  'TemplateLanguage:uk': {
    eng: 'Ukrainian',
    ru: 'Украинский',
    pt: 'Ucraniano',
    cn: '乌克兰语',
  },
  'Attach file': {
    eng: 'Attach file',
    ru: 'Прикрепите файл',
    pt: 'Anexe o arquivo',
  },
  'or drop it here': {
    eng: 'or drag it here',
    ru: 'или перетащите его сюда',
    pt: 'ou arraste-o para cá',
  },
  'Profile:AccountStatus': {
    eng: 'Account status',
    ru: 'Статус аккаунта',
  },
  'IntentEdit:titleSaved': {
    eng: 'Saved',
    ru: 'Сохранено',
    pt: 'Salvas',
    cn: '已保存',
  },
  'Unexpected error': {
    eng: 'Unexpected error',
    ru: 'Непредвиденная ошибка',
    cn: '意外错误',
  },
  rateLimitExceeded_zero: {
    eng: 'The number of retries has been exceeded. Repeat after $[1] seconds.',
    ru: 'Количество попыток превышено. Повторите через $[1] секунд.',
  },
  rateLimitExceeded_one: {
    eng: 'The number of retries has been exceeded. Repeat after $[1] second.',
    ru: 'Количество попыток превышено. Повторите через $[1] секунду.',
  },
  rateLimitExceeded_two: {
    eng: 'The number of retries has been exceeded. Repeat after $[1] seconds.',
    ru: 'Количество попыток превышено. Повторите через $[1] секунды.',
  },
  rateLimitExceeded_few: {
    eng: 'The number of retries has been exceeded. Repeat after $[1] seconds.',
    ru: 'Количество попыток превышено. Повторите через $[1] секунды.',
  },
  rateLimitExceeded_many: {
    eng: 'The number of retries has been exceeded. Repeat after $[1] seconds.',
    ru: 'Количество попыток превышено. Повторите через $[1] секунд.',
  },
  rateLimitExceeded_other: {
    eng: 'The number of retries has been exceeded. Repeat after $[1] seconds.',
    ru: 'Количество попыток превышено. Повторите через $[1] секунд.',
  },
  'AnswerBlocks:answer_tooltip_replace': {
    eng: 'Make sure the selected reply type is supported by the channel you are using.',
    ru: 'Убедитесь, что выбранный тип ответа поддерживается каналом, который вы используете.',
    pt: 'Certifique-se de que o tipo de resposta selecionado é compatível com o canal que você está usando.',
  },
  'Analystics:Clients:List:Modal': {
    eng: `Client lists are uploaded when <a href="#{config.zenflow.helpUrl}/docs/en/publication_channels/text-campaigns" target='_blank'>text campaigns</a> are created and are available for download.`,
    ru: `Списки подгружаются при создании <a href="#{config.zenflow.helpUrl}/docs/ru/publication_channels/text-campaigns" target='_blank'>рассылок</a> и доступны для скачивания.`,
  },
  'Channels:Webim2:Operator': {
    eng: 'Agent',
    ru: 'Оператор',
  },
  'Channels:Webim2:NoOperator': {
    eng: 'Agent is not selected',
    ru: 'Оператор не выбран',
  },
  contactLink: {
    eng: '#{config.zenflow.contactLink}',
    ru: '#{config.zenflow.contactLink}',
  },
  'BaseEditForm:BE-error chatadapter.messaging.invalid_inbound_channel_configuration': {
    eng: 'Invalid channel host',
    ru: 'Недопустимый хост канала',
    pt: 'Invalid channel host',
  },
  'BaseEditForm:BE-error chatadapter.validation.invalid_channel_token': {
    eng: 'Invalid channel token',
    ru: 'Недопустимый токен канала',
    pt: 'Invalid channel token',
  },
};

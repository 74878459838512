import { mapKeys, mergeWith, isObject } from 'lodash';
import { localization as analyticLocalization } from '@just-ai/analytic-front';
import { commonLocalization } from './decomposed/common.loc';
import { chatwidgeteditorLocalization } from '../views/ChatWidgetEditPage/localization/chatwidgeteditor.loc.js';
import { partnerpageLocalization } from '../views/PartnerPage/localization/partnerpage.loc.js';

const Localize = require('localize');
require('decliner/dist/decliner.min');

const radix = 10;

const localize = new Localize({
  ...analyticLocalization,
  ...commonLocalization,
  ...chatwidgeteditorLocalization,
  ...partnerpageLocalization,
});

localize.addTranslations = translate => {
  mapKeys(translate, key => {
    if (!localize.getTranslations().hasOwnProperty(key)) {
      localize.loadTranslations(translate);
    }
  });
};

const defaultHelpUrlsPath = {
  root: {
    eng: '/',
    ru: '/',
    cn: '/',
    pt: '/',
  },
  faq: {
    eng: '/faq',
    ru: '/faq',
    cn: '/faq',
  },
  getStarted: {
    eng: '/quick_start',
    ru: '/quick_start',
    cn: '/quick_start',
    pt: '/quick_start',
  },
  tutorials: {
    eng: '/how_to',
    ru: '/how_to',
    cn: '/how_to',
  },
  basics: {
    eng: '/overview/glossary',
    ru: '/overview/glossary',
    cn: '/overview/glossary',
  },
  'MyBot:DocsLink:BasicStatistic': {
    eng: '/basics/statistics',
    ru: '/basics/statistics',
    cn: '/basics/statistics',
  },
  'MyBot:DocsLink:BasicDialogs': {
    eng: '/basics/dialogs',
    ru: '/basics/dialogs',
    cn: '/basics/dialogs',
  },
  'MyBot:DocsLink:OutboundCalls': {
    eng: '/smart_calls/outbound_calls',
    ru: '/smart_calls/outbound_calls',
    cn: '/smart_calls/outbound_calls',
  },
  'MyBot:DocsLink:IncomingCalls': {
    eng: '/smart_calls/incoming_calls',
    ru: '/smart_calls/incoming_calls',
    cn: '/smart_calls/incoming_calls',
  },
  basicsEuro: {
    eng: '/overview/glossary',
    ru: '/overview/glossary',
    cn: '/overview/glossary',
  },
  blocks: {
    eng: '/basics/blocks',
    ru: '/basics/blocks',
    cn: '/basics/blocks',
    pt: '/basics/blocks',
  },
  systemEntities: {
    eng: '/advanced/entities_references/system_entities_references',
    ru: '/advanced/entities_references/system_entities_references',
    cn: '/advanced/entities_references/system_entities_references',
    pt: '/advanced/entities_references/system_entities_references',
  },
  knowledgeBase: {
    eng: '/advanced/knowledge-base/visual-editor-knowledge-base',
    ru: '/advanced/knowledge-base/visual-editor-knowledge-base',
    cn: '/advanced/knowledge-base/visual-editor-knowledge-base',
  },
  chatWidgetDoc: {
    eng: '/publication_channels/chat_widget',
    ru: '/publication_channels/chat_widget',
    cn: '/publication_channels/chat_widget',
    pt: '/publication_channels/chat_widget',
  },
  aimychatDoc: {
    eng: '/publication_channels/agent-platform',
    ru: '/publication_channels/agent-platform',
  },
  aimychatTovieDoc: {
    eng: '/publication_channels/agent-platform',
    ru: '/publication_channels/agent-platform',
  },
  telegramDoc: {
    eng: '/publication_channels/telegram',
    ru: '/publication_channels/telegram',
    cn: '/publication_channels/telegram',
  },
  facebookDoc: {
    eng: '/publication_channels/facebook',
    ru: '/publication_channels/facebook',
    cn: '/publication_channels/facebook',
  },
  instagramDoc: {
    eng: '/publication_channels/instagram',
    ru: '/publication_channels/instagram',
    cn: '/publication_channels/instagram',
    pt: '/publication_channels/instagram',
  },
  vkDoc: {
    eng: '/publication_channels/vk',
    ru: '/publication_channels/vk',
    cn: '/publication_channels/vk',
  },
  businessChannels: {
    eng: '/publication_channels/business_integrations',
    ru: '/publication_channels/business_integrations',
    cn: '/publication_channels/business_integrations',
  },
  whatsAppDirect: {
    eng: '/publication_channels/whatsapp-business/direct',
    ru: '/publication_channels/whatsapp-business/direct',
    cn: '/publication_channels/whatsapp-business/direct',
    pt: '/publication_channels/whatsapp-business/direct',
  },
  whatsAppIDigital: {
    eng: '/publication_channels/whatsapp-business',
    ru: '/publication_channels/whatsapp-business/i-digital',
    cn: '/publication_channels/whatsapp-business',
    pt: '/publication_channels/whatsapp-business',
  },
  wazzupAppDoc: {
    eng: '/publication_channels/wazzup',
    ru: '/publication_channels/wazzup',
    cn: '/publication_channels/wazzup',
  },
  viberDoc: {
    eng: '/publication_channels/viber',
    ru: '/publication_channels/viber',
    cn: '/publication_channels/viber',
  },
  chatAPIdoc: {
    eng: '/publication_channels/chat_api',
    ru: '/publication_channels/chat_api',
    cn: '/publication_channels/chat_api',
    pt: '/publication_channels/chat_api',
  },
  azureDoc: {
    eng: '/publication_channels/ms_teams',
    ru: '/publication_channels/ms_teams',
    cn: '/publication_channels/ms_teams',
  },
  webimDoc: {
    eng: '/publication_channels/to_operator_webim',
    ru: '/publication_channels/to_operator_webim',
    cn: '/publication_channels/to_operator_webim',
  },
  inboundLivetexDoc: {
    eng: '/publication_channels/livetex',
    ru: '/publication_channels/livetex',
    cn: '/publication_channels/livetex',
  },
  incomingWebimDoc: {
    eng: '/publication_channels/webim.2.0',
    ru: '/publication_channels/webim.2.0',
    cn: '/publication_channels/webim.2.0',
  },
  JivoSiteDoc: {
    eng: '/publication_channels/jivo',
    ru: '/publication_channels/jivo',
    cn: '/publication_channels/jivo',
  },
  aliceDoc: {
    eng: '/publication_channels/alice',
    ru: '/publication_channels/alice',
    cn: '/publication_channels/alice',
  },
  marusiaDoc: {
    eng: '/publication_channels/marusia',
    ru: '/publication_channels/marusia',
    cn: '/publication_channels/marusia',
  },
  googleAssistantDoc: {
    eng: '/publication_channels/google_assistant',
    ru: '/publication_channels/google_assistant',
    cn: '/publication_channels/google_assistant',
  },
  aimyboxDoc: {
    eng: '/publication_channels/aimybox',
    ru: '/publication_channels/aimybox',
    cn: '/publication_channels/aimybox',
  },
  bitrixDoc: {
    eng: '/publication_channels/bitrix24',
    ru: '/publication_channels/bitrix24',
    cn: '/publication_channels/bitrix24',
  },
  jivositeDoc: {
    eng: '/publication_channels/jivo',
    ru: '/publication_channels/jivo',
    cn: '/publication_channels/jivo',
    pt: '/publication_channels/jivo',
  },
  alexaDoc: {
    eng: '/publication_channels/alexa',
    ru: '/publication_channels/alexa',
    cn: '/publication_channels/alexa',
  },
  livetexDoc: {
    eng: '/publication_channels/to_operator_livetex',
    ru: '/publication_channels/to_operator_livetex',
    cn: '/publication_channels/to_operator_livetex',
  },
  sberDoc: {
    eng: '/publication_channels/salut',
    ru: '/publication_channels/salut',
    cn: '/publication_channels/salut',
  },
  wechatDoc: {
    eng: '/publication_channels/wechat',
    ru: '/publication_channels/wechat',
    cn: '/publication_channels/wechat',
  },
  whatsAppNexmo: {
    eng: '/publication_channels/whatsapp-business/vonage',
    ru: '/publication_channels/whatsapp-business/vonage',
    pt: '/publication_channels/whatsapp-business/vonage',
    cn: '/publication_channels/whatsapp-business/vonage',
  },
  threadsDoc: {
    eng: '/publication_channels/edna.chatcenter',
    ru: '/publication_channels/edna.chatcenter',
  },
  operatorApiDoc: {
    eng: '/publication_channels/operator_api',
    ru: '/publication_channels/operator_api',
    pt: '/publication_channels/operator_api',
  },
  webhookSettings: {
    eng: '/basics/webhooks',
    ru: '/basics/webhooks',
    cn: '/basics/webhooks',
  },
  entities: {
    eng: '/advanced/entities_references',
    ru: '/advanced/entities_references',
    cn: '/advanced/entities_references',
  },
  systemDictionaries: {
    eng: '/advanced/entities_references/system_entities_references',
    ru: '/advanced/entities_references/system_entities_references',
    cn: '/advanced/entities_references/system_entities_references',
    pt: '/advanced/entities_references/system_entities_references',
  },
  channels: {
    eng: '/publication_channels',
    ru: '/publication_channels',
    cn: '/publication_channels',
    pt: '/publication_channels',
  },
  whatsNextYandex: {
    eng: '/publication_channels/alice',
    ru: '/publication_channels/alice',
    cn: '/publication_channels/alice',
  },
  chatWidget: {
    eng: '/publication_channels/chat_widget',
    ru: '/publication_channels/chat_widget',
    pt: '/publication_channels/chat_widget',
  },
  chatApi: {
    eng: '/publication_channels/chat_api',
    ru: '/publication_channels/chat_api',
    cn: '',
    pt: '/publication_channels/chat_api',
  },
  knowledge: {
    eng: '/advanced/knowledge-base/visual-editor-knowledge-base',
    ru: '/advanced/knowledge-base/visual-editor-knowledge-base',
    cn: '/advanced/knowledge-base/visual-editor-knowledge-base',
  },
  howToCreateBot: {
    eng: '/how_to/payment_support',
    ru: '/how_to/payment_support',
    cn: '/how_to/payment_support',
  },
  visualEditorHelpDocs: {
    eng: '/',
    ru: '/',
    cn: '/',
    pt: '/',
  },
  'visualEditorHelpDocs:Gpt': {
    eng: '/quick_start/chatgpt#in-visual-editor',
    ru: '/quick_start/chatgpt#in-visual-editor',
    cn: '/quick_start/chatgpt#in-visual-editor',
    pt: '/quick_start/chatgpt#in-visual-editor',
  },
  'Learn more link': {
    eng: '/faq/payment_issues',
    ru: '/faq/payment_issues',
    cn: '/faq/payment_issues',
  },
  'helpLink yandex': {
    eng: '',
    ru: '/publication_channels/alice',
    cn: '',
  },
  'helpLink vk': {
    eng: '/publication_channels/vk',
    ru: '/publication_channels/vk',
    cn: '/publication_channels/vk',
  },
  'helpLink telegram': {
    eng: '/publication_channels/telegram',
    ru: '/publication_channels/telegram',
    cn: '/publication_channels/telegram',
  },
  'helpLink facebook': {
    eng: '/publication_channels/facebook',
    ru: '/publication_channels/facebook',
    cn: '/publication_channels/facebook',
  },
  'helpLink instagram': {
    eng: '/publication_channels/instagram',
    ru: '/publication_channels/instagram',
    cn: '/publication_channels/instagram',
    pt: '/publication_channels/instagram',
  },
  'helpLink google': {
    eng: '/publication_channels/google_assistant',
    ru: '/publication_channels/google_assistant',
    cn: '/publication_channels/google_assistant',
  },
  'helpLink zenbox': {
    eng: '/publication_channels/aimybox',
    ru: '/publication_channels/aimybox',
    cn: '/publication_channels/aimybox',
  },
  'helpLink alexa': {
    eng: '/publication_channels/alexa',
    ru: '',
    cn: '/publication_channels/alexa',
  },
  'helpLink bitrix': {
    eng: '',
    ru: '/publication_channels/bitrix24',
    cn: '',
  },
  'helpLink how to create bot': {
    eng: '/quick_start',
    ru: '/quick_start',
    cn: '/quick_start',
    pt: '/quick_start',
  },
  'helpLink smart calls': {
    eng: '/smart_calls',
    ru: '/smart_calls',
    cn: '/smart_calls',
    pt: '/smart_calls',
  },
  'helpLink how to create smart calls': {
    eng: '/smart_calls/new_smart_call',
    ru: '/smart_calls/new_smart_call',
    cn: '/smart_calls/new_smart_call',
    pt: '/smart_calls/new_smart_call',
  },
  'helpLink incoming calls': {
    eng: '/smart_calls/incoming_calls',
    ru: '/smart_calls/incoming_calls',
    cn: '/smart_calls/incoming_calls',
    pt: '/smart_calls/incoming_calls',
  },
  'helpLink Uniq clients': {
    eng: '/basics/unique_user',
    ru: '/basics/unique_user',
    cn: '',
  },
  'helpLink Themes': {
    eng: '/advanced/knowledge-base/visual-editor-knowledge-base',
    ru: '/advanced/knowledge-base/visual-editor-knowledge-base',
    cn: '/advanced/knowledge-base/visual-editor-knowledge-base',
  },
  'helpLink Group phrases': {
    eng: '/how-to-create-a-script/user-says/intent',
    ru: '/how-to-create-a-script/user-says/intent',
    cn: '/how-to-create-a-script/user-says/intent',
    pt: '/how-to-create-a-script/user-says/intent',
  },
  'helpLink how to train bot': {
    eng: '/how-to-create-a-script/user-says/intent',
    ru: '/how-to-create-a-script/user-says/intent',
    cn: '/how-to-create-a-script/user-says/intent',
    pt: '/how-to-create-a-script/user-says/intent',
  },
  'helpLink scenario for calls': {
    eng: '/smart_calls/call_script',
    ru: '/smart_calls/call_script',
    cn: '/smart_calls/call_script',
    pt: '/smart_calls/call_script',
  },
  'helpLink scenario calls reports': {
    eng: '/smart_calls/call_report',
    ru: '/smart_calls/call_report',
    cn: '/smart_calls/call_report',
    pt: '/smart_calls/call_report',
  },
  'Header how to create smart calls link': {
    eng: '/smart_calls',
    ru: '/smart_calls',
    cn: '/smart_calls',
    pt: '/smart_calls',
  },
  'Header calls planning link': {
    eng: '/pricing',
    ru: '/pricing',
    cn: '/pricing',
  },
  'Affiliate Program Rules link': {
    eng: '/partnership',
    ru: '/partnership',
    cn: '/partnership',
  },
  'How to link a client link': {
    eng: '/partnership',
    ru: '/partnership#client-sign-up',
    cn: '/partnership',
  },
  'How to transfer a bot link': {
    eng: '/partnership',
    ru: '/partnership#bot-development',
    cn: '/partnership',
  },
  'How will I receive payments link': {
    eng: '/partnership',
    ru: '/partnership#payment-reward',
    cn: '/partnership',
  },
  'PartnerPage: materials block 3 link 1': {
    eng: '/overview/partnership',
    ru: '/overview/partnership',
    pt: '/overview/partnership',
  },
  'WhatsAppEditForm how to choose link': {
    eng: '/publication_channels/whatsapp-business',
    ru: '/publication_channels/whatsapp-business',
    cn: '/publication_channels/whatsapp-business',
    pt: '/publication_channels/whatsapp-business',
  },
  'Whats next link MARUSIA': {
    eng: '/publication_channels/marusia',
    ru: '/publication_channels/marusia',
    cn: '/publication_channels/marusia',
  },
  'Whats next link AZURE': {
    eng: '/publication_channels/ms_teams',
    ru: '/publication_channels/ms_teams',
    cn: '/publication_channels/ms_teams',
  },
  'Templates Docs link': {
    eng: '/',
    ru: '/',
    cn: '/',
    pt: '/',
  },
  'Templates Intents doc link': {
    eng: '/how-to-create-a-script/user-says/intent',
    ru: '/how-to-create-a-script/user-says/intent',
    cn: '/how-to-create-a-script/user-says/intent',
    pt: '/how-to-create-a-script/user-says/intent',
  },
  'CallsListItem webinars link': {
    eng: '',
    ru: 'https://aimylogic.com/ru/webinars',
  },
  'CallTemplates doc link': {
    eng: '/quick_start/how_to_start/call_templates',
    ru: '/quick_start/how_to_start/call_templates',
    cn: '/quick_start/how_to_start/call_templates',
  },
  'BotTemplates doc link': {
    eng: '/quick_start/how_to_start/chat_templates',
    ru: '/quick_start/how_to_start/chat_templates',
    cn: '/quick_start/how_to_start/chat_templates',
  },
  'How to create smartcall link': {
    eng: '/smart_calls/new_smart_call',
    ru: '/smart_calls/new_smart_call',
    cn: '/smart_calls/new_smart_call',
    pt: '/smart_calls/new_smart_call',
  },
  'DocLink botStructure': {
    eng: '/basics/bots_structure',
    ru: '/basics/bots_structure',
    cn: '/basics/bots_structure',
  },
  'ProjectOverview: docs table link': {
    eng: '/basics/results',
    ru: '/basics/results',
    cn: '/basics/results',
  },
  'ProjectOverview: resultLink': {
    eng: '/basics/results',
    ru: '/basics/results',
    cn: '/basics/results',
  },
  'PublishIvrModal: warning not trunks link docs link': {
    eng: '/smart_calls/sip_settings',
    ru: '/smart_calls/sip_settings',
    cn: '/smart_calls/sip_settings',
    pt: '/smart_calls/sip_settings',
  },
  'ToggleCollapsedBotList: LINK what is smart call': {
    eng: '/smart_calls',
    ru: '/smart_calls',
    cn: '/smart_calls',
    pt: '/smart_calls',
  },
  'ToggleCollapsedBotList: LINK tariff and balance': {
    eng: '/smart_calls/calls_subscription',
    ru: '/smart_calls/calls_subscription',
    cn: '/smart_calls/calls_subscription',
    pt: '/smart_calls/calls_subscription',
  },
  'Documentation getMessageAboutConfiguration link VK': {
    eng: '/publication_channels/vk',
    ru: '/publication_channels/vk',
    cn: '/publication_channels/vk',
  },
  'Documentation getMessageAboutConfiguration link FACEBOOK': {
    eng: '/publication_channels/facebook',
    ru: '/publication_channels/facebook',
    cn: '/publication_channels/facebook',
  },
  'Documentation getMessageAboutConfiguration link INSTAGRAM': {
    eng: '/publication_channels/instagram',
    ru: '/publication_channels/instagram',
    cn: '/publication_channels/instagram',
    pt: '/publication_channels/instagram',
  },
  'Documentation getMessageAboutConfiguration link TELEGRAM': {
    eng: '/publication_channels/telegram',
    ru: '/publication_channels/telegram',
    cn: '/publication_channels/telegram',
  },
  'CsvUploader:docLink': {
    eng: '/advanced/entities_references/custom_dictionaries',
    ru: '/advanced/entities_references/custom_dictionaries',
    cn: '/advanced/entities_references/custom_dictionaries',
  },
  'CsvUploader:docLinkBatch': {
    eng: '/advanced/entities_references/custom_dictionaries#upload-multiple-entities',
    ru: '/advanced/entities_references/custom_dictionaries#upload-multiple-entities',
    cn: '/advanced/entities_references/custom_dictionaries#upload-multiple-entities',
  },
  'Lead generation bot template link': {
    eng: '/publication_channels/chat_widget#add-chat-widget',
    ru: '/publication_channels/chat_widget#add-chat-widget',
    cn: '/publication_channels/chat_widget#add-chat-widget',
    pt: '/publication_channels/chat_widget#add-chat-widget',
  },
  'Google Assistant link': {
    eng: '/publication_channels/google_assistant',
    ru: '/publication_channels/google_assistant',
    cn: '/publication_channels/google_assistant',
  },
  'TemplateWizard first link': {
    eng: '',
    ru: '/smart_calls/number_list_template',
    cn: '',
  },
  'TemplateWizard second link': {
    eng: '',
    ru: '/smart_calls/analytics_script_markup',
    cn: '',
  },
  'Google Assistant Triggers link': {
    eng: '/publication_channels/google_assistant',
    ru: '/publication_channels/google_assistant',
    cn: '/publication_channels/google_assistant',
  },
  'MyBot:DocsLink:VisualEditor': {
    eng: '/visual-editor',
    ru: '/visual-editor',
    cn: '/visual-editor',
    pt: '/visual-editor',
  },
  'AnswerModal:info_link_image': {
    eng: '/how-to-create-a-script/bot-response/image',
    ru: '/how-to-create-a-script/bot-response/image',
  },
  'AnswerModal:info_link_audio': {
    eng: '/how-to-create-a-script/bot-response/audio',
    ru: '/how-to-create-a-script/bot-response/audio',
  },
  'AnswerModal:info_link_file': {
    eng: '/advanced/knowledge-base/skill-builder-knowledge-base/',
    ru: '/advanced/knowledge-base/skill-builder-knowledge-base/',
  },
  'AnswerBlocks:question_tooltip_link': {
    eng: '/advanced/knowledge-base/faq#phrases',
    ru: '/advanced/knowledge-base/faq#phrases',
  },
  'AnswerBlocks:answer_tooltip_link': {
    eng: '/advanced/knowledge-base/faq#phrases',
    ru: '/advanced/knowledge-base/faq#phrases',
  },
  'MailingList: help link': {
    eng: '/publication_channels/text-campaigns',
    ru: '/publication_channels/text-campaigns',
    pt: '/publication_channels/text-campaigns',
  },
};

function hasAllTranslates(obj) {
  return obj.hasOwnProperty('ru') && Boolean(obj.ru) && obj.hasOwnProperty('eng') && Boolean(obj.eng);
}

localize.setAppConfigVariables = (zenflowAppConfig, botadminAppConfig) => {
  localize.appConfigVariables = {
    productName: Boolean(zenflowAppConfig.productName) ? zenflowAppConfig.productName : 'Aimylogic',
    companyName: Boolean(zenflowAppConfig.companyName) ? zenflowAppConfig.companyName : 'Aimylogic',
    termsOfUseUrl:
      Boolean(zenflowAppConfig.termsOfUseUrl) && isObject(zenflowAppConfig.termsOfUseUrl)
        ? hasAllTranslates(zenflowAppConfig.termsOfUseUrl)
          ? zenflowAppConfig.termsOfUseUrl
          : {
              eng: Boolean(zenflowAppConfig.termsOfUseUrl.eng) ? zenflowAppConfig.termsOfUseUrl.eng : ' ',
              ru: Boolean(zenflowAppConfig.termsOfUseUrl.ru) ? zenflowAppConfig.termsOfUseUrl.ru : ' ',
              cn: Boolean(zenflowAppConfig.termsOfUseUrl.eng) ? zenflowAppConfig.termsOfUseUrl.eng : ' ',
            }
        : {
            eng: 'https://aimylogic.com/documents/terms-of-service-en/',
            ru: 'https://aimylogic.com/documents/terms-of-service-ru/',
            cn: 'https://aimylogic.com/documents/terms-of-service-en/',
          },
    helpUrl:
      Boolean(zenflowAppConfig.helpUrl) &&
      isObject(zenflowAppConfig.helpUrl) &&
      hasAllTranslates(zenflowAppConfig.helpUrl)
        ? zenflowAppConfig.helpUrl
        : {
            eng: 'https://help.cloud.just-ai.com/en/aimylogic',
            ru: 'https://help.cloud.just-ai.com/aimylogic',
            cn: 'https://help.cloud.just-ai.com/en/aimylogic',
          },
    helpUrlBotadmin:
      Boolean(botadminAppConfig.helpUrl) &&
      isObject(botadminAppConfig.helpUrl) &&
      hasAllTranslates(botadminAppConfig.helpUrl)
        ? botadminAppConfig.helpUrl
        : {
            eng: 'https://help.cloud.just-ai.com/en/jaicp',
            ru: 'https://help.cloud.just-ai.com/jaicp',
            cn: 'https://help.cloud.just-ai.com/en/jaicp',
          },
    privacyPolicyUrl:
      Boolean(zenflowAppConfig.privacyPolicyUrl) && isObject(zenflowAppConfig.privacyPolicyUrl)
        ? hasAllTranslates(zenflowAppConfig.privacyPolicyUrl)
          ? zenflowAppConfig.privacyPolicyUrl
          : {
              eng: Boolean(zenflowAppConfig.privacyPolicyUrl.eng) ? zenflowAppConfig.privacyPolicyUrl.eng : ' ',
              ru: Boolean(zenflowAppConfig.privacyPolicyUrl.ru) ? zenflowAppConfig.privacyPolicyUrl.ru : ' ',
              cn: Boolean(zenflowAppConfig.privacyPolicyUrl.eng) ? zenflowAppConfig.privacyPolicyUrl.eng : ' ',
            }
        : {
            eng: 'https://aimylogic.com/documents/privacy-policy-en/',
            ru: 'https://aimylogic.com/politika_obrabotki_personalnykh_dannykh',
            cn: 'https://aimylogic.com/documents/privacy-policy-en/',
          },
    helpUrlsPath: Boolean(zenflowAppConfig.helpUrlsPath)
      ? { ...defaultHelpUrlsPath, ...zenflowAppConfig.helpUrlsPath }
      : defaultHelpUrlsPath,
    helpBotadminUrlsPath: Boolean(botadminAppConfig.helpUrlsPath)
      ? { ...defaultHelpUrlsPath, ...botadminAppConfig.helpUrlsPath }
      : defaultHelpUrlsPath,
  };

  localize.addTranslations({
    'AppHelpNavListItems help link': getUrlPath('root'),
    'SaveComponent Help link': getUrlPath('root'),
    'Frequently asking questions link': getUrlPath('faq'),
    'Getting started link': getUrlPath('getStarted'),
    'Tutorials link': getUrlPath('tutorials'),
    'Basics link': getUrlPath('basics'),
    'MyBot:DocsLink:BasicStatistic': getUrlPath('MyBot:DocsLink:BasicStatistic'),
    'MyBot:DocsLink:BasicDialogs': getUrlPath('MyBot:DocsLink:BasicDialogs'),
    'MyBot:DocsLink:OutboundCalls': getUrlPath('MyBot:DocsLink:OutboundCalls'),
    'MyBot:DocsLink:IncomingCalls': getUrlPath('MyBot:DocsLink:IncomingCalls'),
    'BasicsEuro link': getUrlPath('basicsEuro'),
    'Blocks link': getUrlPath('blocks'),
    'ToS link': getAppConfigVariable('termsOfUseUrl'),
    'System entities link': getUrlPath('systemEntities'),
    'MailingList: help link': getUrlPath('MailingList: help link'),
    'Knowledge base link': getUrlPath('knowledgeBase'),
    'Google Assistant Triggers link': getUrlPath('Google Assistant Triggers link'),
    'CHAT_WIDGET doc link': getUrlPath('chatWidgetDoc'),
    'AIMYCHAT doc link': getUrlPath('aimychatDoc'),
    'AIMYCHAT:Tovie doc link': getUrlPath('aimychatTovieDoc'),
    'TELEGRAM doc link': getUrlPath('telegramDoc'),
    'FACEBOOK doc link': getUrlPath('facebookDoc'),
    'INSTAGRAM doc link': getUrlPath('instagramDoc'),
    'VK doc link': getUrlPath('vkDoc'),
    'BusinessChannels link': getUrlPath('businessChannels'),
    'WHATSAPP doc link': getUrlPath('whatsAppDirect'),
    'I_DIGITAL doc link': getUrlPath('whatsAppIDigital'),
    'WAZZUP doc link': getUrlPath('wazzupAppDoc'),
    'INCOMING_JIVOSITE doc link': getUrlPath('JivoSiteDoc'),
    'AZURE doc link': getUrlPath('azureDoc'),
    'VIBER doc link': getUrlPath('viberDoc'),
    'CHAT_API doc link': getUrlPath('chatAPIdoc'),
    'WEBIM doc link': getUrlPath('webimDoc'),
    'INBOUND_LIVETEX doc link': getUrlPath('inboundLivetexDoc'),
    'WECHAT doc link': getUrlPath('wechatDoc'),
    'INCOMING_WEBIM2 doc link': getUrlPath('incomingWebimDoc'),
    'YANDEX doc link': getUrlPath('aliceDoc'),
    'MARUSIA doc link': getUrlPath('marusiaDoc'),
    'GOOGLE doc link': getUrlPath('googleAssistantDoc'),
    'ZENBOX doc link': getUrlPath('aimyboxDoc'),
    'LIVETEX doc link': getUrlPath('livetexDoc'),
    'ALEXA doc link': getUrlPath('alexaDoc'),
    'NEXMO doc link': getUrlPath('whatsAppNexmo'),
    'SBER doc link': getUrlPath('sberDoc'),
    'BITRIX doc link': getUrlPath('bitrixDoc'),
    'THREADS doc link': getUrlPath('threadsDoc'),
    'OPERATORAPI doc link': getUrlPath('operatorApiDoc'),
    'Learn more link': getUrlPath('Learn more link'),
    'helpLink webhook settings': getUrlPath('webhookSettings'),
    'helpLink entities': getUrlPath('entities'),
    'helpLink system dictionaries': getUrlPath('systemDictionaries'),
    'helpLink channels': getUrlPath('channels'),
    'helpLink alisa': getUrlPath('channels'),
    'helpLink yandex': getUrlPath('helpLink yandex'),
    'helpLink vk': getUrlPath('helpLink vk'),
    'helpLink telegram': getUrlPath('helpLink telegram'),
    'helpLink chat_widget': getUrlPath('chatWidget'),
    'helpLink facebook': getUrlPath('helpLink facebook'),
    'helpLink instagram': getUrlPath('helpLink instagram'),
    'helpLink google': getUrlPath('helpLink google'),
    'helpLink zenbox': getUrlPath('helpLink zenbox'),
    'helpLink chat_api': getUrlPath('chatApi'),
    'helpLink alexa': getUrlPath('helpLink alexa'),
    'helpLink bitrix': getUrlPath('helpLink bitrix'),
    'helpLink knowledge': getUrlPath('helpLink knowledge'),
    'helpLink templates': getUrlPath('howToCreateBot'),
    'helpLink how to create bot': getUrlPath('helpLink how to create bot'),
    'helpLink smart calls': getUrlPath('helpLink smart calls'),
    'helpLink how to create smart calls': getUrlPath('helpLink how to create smart calls'),
    'helpLink incoming calls': getUrlPath('helpLink incoming calls'),
    'helpLink Uniq clients': getUrlPath('helpLink Uniq clients'),
    'helpLink Themes': getUrlPath('helpLink Themes'),
    'helpLink Group phrases': getUrlPath('helpLink Group phrases'),
    'helpLink how to train bot': getUrlPath('helpLink how to train bot'),
    'helpLink scenario for calls': getUrlPath('helpLink scenario for calls'),
    'helpLink scenario calls reports': getUrlPath('helpLink scenario calls reports'),
    'Header how to create smart calls link': getUrlPath('Header how to create smart calls link'),
    'Header calls planning link': getUrlPath('Header calls planning link'),
    'Affiliate Program Rules link': getUrlPath('Affiliate Program Rules link'),
    'How to link a client link': getUrlPath('How to link a client link'),
    'Partnership agreement link': {
      eng: 'https://just-ai.com/ru/aimylogic_partnership_oferta_2019.pdf',
      ru: 'https://just-ai.com/ru/aimylogic_partnership_oferta_2019.pdf',
      cn: 'https://just-ai.com/ru/aimylogic_partnership_oferta_2019.pdf',
    },
    'How to transfer a bot link': getUrlPath('How to transfer a bot link'),
    'How will I receive payments link': getUrlPath('How will I receive payments link'),
    'WhatsAppEditForm how to choose link': getUrlPath('WhatsAppEditForm how to choose link'),
    'Whats next link YANDEX': getUrlPath('whatsNextYandex'),
    'Whats next link I_DIGITAL': getUrlPath('whatsAppIDigital'),
    'Whats next link MARUSIA': getUrlPath('Whats next link MARUSIA'),
    'Whats next link AZURE': getUrlPath('Whats next link AZURE'),
    'VisualEditorHelp docs link': getUrlPath('visualEditorHelpDocs'),
    'VisualEditorHelp:Docs:Link:Gpt': getUrlPath('visualEditorHelpDocs:Gpt'),
    'Templates Docs link': getUrlPath('Templates Docs link'),
    'Templates Intents doc link': getUrlPath('Templates Intents doc link'),

    'CallTemplates doc link': getUrlPath('CallTemplates doc link'),
    'BotTemplates doc link': getUrlPath('BotTemplates doc link'),
    'privacy policy link': getAppConfigVariable('privacyPolicyUrl'),
    'How to create smartcall link': getUrlPath('How to create smartcall link'),
    'DocLink botStructure': getUrlPath('DocLink botStructure'),
    'ProjectOverview: docs table link': getUrlPath('ProjectOverview: docs table link'),
    'ProjectOverview: resultLink': getUrlPath('ProjectOverview: resultLink'),
    'PublishIvrModal: warning not trunks link docs link': getUrlPath(
      'PublishIvrModal: warning not trunks link docs link'
    ),
    'ToggleCollapsedBotList: LINK what is smart call': getUrlPath('ToggleCollapsedBotList: LINK what is smart call'),
    'ToggleCollapsedBotList: LINK tariff and balance': getUrlPath('ToggleCollapsedBotList: LINK tariff and balance'),
    'Documentation getMessageAboutConfiguration link VK': getUrlPath(
      'Documentation getMessageAboutConfiguration link VK'
    ),
    'Documentation getMessageAboutConfiguration link FACEBOOK': getUrlPath(
      'Documentation getMessageAboutConfiguration link FACEBOOK'
    ),
    'Documentation getMessageAboutConfiguration link INSTAGRAM': getUrlPath(
      'Documentation getMessageAboutConfiguration link INSTAGRAM'
    ),
    'Documentation getMessageAboutConfiguration link TELEGRAM': getUrlPath(
      'Documentation getMessageAboutConfiguration link TELEGRAM'
    ),
    'Lead generation bot template link': getUrlPath('Lead generation bot template link'),
    'Google Assistant link': getUrlPath('Google Assistant link'),
    'TemplateWizard first link': getUrlPath('TemplateWizard first link'),
    'TemplateWizard second link': getUrlPath('TemplateWizard second link'),
    'PartnerPage: footer link docs': getUrlPath('root'),
    'CsvUploader:docLink': getUrlPath('CsvUploader:docLink'),
    'CsvUploader:docLinkBatch': getUrlPath('CsvUploader:docLinkBatch'),
    'MyBot:DocsLink:VisualEditor': getUrlPath('MyBot:DocsLink:VisualEditor'),
    'AnswerModal:info_link_image': getUrlPath('AnswerModal:info_link_image'),
    'AnswerModal:info_link_audio': getUrlPath('AnswerModal:info_link_audio'),
    'AnswerModal:info_link_file': getUrlPath('AnswerModal:info_link_file'),
    'ChatWidgetEditor.embedmentContainer.docLink': getUrlPath('ChatWidgetEditor.embedmentContainer.docLink'),
    'PartnerPage: materials sales kit left link 2': getUrlPath('PartnerPage: materials sales kit left link 2'),
    'PartnerPage: materials block 2 link 1': getUrlPath('PartnerPage: materials block 2 link 1'),
    'PartnerPage: materials block 3 link 1': getUrlPath('PartnerPage: materials block 3 link 1'),
    'FAQ:activationNotification:url': getUrlPath('FAQ:activationNotification:url'),
    'AnswerBlocks:question_tooltip_link': getUrlPath('AnswerBlocks:question_tooltip_link'),
    'AnswerBlocks:answer_tooltip_link': getUrlPath('AnswerBlocks:answer_tooltip_link'),
    'AnswerModal:info_link': getUrlPath('AnswerModal:info_link'),
    'FAQ:cdqaModal:linkUrl': getUrlPath('FAQ:cdqaModal:linkUrl'),
  });
};

const getAppConfigVariable = key => {
  return Boolean(localize.appConfigVariables) && localize.appConfigVariables.hasOwnProperty(key)
    ? localize.appConfigVariables[key]
    : '';
};

export const getUrlPath = key => {
  const { helpUrl, helpUrlsPath } = localize?.appConfigVariables || {};
  if (!helpUrl || !helpUrlsPath) {
    return {
      eng: '',
      ru: '',
      cn: '',
      pt: '',
    };
  }

  const cnHelpUrl = helpUrl.cn ? {} : { cn: helpUrl.eng };
  const ptHelpUrl = helpUrl.pt ? {} : { pt: helpUrl.eng };

  let result = helpUrl;

  if (helpUrlsPath.hasOwnProperty(key)) {
    result = mergeWith({ ...helpUrl, ...cnHelpUrl, ...ptHelpUrl }, helpUrlsPath[key], (url, path) => url + path);
  }

  return result;
};

export const getBotadminUrlPath = key => {
  const { helpUrl, helpBotadminUrlsPath } = localize?.appConfigVariables || {};
  if (!helpUrl || !helpBotadminUrlsPath) {
    return {
      eng: '',
      ru: '',
      cn: '',
      pt: '',
    };
  }

  const cnHelpUrl = helpUrl.cn ? {} : { cn: helpUrl.eng };
  const ptHelpUrl = helpUrl.pt ? {} : { pt: helpUrl.eng };

  let result = helpUrl;

  if (helpBotadminUrlsPath.hasOwnProperty(key)) {
    result = mergeWith(
      { ...helpUrl, ...cnHelpUrl, ...ptHelpUrl },
      helpBotadminUrlsPath[key],
      (url, path) => url + path
    );
  }

  return result;
};

localize.throwOnMissingTranslation(false);

localize.decliner = (arr, count) => {
  return arr.decline(parseInt(count, radix));
};

localize.checkExistKey = key => key !== localize.translate(key);

export const isExistKey = namespacedKey => localize.translate(namespacedKey) !== namespacedKey;

export default localize;

export const { translate: t, translateWithPlural: tWithPlural } = localize;
export const tWithCheck = (...args) => {
  const translated = t(...args);
  if (translated === args[0]) {
    return '';
  }
  return translated;
};

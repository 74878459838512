import React from 'react';
import AppIcon from '../../AppIcon/index';
import { NavLink } from 'react-router-dom';
import { GA } from '../../../pipes/pureFunctions';
import { Bots, Calls, LinkIcon, ThreeDots } from '../../Sidebar/SidebarItems/SidebarIcons';
// @ts-ignore
import { Button } from 'altreidsds';
import localize from 'localization/index';
import classNames from 'classnames';
import classes from './NewHeader.module.scss';
// @ts-ignore
import Cookies from 'universal-cookie';
import AdditionalInfo from './AdditionalInfo';
import TariffInfo from './TariffInfo';
import PersonalInfo from './PersonalInfo';
import LeftListItem from './LeftListItem';
import history from '../../../appHistory';
import { isIncluded } from '../../../isAccessFunction';
import { CloudDomains, RCurrentUser } from '../../../types';
import CloudMenu from '@just-ai/just-ui/dist/CloudMenu';

const { translate: t } = localize;

const cookies = new Cookies();

const LINKS = (
  isBotsEnabled: boolean,
  isCallsEnabled: boolean,
  isPricesEnabled: boolean,
  isPartnerEnabled: boolean,
  isHelpLinkEnabled: boolean
) => {
  const result = [];
  if (isBotsEnabled)
    result.push({
      link: '/',
      label: 'CallsListItem homepage',
      onClick: () => {
        cookies.remove('lastPage');
        GA('GAEvent', 'glavnaya', 'clicked');
      },
      beforeIcon: <Bots className={classes.left} />,
    });
  if (isCallsEnabled)
    result.push({
      link: '/callsbots',
      label: 'CallsListItem calls',
      onClick: () => GA('GAEvent', 'obzvony', 'clicked'),
      beforeIcon: <Calls className={classes.left} />,
    });
  if (isPricesEnabled && isIncluded(['zenflow_billing_feature']))
    result.push({
      link: '/prices',
      label: 'CallsListItem tariffs',
      onClick: () => GA('GAEvent', 'main_tariff', 'clicked'),
    });
  if (isPartnerEnabled)
    result.push({
      link: '/partner',
      label: 'CallsListItem partner',
      onClick: () => {},
    });
  if (isHelpLinkEnabled) {
    result.push({
      type: 'external',
      link: t('AppHelpNavListItems help link'),
      label: 'CallsListItem helpCenter',
      onClick: () => GA('GAEvent', 'webinars', 'clicked'),
      afterIcon: <LinkIcon className={classNames(classes.greyIcon, classes.right)} />,
    });
  }

  return result;
};

export interface HeaderInterface {
  dataTestId?: string;
  logo?: string;
  botType?: string;
  pathname: string;
  teaser?: { link: string; image: string };
  paymentDate: string;
  tariff: RCurrentUser['currentUser']['tariff'];
  avatar?: string;
  email: string;
  logout: () => void;
  isBotsEnabled: boolean;
  isCallsEnabled: boolean;
  isPricesEnabled: boolean;
  isPartnerEnabled: boolean;
  isTariffEnabled: boolean;
  isOrderBotEnabled: boolean;
  isHelpLinkEnabled: boolean;
  language: string;
  isPartner: boolean;
  addSnackbar: (text: string) => void;
  manualControl: boolean;
  cloudDomains: CloudDomains;
  universalLoginMenuEnabled: boolean;
  login: string;
  limitsUpdateDate: string;
  tariffPeriodType: string;
  productNames: { [key: string]: string };
}

export function NewHeader(props: HeaderInterface) {
  const {
    dataTestId,
    botType,
    pathname,
    teaser,
    tariff,
    avatar,
    email,
    logout,
    isBotsEnabled,
    isCallsEnabled,
    isPricesEnabled,
    isPartnerEnabled,
    isTariffEnabled,
    isOrderBotEnabled,
    isHelpLinkEnabled,
    isPartner,
    language,
    addSnackbar,
    manualControl,
    cloudDomains,
    universalLoginMenuEnabled,
    productNames,
    login,
  } = props;
  return (
    <div id='header' className={classes.container} data-test-id={dataTestId}>
      <NavLink
        className={classes.logo}
        to={botType === 'CALLS_BOT' ? '/callsbots' : '/'}
        data-test-id='Header.CallsBotsLink'
      >
        <AppIcon />
      </NavLink>

      <div className={classes.leftList}>
        {LINKS(isBotsEnabled, isCallsEnabled, isPricesEnabled, isPartnerEnabled, isHelpLinkEnabled).map(x => (
          <LeftListItem key={x.label} {...x} pathname={pathname} data-test-id={'Header.' + x.link} />
        ))}
        {isHelpLinkEnabled && (
          <div className={classNames(classes.leftListItem, classes.threeDotsContainer)}>
            <ThreeDots className={classes.threeDots} data-test-id='Header.ThreeDots' />
            <AdditionalInfo teaser={teaser} language={language} manualControl={manualControl} />
          </div>
        )}
      </div>

      <div className={classes.rightList}>
        {isOrderBotEnabled && (
          <Button
            className={classes.orderBotButton}
            data-test-id='Header.RequestBotDevelopmentButton'
            onClick={() => {
              GA('GAEvent', 'order_bot', 'click');
              history.push('/botDevelopmentRequest');
            }}
            variant='outlined'
            color='primary'
            size='small'
          >
            {t('Request bot development')}
          </Button>
        )}
        {isTariffEnabled && <TariffInfo tariff={tariff} language={language} />}
        <PersonalInfo
          dataTestId='Header.PersonalInfo'
          avatar={avatar}
          email={email}
          logout={logout}
          language={language}
          isPartner={isPartner}
          tariff={tariff}
          addSnackbar={addSnackbar}
          login={login}
        />
        {universalLoginMenuEnabled && <CloudMenu cloudDomains={cloudDomains} productNames={productNames} />}
      </div>
    </div>
  );
}

export default React.memo(NewHeader);

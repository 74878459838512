import {
  ADD_ACTION,
  ADD_CONNECTION_WITH_WINDOW,
  ADD_EXAMPLE,
  ADD_PATTERN,
  ADD_SCREEN_BEFORE_META,
  ADD_WINDOW,
  DROP_VISUAL_AFTER_FILE_REMOVE,
  DUMMY_FULFILLED,
  EDIT_NODE,
  GROUP_MOVE_SAVE_TO_STORE,
  LOAD_JSON,
  LOAD_JSON_REJECTED,
  NEW_CONNECTOR,
  NEW_FROM_STATE_CONNECTOR,
  NEW_NODE_POSITION,
  REMOVE_ACTION,
  CHANGE_TITLE,
  REMOVE_CONNECTOR,
  REMOVE_EXAMPLE,
  REMOVE_FROM_STATE_CONNECTOR,
  REMOVE_IMAGE_FROM_SERVER_FULFILLED,
  REMOVE_PATTERN,
  RESORT_BUTTON,
  SAVE_BUTTONS,
  SAVE_CONFIRMATION,
  SAVE_HTTP_REQUEST,
  SAVE_INPUT_NUMBER_NODE,
  SAVE_INPUT_PHONE,
  SAVE_INPUT_TEXT_NODE,
  SAVE_INTENT_GROUP,
  SAVE_NODE,
  SAVE_SWITCH,
  SAVE_WEBHOOK_SCREEN_ACTION,
  SAVE_WEBHOOK_URL,
  SC_TO_JSON_FULFILLED,
  SC_TO_JSON_PENDING,
  SC_TO_JSON_REJECTED,
  SET_DEFFERED,
  SET_IMAGE_URL,
  SET_JSON,
  SET_SCALE,
  STOP_TEST,
  TOGGLE_NODE,
  TOGGLE_WEBHOOK_SCREEN_ACTION,
  WINDOW_ADD_BUTTON,
  WINDOW_ADD_CONDITION,
  WINDOW_ADD_EVENT,
  WINDOW_ADD_RANDOM,
  WINDOW_ADD_SYSTEM_BUTTON,
  WINDOW_CHANGE_BUTTON_JSON,
  WINDOW_CHANGE_BUTTON_TEXT,
  WINDOW_CHANGE_BUTTON_URL,
  WINDOW_CHANGE_CONDITION,
  WINDOW_CHANGE_EVENT,
  WINDOW_CHANGE_EXAMPLE,
  WINDOW_CHANGE_PATTERN,
  SAVE_SCRIPT,
  WINDOW_DELETE_BUTTON,
  WINDOW_DELETE_CONDITION,
  WINDOW_DELETE_RANDOM,
  WINDOW_REMOVE,
  WINDOW_REMOVE_EVENT,
  WINDOW_RESORT,
  WINDOW_SAVE_ANSWER,
  WINDOW_SAVE_CONDITIONS,
  SET_WEBHOOK_DATA,
  RESTORE_LAST_STATE,
  SAVE_INPUT_DATETIME,
  SET_BLOCK_BETWEEN_COLS,
  SET_AFTER_BLOCK_IN_COLS,
  MOVE_TO_THE_TOP_OF_COL,
  SAVE_WEBHOOK_COLOR,
  SAVE_IMAGE_URL,
  SAVE_IMAGE_CREDENTIALS,
  COPY_BLOCK,
  PUT_BLOCK,
  CUT_BLOCK,
  SAVE_CRM_INTEGRATION,
  SAVE_AUDIOS_TO_STORE,
  SAVE_TIMEOUT,
  SAVE_INPUT_FILE_NODE,
  ADD_READY_INTENT,
  SAVE_TRANSFER_TO_CALL_OPERATOR,
  SAVE_VIDEO_URL,
  APPLY_AUTOSAVE,
  SAVE_HTTP_EMAIL,
  SAVE_SMS,
  SAVE_GOOGLE_SHEETS,
  SAVE_TIMETABLE,
  SAVE_TelegramPayment,
} from '../../components/visualeditor/visualEditorConstants';
import { SAVE_JSON_FULFILLED } from '../../actions/scenario.actions';
import {
  addNewButton,
  calculateGroupIndex,
  changeButtonText,
  makeActionConnections,
  makeFromStateConnections,
  makePatternConnections,
  pushNewNode,
  getNewNodeID,
  colsResolver,
  getColFromName,
  makeNewUniqueId,
  bx24JSON,
  bx24Splitter,
  transformNodeActions,
  getBounding,
  createBlockOnPutBeforeMeta,
} from './utils';
import { t } from '../../localization';
import { cloneDeep, forEach, isNull, isNumber, isUndefined } from 'lodash';
import md5 from 'md5';

const InitialState = {
  scale: 1,
  leftOffset: 0,
  topOffset: 0,
  editNode: null,
  initialNodes: [],
  data: {
    nodes: [],
    arrayNodes: [],
    connections: [],
    fromStateConnections: [],
    hoverConnections: [],
    initScripts: [],
  },
  theme: '/',
  fetching: false,
  webHook: null,
  initialWebhook: null,

  testRun: false,
  testRunPending: false,

  newsletterId: null,
  newsletterName: '',

  lastState: null,
  lastStateWebhook: null,

  copiedBlock: null,
  copiedBlockId: null,
};

const makeConnections = item => {
  let groupIterator = 0;
  let NewConnections = [];
  [NewConnections, groupIterator] = makePatternConnections(item.id, item.patterns, NewConnections, groupIterator);
  [NewConnections, groupIterator] = makePatternConnections(item.id, item.examples, NewConnections, groupIterator);
  NewConnections = makeActionConnections(item.id, item.actions, NewConnections, groupIterator);

  return NewConnections;
};

const makeAllConnections = nodes => {
  let connections = [];
  let fromStateConnections = [];
  forEach(nodes, item => {
    switch (item.type) {
      case 'TransferToOperator':
        if (Boolean(item.noOperatorsOnlineState)) {
          connections.push({
            from_node: item.id,
            fromGroup: 0,
            from: 1,
            to_node: item.noOperatorsOnlineState,
            to: 0,
          });
        }

        if (Boolean(item.dialogCompletedState)) {
          connections.push({
            from_node: item.id,
            fromGroup: 0,
            from: 0,
            to_node: item.dialogCompletedState,
            to: 0,
          });
        }

        break;
      case 'InputFile':
        if (item.then) {
          connections.push({
            from_node: item.id,
            fromGroup: 0,
            from: 0,
            to_node: item.then,
            to: 0,
          });
        }
        if (item.errorState) {
          connections.push({
            from_node: item.id,
            fromGroup: 0,
            from: 1,
            to_node: item.errorState,
            to: 0,
          });
        }
        try {
          item.actions[0].buttons.forEach((but, butIndex) => {
            if (Boolean(but.transition)) {
              connections.push({
                from_node: item.id,
                fromGroup: 1,
                from: butIndex,
                to_node: but.transition,
                to: 0,
              });
            }
          });
        } catch (e) {}

        break;

      case 'InputNumber':
      case 'InputPhoneNumber':
      case 'InputText':
      case 'InputDateTime':
      case 'Transition':
        if (item.then) {
          connections.push({
            from_node: item.id,
            fromGroup: 0,
            from: 0,
            to_node: item.then,
            to: 0,
          });
        }
        try {
          item.actions[0].buttons.forEach((but, butIndex) => {
            if (Boolean(but.transition)) {
              connections.push({
                from_node: item.id,
                fromGroup: 1,
                from: butIndex,
                to_node: but.transition,
                to: 0,
              });
            }
          });
        } catch (e) {}

        break;
      case 'HttpRequest':
        if (item.okState) {
          connections.push({
            from_node: item.id,
            fromGroup: 0,
            from: 0,
            to_node: item.okState,
            to: 0,
          });
        }
        if (item.errorState) {
          connections.push({
            from_node: item.id,
            fromGroup: 0,
            from: 1,
            to_node: item.errorState,
            to: 0,
          });
        }
        try {
          item.actions[0].buttons.forEach((but, butIndex) => {
            if (Boolean(but.transition)) {
              connections.push({
                from_node: item.id,
                fromGroup: 1,
                from: butIndex,
                to_node: but.transition,
                to: 0,
              });
            }
          });
        } catch (e) {}

        break;
      case 'Sms':
      case 'GoogleSheets':
      case 'TelegramPayment':
      case 'Timetable':
      case 'Email':
      case 'CrmIntegration':
        if (item.okState) {
          connections.push({
            from_node: item.id,
            fromGroup: 0,
            from: 0,
            to_node: item.okState,
            to: 0,
          });
        }
        if (item.errorState) {
          connections.push({
            from_node: item.id,
            fromGroup: 0,
            from: 1,
            to_node: item.errorState,
            to: 0,
          });
        }
        if (item.catchAllState) {
          connections.push({
            from_node: item.id,
            fromGroup: 0,
            from: 2,
            to_node: item.catchAllState,
            to: 0,
          });
        }
        break;
      case 'IntentGroup':
        item.intents.forEach((intent, key) => {
          if (intent.then) {
            connections.push({
              from_node: item.id,
              fromGroup: 0,
              from: key,
              to_node: intent.then,
              to: 0,
            });
          }
        });
        if (item.fallback) {
          connections.push({
            from_node: item.id,
            fromGroup: 0,
            from: item.intents.length,
            to_node: item.fallback,
            to: 0,
          });
        }
        try {
          item.actions[0].buttons.forEach((but, butIndex) => {
            if (Boolean(but.transition)) {
              connections.push({
                from_node: item.id,
                fromGroup: 1,
                from: butIndex,
                to_node: but.transition,
                to: 0,
              });
            }
          });
        } catch (e) {}

        break;
      case 'Confirmation':
        if (item.disagreeState !== '') {
          connections.push({
            from_node: item.id,
            fromGroup: 0,
            from: 0,
            to_node: item.disagreeState,
            to: 0,
          });
        }
        if (item.agreeState !== '') {
          connections.push({
            from_node: item.id,
            fromGroup: 1,
            from: 0,
            to_node: item.agreeState,
            to: 0,
          });
        }

        break;

      case 'TransferCallToOperator':
        if (item.then) {
          connections.push({
            from_node: item.id,
            fromGroup: 0,
            from: 0,
            to_node: item.then,
            to: 0,
          });
        }
        if (item.errorState) {
          connections.push({
            from_node: item.id,
            fromGroup: 0,
            from: 1,
            to_node: item.errorState,
            to: 0,
          });
        }
        break;
      case 'screen':
        connections = connections.concat(makeConnections(item));
        let mainPatternIndex = 0;
        [fromStateConnections, mainPatternIndex] = makeFromStateConnections(
          mainPatternIndex,
          item.id,
          item.patterns,
          fromStateConnections
        );
        [fromStateConnections, mainPatternIndex] = makeFromStateConnections(
          mainPatternIndex,
          item.id,
          item.examples,
          fromStateConnections
        );
        [fromStateConnections, mainPatternIndex] = makeFromStateConnections(
          mainPatternIndex,
          item.id,
          item.events,
          fromStateConnections
        );
        break;
      default:
        break;
    }
  });
  return {
    connections: connections,
    fromStateConnections: fromStateConnections,
  };
};
const defaultNextState = null;

const makeArrayNodes = nodes => {
  let arrayNodes = [];
  nodes?.forEach(node => {
    let RowId = getColFromName(node.name);
    if (!arrayNodes[RowId]) {
      arrayNodes[RowId] = [];
    }
    arrayNodes[isNumber(RowId) ? RowId : 0].push(node);
  });
  for (let i = 0; i < arrayNodes.length; i += 1) {
    if (!Boolean(arrayNodes[i])) {
      arrayNodes[i] = [];
    }
  }
  return arrayNodes;
};

export default function visualEditorReducer(state = InitialState, action) {
  switch (action.type) {
    case SET_JSON: {
      let allConnections = makeAllConnections(action.json);

      let nodes = action.json.map(i => ({
        ...i,
        uniqueId: md5(JSON.stringify(i)),
      }));

      let initialNodes = cloneDeep(nodes);

      return {
        ...state,
        data: {
          nodes: action.json,
          ...allConnections,
        },
        fetching: false,
        initialNodes: initialNodes,
        lastState: null,
      };
    }
    case SAVE_JSON_FULFILLED: {
      let iniNodes = [];

      try {
        iniNodes = JSON.parse(action.payload.config.data);
        iniNodes = iniNodes.blocks;
      } catch (e) {
        iniNodes = {};
      }

      iniNodes = iniNodes.map(i => {
        let actions = transformNodeActions(i);

        return {
          ...i,
          actions: actions ? [...actions] : actions,
          uniqueId: md5(JSON.stringify(i)),
        };
      });

      const arrayNodes = makeArrayNodes(iniNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: iniNodes,
          arrayNodes: arrayNodes,
        },
        fetching: false,
        initialNodes: iniNodes,
        lastState: null,
      };
    }
    case DROP_VISUAL_AFTER_FILE_REMOVE: {
      return {
        ...state,
        data: {
          ...InitialState.data,
        },
        theme: '/',
        lastState: null,
      };
    }
    case SET_IMAGE_URL: {
      let newNodes = [...state.data.nodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      newNodes[windowIndex].actions[action.arrayIndex].url = action.url;
      newNodes[windowIndex].actions[action.arrayIndex].wasLoaded = false;

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
        },
        lastState: null,
      };
    }
    case SAVE_IMAGE_CREDENTIALS: {
      let newNodes = [...state.data.nodes];
      let newArrayNodes = [...state.data.arrayNodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.data.windowId;
      });

      newNodes[windowIndex].actions[action.data.arrayIndex].name = action.data.name;
      newNodes[windowIndex].actions[action.data.arrayIndex].description = action.data.description;

      delete newNodes[windowIndex].uniqueId;
      newNodes[windowIndex].uniqueId = md5(
        JSON.stringify({
          ...newNodes[windowIndex],
          date: new Date(),
        })
      );
      let colNewNode = getColFromName(newNodes[windowIndex].name);
      let itemInArrayNodes = state.data.arrayNodes[colNewNode].findIndex(i => i.id === newNodes[windowIndex].id);
      newArrayNodes[colNewNode].splice(itemInArrayNodes, 1, { ...newNodes[windowIndex] });

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
        },
        lastState: null,
      };
    }
    case SAVE_IMAGE_URL:
    case SAVE_VIDEO_URL: {
      let newNodes = [...state.data.nodes];
      let newArrayNodes = [...state.data.arrayNodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.payload.config.item.windowId;
      });
      if (typeof action.payload.data === 'string') {
        newNodes[windowIndex].actions[action.payload.config.item.arrayIndex].url = action.payload.data;
      } else {
        newNodes[windowIndex].actions[action.payload.config.item.arrayIndex] = action.payload.data;
      }
      newNodes[windowIndex].actions[action.payload.config.item.arrayIndex].wasLoaded = true;

      if (action.payload.config.item.needTransition) {
        let itemTransitionToIndex = newNodes.findIndex(node => node.boundsTo === newNodes[windowIndex].id);
        if (itemTransitionToIndex > -1) {
          let item = newNodes[windowIndex];

          if (Boolean(item)) {
            if (isNull(item.actions)) {
              item.actions = [];
            }
            item.actions = item.actions.filter(action => action.type !== 'buttons');
            if (item.actions.findIndex(itemAction => itemAction.type === 'transition') === -1) {
              item.actions.push({
                type: 'transition',
                deferred: false,
                transition: newNodes[itemTransitionToIndex].id,
              });
            }
          }
        }
      }

      delete newNodes[windowIndex].uniqueId;
      newNodes[windowIndex].uniqueId = md5(
        JSON.stringify({
          ...newNodes[windowIndex],
          date: new Date(),
        })
      );
      let colNewNode = getColFromName(newNodes[windowIndex].name);
      let itemInArrayNodes = state.data.arrayNodes[colNewNode].findIndex(i => i.id === newNodes[windowIndex].id);
      newArrayNodes[colNewNode].splice(itemInArrayNodes, 1, { ...newNodes[windowIndex] });

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
        },
        lastState: null,
      };
    }
    case SAVE_AUDIOS_TO_STORE: {
      let newNodes = [...state.data.nodes];
      let newArrayNodes = [...state.data.arrayNodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.payload.config.item.windowId;
      });
      newNodes[windowIndex].actions[action.payload.config.item.arrayIndex].audios = action.payload.data;

      if (action.payload.config.item.needTransition) {
        let itemTransitionToIndex = newNodes.findIndex(node => node.boundsTo === newNodes[windowIndex].id);
        if (itemTransitionToIndex > -1) {
          let item = newNodes[windowIndex];

          if (Boolean(item)) {
            if (isNull(item.actions)) {
              item.actions = [];
            }
            item.actions = item.actions.filter(action => action.type !== 'buttons');
            if (item.actions.findIndex(itemAction => itemAction.type === 'transition') === -1) {
              item.actions.push({
                type: 'transition',
                deferred: false,
                transition: newNodes[itemTransitionToIndex].id,
              });
            }
          }
        }
      }

      delete newNodes[windowIndex].uniqueId;
      newNodes[windowIndex].uniqueId = md5(
        JSON.stringify({
          ...newNodes[windowIndex],
          date: new Date(),
        })
      );
      let colNewNode = getColFromName(newNodes[windowIndex].name);
      let itemInArrayNodes = state.data.arrayNodes[colNewNode].findIndex(i => i.id === newNodes[windowIndex].id);
      newArrayNodes[colNewNode].splice(itemInArrayNodes, 1, { ...newNodes[windowIndex] });

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
        },
        lastState: null,
      };
    }
    case REMOVE_IMAGE_FROM_SERVER_FULFILLED: {
      let newNodes = cloneDeep(state.data.nodes);
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.payload.config.item.windowIndex;
      });
      newNodes[windowIndex].actions[action.payload.config.item.arrayIndex].url = null;
      newNodes[windowIndex].actions[action.payload.config.item.arrayIndex].wasLoaded = false;
      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
        },
        lastState: state.data.nodes,
      };
    }
    case LOAD_JSON_REJECTED: {
      return {
        ...state,
        data: { ...InitialState.data },
        fetching: false,
        lastState: null,
      };
    }

    case SC_TO_JSON_PENDING: {
      return {
        ...state,
        data: {
          ...InitialState.data,
        },
        fetching: true,
      };
    }
    case SC_TO_JSON_REJECTED: {
      return {
        ...state,
        data: {
          ...InitialState.data,
        },
        fetching: false,
        lastState: null,
      };
    }
    case SC_TO_JSON_FULFILLED:
    case LOAD_JSON: {
      let nodes = action.data.blocks;
      if (nodes.length === 1) {
        nodes = nodes.map(block => {
          block.name = 'col#0';
          return block;
        });
      }

      nodes = nodes.map(i => {
        let actions = transformNodeActions(i);

        return {
          ...i,
          actions: actions ? [...actions] : actions,
          uniqueId: md5(JSON.stringify(i)),
        };
      });
      nodes = colsResolver(nodes);

      let arrayNodes = makeArrayNodes(nodes);

      let initialNodes = cloneDeep(nodes);
      let allConnections = makeAllConnections(nodes);

      return {
        ...state,
        data: {
          nodes: nodes,
          arrayNodes: arrayNodes,
          ...allConnections,
        },
        theme: action.data.theme,
        fetching: false,
        initialNodes: initialNodes,
        lastState: null,
      };
    }

    case DUMMY_FULFILLED: {
      let nodes = action.payload.data.blocks;

      let initialNodes = cloneDeep(nodes);
      let allConnections = makeAllConnections(nodes);

      return {
        ...InitialState,
        scale: state.scale,
        data: {
          nodes: nodes,
          arrayNodes: nodes,
          ...allConnections,
        },
        theme: action.payload.data.theme,
        fetching: false,
        initialNodes: initialNodes,
        newsletterId: null,
        lastState: null,
      };
    }

    case WINDOW_ADD_EVENT: {
      const newNodes = [...state.data.nodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      switch (action.eventType) {
        case 'global': {
          try {
            newNodes[windowIndex].events[action.eventType].push('');
          } catch (e) {
            if (!newNodes[windowIndex].events) {
              newNodes[windowIndex].events = {};
            }
            newNodes[windowIndex].events[action.eventType] = [];
            newNodes[windowIndex].events[action.eventType].push('');
          }
          break;
        }
        case 'context':
        case 'theme': {
          try {
            newNodes[windowIndex].events[action.eventType].push({
              pattern: '',
            });
          } catch (e) {
            if (!newNodes[windowIndex].events) {
              newNodes[windowIndex].events = {};
            }
            newNodes[windowIndex].events[action.eventType] = [];
            newNodes[windowIndex].events[action.eventType].push({
              pattern: '',
            });
          }

          break;
        }
        default:
          break;
      }

      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          ...allConnections,
        },
      };
    }

    case WINDOW_CHANGE_EVENT: {
      let newNodes = [...state.data.nodes];
      const index = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      switch (action.eventType) {
        case 'global': {
          newNodes[index].events[action.eventType][action.arrayIndex] = action.text;
          break;
        }
        case 'context':
        case 'theme': {
          newNodes[index].events[action.eventType][action.arrayIndex].pattern = action.text;
          break;
        }
        default:
          break;
      }
      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
        },
      };
    }

    case WINDOW_REMOVE_EVENT: {
      const newNodes = [...state.data.nodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      newNodes[windowIndex].events[action.eventType].splice(action.arrayIndex, 1);
      if (newNodes[windowIndex].events[action.eventType].length === 0) {
        newNodes[windowIndex].events[action.eventType] = null;
      }

      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          ...allConnections,
        },
      };
    }

    case SET_DEFFERED: {
      const newNodes = [...state.data.nodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      newNodes[windowIndex].actions[action.arrayIndex].deferred = action.value;
      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
        },
      };
    }
    case EDIT_NODE: {
      const editNode = state.data.nodes.filter(node => {
        return node.id === action.windowId;
      });
      return {
        ...state,
        editNode: editNode[0],
      };
    }
    case SAVE_NODE: {
      let newNodes = [...state.data.nodes];

      const index = newNodes.findIndex(node => {
        return node.id === action.editNode.id;
      });

      newNodes[index].name = action.inputs.newName;

      if (!!action.inputs.modifiers) {
        newNodes[index].modifiers = action.inputs.modifiers;
      }

      const whatReplace = new RegExp(action.editNode.id, 'g');
      const onWhatReplace = action.inputs.nodeId + '';

      newNodes = JSON.parse(JSON.stringify(newNodes).replace(whatReplace, onWhatReplace));

      let allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        editNode: null,
        data: {
          ...state.data,
          nodes: newNodes,
          ...allConnections,
        },
        lastState: null,
      };
    }
    case SAVE_CONFIRMATION:
    case SAVE_HTTP_REQUEST:
    case SAVE_SWITCH:
    case SAVE_INPUT_DATETIME:
    case SAVE_INPUT_NUMBER_NODE:
    case SAVE_INTENT_GROUP:
    case SAVE_INPUT_PHONE:
    case SAVE_CRM_INTEGRATION:
    case SAVE_INPUT_TEXT_NODE:
    case SAVE_INPUT_FILE_NODE:
    case SAVE_HTTP_EMAIL:
    case SAVE_SMS:
    case SAVE_GOOGLE_SHEETS:
    case SAVE_TelegramPayment:
    case SAVE_TIMETABLE:
    case SAVE_TRANSFER_TO_CALL_OPERATOR: {
      let newNodes = [...state.data.nodes];
      let newArrayNodes = [...state.data.arrayNodes];

      const index = newNodes.findIndex(node => {
        return node.id === action.editNode?.id;
      });

      if (index === -1) return state;

      let colNewNode = getColFromName(newNodes[index].name);
      let itemInArrayNodes = newArrayNodes[colNewNode].findIndex(i => i.id === newNodes[index].id);

      switch (newNodes[index].type) {
        case 'InputFile':
        case 'InputText': {
          newNodes[index].html = action.inputs.html;
          newNodes[index].htmlEnabled = action.inputs.htmlEnabled;
          newNodes[index].prompt = action.inputs.prompt;
          newNodes[index].varName = action.inputs.varName;
          newNodes[index].boundsTo = getBounding(newNodes, newNodes[index], action.inputs.boundsTo);

          break;
        }
        case 'InputNumber': {
          newNodes[index].prompt = action.inputs.prompt;
          newNodes[index].html = action.inputs.html;
          newNodes[index].htmlEnabled = action.inputs.htmlEnabled;
          newNodes[index].varName = action.inputs.varName;
          newNodes[index].minValue = action.inputs.minValue;
          newNodes[index].maxValue = action.inputs.maxValue;
          newNodes[index].failureMessage = action.inputs.failureMessage;
          newNodes[index].failureMessageHtml = action.inputs.failureMessageHtml;
          newNodes[index].boundsTo = getBounding(newNodes, newNodes[index], action.inputs.boundsTo);
          break;
        }
        case 'HttpRequest': {
          delete action.inputs.new;
          newNodes[index] = action.inputs;
          break;
        }
        case 'IntentGroup': {
          newNodes[index] = action.inputs;
          if (newNodes[index].boundsTo) {
            newNodes[index].boundsTo =
              newNodes.findIndex(node => node.id === newNodes[index].boundsTo) > -1 ? newNodes[index].boundsTo : '';
          }
          break;
        }
        case 'CrmIntegration': {
          /**
           channelType : "BITRIX",
           task : "LEAD_CREATION",
           parameters : "{ \"fields[TITLE]\": \"ИП Титов\", \"fields[NAME]\":\"Глеб\", \"fields[SECOND_NAME]\":\"Егорович\", \"fields[LAST_NAME]\":\"Титов\", \"fields[STATUS_ID]\":\"NEW\", \"fields[OPENED]\":\"Y\", \"fields[ASSIGNED_BY_ID]\":1, \"fields[CURRENCY_ID]\":\"USD\", \"fields[OPPORTUNITY]\":1250, \"fields[PHONE][0][VALUE]\":55588, \"fields[PHONE][0][VALUE_TYPE]\":\"WORK\", \"params[REGISTER_SONET_EVENT]\":\"Y\"}",
           fields: {},
           * */

          newNodes[index].boundsTo = action.editNode.boundsTo;
          newNodes[index].channelType = action.fields.type;
          newNodes[index].task = action.fields.task;
          newNodes[index].parameters = bx24Splitter(bx24JSON(action.fields.fields));
          newNodes[index].fields = action.fields.fields;
          break;
        }
        case 'InputDateTime':
        case 'InputPhoneNumber': {
          newNodes[index].prompt = action.obj.prompt;
          newNodes[index].html = action.obj.html;
          newNodes[index].htmlEnabled = action.obj.htmlEnabled;
          newNodes[index].varName = action.obj.varName;
          newNodes[index].failureMessage = action.obj.failureMessage;
          newNodes[index].failureMessageHtml = action.obj.failureMessageHtml;
          newNodes[index].boundsTo =
            newNodes.filter(node => node.id !== newNodes[index].id).findIndex(item => item.id === action.obj.boundsTo) >
            -1
              ? action.obj.boundsTo
              : '';
          break;
        }
        case 'TransferToOperator': {
          newNodes[index].ignoreOffline = action.inputs.ignoreOffline;
          if (action.inputs.ignoreOffline) {
            newNodes[index].noOperatorsOnlineState = '';
          }
          newNodes[index].messageBeforeTransfer = action.inputs.messageBeforeTransfer;
          newNodes[index].messageBeforeTransferHtml = action.inputs.messageBeforeTransferHtml;
          newNodes[index].titleOfCloseButton = action.inputs.titleOfCloseButton;
          newNodes[index].messageForWaitingOperator = action.inputs.messageForWaitingOperator;
          newNodes[index].messageForWaitingOperatorHtml = action.inputs.messageForWaitingOperatorHtml;
          newNodes[index].prechatAttributes =
            Object.keys(action.inputs.prechatAttributes).length === 0 ? null : action.inputs.prechatAttributes;
          newNodes[index].boundsTo =
            newNodes
              .filter(node => node.id !== newNodes[index].id)
              .findIndex(item => item.id === action.inputs.boundsTo) > -1
              ? action.inputs.boundsTo
              : '';
          newNodes[index].sendMessagesToOperator = action.inputs.sendMessagesToOperator;
          newNodes[index].sendMessageHistoryAmount = action.inputs.sendMessageHistoryAmount;
          newNodes[index].htmlEnabled = action.inputs.htmlEnabled;
          newNodes[index].destination = action.inputs.destination;
          break;
        }
        case 'Confirmation': {
          newNodes[index].name = action.inputs.newName;
          newNodes[index].prompt = action.inputs.prompt;
          newNodes[index].useButtons = action.inputs.useButtons;
          newNodes[index].agreeButton = action.inputs.agreeButton;
          newNodes[index].disagreeButton = action.inputs.disagreeButton;

          const whatReplace = new RegExp(action.editNode.id, 'g');
          const onWhatReplace = action.inputs.nodeId + '';

          newNodes = JSON.parse(JSON.stringify(newNodes).replace(whatReplace, onWhatReplace));
          break;
        }
        case 'TransferCallToOperator': {
          newNodes[index].phoneNumber = action.inputs.phoneNumber;
          newNodes[index].boundsTo =
            newNodes
              .filter(node => node.id !== newNodes[index].id)
              .findIndex(item => item.id === action.inputs.boundsTo) > -1
              ? action.inputs.boundsTo
              : '';
          break;
        }
        case 'Email': {
          newNodes[index].destination = action.inputs.destination;
          newNodes[index].subject = action.inputs.subject;
          newNodes[index].text = action.inputs.text;
          newNodes[index].files = action.inputs.files;
          newNodes[index].html = action.inputs.html;
          newNodes[index].htmlEnabled = action.inputs.htmlEnabled;
          newNodes[index].boundsTo =
            newNodes
              .filter(node => node.id !== newNodes[index].id)
              .findIndex(item => item.id === action.editNode.boundsTo) > -1
              ? action.editNode.boundsTo
              : '';
          break;
        }
        case 'Sms': {
          newNodes[index].destination = action.inputs.destination;
          newNodes[index].text = action.inputs.text;
          newNodes[index].boundsTo = getBounding(newNodes, newNodes[index], action.editNode.boundsTo);
          break;
        }
        case 'GoogleSheets': {
          newNodes[index].integrationId = action.inputs.integrationId;
          newNodes[index].spreadsheetId = action.inputs.spreadsheetId;
          newNodes[index].sheetName = action.inputs.sheetName;
          newNodes[index].operationType = action.inputs.operationType;
          newNodes[index].body = action.inputs.body;
          newNodes[index].boundsTo = getBounding(newNodes, newNodes[index], action.editNode.boundsTo);
          break;
        }
        //Timetable here
        //TelegramPayment here
        default: {
          delete action.inputs.new;
          newNodes[index] = action.inputs;
          break;
        }
      }

      delete newNodes[index].uniqueId;
      newNodes[index] = makeNewUniqueId(newNodes[index]);
      newArrayNodes[colNewNode].splice(itemInArrayNodes, 1, { ...newNodes[index] });

      let itemBoundToIndex = -1;
      if (newNodes[index].boundsTo !== '') {
        let item = newNodes.find(node => node.id === newNodes[index].boundsTo);
        itemBoundToIndex = newNodes.findIndex(node => node.id === newNodes[index].boundsTo);
        if (Boolean(item) && !isNull(item.actions)) {
          let actions = item.actions.filter(action => action.type !== 'buttons');

          if (Boolean(newNodes[index].actions)) {
            let buttons = item.actions.filter(action => action.type === 'buttons');

            if (buttons.length !== 0) {
              let metaButtonsIndex = newNodes[index].actions.findIndex(action => action.type === 'buttons');
              if (metaButtonsIndex > -1) {
                newNodes[index].actions[metaButtonsIndex].buttons = buttons[0].buttons;
              } else {
                newNodes[index].actions = buttons;
              }
            }
          }

          item.actions = actions;
          if (item.actions.findIndex(itemAction => itemAction.type === 'transition') === -1) {
            item.actions.push({
              type: 'transition',
              deferred: false,
              transition: newNodes[index].id,
            });
          }

          item = makeNewUniqueId(item);
        }
      }
      if (itemBoundToIndex > -1) {
        let newItem = { ...newNodes[index] };
        newNodes.splice(index, 1);
        let itemInArrayNodes = newArrayNodes[colNewNode].findIndex(i => i.id === newItem.id);
        newArrayNodes[colNewNode].splice(itemInArrayNodes, 1);
        let arrayNodesItemBoundToIndex = newArrayNodes[colNewNode].findIndex(node => node.id === newItem.boundsTo);

        newNodes.splice(itemBoundToIndex + 1, 0, newItem);
        newArrayNodes[colNewNode].splice(arrayNodesItemBoundToIndex + 1, 0, newItem);
      }

      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        editNode: null,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
          ...allConnections,
        },
        lastState: null,
      };
    }

    case TOGGLE_NODE: {
      return {
        ...state,
        editNode: null,
      };
    }

    case GROUP_MOVE_SAVE_TO_STORE: {
      return {
        ...state,
        data: {
          ...state.data,
          nodes: action.obj.nodes,
        },
      };
    }

    case NEW_CONNECTOR: {
      let newNodes = cloneDeep(state.data.nodes);
      let lastState = null;
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.n1;
      });
      if (windowIndex === -1) return state;
      switch (newNodes[windowIndex]?.type) {
        case 'Sms':
        case 'GoogleSheets':
        case 'Email':
        case 'Timetable':
        case 'TelegramPayment':
        case 'CrmIntegration':
          switch (action.o) {
            case 0:
              if (Boolean(newNodes[windowIndex].okState)) {
                lastState = state.data.nodes;
              }
              newNodes[windowIndex].okState = action.n2;
              break;
            case 1:
              if (Boolean(newNodes[windowIndex].errorState)) {
                lastState = state.data.nodes;
              }
              newNodes[windowIndex].errorState = action.n2;
              break;
            case 2:
              if (Boolean(newNodes[windowIndex].catchAllState)) {
                lastState = state.data.nodes;
              }
              newNodes[windowIndex].catchAllState = action.n2;
              break;
            default: {
              if (Boolean(newNodes[windowIndex].okState)) {
                lastState = state.data.nodes;
              }
              newNodes[windowIndex].okState = action.n2;
              break;
            }
          }
          break;
        case 'TransferToOperator':
          switch (action.o) {
            case 0:
              if (Boolean(newNodes[windowIndex].dialogCompletedState)) {
                lastState = state.data.nodes;
              }
              newNodes[windowIndex].dialogCompletedState = action.n2;
              break;
            case 1:
              if (Boolean(newNodes[windowIndex].noOperatorsOnlineState)) {
                lastState = state.data.nodes;
              }
              newNodes[windowIndex].noOperatorsOnlineState = action.n2;
              break;
            default: {
              if (Boolean(newNodes[windowIndex].dialogCompletedState)) {
                lastState = state.data.nodes;
              }
              newNodes[windowIndex].dialogCompletedState = action.n2;
              break;
            }
          }
          break;
        case 'Confirmation':
          switch (action.og) {
            case 1:
              if (Boolean(newNodes[windowIndex].agreeState)) {
                lastState = state.data.nodes;
              }
              newNodes[windowIndex].agreeState = action.n2;
              break;
            case 0:
              if (Boolean(newNodes[windowIndex].disagreeState)) {
                lastState = state.data.nodes;
              }
              newNodes[windowIndex].disagreeState = action.n2;
              break;
            default: {
              if (Boolean(newNodes[windowIndex].disagreeState)) {
                lastState = state.data.nodes;
              }
              newNodes[windowIndex].disagreeState = action.n2;
              break;
            }
          }
          break;

        case 'InputNumber':
        case 'InputPhoneNumber':
        case 'InputPhone':
        case 'InputText':
        case 'InputDateTime':
        case 'Transition':
          if (action.og === 0) {
            if (Boolean(newNodes[windowIndex].then)) {
              lastState = state.data.nodes;
            }
            newNodes[windowIndex].then = action.n2;
          } else {
            if (Boolean(newNodes[windowIndex].actions[0].buttons[action.o].transition)) {
              lastState = state.data.nodes;
            }
            newNodes[windowIndex].actions[0].buttons[action.o].transition = action.n2;
          }

          break;
        case 'InputFile':
          if (action.og === 0) {
            switch (action.o) {
              case 0: {
                if (Boolean(newNodes[windowIndex].then)) {
                  lastState = state.data.nodes;
                }
                newNodes[windowIndex].then = action.n2;
                break;
              }
              case 1: {
                if (Boolean(newNodes[windowIndex].errorState)) {
                  lastState = state.data.nodes;
                }
                newNodes[windowIndex].errorState = action.n2;
                break;
              }
              default:
                break;
            }
          } else {
            if (Boolean(newNodes[windowIndex].actions[0].buttons[action.o].transition)) {
              lastState = state.data.nodes;
            }
            newNodes[windowIndex].actions[0].buttons[action.o].transition = action.n2;
          }

          break;
        case 'HttpRequest':
          if (action.og === 0) {
            switch (action.o) {
              case 0: {
                if (Boolean(newNodes[windowIndex].okState)) {
                  lastState = state.data.nodes;
                }
                newNodes[windowIndex].okState = action.n2;
                break;
              }
              case 1: {
                if (Boolean(newNodes[windowIndex].errorState)) {
                  lastState = state.data.nodes;
                }
                newNodes[windowIndex].errorState = action.n2;
                break;
              }
              default:
                break;
            }
          } else {
            if (Boolean(newNodes[windowIndex].actions[0].buttons[action.o].transition)) {
              lastState = state.data.nodes;
            }
            newNodes[windowIndex].actions[0].buttons[action.o].transition = action.n2;
          }

          break;
        case 'IntentGroup':
          if (action.og === 0) {
            if (isUndefined(newNodes[windowIndex].intents[action.o])) {
              if (Boolean(newNodes[windowIndex].fallback)) {
                lastState = state.data.nodes;
              }
              newNodes[windowIndex].fallback = action.n2;
            } else {
              if (Boolean(newNodes[windowIndex].intents[action.o].then)) {
                lastState = state.data.nodes;
              }
              newNodes[windowIndex].intents[action.o].then = action.n2;
            }
          } else {
            if (Boolean(newNodes[windowIndex].actions[0].buttons[action.o].transition)) {
              lastState = state.data.nodes;
            }
            newNodes[windowIndex].actions[0].buttons[action.o].transition = action.n2;
          }

          break;

        case 'TransferCallToOperator':
          switch (action.o) {
            case 0:
              if (Boolean(newNodes[windowIndex].then)) {
                lastState = state.data.nodes;
              }
              newNodes[windowIndex].then = action.n2;
              break;
            case 1:
              if (Boolean(newNodes[windowIndex].errorState)) {
                lastState = state.data.nodes;
              }
              newNodes[windowIndex].errorState = action.n2;
              break;
            default: {
              if (Boolean(newNodes[windowIndex].then)) {
                lastState = state.data.nodes;
              }
              newNodes[windowIndex].then = action.n2;
              break;
            }
          }
          break;

        case 'screen':
          const [groupIndex, groupName, patternName] = calculateGroupIndex(
            newNodes[windowIndex].actions,
            newNodes[windowIndex].patterns,
            newNodes[windowIndex].examples,
            action.og
          );
          switch (groupName) {
            case 'actions': {
              switch (newNodes[windowIndex].actions[groupIndex].type) {
                case 'buttons': {
                  if (Boolean(newNodes[windowIndex].actions[groupIndex].buttons[action.o].transition)) {
                    lastState = state.data.nodes;
                  }
                  newNodes[windowIndex].actions[groupIndex].buttons[action.o].transition = action.n2;
                  break;
                }
                case 'random': {
                  if (Boolean(newNodes[windowIndex].actions[groupIndex].transitions[action.o])) {
                    lastState = state.data.nodes;
                  }
                  newNodes[windowIndex].actions[groupIndex].transitions[action.o] = action.n2;
                  break;
                }
                case 'transition': {
                  newNodes[windowIndex].actions[groupIndex].transition = action.n2;
                  break;
                }
                case 'condition': {
                  if (
                    !!newNodes[windowIndex].actions[groupIndex].conditions &&
                    action.o + 1 <= newNodes[windowIndex].actions[groupIndex].conditions.length
                  ) {
                    if (Boolean(newNodes[windowIndex].actions[groupIndex].conditions[action.o].transition)) {
                      lastState = state.data.nodes;
                    }
                    newNodes[windowIndex].actions[groupIndex].conditions[action.o].transition = action.n2;
                  } else {
                    if (Boolean(newNodes[windowIndex].actions[groupIndex].defaultTransition)) {
                      lastState = state.data.nodes;
                    }
                    newNodes[windowIndex].actions[groupIndex].defaultTransition = action.n2;
                  }
                  break;
                }
                case 'timeout': {
                  newNodes[windowIndex].actions[groupIndex].then = action.n2;
                  break;
                }
                default:
                  break;
              }
              break;
            }
            case 'patterns': {
              newNodes[windowIndex].patterns[patternName][groupIndex].toState = action.n2;
              break;
            }
            case 'examples': {
              newNodes[windowIndex].examples[patternName][groupIndex].toState = action.n2;
              break;
            }
            default:
              break;
          }

          break;
        default:
          break;
      }

      const allConnections = makeAllConnections(newNodes);

      if (newNodes[windowIndex]?.uniqueId) {
        delete newNodes[windowIndex].uniqueId;
      }
      newNodes[windowIndex].uniqueId = md5(
        JSON.stringify({
          ...newNodes[windowIndex],
          date: new Date(),
        })
      );

      let newArrayNodes = makeArrayNodes(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
          ...allConnections,
        },
        lastState: lastState,
      };
    }

    case REMOVE_CONNECTOR: {
      let newNodes = cloneDeep(state.data.nodes);
      let newArrayNodes = [...state.data.arrayNodes];

      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.connector.from_node;
      });
      let flag = state.data.connections.find(
        item =>
          item.from_node === action.connector.from_node &&
          item.fromGroup === action.connector.fromGroup &&
          item.from === action.connector.from
      );

      switch (newNodes[windowIndex]?.type) {
        case 'Sms':
        case 'GoogleSheets':
        case 'Email':
        case 'Timetable':
        case 'TelegramPayment':
        case 'CrmIntegration':
          switch (action.connector.from) {
            case 0:
              newNodes[windowIndex].okState = defaultNextState;
              break;
            case 1:
              newNodes[windowIndex].errorState = defaultNextState;
              break;
            case 2:
              newNodes[windowIndex].catchAllState = defaultNextState;
              break;
            default:
              break;
          }
          break;
        case 'TransferToOperator':
          switch (action.connector.from) {
            case 0:
              newNodes[windowIndex].dialogCompletedState = defaultNextState;
              break;
            case 1:
              newNodes[windowIndex].noOperatorsOnlineState = defaultNextState;
              break;
            default:
              break;
          }
          break;
        case 'Confirmation':
          if (action.connector.fromGroup === 0) {
            newNodes[windowIndex].disagreeState = defaultNextState;
          } else {
            newNodes[windowIndex].agreeState = defaultNextState;
          }
          break;
        case 'IntentGroup': {
          if (action.connector.fromGroup === 0) {
            if (isUndefined(newNodes[windowIndex].intents[action.connector.from])) {
              newNodes[windowIndex].fallback = '';
            } else {
              newNodes[windowIndex].intents[action.connector.from].then = '';
            }
          } else {
            newNodes[windowIndex].actions[0].buttons[action.connector.from].transition = '';
          }

          break;
        }
        case 'InputFile': {
          if (action.connector.fromGroup === 0) {
            switch (action.connector.from) {
              case 0: {
                newNodes[windowIndex].then = '';
                break;
              }
              case 1: {
                newNodes[windowIndex].errorState = '';
                break;
              }
              default:
                break;
            }
          } else {
            newNodes[windowIndex].actions[0].buttons[action.connector.from].transition = '';
          }
          break;
        }
        case 'HttpRequest': {
          if (action.connector.fromGroup === 0) {
            switch (action.connector.from) {
              case 0: {
                newNodes[windowIndex].okState = '';
                break;
              }
              case 1: {
                newNodes[windowIndex].errorState = '';
                break;
              }
              default:
                break;
            }
          } else {
            newNodes[windowIndex].actions[0].buttons[action.connector.from].transition = '';
          }

          break;
        }

        case 'InputText':
        case 'InputDateTime':
        case 'InputPhoneNumber':
        case 'InputPhone':
        case 'InputNumber':
        case 'Transition':
          if (action.connector.fromGroup === 0) {
            newNodes[windowIndex].then = defaultNextState;
          } else {
            newNodes[windowIndex].actions[0].buttons[action.connector.from].transition = '';
          }
          break;

        case 'TransferCallToOperator':
          switch (action.connector.from) {
            case 0:
              newNodes[windowIndex].then = defaultNextState;
              break;
            case 1:
              newNodes[windowIndex].errorState = defaultNextState;
              break;
            default:
              break;
          }
          break;

        case 'screen':
          const [groupIndex, groupName, patternName] = calculateGroupIndex(
            newNodes[windowIndex].actions,
            newNodes[windowIndex].patterns,
            newNodes[windowIndex].examples,
            action.connector.fromGroup
          );
          switch (groupName) {
            case 'actions': {
              switch (newNodes[windowIndex].actions[groupIndex].type) {
                case 'buttons': {
                  newNodes[windowIndex].actions[groupIndex].buttons.splice(action.connector.from, 1, {
                    name: newNodes[windowIndex].actions[groupIndex].buttons[action.connector.from].name,
                    transition: defaultNextState,
                  });
                  break;
                }
                case 'transition': {
                  newNodes[windowIndex].actions[groupIndex] = {
                    type: 'transition',
                    deferred: true,
                  };
                  break;
                }
                case 'condition': {
                  if (Boolean(newNodes[windowIndex].actions[groupIndex].conditions[action.connector.from])) {
                    newNodes[windowIndex].actions[groupIndex].conditions[action.connector.from].transition = null;
                  } else {
                    newNodes[windowIndex].actions[groupIndex].defaultTransition = null;
                  }
                  break;
                }
                case 'random': {
                  newNodes[windowIndex].actions[groupIndex].transitions.splice(action.connector.from, 1);
                  break;
                }
                case 'timeout': {
                  newNodes[windowIndex].actions[groupIndex].then = '';
                  break;
                }
                default:
                  break;
              }
              break;
            }
            case 'patterns': {
              newNodes[windowIndex].patterns[patternName][groupIndex] = {
                pattern: state.data.nodes[windowIndex].patterns[patternName][groupIndex].pattern,
              };
              break;
            }
            case 'examples': {
              newNodes[windowIndex].examples[patternName][groupIndex] = {
                pattern: state.data.nodes[windowIndex].examples[patternName][groupIndex].pattern,
              };
              break;
            }
            default:
              break;
          }
          break;
        default:
          break;
      }

      delete newNodes[windowIndex].uniqueId;
      newNodes[windowIndex].uniqueId = md5(
        JSON.stringify({
          ...newNodes[windowIndex],
          date: new Date(),
        })
      );

      let col = getColFromName(newNodes[windowIndex].name);
      let indexInArrayNodes = newArrayNodes[col].findIndex(item => item.id === newNodes[windowIndex].id);
      newArrayNodes[col].splice(indexInArrayNodes, 1, { ...newNodes[windowIndex] });

      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
          ...allConnections,
        },

        lastState: Boolean(flag) ? state.data.nodes : null,
      };
    }
    // FROM STATE CONNECTOR
    case NEW_FROM_STATE_CONNECTOR: {
      let newNodes = cloneDeep(state.data.nodes);
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.to_node;
      });
      newNodes[windowIndex][action.patternType][action.key][action.from].fromState = action.from_node;
      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          ...allConnections,
        },

        lastState: state.data.nodes,
      };
    }
    case REMOVE_FROM_STATE_CONNECTOR: {
      let newNodes = [...state.data.nodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.connector.to_node;
      });
      newNodes[windowIndex].patterns[action.connector.key][action.connector.from].fromState = null;

      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          ...allConnections,
        },
      };
    }
    // !!! FROM STATE CONNECTOR

    case ADD_WINDOW: {
      let newNodes = [];

      if (state.data.nodes) {
        try {
          newNodes = cloneDeep(state.data.nodes);
        } catch (e) {
          newNodes = [];
        }
      }

      newNodes = newNodes.filter(item => item.type !== 'dummyNode');
      newNodes = pushNewNode(newNodes, newNodes.length, action.data.type, action.data.col, action.data.newBlockData);

      const Index = newNodes.length - 1;
      if (action.data.newBlockData) {
        newNodes[Index] = makeNewUniqueId(newNodes[Index]);
      }

      if (Boolean(action.data) && action.data.boundsTo !== '') {
        newNodes[Index].boundsTo = action.data.boundsTo;
        let itemBoundToIndex = -1;

        let item = newNodes.find(node => node.id === action.data.boundsTo);
        itemBoundToIndex = newNodes.findIndex(node => node.id === action.data.boundsTo);
        if (Boolean(item) && item.type === 'screen') {
          if (isNull(item.actions)) {
            item.actions = [];
          }
          let buttons = item.actions.find(action => action.type === 'buttons');
          if (buttons) {
            switch (action.data.type) {
              case 'IntentGroup': {
                newNodes[Index].actions = [buttons];
                break;
              }
              default:
                break;
            }
          }
          item.actions = item.actions.filter(action => action.type !== 'buttons');
          if (item.actions.findIndex(itemAction => itemAction.type === 'transition') === -1) {
            item.actions.push({
              type: 'transition',
              deferred: false,
              transition: newNodes[Index].id,
            });
          }

          item = makeNewUniqueId(item);
        }
        if (itemBoundToIndex > -1) {
          let newItem = { ...newNodes[Index] };
          newNodes.splice(Index, 1);
          newNodes.splice(itemBoundToIndex + 1, 0, newItem);
        }
      }

      if (action.data.beforeIndex > -1) {
        let newItem = cloneDeep(newNodes[newNodes.length - 1]);
        newNodes.splice(newNodes.length - 1, 1);
        newNodes.splice(action.data.beforeIndex, 0, newItem);
      }

      let arrayNodes = makeArrayNodes(newNodes);
      console.log(newNodes);
      let lastState = null;
      if (action.data.type === 'dummyNode') {
        lastState = state.lastState;
      }
      if (action.data.newBlockData) {
        lastState = null;
      }

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: arrayNodes,
        },
        lastState: lastState,
      };
    }
    case ADD_READY_INTENT: {
      let newNodes = cloneDeep(state.data.nodes);
      let targetNode = newNodes.find(node => node.id === action.nodeId);

      targetNode.intents = [...targetNode.intents, ...action.newBlockData.intents];

      targetNode = makeNewUniqueId(targetNode);

      let arrayNodes = makeArrayNodes(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes,
        },
      };
    }
    case ADD_SCREEN_BEFORE_META: {
      let newNodes = cloneDeep(state.data.nodes);
      let newArrayNodes = [...state.data.arrayNodes];

      /**
       nodeAfterId,
       node,
       col,
       afterOrInstead
       */

      let foundItemIndex = newNodes.findIndex(item => item.id === action.nodeAfterId);
      let newNodeId = getNewNodeID(newNodes, newNodes.length);

      if (action.afterOrInstead) {
        let newNode = {
          ...action.node,
          id: newNodeId,
          boundsTo: action.nodeAfterId,
        };

        delete newNode.uniqueId;
        newNode.uniqueId = md5(
          JSON.stringify({
            ...newNode,
            date: new Date(),
          })
        );

        newNodes.splice(foundItemIndex + 1, 0, newNode);

        let col = getColFromName(action.node.name);
        let indexInArrayNodes = newArrayNodes[col].findIndex(item => item.id === action.nodeAfterId);
        newArrayNodes[col].splice(indexInArrayNodes + 1, 0, newNode);
      } else {
        //instead
        let newNode = {
          ...action.node,
          id: action.nodeAfterId,
        };
        delete newNode.uniqueId;
        newNode.uniqueId = md5(
          JSON.stringify({
            ...newNode,
            date: new Date(),
          })
        );

        newNodes.splice(foundItemIndex, 1, newNode);

        let col = getColFromName(action.node.name);
        let indexInArrayNodes = newArrayNodes[col].findIndex(i => i.id === action.node.id);
        newArrayNodes[col].splice(indexInArrayNodes, 1, newNode);
      }

      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
          ...allConnections,
        },
        lastState: null,
      };
    }

    case ADD_CONNECTION_WITH_WINDOW: {
      let newNodes = [...state.data.nodes];
      newNodes = pushNewNode(newNodes, newNodes.length, action.data.node.x, action.data.node.y);

      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.data.connection.n1;
      });

      const [groupIndex, groupName, patternName] = calculateGroupIndex(
        newNodes[windowIndex].actions,
        newNodes[windowIndex].patterns,
        newNodes[windowIndex].examples,
        action.data.connection.og
      );

      const n2 = newNodes[newNodes.length - 1].id;

      switch (groupName) {
        case 'actions': {
          switch (newNodes[windowIndex].actions[groupIndex].type) {
            case 'buttons': {
              newNodes[windowIndex].actions[groupIndex].buttons[action.data.connection.o].transition = n2;
              break;
            }
            case 'random': {
              newNodes[windowIndex].actions[groupIndex].transitions[action.data.connection.o] = n2;
              break;
            }
            case 'transition': {
              newNodes[windowIndex].actions[groupIndex].transition = n2;
              break;
            }
            case 'condition': {
              if (
                !!newNodes[windowIndex].actions[groupIndex].conditions &&
                action.data.connection.o + 1 <= newNodes[windowIndex].actions[groupIndex].conditions.length
              ) {
                newNodes[windowIndex].actions[groupIndex].conditions[action.data.connection.o].transition = n2;
              } else {
                newNodes[windowIndex].actions[groupIndex].defaultTransition = n2;
              }
              break;
            }
            default:
              break;
          }
          break;
        }
        case 'patterns': {
          newNodes[windowIndex].patterns[patternName][groupIndex].toState = n2;
          break;
        }
        case 'examples': {
          newNodes[windowIndex].examples[patternName][groupIndex].toState = n2;
          break;
        }
        default:
          break;
      }

      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          ...allConnections,
        },
      };
    }

    case WINDOW_DELETE_BUTTON: {
      let newNodes = cloneDeep(state.data.nodes);
      let newArrayNodes = cloneDeep(state.data.arrayNodes);
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });

      newNodes[windowIndex].actions[action.arrayIndex].buttons.splice(action.subIndex, 1);
      if (newNodes[windowIndex].actions[action.arrayIndex].buttons.length === 0) {
        newNodes[windowIndex].actions?.splice(action.arrayIndex, 1);
      }

      newNodes[windowIndex] = makeNewUniqueId(newNodes[windowIndex]);

      let col = getColFromName(newNodes[windowIndex].name);
      let indexInArrayNodes = newArrayNodes[col].findIndex(item => item.id === newNodes[windowIndex].id);
      newArrayNodes[col].splice(indexInArrayNodes, 1, { ...newNodes[windowIndex] });

      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
          ...allConnections,
        },
        lastState: state.data.nodes,
      };
    }
    case WINDOW_DELETE_CONDITION: {
      let newNodes = cloneDeep(state.data.nodes);
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });

      newNodes[windowIndex].actions[action.arrayIndex].conditions.splice(action.subIndex, 1);
      const allConnections = makeAllConnections(newNodes);
      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          ...allConnections,
        },
        lastState: state.data.nodes,
      };
    }
    case WINDOW_ADD_RANDOM: {
      let newNodes = [...state.data.nodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      newNodes[windowIndex].actions[action.arrayIndex].transitions.push('NewRandomValue');
      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
        },
      };
    }
    case WINDOW_DELETE_RANDOM: {
      let newNodes = [...state.data.nodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      newNodes[windowIndex].actions[action.arrayIndex].transitions.splice(action.subIndex, 1);

      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          ...allConnections,
        },
      };
    }
    case WINDOW_ADD_BUTTON: {
      let newNodes = [...state.data.nodes];
      let newArrayNodes = [...state.data.arrayNodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      if (!Boolean(newNodes[windowIndex].actions)) {
        if (newNodes[windowIndex].type !== 'screen') {
          newNodes[windowIndex].actions = [
            {
              type: 'buttons',
              buttons: [],
            },
          ];
        }
      }
      if (
        !newNodes[windowIndex].actions[action.arrayIndex] ||
        newNodes[windowIndex].actions[action.arrayIndex].type !== 'buttons'
      ) {
        let transitionIndex = newNodes[windowIndex].actions.findIndex(item => item.type === 'transition');
        if (transitionIndex > -1) {
          newNodes[windowIndex].actions.splice(transitionIndex, 0, {
            type: 'buttons',
            buttons: [],
          });
        } else {
          newNodes[windowIndex].actions.push({
            type: 'buttons',
            buttons: [],
          });
        }
      }

      if (
        newNodes[windowIndex].actions[action.arrayIndex].buttons.findIndex(button => button.name === action.text) > -1
      ) {
        newNodes[windowIndex].actions[action.arrayIndex].buttons = addNewButton(
          [...newNodes[windowIndex].actions[action.arrayIndex].buttons],
          newNodes[windowIndex].actions[action.arrayIndex].buttons.length,
          action.text
        );
      } else {
        newNodes[windowIndex].actions[action.arrayIndex].buttons.push({
          transition: '',
          name: action.text,
        });
      }
      delete newNodes[windowIndex].uniqueId;
      newNodes[windowIndex].uniqueId = md5(
        JSON.stringify({
          ...newNodes[windowIndex],
          date: new Date(),
        })
      );

      let col = getColFromName(newNodes[windowIndex].name);
      let indexInArray = newArrayNodes[col].findIndex(i => i.id === newNodes[windowIndex].id);
      newArrayNodes[col].splice(indexInArray, 1, { ...newNodes[windowIndex] });

      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
          ...allConnections,
        },
        lastState: null,
      };
    }
    case WINDOW_CHANGE_BUTTON_TEXT: {
      let newNodes = [...state.data.nodes];
      let newArrayNodes = [...state.data.arrayNodes];

      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      newNodes[windowIndex].actions[action.arrayIndex].buttons[action.subIndex] = changeButtonText(
        [...newNodes[windowIndex].actions[action.arrayIndex].buttons],
        action.subIndex,
        action.text
      );

      delete newNodes[windowIndex].uniqueId;
      newNodes[windowIndex].uniqueId = md5(
        JSON.stringify({
          ...newNodes[windowIndex],
          date: new Date(),
        })
      );

      let col = getColFromName(newNodes[windowIndex].name);
      let indexInArray = newArrayNodes[col].findIndex(i => i.id === newNodes[windowIndex].id);
      newArrayNodes[col].splice(indexInArray, 1, { ...newNodes[windowIndex] });

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
        },
        lastState: null,
      };
    }

    case SAVE_BUTTONS: {
      let newNodes = [...state.data.nodes];
      let newArrayNodes = [...state.data.arrayNodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      newNodes[windowIndex].actions[action.arrayIndex].buttons = action.buttons;

      delete newNodes[windowIndex].uniqueId;
      newNodes[windowIndex].uniqueId = md5(
        JSON.stringify({
          ...newNodes[windowIndex],
          date: new Date(),
        })
      );

      let col = getColFromName(newNodes[windowIndex].name);
      let indexInArray = newArrayNodes[col].findIndex(i => i.id === newNodes[windowIndex].id);
      newArrayNodes[col].splice(indexInArray, 1, { ...newNodes[windowIndex] });

      const allConnections = makeAllConnections(newNodes);
      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
          ...allConnections,
        },
        lastState: null,
      };
    }

    case WINDOW_CHANGE_BUTTON_JSON: {
      let newNodes = [...state.data.nodes];
      let newArrayNodes = [...state.data.arrayNodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      newNodes[windowIndex].actions[action.arrayIndex].buttons[action.subIndex].json = action.text;

      delete newNodes[windowIndex].uniqueId;
      newNodes[windowIndex].uniqueId = md5(
        JSON.stringify({
          ...newNodes[windowIndex],
          date: new Date(),
        })
      );

      let col = getColFromName(newNodes[windowIndex].name);
      let indexInArray = newArrayNodes[col].findIndex(i => i.id === newNodes[windowIndex].id);
      newArrayNodes[col].splice(indexInArray, 1, { ...newNodes[windowIndex] });

      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
          ...allConnections,
        },
        lastState: null,
      };
    }
    case WINDOW_CHANGE_BUTTON_URL: {
      let newNodes = [...state.data.nodes];
      let newArrayNodes = [...state.data.arrayNodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      if (Boolean(action.url)) {
        newNodes[windowIndex].actions[action.arrayIndex].buttons[action.subIndex].url = action.url;
      } else {
        delete newNodes[windowIndex].actions[action.arrayIndex].buttons[action.subIndex].url;
      }

      delete newNodes[windowIndex].uniqueId;
      newNodes[windowIndex].uniqueId = md5(
        JSON.stringify({
          ...newNodes[windowIndex],
          date: new Date(),
        })
      );

      let col = getColFromName(newNodes[windowIndex].name);
      let indexInArray = newArrayNodes[col].findIndex(i => i.id === newNodes[windowIndex].id);
      newArrayNodes[col].splice(indexInArray, 1, { ...newNodes[windowIndex] });

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
        },
        lastState: null,
      };
    }
    case WINDOW_CHANGE_CONDITION: {
      let newNodes = [...state.data.nodes];
      let newArrayNodes = [...state.data.arrayNodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      newNodes[windowIndex].actions[action.arrayIndex].conditions[action.subIndex].condition = action.text;

      delete newNodes[windowIndex].uniqueId;
      newNodes[windowIndex].uniqueId = md5(
        JSON.stringify({
          ...newNodes[windowIndex],
          date: new Date(),
        })
      );

      let col = getColFromName(newNodes[windowIndex].name);
      let indexInArray = newArrayNodes[col].findIndex(i => i.id === newNodes[windowIndex].id);
      newArrayNodes[col].splice(indexInArray, 1, { ...newNodes[windowIndex] });

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
        },
        lastState: null,
      };
    }

    case WINDOW_SAVE_ANSWER: {
      let newNodes = [...state.data.nodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      if (newNodes[windowIndex].actions[action.arrayIndex].type === 'answer') {
        newNodes[windowIndex].actions[action.arrayIndex].type = 'answers';
      }
      delete newNodes[windowIndex].actions[action.arrayIndex].text;
      newNodes[windowIndex].actions[action.arrayIndex].answers = action.answers;

      if (action.needTransition) {
        let itemTransitionToIndex = newNodes.findIndex(node => node.boundsTo === newNodes[windowIndex].id);
        if (itemTransitionToIndex > -1) {
          let item = newNodes[windowIndex];

          if (Boolean(item)) {
            if (isNull(item.actions)) {
              item.actions = [];
            }
            if (item.actions.findIndex(itemAction => itemAction.type === 'transition') === -1) {
              item.actions.push({
                type: 'transition',
                deferred: false,
                transition: newNodes[itemTransitionToIndex].id,
              });
            }
          }
        }
      }

      delete newNodes[windowIndex].uniqueId;
      newNodes[windowIndex].uniqueId = md5(JSON.stringify({ ...newNodes[windowIndex], date: new Date() }));

      let colNewNode = getColFromName(newNodes[windowIndex].name);
      let itemInArrayNodes = state.data.arrayNodes[colNewNode].findIndex(i => i.id === newNodes[windowIndex].id);
      let newArrayNodes = [...state.data.arrayNodes];
      newArrayNodes[colNewNode].splice(itemInArrayNodes, 1, { ...newNodes[windowIndex] });

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
        },
        lastState: null,
      };
    }

    case SAVE_SCRIPT:
    case SAVE_TIMEOUT: {
      let newNodes = [...state.data.nodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });

      newNodes[windowIndex].actions[action.arrayIndex] = {
        ...newNodes[windowIndex].actions[action.arrayIndex],
        ...action.fields,
      };

      if (action.needTransition) {
        let itemTransitionToIndex = newNodes.findIndex(node => node.boundsTo === newNodes[windowIndex].id);
        if (itemTransitionToIndex > -1) {
          let item = newNodes[windowIndex];

          if (Boolean(item)) {
            if (isNull(item.actions)) {
              item.actions = [];
            }
            if (item.actions.findIndex(itemAction => itemAction.type === 'transition') === -1) {
              item.actions.push({
                type: 'transition',
                deferred: false,
                transition: newNodes[itemTransitionToIndex].id,
              });
            }
          }
        }
      }

      delete newNodes[windowIndex].uniqueId;
      newNodes[windowIndex].uniqueId = md5(JSON.stringify({ ...newNodes[windowIndex], date: new Date() }));

      let colNewNode = getColFromName(newNodes[windowIndex].name);
      let itemInArrayNodes = state.data.arrayNodes[colNewNode].findIndex(i => i.id === newNodes[windowIndex].id);
      let newArrayNodes = [...state.data.arrayNodes];
      newArrayNodes[colNewNode].splice(itemInArrayNodes, 1, { ...newNodes[windowIndex] });

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
        },
        lastState: null,
      };
    }

    case WINDOW_ADD_SYSTEM_BUTTON: {
      let newNodes = [...state.data.nodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      newNodes[windowIndex].actions[action.arrayIndex].buttons.push({ json: action.text });
      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          ...allConnections,
        },
      };
    }
    case WINDOW_ADD_CONDITION: {
      let newNodes = [...state.data.nodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      newNodes[windowIndex].actions[action.arrayIndex].conditions.push({ condition: action.text });
      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          ...allConnections,
        },
        lastState: null,
      };
    }

    case WINDOW_SAVE_CONDITIONS: {
      let newNodes = [...state.data.nodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      newNodes[windowIndex].actions[action.arrayIndex] = action.obj;
      let buttonsIndex = newNodes[windowIndex].actions.findIndex(i => i.type === 'buttons');
      if (buttonsIndex > -1) {
        newNodes[windowIndex].actions.splice(buttonsIndex, 1);
      }
      delete newNodes[windowIndex].uniqueId;
      newNodes[windowIndex].uniqueId = md5(
        JSON.stringify({
          ...newNodes[windowIndex],
          date: new Date(),
        })
      );

      let colNewNode = getColFromName(newNodes[windowIndex].name);
      let itemInArrayNodes = state.data.arrayNodes[colNewNode].findIndex(i => i.id === newNodes[windowIndex].id);
      let newArrayNodes = [...state.data.arrayNodes];
      newArrayNodes[colNewNode].splice(itemInArrayNodes, 1, { ...newNodes[windowIndex] });

      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
          ...allConnections,
        },
        lastState: null,
      };
    }
    case WINDOW_RESORT: {
      const newNodes = [...state.data.nodes];
      const newArrayNodes = [...state.data.arrayNodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowId;
      });
      newNodes[windowIndex].actions = action.array;

      delete newNodes[windowIndex].uniqueId;
      newNodes[windowIndex].uniqueId = md5(
        JSON.stringify({
          ...newNodes[windowIndex],
          date: new Date(),
        })
      );
      let colNewNode = getColFromName(newNodes[windowIndex].name);
      let itemInArrayNodes = state.data.arrayNodes[colNewNode].findIndex(i => i.id === newNodes[windowIndex].id);
      newArrayNodes[colNewNode].splice(itemInArrayNodes, 1, { ...newNodes[windowIndex] });

      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
          ...allConnections,
        },
        lastState: null,
      };
    }
    case ADD_ACTION: {
      let newNodes = cloneDeep(state.data.nodes);
      const windowIndex = newNodes.findIndex(item => item.id === action.windowIndex);
      if (!newNodes[windowIndex]) return state;
      if (newNodes[windowIndex].type === 'dummyNode') {
        newNodes[windowIndex].type = 'screen';
      }
      if (!newNodes[windowIndex].actions) {
        newNodes[windowIndex].actions = [];
      }

      let beforeButtonsIndex = newNodes[windowIndex].actions.findIndex(item =>
        ['buttons', 'transition', 'condition', 'timeout'].includes(item.type)
      );

      switch (action.actionType) {
        case 'video': {
          if (beforeButtonsIndex > -1) {
            newNodes[windowIndex].actions.splice(beforeButtonsIndex, 0, {
              type: 'video',
              url: '',
              name: '',
              title: '',
            });
          } else {
            newNodes[windowIndex].actions.push({
              type: 'video',
              url: '',
              name: '',
              title: '',
            });
          }

          break;
        }
        case 'answer': {
          if (beforeButtonsIndex > -1) {
            newNodes[windowIndex].actions.splice(beforeButtonsIndex, 0, {
              type: 'answer',
              text: '',
            });
          } else {
            newNodes[windowIndex].actions.push({
              type: 'answer',
              text: '',
            });
          }

          break;
        }
        case 'audios': {
          if (beforeButtonsIndex > -1) {
            newNodes[windowIndex].actions.splice(beforeButtonsIndex, 0, {
              type: 'audios',
              audios: [
                /*{
                                    "type": "audio",
                                    "url": "http://audiourl.com/tratata.mp3",
                                    "name": "filename"
                                }*/
              ],
            });
          } else {
            newNodes[windowIndex].actions.push({
              type: 'audios',
              audios: [
                /*{
                                    "type": "audio",
                                    "url": "http://audiourl.com/tratata.mp3",
                                    "name": "filename"
                                }*/
              ],
            });
          }

          break;
        }
        case 'answers': {
          if (beforeButtonsIndex > -1) {
            newNodes[windowIndex].actions.splice(beforeButtonsIndex, 0, {
              type: 'answers',
              answers: [],
            });
          } else {
            newNodes[windowIndex].actions.push({
              type: 'answers',
              answers: [],
            });
          }

          break;
        }
        case 'image': {
          if (beforeButtonsIndex > -1) {
            newNodes[windowIndex].actions.splice(beforeButtonsIndex, 0, {
              type: 'image',
              url: '',
              name: null,
              description: null,
              file: null,
              wasLoaded: false,
            });
          } else {
            newNodes[windowIndex].actions.push({
              type: 'image',
              url: '',
              name: null,
              description: null,
              file: null,
              wasLoaded: false,
            });
          }

          break;
        }
        case 'timeout': {
          if (beforeButtonsIndex > -1) {
            newNodes[windowIndex].actions.splice(beforeButtonsIndex, 0, {
              type: 'timeout',
              timeValue: '',
              timeType: '',
              then: '',
            });
          } else {
            newNodes[windowIndex].actions.push({
              type: 'timeout',
              timeValue: '',
              timeType: '',
              then: '',
            });
          }
          break;
        }
        case 'buttons': {
          newNodes[windowIndex].actions.push({
            type: 'buttons',
            buttons: [
              {
                name: t('Transition'),
              },
            ],
          });
          break;
        }
        case 'transition': {
          newNodes[windowIndex].actions.push({
            type: 'transition',
            deferred: false,
          });
          break;
        }
        case 'script': {
          if (beforeButtonsIndex > -1) {
            newNodes[windowIndex].actions.splice(beforeButtonsIndex, 0, {
              type: 'script',
              script: '',
              title: '',
            });
          } else {
            newNodes[windowIndex].actions.push({
              type: 'script',
              script: '',
              title: '',
            });
          }
          break;
        }
        case 'condition': {
          if (beforeButtonsIndex > -1) {
            newNodes[windowIndex].actions.splice(beforeButtonsIndex, 0, {
              type: 'condition',
              conditions: [],
              defaultTransition: null,
              new: true,
            });
          } else {
            newNodes[windowIndex].actions.push({
              type: 'condition',
              conditions: [],
              defaultTransition: null,
              new: true,
            });
          }

          break;
        }
        case 'random': {
          newNodes[windowIndex].actions.push({
            type: 'random',
            transitions: [],
          });
          break;
        }
        default:
          break;
      }

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: makeArrayNodes(newNodes),
        },
        lastState: null,
      };
    }
    case REMOVE_ACTION: {
      let newNodes = cloneDeep(state.data.nodes);
      let newArrayNodes = cloneDeep(state.data.arrayNodes);

      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      if (!Boolean(newNodes[windowIndex])) {
        return state;
      }
      newNodes[windowIndex].actions?.splice(action.arrayIndex, 1);
      let lastState = action.rollBack ? [...state.data.nodes] : null;

      delete newNodes[windowIndex].uniqueId;
      newNodes[windowIndex].uniqueId = md5(
        JSON.stringify({
          ...newNodes[windowIndex],
          date: new Date(),
        })
      );
      let colNewNode = getColFromName(newNodes[windowIndex].name);
      let itemInArrayNodes = state.data.arrayNodes[colNewNode].findIndex(i => i.id === newNodes[windowIndex].id);
      newArrayNodes[colNewNode].splice(itemInArrayNodes, 1, { ...newNodes[windowIndex] });

      if (newNodes[windowIndex].actions.length === 0) {
        lastState = null;
        newNodes.splice(windowIndex, 1);
        newArrayNodes[colNewNode].splice(itemInArrayNodes, 1);
      }

      const allConnections = makeAllConnections(newNodes);
      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
          ...allConnections,
        },
        lastState: lastState,
      };
    }

    case CHANGE_TITLE: {
      const newArrayNodes = cloneDeep(state.data.arrayNodes);
      const newNodes = cloneDeep(state.data.nodes);
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });

      switch (action.blockType) {
        case 'screen': {
          const newActions = [...newNodes[windowIndex].actions];

          newActions[action.arrayIndex] = {
            ...newActions[action.arrayIndex],
            title: action.title,
          };

          newNodes[windowIndex].actions = newActions;

          break;
        }
        case 'IntentGroup':
        case 'InputNumber':
        case 'TransferToOperator':
        case 'InputPhone':
        case 'InputPhoneNumber':
        case 'HttpRequest':
        case 'InputText':
        case 'InputFile':
        case 'TransferCallToOperator':
        default:
          newNodes[windowIndex] = {
            ...newNodes[windowIndex],
            title: action.title,
          };
          break;
      }

      delete newNodes[windowIndex].uniqueId;
      newNodes[windowIndex] = makeNewUniqueId(newNodes[windowIndex]);

      let colNewNode = getColFromName(newNodes[windowIndex].name);
      let itemInArrayNodes = state.data.arrayNodes[colNewNode].findIndex(i => i.id === newNodes[windowIndex].id);
      newArrayNodes[colNewNode].splice(itemInArrayNodes, 1, { ...newNodes[windowIndex] });

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
        },
      };
    }

    case NEW_NODE_POSITION: {
      let newNodes = [...state.data.nodes];
      const nodeIndex = newNodes.findIndex(item => {
        return item.id === action.index;
      });
      if (nodeIndex > -1) {
        newNodes[nodeIndex].x = action.pos.x;
        newNodes[nodeIndex].y = action.pos.y;
      }
      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
        },
      };
    }

    case WINDOW_REMOVE: {
      const newNodes = cloneDeep(state.data.nodes);
      let filterNodes = newNodes.filter(item => {
        return !action.windowIdList.includes(item.id);
      });

      const removedWindows = newNodes.filter(item => action.windowIdList.includes(item.id));

      let screenNextNodeId = null;

      if (removedWindows.length === 1) {
        const removedWindow = removedWindows[0];
        const screen = state.data.arrayNodes.find(screen => screen.find(node => node.id === removedWindow.id));
        const nextNode = screen?.filter(node => node.boundsTo === removedWindow.id)[0] || null;

        screenNextNodeId = nextNode?.id || null;
      }

      let lastState = state.data.nodes;

      if ((removedWindows.length === 1 && removedWindows[0].type === 'dummyNode') || removedWindows.length < 0) {
        lastState = null;
      }
      if (!action.saveLastHistory) {
        lastState = null;
      }

      let filterNodeNeedNewUniqueId = false;
      //if action window is not "screen"
      removedWindows.forEach(actionWindow => {
        //if action window is not "screen"
        let boundsTo = '';
        if (actionWindow.type !== 'screen' && actionWindow.boundsTo !== '') {
          boundsTo = actionWindow.boundsTo;
        }

        if (Boolean(boundsTo)) {
          let filterNode = filterNodes.find(node => {
            return node.id === boundsTo;
          });

          if (Boolean(filterNode)) {
            let transitionIndex = filterNode.actions.findIndex(action => action.type === 'transition');
            if (transitionIndex > -1) {
              filterNode.actions.splice(transitionIndex, 1);
              filterNodeNeedNewUniqueId = true;
            }
            if (Boolean(actionWindow.actions)) {
              let buttons = actionWindow.actions.find(action => action.type === 'buttons');
              if (Boolean(buttons) && Boolean(buttons.buttons) && buttons.buttons.length > 0) {
                filterNode.actions.push(buttons);
                filterNodeNeedNewUniqueId = true;
              }
            }
          }

          if (filterNodeNeedNewUniqueId) {
            filterNode = makeNewUniqueId(filterNode);
          }
        }

        //if item was bounded to deleted item
        let boundsToNode = filterNodes.find(node => {
          return node.boundsTo === actionWindow.id;
        });
        if (Boolean(boundsToNode)) {
          boundsToNode = makeNewUniqueId(boundsToNode);
          boundsToNode.boundsTo = '';
        }
      });

      let itemNeedNewUniqueId = false;

      let newFilterNodes = filterNodes.map(item => {
        switch (item.type) {
          case 'TransferToOperator':
            itemNeedNewUniqueId = false;

            if (action.windowIdList.includes(item.noOperatorsOnlineState)) {
              item.noOperatorsOnlineState = screenNextNodeId;
              itemNeedNewUniqueId = true;
            }
            if (action.windowIdList.includes(item.dialogCompletedState)) {
              item.dialogCompletedState = screenNextNodeId;
              itemNeedNewUniqueId = true;
            }
            if (action.windowIdList.includes(item.boundsTo)) {
              item.boundsTo = '';
              itemNeedNewUniqueId = true;
            }
            try {
              if (item.actions[0].buttons.length > 0) {
                item.actions[0].buttons.forEach((button, key) => {
                  if (action.windowIdList.includes(button.transition)) {
                    item.actions[0].buttons[key].transition = screenNextNodeId;
                    itemNeedNewUniqueId = true;
                  }
                });
              }
            } catch (e) {}

            if (itemNeedNewUniqueId) {
              item = makeNewUniqueId(item);
            }
            return item;
          case 'IntentGroup': {
            itemNeedNewUniqueId = false;
            if (action.windowIdList.includes(item.boundsTo)) {
              item.boundsTo = '';
              itemNeedNewUniqueId = true;
            }
            item.intents.forEach(itm => {
              if (action.windowIdList.includes(itm.then)) {
                itm.then = screenNextNodeId;
                itemNeedNewUniqueId = true;
              }
            });
            if (action.windowIdList.includes(item.fallback)) {
              item.fallback = screenNextNodeId;
              itemNeedNewUniqueId = true;
            }
            try {
              if (item.actions[0].buttons.length > 0) {
                item.actions[0].buttons.forEach((button, key) => {
                  if (action.windowIdList.includes(button.transition)) {
                    itemNeedNewUniqueId = true;
                    item.actions[0].buttons[key].transition = screenNextNodeId;
                  }
                });
              }
            } catch (e) {}

            if (itemNeedNewUniqueId) {
              item = makeNewUniqueId(item);
            }

            return item;
          }
          case 'Transition':
          case 'InputNumber':
          case 'InputPhone':
          case 'InputPhoneNumber':
          case 'InputText':
          case 'InputDateTime':
            itemNeedNewUniqueId = false;

            if (action.windowIdList.includes(item.then)) {
              item.then = screenNextNodeId;
              itemNeedNewUniqueId = true;
            }
            if (action.windowIdList.includes(item.boundsTo)) {
              item.boundsTo = '';
              itemNeedNewUniqueId = true;
            }
            try {
              if (item.actions[0].buttons.length > 0) {
                item.actions[0].buttons.forEach((button, key) => {
                  if (action.windowIdList.includes(button.transition)) {
                    item.actions[0].buttons[key].transition = screenNextNodeId;
                    itemNeedNewUniqueId = true;
                  }
                });
              }
            } catch (e) {}

            if (itemNeedNewUniqueId) {
              item = makeNewUniqueId(item);
            }

            return item;
          case 'Email':
          case 'Sms':
          case 'TelegramPayment':
          case 'Timetable':
          case 'HttpRequest':
          case 'GoogleSheets':
          case 'CrmIntegration':
            itemNeedNewUniqueId = false;

            if (action.windowIdList.includes(item.then)) {
              item.then = screenNextNodeId;
              itemNeedNewUniqueId = true;
            }
            if (action.windowIdList.includes(item.okState)) {
              item.okState = screenNextNodeId;
              itemNeedNewUniqueId = true;
            }
            if (action.windowIdList.includes(item.errorState)) {
              item.errorState = screenNextNodeId;
              itemNeedNewUniqueId = true;
            }
            if (action.windowIdList.includes(item.catchAllState)) {
              item.catchAllState = screenNextNodeId;
              itemNeedNewUniqueId = true;
            }
            if (action.windowIdList.includes(item.boundsTo)) {
              item.boundsTo = screenNextNodeId;
              itemNeedNewUniqueId = true;
            }
            try {
              if (item.actions[0].buttons.length > 0) {
                item.actions[0].buttons.forEach((button, key) => {
                  if (action.windowIdList.includes(button.transition)) {
                    item.actions[0].buttons[key].transition = screenNextNodeId;
                    itemNeedNewUniqueId = true;
                  }
                });
              }
            } catch (e) {}

            if (itemNeedNewUniqueId) {
              item = makeNewUniqueId(item);
            }

            return item;
          case 'InputFile':
          case 'TransferCallToOperator':
            itemNeedNewUniqueId = false;

            if (action.windowIdList.includes(item.then)) {
              item.then = screenNextNodeId;
              itemNeedNewUniqueId = true;
            }
            if (action.windowIdList.includes(item.errorState)) {
              item.errorState = screenNextNodeId;
              itemNeedNewUniqueId = true;
            }
            if (action.windowIdList.includes(item.boundsTo)) {
              item.boundsTo = '';
              itemNeedNewUniqueId = true;
            }
            try {
              item.actions[0].buttons.forEach((button, key) => {
                if (action.windowIdList.includes(button.transition)) {
                  item.actions[0].buttons[key].transition = screenNextNodeId;
                  itemNeedNewUniqueId = true;
                }
              });
            } catch (e) {}

            if (itemNeedNewUniqueId) {
              item = makeNewUniqueId(item);
            }
            return item;

          case 'screen':
            let newItemActions = null;

            if (!!item.actions) {
              newItemActions = item.actions.map(iaction => {
                switch (iaction.type) {
                  case 'buttons': {
                    for (let i = 0; i < iaction.buttons.length; i += 1) {
                      if (action.windowIdList.includes(iaction.buttons[i].transition)) {
                        iaction.buttons[i].transition = screenNextNodeId;
                        itemNeedNewUniqueId = true;
                      }
                    }
                    break;
                  }
                  case 'random': {
                    for (let i = 0; i < iaction.transition; i += 1) {
                      if (action.windowIdList.includes(iaction.transition[i])) {
                        iaction.transition[i] = screenNextNodeId;
                        itemNeedNewUniqueId = true;
                      }
                    }
                    break;
                  }
                  case 'timeout': {
                    if (action.windowIdList.includes(iaction.then)) {
                      iaction.then = screenNextNodeId;
                      itemNeedNewUniqueId = true;
                    }
                    break;
                  }
                  case 'transition': {
                    if (action.windowIdList.includes(iaction.transition)) {
                      iaction.transition = screenNextNodeId;
                      itemNeedNewUniqueId = true;
                    }
                    break;
                  }
                  case 'condition': {
                    for (let i = 0; i < iaction.conditions.length; i += 1) {
                      if (action.windowIdList.includes(iaction.conditions[i].transition)) {
                        iaction.conditions[i].transition = screenNextNodeId;
                        itemNeedNewUniqueId = true;
                      }
                    }
                    if (action.windowIdList.includes(iaction.defaultTransition)) {
                      iaction.defaultTransition = screenNextNodeId;
                      itemNeedNewUniqueId = true;
                    }
                    break;
                  }
                  default:
                    break;
                }

                return iaction;
              });
            }

            item.actions = newItemActions;

            if (itemNeedNewUniqueId) {
              item = makeNewUniqueId(item);
            }

            return item;
          default:
            return item;
        }
      });

      if (removedWindows.length > 0 && action.flag) {
        removedWindows.forEach(actionWindow => {
          let deleteCols = getColFromName(actionWindow.name);
          //check if there is already items in column
          let colItems = newFilterNodes.filter(item => {
            return item.name === actionWindow.name;
          });
          //if there is no items need to change col for others in right
          if (colItems.length === 0) {
            newFilterNodes.forEach(item => {
              let itemCol = getColFromName(item.name);
              if (itemCol > deleteCols) {
                item.name = 'col#' + (itemCol - 1);
              }
              return item;
            });
          }
        });
      }

      let allConnections = makeAllConnections(newFilterNodes);

      let arrayNodes = makeArrayNodes(newFilterNodes);

      let newWebHook = cloneDeep(state.webHook);

      let newfulfilmentActions = [];

      if (newWebHook) {
        if (action.addingNewNode) {
          const newNode = state.data.nodes.find(node => node.type === 'dummyNode');
          if (newNode) {
            newfulfilmentActions = newWebHook.fulfillment.actions.map(whAction => {
              if (action.windowIdList.includes(whAction.state)) {
                whAction.state = newNode.id;
              }
              return whAction;
            });
          }
        } else {
          try {
            newfulfilmentActions = newWebHook.fulfillment.actions.filter(
              item => !action.windowIdList.includes(item.state)
            );
          } catch (e) {
            console.error(e);
          }
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newFilterNodes,
          arrayNodes: arrayNodes,
          ...allConnections,
        },
        webHook: {
          ...state.webHook,
          fulfillment: {
            ...(state.webHook?.fulfillment || {}),
            actions: newfulfilmentActions,
          },
        },
        lastState: lastState,
        lastStateWebhook: state.webHook,
      };
    }
    case SET_SCALE: {
      return {
        ...state,
        scale: action.scale,
        leftOffset: action.leftOffset,
        topOffset: action.topOffset,
      };
    }

    //pattern block start

    case ADD_PATTERN: {
      const newNodes = [...state.data.nodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      switch (action.patternType) {
        case 'global': {
          try {
            newNodes[windowIndex].patterns[action.patternType].push('');
          } catch (e) {
            if (!newNodes[windowIndex].patterns) {
              newNodes[windowIndex].patterns = {};
            }
            newNodes[windowIndex].patterns[action.patternType] = [];
            newNodes[windowIndex].patterns[action.patternType].push('');
          }
          break;
        }
        case 'context':
        case 'theme': {
          try {
            newNodes[windowIndex].patterns[action.patternType].push({
              pattern: '',
            });
          } catch (e) {
            if (!newNodes[windowIndex].patterns) {
              newNodes[windowIndex].patterns = {};
            }
            newNodes[windowIndex].patterns[action.patternType] = [];
            newNodes[windowIndex].patterns[action.patternType].push({
              pattern: '',
            });
          }

          break;
        }
        default:
          break;
      }

      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          ...allConnections,
        },
      };
    }
    case REMOVE_PATTERN: {
      const newNodes = [...state.data.nodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      newNodes[windowIndex].patterns[action.patternType].splice(action.arrayIndex, 1);
      if (newNodes[windowIndex].patterns[action.patternType].length === 0) {
        newNodes[windowIndex].patterns[action.patternType] = null;
      }
      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          ...allConnections,
        },
      };
    }
    case WINDOW_CHANGE_PATTERN: {
      let newNodes = [...state.data.nodes];
      const index = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      switch (action.patternType) {
        case 'global': {
          newNodes[index].patterns[action.patternType][action.arrayIndex] = action.text;
          break;
        }
        case 'context':
        case 'theme': {
          newNodes[index].patterns[action.patternType][action.arrayIndex].pattern = action.text;
          break;
        }
        default:
          break;
      }

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
        },
      };
    }

    //pattern block end

    //examples block start

    case ADD_EXAMPLE: {
      const newNodes = [...state.data.nodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      switch (action.patternType) {
        case 'global': {
          try {
            newNodes[windowIndex].examples[action.patternType].push('');
          } catch (e) {
            if (!newNodes[windowIndex].examples) {
              newNodes[windowIndex].examples = {};
            }
            newNodes[windowIndex].examples[action.patternType] = [];
            newNodes[windowIndex].examples[action.patternType].push('');
          }
          break;
        }
        case 'context':
        case 'theme': {
          try {
            newNodes[windowIndex].examples[action.patternType].push({
              pattern: '',
            });
          } catch (e) {
            if (!newNodes[windowIndex].examples) {
              newNodes[windowIndex].examples = {};
            }
            newNodes[windowIndex].examples[action.patternType] = [];
            newNodes[windowIndex].examples[action.patternType].push({
              pattern: '',
            });
          }

          break;
        }
        default:
          break;
      }

      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          ...allConnections,
        },
      };
    }
    case REMOVE_EXAMPLE: {
      const newNodes = [...state.data.nodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      newNodes[windowIndex].examples[action.patternType].splice(action.arrayIndex, 1);
      if (newNodes[windowIndex].examples[action.patternType].length === 0) {
        newNodes[windowIndex].examples[action.patternType] = null;
      }
      const allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          ...allConnections,
        },
      };
    }
    case WINDOW_CHANGE_EXAMPLE: {
      let newNodes = [...state.data.nodes];
      const index = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      switch (action.patternType) {
        case 'global': {
          newNodes[index].examples[action.patternType][action.arrayIndex] = action.text;
          break;
        }
        case 'context':
        case 'theme': {
          newNodes[index].examples[action.patternType][action.arrayIndex].pattern = action.text;
          break;
        }
        default:
          break;
      }

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
        },
      };
    }
    //examples block end
    case STOP_TEST: {
      return {
        ...state,
        testRun: false,
        testRunPending: false,
      };
    }

    case RESTORE_LAST_STATE: {
      let allConnections = makeAllConnections(state.lastState);
      let arrayNodes = makeArrayNodes(state.lastState);
      return {
        ...state,
        data: {
          ...state.data,
          nodes: state.lastState,
          arrayNodes,
          ...allConnections,
        },
        lastState: null,
        lastStateWebhook: null,
        webHook: state.lastStateWebhook || state.webHook,
      };
    }

    case RESORT_BUTTON: {
      const newArrayNodes = [...state.data.arrayNodes];
      const newNodes = [...state.data.nodes];
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });

      newNodes[windowIndex].actions[action.arrayIndex].buttons = action.array;
      const allConnections = makeAllConnections(newNodes);

      delete newNodes[windowIndex].uniqueId;
      newNodes[windowIndex] = makeNewUniqueId(newNodes[windowIndex]);

      let colNewNode = getColFromName(newNodes[windowIndex].name);
      let itemInArrayNodes = state.data.arrayNodes[colNewNode].findIndex(i => i.id === newNodes[windowIndex].id);
      newArrayNodes[colNewNode].splice(itemInArrayNodes, 1, { ...newNodes[windowIndex] });

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
          ...allConnections,
        },
      };
    }

    case SET_WEBHOOK_DATA: {
      let webhookSettings = JSON.parse(action.data);
      if (!Boolean(webhookSettings)) {
        webhookSettings = {};
      }
      if (!Boolean(webhookSettings?.fulfillment)) {
        webhookSettings = {
          ...webhookSettings,
          fulfillment: {
            settings: {
              default: {
                url: '',
              },
              channels: [],
            },
            actions: [],
          },
        };
      }
      return {
        ...state,
        webHook: webhookSettings,
      };
    }

    case SAVE_WEBHOOK_URL: {
      let newWebhook = { ...state.webHook };
      newWebhook.fulfillment.settings.default.url = action.url;

      return {
        ...state,
        webHook: newWebhook,
      };
    }

    case SAVE_WEBHOOK_SCREEN_ACTION: {
      let newWebhook = cloneDeep(state.webHook);
      let index = newWebhook.fulfillment.actions.findIndex(item => item.state === action.nodeId);

      if (index > -1) {
        if (action.action.length === 0) {
          newWebhook.fulfillment.actions.splice(index, 1);
        } else {
          newWebhook.fulfillment.actions[index] = {
            ...newWebhook.fulfillment.actions[index],
            action: action.action,
          };
        }
      } else {
        if (action.action.length > 0) {
          newWebhook.fulfillment.actions.push({
            state: action.nodeId,
            action: action.action,
          });
        }
      }

      return {
        ...state,
        webHook: newWebhook,
      };
    }

    case SAVE_WEBHOOK_COLOR: {
      let newWebhook = cloneDeep(state.webHook);
      let index = newWebhook.fulfillment.actions.findIndex(item => item.state === action.nodeId);

      if (index > -1 && newWebhook.fulfillment.actions[index]) {
        newWebhook.fulfillment.actions[index] = {
          ...newWebhook.fulfillment.actions[index],
          color: action.color,
        };
      }

      return {
        ...state,
        webHook: newWebhook,
      };
    }

    case TOGGLE_WEBHOOK_SCREEN_ACTION: {
      let newWebhook = cloneDeep(state.webHook);
      let index = newWebhook.fulfillment.actions.find(item => item.state === action.nodeId);
      if (Boolean(index) && index.action.length !== 0) {
        index.enabled = !index.enabled;
      }

      return {
        ...state,
        webHook: newWebhook,
      };
    }

    case SET_BLOCK_BETWEEN_COLS: {
      /*whatMoveId,
             newCol,*/
      let itemsToMove = state.data.nodes.filter(
        node => node.id === action.whatMoveId || node.boundsTo === action.whatMoveId
      );

      itemsToMove = itemsToMove.map(item => {
        item.name = 'col#' + action.newCol;
        return item;
      });

      let notActionNodes = state.data.nodes.filter(
        node => node.id !== action.whatMoveId && node.boundsTo !== action.whatMoveId
      );

      let nodes = notActionNodes.map(item => {
        let col = parseInt(item.name.split('#')[1], 10);
        if (col >= action.newCol) {
          item.name = 'col#' + (col + 1);
        }
        return item;
      });

      let indexToInsert = nodes.findIndex(item => getColFromName(item.name) === action.newCol + 1);
      if (indexToInsert === -1) {
        indexToInsert = nodes.length;
      }
      if (itemsToMove[0].type !== 'screen' && itemsToMove.length > 1) {
        itemsToMove = itemsToMove.reverse();
      }

      itemsToMove.forEach((item, key) => {
        nodes.splice(indexToInsert + key, 0, item);
      });

      nodes = colsResolver(nodes);
      let arrayNodes = makeArrayNodes(nodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: nodes,
          arrayNodes: arrayNodes,
        },
      };
    }

    case SET_AFTER_BLOCK_IN_COLS: {
      let itemsToMove = state.data.nodes.filter(
        node => node.id === action.whatMoveId || node.boundsTo === action.whatMoveId
      );

      let newCol = getColFromName(state.data.nodes.find(item => item.id === action.nodeIdAfter).name);

      itemsToMove = itemsToMove.map(item => {
        item.name = 'col#' + newCol;
        return item;
      });

      let notActionNodes = state.data.nodes.filter(
        node => node.id !== action.whatMoveId && node.boundsTo !== action.whatMoveId
      );

      let insertIndex = notActionNodes.findIndex(item => item.id === action.nodeIdAfter);

      if (itemsToMove[0].type !== 'screen' && itemsToMove.length > 1) {
        itemsToMove = itemsToMove.reverse();
      }

      itemsToMove.forEach((item, key) => {
        notActionNodes.splice(insertIndex + key + 1, 0, item);
      });

      notActionNodes = colsResolver(notActionNodes);
      let arrayNodes = makeArrayNodes(notActionNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: notActionNodes,
          arrayNodes,
        },
      };
    }

    case MOVE_TO_THE_TOP_OF_COL: {
      let itemsToMove = state.data.nodes.filter(
        node => node.id === action.whatMoveId || node.boundsTo === action.whatMoveId
      );

      itemsToMove = itemsToMove.map(item => {
        item.name = state.data.nodes[action.nodeIndexBefore].name;
        return item;
      });
      if (itemsToMove[0].type !== 'screen' && itemsToMove.length > 1) {
        itemsToMove = itemsToMove.reverse();
      }

      let notActionNodes = state.data.nodes.filter(
        node => node.id !== action.whatMoveId && node.boundsTo !== action.whatMoveId
      );

      itemsToMove.forEach((item, key) => {
        notActionNodes.splice(action.nodeIndexBefore + key, 0, item);
      });

      notActionNodes = colsResolver(notActionNodes);
      let arrayNodes = makeArrayNodes(notActionNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: notActionNodes,
          arrayNodes,
        },
      };
    }

    case CUT_BLOCK:
    case COPY_BLOCK: {
      let copiedBlock = cloneDeep(action.copiedBlock);

      switch (copiedBlock?.type) {
        case 'timeout': {
          copiedBlock.then = '';
          break;
        }
        case 'condition': {
          copiedBlock.conditions = copiedBlock.conditions.map(condition => {
            condition.transition = null;
            return condition;
          });
          copiedBlock.defaultTransition = null;
          break;
        }
        case 'IntentGroup': {
          copiedBlock.boundsTo = '';
          copiedBlock.fallback = '';
          copiedBlock.name = 'col#';
          if (copiedBlock.actions && copiedBlock.actions.length > 0) {
            copiedBlock.actions[0].buttons = [];
          }

          copiedBlock.intents = copiedBlock.intents.map(i => {
            i.then = '';
            return i;
          });
          break;
        }
        case 'InputText': {
          copiedBlock.boundsTo = '';
          copiedBlock.name = 'col#';
          if (copiedBlock.actions && copiedBlock.actions.length > 0) {
            copiedBlock.actions[0].buttons = [];
          }

          copiedBlock.then = '';

          break;
        }
        case 'InputNumber': {
          copiedBlock.boundsTo = '';
          copiedBlock.name = 'col#';
          if (copiedBlock.actions && copiedBlock.actions.length > 0) {
            copiedBlock.actions[0].buttons = [];
          }

          copiedBlock.then = '';

          break;
        }

        case 'InputFile': {
          copiedBlock.boundsTo = '';
          copiedBlock.name = 'col#';
          copiedBlock.then = '';
          copiedBlock.errorState = '';
          delete copiedBlock.actions;
          break;
        }
        case 'HttpRequest': {
          copiedBlock.boundsTo = '';
          copiedBlock.name = 'col#';
          copiedBlock.then = '';
          copiedBlock.okState = '';
          copiedBlock.errorState = '';
          delete copiedBlock.actions;
          break;
        }
        case 'Email': {
          copiedBlock.boundsTo = '';
          copiedBlock.name = 'col#';
          copiedBlock.okState = '';
          copiedBlock.errorState = '';
          delete copiedBlock.actions;
          break;
        }
        case 'TelegramPayment':
          copiedBlock.catchAllState = '';
        case 'Timetable': {
          delete copiedBlock.actions;
        }
        case 'Sms':
        case 'GoogleSheets':
        case 'CrmIntegration': {
          copiedBlock.boundsTo = '';
          copiedBlock.name = 'col#';
          copiedBlock.then = '';
          copiedBlock.okState = '';
          copiedBlock.errorState = '';
          break;
        }
        case 'TransferToOperator': {
          copiedBlock.boundsTo = '';
          copiedBlock.name = 'col#';
          copiedBlock.then = '';

          copiedBlock.noOperatorsOnlineState = '';
          copiedBlock.dialogCompletedState = '';

          break;
        }
        case 'InputPhoneNumber': {
          copiedBlock.boundsTo = '';
          copiedBlock.name = 'col#';
          if (copiedBlock.actions && copiedBlock.actions.length > 0) {
            copiedBlock.actions[0].buttons = [];
          }

          copiedBlock.then = null;

          break;
        }
        case 'InputDateTime': {
          copiedBlock.boundsTo = '';
          copiedBlock.name = 'col#';
          copiedBlock.then = null;
          break;
        }
        case 'Transition': {
          copiedBlock.boundsTo = '';
          copiedBlock.name = 'col#';
          copiedBlock.then = null;

          break;
        }
        case 'EndSession': {
          copiedBlock.boundsTo = '';
          copiedBlock.name = 'col#';

          break;
        }
        case 'TransferCallToOperator': {
          copiedBlock.boundsTo = '';
          copiedBlock.name = 'col#';
          copiedBlock.then = '';
          copiedBlock.errorState = '';

          break;
        }
      }

      if (action.type === CUT_BLOCK && action.copiedBlockId) {
        let STATE = { ...state };
        let cutted = state.data.nodes.find(i => i.id === action.copiedBlockId);
        if (!cutted) return state;
        let clearedActions =
          cutted.type === 'screen'
            ? cutted.actions.filter(act => act.type !== 'buttons' && act.type !== 'transition')
            : null;
        if (clearedActions && clearedActions.filter((act, index) => index !== action.arrayIndex).length > 0) {
          /*
                    item.type === 'buttons'
                    || item.type === 'transition'
                    || item.type === 'condition'


                    type: REMOVE_ACTION,
                        windowIndex,
                        arrayIndex,
                        groupIndex,
                        rollBack*/
          STATE = visualEditorReducer(state, {
            type: REMOVE_ACTION,
            windowIndex: action.copiedBlockId,
            arrayIndex: action.arrayIndex,
          });
        } else {
          STATE = visualEditorReducer(state, {
            type: WINDOW_REMOVE,
            windowIdList: [action.copiedBlockId],
            flag: true,
            saveLastHistory: false,
          });
        }

        let allConnections = makeAllConnections(STATE.data.nodes);

        return {
          ...STATE,
          copiedBlock: copiedBlock,
          copiedBlockId: action.copiedBlockId,
          lastState: action.type === CUT_BLOCK ? state.data.nodes : null,
          data: {
            ...STATE.data,
            ...allConnections,
          },
        };
      }

      return {
        ...state,
        copiedBlock: copiedBlock,
        copiedBlockId: action.copiedBlockId,
        lastState: null,
      };
    }

    case PUT_BLOCK: {
      // ADD ACTION

      let newNodes = cloneDeep(state.data.nodes);
      const windowIndex = newNodes.findIndex(item => {
        return item.id === action.windowIndex;
      });
      if (newNodes[windowIndex].type === 'dummyNode') {
        newNodes[windowIndex].type = 'screen';
      }

      if (
        !newNodes[windowIndex].actions &&
        ['answer', 'answers', 'audios', 'image', 'video', 'script'].includes(newNodes[windowIndex].type)
      ) {
        newNodes[windowIndex].actions = [];
      }

      let beforeButtonsIndex = newNodes[windowIndex].actions
        ? newNodes[windowIndex].actions.findIndex(
            item =>
              item.type === 'buttons' ||
              item.type === 'transition' ||
              item.type === 'condition' ||
              item.type === 'timeout'
          )
        : -1;

      let copiedBlock = cloneDeep(state.copiedBlock);
      let newArrayNodes = [...state.data.arrayNodes];

      let wasReplaced = false;

      switch (state.copiedBlock.type) {
        case 'condition':
        case 'timeout': {
          if (newNodes[windowIndex].type === 'screen') {
            newNodes[windowIndex].actions = newNodes[windowIndex].actions.filter(a => a.type !== 'buttons');
            if (beforeButtonsIndex > -1) {
              newNodes[windowIndex].actions.splice(beforeButtonsIndex, 0, { ...copiedBlock });
            } else {
              newNodes[windowIndex].actions.push({ ...copiedBlock });
            }
          } else {
            const { allNodesArray, allNodesArrayByColumn } = createBlockOnPutBeforeMeta(
              windowIndex,
              copiedBlock,
              newNodes[windowIndex],
              newNodes,
              newArrayNodes
            );
            newNodes = allNodesArray;
            newArrayNodes = allNodesArrayByColumn;
          }
          break;
        }
        case 'answer': {
          if (newNodes[windowIndex].type === 'screen') {
            if (beforeButtonsIndex > -1) {
              newNodes[windowIndex].actions.splice(beforeButtonsIndex, 0, {
                type: 'answers',
                answers: [
                  {
                    type: 'answer',
                    text: copiedBlock.text,
                    html: copiedBlock.html,
                    htmlEnabled: copiedBlock.htmlEnabled,
                  },
                ],
              });
            } else {
              newNodes[windowIndex].actions.push({
                type: 'answers',
                answers: [
                  {
                    type: 'answer',
                    text: copiedBlock.text,
                    html: copiedBlock.html,
                    htmlEnabled: copiedBlock.htmlEnabled,
                  },
                ],
              });
            }
          } else {
            /**
             * newNodes[windowIndex] - не типа screen
             */
            const { allNodesArray, allNodesArrayByColumn } = createBlockOnPutBeforeMeta(
              windowIndex,
              copiedBlock,
              newNodes[windowIndex],
              newNodes,
              newArrayNodes
            );
            newNodes = allNodesArray;
            newArrayNodes = allNodesArrayByColumn;
          }

          break;
        }
        case 'answers': {
          if (newNodes[windowIndex].type === 'screen') {
            if (beforeButtonsIndex > -1) {
              newNodes[windowIndex].actions.splice(beforeButtonsIndex, 0, {
                type: 'answers',
                answers: copiedBlock.answers,
                html: copiedBlock.html,
                htmlEnabled: copiedBlock.htmlEnabled,
              });
            } else {
              newNodes[windowIndex].actions.push({
                type: 'answers',
                answers: copiedBlock.answers,
                html: copiedBlock.html,
                htmlEnabled: copiedBlock.htmlEnabled,
              });
            }
          } else {
            const { allNodesArray, allNodesArrayByColumn } = createBlockOnPutBeforeMeta(
              windowIndex,
              copiedBlock,
              newNodes[windowIndex],
              newNodes,
              newArrayNodes
            );
            newNodes = allNodesArray;
            newArrayNodes = allNodesArrayByColumn;
          }

          break;
        }
        case 'audios': {
          if (newNodes[windowIndex].type === 'screen') {
            if (beforeButtonsIndex > -1) {
              newNodes[windowIndex].actions.splice(beforeButtonsIndex, 0, {
                type: 'audios',
                audios: copiedBlock.audios,
              });
            } else {
              newNodes[windowIndex].actions.push({
                type: 'audios',
                audios: copiedBlock.audios,
              });
            }
          } else {
            const { allNodesArray, allNodesArrayByColumn } = createBlockOnPutBeforeMeta(
              windowIndex,
              copiedBlock,
              newNodes[windowIndex],
              newNodes,
              newArrayNodes
            );
            newNodes = allNodesArray;
            newArrayNodes = allNodesArrayByColumn;
          }

          break;
        }
        case 'image': {
          if (newNodes[windowIndex].type === 'screen') {
            if (beforeButtonsIndex > -1) {
              newNodes[windowIndex].actions.splice(beforeButtonsIndex, 0, {
                type: 'image',
                url: copiedBlock.url,
                file: null,
                wasLoaded: true,
              });
            } else {
              newNodes[windowIndex].actions.push({
                type: 'image',
                url: copiedBlock.url,
                file: null,
                wasLoaded: true,
              });
            }
          } else {
            const { allNodesArray, allNodesArrayByColumn } = createBlockOnPutBeforeMeta(
              windowIndex,
              copiedBlock,
              newNodes[windowIndex],
              newNodes,
              newArrayNodes
            );
            newNodes = allNodesArray;
            newArrayNodes = allNodesArrayByColumn;
          }

          break;
        }
        case 'video': {
          if (newNodes[windowIndex].type === 'screen') {
            if (beforeButtonsIndex > -1) {
              newNodes[windowIndex].actions.splice(beforeButtonsIndex, 0, {
                type: 'video',
                url: copiedBlock.url,
                name: copiedBlock.name,
                title: copiedBlock.title,
              });
            } else {
              newNodes[windowIndex].actions.push({
                type: 'video',
                url: copiedBlock.url,
                name: copiedBlock.name,
                title: copiedBlock.title,
              });
            }
          } else {
            const { allNodesArray, allNodesArrayByColumn } = createBlockOnPutBeforeMeta(
              windowIndex,
              copiedBlock,
              newNodes[windowIndex],
              newNodes,
              newArrayNodes
            );
            newNodes = allNodesArray;
            newArrayNodes = allNodesArrayByColumn;
          }

          break;
        }
        case 'script': {
          if (newNodes[windowIndex].type === 'screen') {
            if (beforeButtonsIndex > -1) {
              newNodes[windowIndex].actions.splice(beforeButtonsIndex, 0, {
                type: 'script',
                script: copiedBlock.script,
                title: copiedBlock.title,
              });
            } else {
              newNodes[windowIndex].actions.push({
                type: 'script',
                script: copiedBlock.script,
                title: copiedBlock.title,
              });
            }
          } else {
            const { allNodesArray, allNodesArrayByColumn } = createBlockOnPutBeforeMeta(
              windowIndex,
              copiedBlock,
              newNodes[windowIndex],
              newNodes,
              newArrayNodes
            );
            newNodes = allNodesArray;
            newArrayNodes = allNodesArrayByColumn;
          }

          break;
        }
        default: {
          copiedBlock.boundsTo = action.windowIndex;
          copiedBlock.name += getColFromName(newNodes[windowIndex].name);
          copiedBlock.id = getNewNodeID(newNodes, newNodes.length);

          if (beforeButtonsIndex > -1) {
            let buttons = newNodes[windowIndex].actions.filter(a => a.type === 'buttons');
            if (buttons && copiedBlock.actions) {
              copiedBlock.actions = buttons;
            }

            newNodes[windowIndex].actions = newNodes[windowIndex].actions.filter(
              a => a.type !== 'buttons' && a.type !== 'transition'
            );
          }
          newNodes[windowIndex].actions.push({
            type: 'transition',
            deferred: false,
            transition: copiedBlock.id,
          });

          delete copiedBlock.uniqueId;
          copiedBlock = makeNewUniqueId(copiedBlock);

          newNodes.splice(windowIndex + 1, 0, copiedBlock);
          break;
        }
      }

      // SAVE BLOCK

      if (!wasReplaced) {
        delete newNodes[windowIndex].uniqueId;
        newNodes[windowIndex].uniqueId = md5(JSON.stringify({ ...newNodes[windowIndex], date: new Date() }));
        let colNewNode = getColFromName(newNodes[windowIndex].name);
        let itemInArrayNodes = state.data.arrayNodes[colNewNode].findIndex(i => i.id === newNodes[windowIndex].id);

        newArrayNodes[colNewNode].splice(itemInArrayNodes, 1, { ...newNodes[windowIndex] });

        if (
          action.blockType &&
          !['answer', 'answers', 'audios', 'image', 'video', 'script'].includes(action.blockType)
        ) {
          newArrayNodes[colNewNode].splice(itemInArrayNodes + 1, 0, copiedBlock);
        }
      }

      let allConnections = makeAllConnections(newNodes);

      return {
        ...state,
        data: {
          ...state.data,
          nodes: newNodes,
          arrayNodes: newArrayNodes,
          ...allConnections,
        },
        lastState: null,
      };
    }

    case APPLY_AUTOSAVE:
      return {
        ...state,
        ...action.data,
      };

    default: {
      return {
        ...state,
      };
    }
  }
}
